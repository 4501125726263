import React from "react";
import PropTypes from "prop-types";
import "./PeformanceDisplay.css";
import i18n from "i18next";

class PeformanceDisplay extends React.Component {
    constructor(props) {
        super(props);
        let animationSpeed = 10;
        if (this.props.performance) {
            animationSpeed =
                (this.props.performance.athletes + this.props.performance.city).length / 4;
        }

        this.state = {
            animationSpeed: animationSpeed,
        };
    }

    componentDidMount() {
        this.setState({
            intervalForToggle: setInterval(() => this.props.onPerformanceScoresAreShown(), 20000),
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalForToggle);
    }

    render() {
        return (
            <div>
                {this.props.performance && (
                    <div>
                        <div className="athlete athlete2">
                            <h3
                                style={{
                                    animation:
                                        "running-text " +
                                        this.state.animationSpeed +
                                        "s linear infinite",
                                    "-moz-animation":
                                        "running-text " +
                                        this.state.animationSpeed +
                                        "s linear infinite",
                                    "-webkit-animation":
                                        "running-text " +
                                        this.state.animationSpeed +
                                        "s linear infinite",
                                }}
                            >
                                {this.props.performance.athletes}, {this.props.performance.city}.
                            </h3>
                        </div>
                        <div className="scores">
                            <div className="score-item score-0">
                                <div>E</div>
                                <div>
                                    {this.props.performance.averageExecutionScores &&
                                        this.props.performance.averageExecutionScores.toFixed(2)}
                                </div>
                            </div>
                            <div className="score-item score-1">
                                <div>A</div>
                                <div>
                                    {this.props.performance.averageArtistryScores &&
                                        this.props.performance.averageArtistryScores.toFixed(2)}
                                </div>
                            </div>
                            <div className="score-item score-0">
                                <div>D</div>
                                <div>
                                    {this.props.performance.averageComplexityScores &&
                                        this.props.performance.averageComplexityScores.toFixed(2)}
                                </div>
                            </div>
                            <div className="score-item score-1">
                                <div>DD</div>
                                <div>
                                    {this.props.performance.totalPenalty &&
                                        this.props.performance.totalPenalty.toFixed(2)}
                                </div>
                            </div>
                        </div>
                        <div className="details">
                            <div className="total-scores">
                                T:{" "}
                                {this.props.performance.totalScores &&
                                    this.props.performance.totalScores.toFixed(3)}
                            </div>
                            <div className="place">
                                {this.props.performance.place} {i18n.t("common:place")}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

PeformanceDisplay.propTypes = {
    performance: PropTypes.object,
    onPerformanceScoresAreShown: PropTypes.func,
};

export default PeformanceDisplay;
