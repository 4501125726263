import React from "react";
import PropTypes from "prop-types";
import { Popover, List, ListItem, ListItemText, Grid, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import RequestManager from "../../Modules/RequestManager";
import "./Athletes.css";

class AthletesSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            athletes: [],
            open: this.props.show || false,
        };

        this.refreshAthletesList = this.refreshAthletesList.bind(this);
        this.getAthletesList = this.getAthletesList.bind(this);
        this.athletClicked = this.athletClicked.bind(this);
        this.startTimer = this.startTimer.bind(this);
    }

    componentDidMount() {
        this.startTimer(this.props.searchParams);
    }

    componentDidUpdate(nextProps) {
        this.startTimer(nextProps.searchParams);
    }

    componentWillUnmount() {
        if (this.searchTimer) {
            clearInterval(this.searchTimer);
        }
    }

    startTimer(searchParams) {
        this.timerSearchParams = searchParams;

        if (!this.searchTimer) {
            this.searchTimer = setInterval(() => this.refreshAthletesList(), 700);
        }
    }

    refreshAthletesList() {
        clearInterval(this.searchTimer);
        this.searchTimer = null;
        RequestManager.fetch("athletesList", "post", this.timerSearchParams, false)
            .then((response) => {
                this.setState({
                    athletes: response.body.Athletes,
                    isLoading: false,
                });
            })
            .catch((err) => {
                throw err;
            });
    }

    athletClicked(e) {
        let myAthlete = this.state.athletes.find((item) => {
            if (item.athleteId === +e.currentTarget.id) {
                return true;
            }
            return false;
        });
        this.props.onSelect(myAthlete);
    }

    eventFocus(event) {
        event.stopPropagation();
        event.nativeEvent.stopImmediatePropagation();
    }

    getAthletesList() {
        return this.state.athletes.map((item) => (
            <ListItem
                button
                key={item.athleteId}
                onClick={this.athletClicked}
                id={item.athleteId}
                divider
            >
                <Grid container id={item.athleteId} justify="space-between">
                    <Grid item xs={5} id={item.athleteId}>
                        <ListItemText>
                            {item.lastName} {item.firstName}
                        </ListItemText>
                    </Grid>
                    <Grid item xs={4} id={item.athleteId}>
                        <ListItemText>{item.city}</ListItemText>
                    </Grid>
                    <Grid item xs={3} id={item.athleteId}>
                        <ListItemText>{item.dateOfBirth}</ListItemText>
                    </Grid>
                </Grid>
            </ListItem>
        ));
    }

    handleClose = (event) => {
        this.setState({
            open: false,
        });
    };

    render() {
        if (this.state.isLoading || !this.state.athletes.length) return <div></div>;

        return (
            <Popover
                open={this.state.open}
                id="popover-contained"
                anchorEl={this.props.target}
                onClose={this.handleClose}
                onFocus={this.eventFocus}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
                className="popover-root"
            >
                <List>
                    <Typography gutterBottom align="center" variant="h6">
                        {this.props.t("selectAthlete")}
                    </Typography>
                    {this.getAthletesList()}
                </List>
            </Popover>
        );
    }
}

AthletesSearch.propTypes = {
    searchParams: PropTypes.object,
    /* expected ***
        FirstName: '',
        LastName: '',
        City: '',
        GenderId: 0,
        LimitRows: 10
    */
    onSelect: PropTypes.func,
    onClose: PropTypes.func,
    containerPadding: PropTypes.number,
    placement: PropTypes.string,
    target: PropTypes.object,
    show: PropTypes.bool,
    container: PropTypes.object,
};

export default withTranslation()(AthletesSearch);
