import { Button, IconButton, Tooltip, Typography } from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import React, { FunctionComponent, MouseEventHandler, useMemo } from "react";
import Enums, { PERFORMANCE_STATUSES, REFEREE_ROLES } from "../../../../../Modules/Enums";
import { ACTIONS_BY } from "../referee-table";
import { useTranslation } from "react-i18next";

const RefereeTableScoreCell: FunctionComponent<any> = ({
    refereeRoleId,
    refereeNumber,
    performance,
    content,
    onSendSignalToJudge,
    onEditPerformance,
    activeSignalArrows,
    actionsBy,
    cellStyles,
    canChairRateAll,
    brigadeVariant,
}) => {
    const { t } = useTranslation("refereePage");

    const performanceReferee = useMemo(
        () =>
            performance.performanceReferees.find(
                (el) => el.refereeRoleId === refereeRoleId && +el.refereeNumber === refereeNumber
            ),
        [performance, refereeRoleId, refereeNumber]
    );

    const sendSignalToJudge: MouseEventHandler<HTMLButtonElement> = (e) => {
        e.stopPropagation();
        if (performanceReferee) {
            onSendSignalToJudge(performanceReferee.refereeId, e.currentTarget.value);
        }
    };

    const DefaultContent: JSX.Element = <Typography>{content}</Typography>;

    if (!performanceReferee || performance.statusId === PERFORMANCE_STATUSES.PLANNED.id) {
        return DefaultContent;
    }

    const isChairScore: boolean = [
        Enums.REFEREE_ROLES.referee.id,
        Enums.REFEREE_ROLES.lineReferee.id,
    ].includes(refereeRoleId);

    const RefereeControls = () => (
        <div className="score_table__row__item_arrows">
            <Tooltip
                title={t("sendSignalToRaiseGrade") || "Send a signal to raise the grade"}
                arrow
            >
                <div>
                    <IconButton
                        disabled={performanceReferee.refereeRoleId === REFEREE_ROLES.referee.id}
                        size="small"
                        className={
                            activeSignalArrows &&
                            activeSignalArrows.find(
                                (v, k) => k === performanceReferee.refereeId && v === "up"
                            ) &&
                            `arrow-active--up`
                        }
                        onClick={sendSignalToJudge}
                        value="up"
                    >
                        <ArrowUpward />
                    </IconButton>
                </div>
            </Tooltip>

            <Tooltip title={t("sendSignalToDowngrade") || "Send a signal to downgrade"} arrow>
                <div>
                    <IconButton
                        disabled={performanceReferee.refereeRoleId === REFEREE_ROLES.referee.id}
                        className={
                            activeSignalArrows &&
                            activeSignalArrows.find(
                                (v, k) => k === performanceReferee.refereeId && v === "down"
                            ) &&
                            `arrow-active--down`
                        }
                        size="small"
                        onClick={sendSignalToJudge}
                        value="down"
                    >
                        <ArrowDownward />
                    </IconButton>
                </div>
            </Tooltip>
        </div>
    );

    const ClickableContent: JSX.Element = (
        <>
            <Button
                className="btn btn-default"
                style={cellStyles}
                disabled={!isChairScore && !canChairRateAll}
                onClick={(e) => {
                    e.stopPropagation();
                    onEditPerformance(performance.id, performanceReferee.refereeId);
                }}
            >
                {content || "-"}
            </Button>

            <RefereeControls />
        </>
    );

    if (actionsBy === ACTIONS_BY.Referee) {
        return ClickableContent;
    }

    return DefaultContent;
};

export default RefereeTableScoreCell;
