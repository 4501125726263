import React, { ChangeEventHandler } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { FormControl, InputLabel, TextField, MenuItem, Select } from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import CompetitionsManager from "../../../Modules/CompetitionsManager";
import Helpers from "../../../Modules/Helpers";
import { useTranslation } from "react-i18next";
import { competitionApiService } from "@/api/Services";
import { Competition } from "@/interfaces";
import { FormikConfig, useFormik } from "formik";
import { useQuery } from "react-query";
import { CompetitionMiniSchema } from "../../../Schema/CompetitionSchema";
import { BrigadeVariant } from "../../../interfaces/competition";
import { SelectInputProps } from "@material-ui/core/Select/SelectInput";

interface CompetitionPopupProps {
    open: boolean;
    selectedCompetition: Competition | null;
    onUpdate(): void;
    modalCancel(): void;
}

type CompetitionMini = Pick<
    Competition,
    "name" | "mainRefereeId" | "beamerUserId" | "ownerUserId" | "brigadeVariant"
>;

const CompetitionPopup: React.FunctionComponent<CompetitionPopupProps> = ({
    open,
    selectedCompetition,
    onUpdate,
    modalCancel,
}: CompetitionPopupProps) => {
    const { t } = useTranslation("adminPage");

    const { data: managers } = useQuery(
        ["managers"],
        CompetitionsManager.getAllCompetitionManagers
    );

    const { data: referees } = useQuery(["referees"], CompetitionsManager.getReferees, {
        select: (data) => data.Referees,
    });

    const { data: beamers } = useQuery(["beamers"], CompetitionsManager.getAllBeamers);

    const onHide = () => {
        modalCancel();
    };

    const submit: FormikConfig<CompetitionMini>["onSubmit"] = async (values) => {
        try {
            if (selectedCompetition) {
                const { message } = await competitionApiService.update(
                    selectedCompetition.id,
                    values
                );
                NotificationManager.success(message);
            } else {
                const { message } = await competitionApiService.create(values);
                NotificationManager.success(message);
            }

            onHide();
            onUpdate();
        } catch (err) {
            const message = err.response?.data?.message;
            NotificationManager.error(message);
        }
    };

    const { handleSubmit, values, errors, touched, handleChange, setFieldValue } = useFormik({
        initialValues: {
            name: selectedCompetition?.name ?? "",
            mainRefereeId: selectedCompetition?.mainRefereeId ?? null,
            beamerUserId: selectedCompetition?.beamerUserId ?? null,
            ownerUserId: selectedCompetition?.ownerUserId ?? null,
            brigadeVariant: selectedCompetition?.brigadeVariant ?? BrigadeVariant.DEFAULT,
        },
        onSubmit: submit,
        validationSchema: CompetitionMiniSchema,
        enableReinitialize: true,
    });

    const handleSelect: SelectInputProps["onChange"] = (e) => {
        const { name, value } = e.target;
        setFieldValue(name ?? "", typeof value === "string" ? null : value);
    };

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>
                {selectedCompetition ? t("editCompetition") : t("newCompetition")}
            </DialogTitle>
            <form noValidate onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        type="text"
                        label={t("competitionName")}
                        required
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        helperText={touched.name ? errors.name : ""}
                        error={touched.name && Boolean(errors.name)}
                        margin="dense"
                        fullWidth
                    />

                    <FormControl fullWidth margin="dense">
                        <InputLabel shrink>{t("mainReferee")}</InputLabel>
                        <Select
                            displayEmpty
                            required
                            value={values.mainRefereeId || ""}
                            name="mainRefereeId"
                            onChange={handleSelect}
                        >
                            <MenuItem value="" key={0}>
                                {t("notAssigned")}
                            </MenuItem>
                            {referees?.map((referee) => (
                                <MenuItem value={referee.refereeId} key={referee.refereeId}>
                                    {Helpers.getFullNameForUser(referee)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="dense">
                        <InputLabel shrink>{t("beamer")}</InputLabel>
                        <Select
                            displayEmpty
                            value={values.beamerUserId || ""}
                            name="beamerUserId"
                            onChange={handleSelect}
                        >
                            <MenuItem value="" key={0}>
                                {t("notAssigned")}
                            </MenuItem>
                            {beamers?.map((beamer) => (
                                <MenuItem value={beamer.id} key={beamer.id}>
                                    {Helpers.getFullNameForUser(beamer)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="dense">
                        <InputLabel shrink>{t("competitionManager")}</InputLabel>
                        <Select
                            displayEmpty
                            value={values.ownerUserId || ""}
                            name="ownerUserId"
                            onChange={handleSelect}
                        >
                            <MenuItem value="" key={0}>
                                {t("notAssigned")}
                            </MenuItem>
                            {managers?.map((item) => {
                                return (
                                    <MenuItem value={item.id} key={item.id}>
                                        {item.lastName + " " + item.firstName}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>

                    <FormControl fullWidth margin="dense">
                        <InputLabel shrink>{t("brigadeVariant")}</InputLabel>
                        <Select
                            value={values.brigadeVariant}
                            name="brigadeVariant"
                            fullWidth
                            margin="dense"
                            onChange={handleChange}
                        >
                            <MenuItem value={BrigadeVariant.DEFAULT}>4</MenuItem>
                            <MenuItem value={BrigadeVariant.EXTENDED}>6</MenuItem>
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onHide} color="default">
                        {t("cancel")}
                    </Button>
                    <Button type="submit" color="primary" variant="contained">
                        {t("save")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default CompetitionPopup;
