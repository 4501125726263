import React from "react";
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    TextField,
    FormGroup,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
} from "@material-ui/core";
import InputMask from "react-input-mask";
import { NotificationManager } from "react-notifications";
import { WithTranslation, withTranslation } from "react-i18next";
import moment from "moment";
import { Athlete } from "@/interfaces";
import { athleteApiService } from "@/api/Services";

type Props = WithTranslation<"adminPage"> & {
    selectedAthlete?: Athlete | null;
    show: boolean;
    modalHide: () => void;
    onUpdate(): void;
};

type State = {
    athleteId: number;
    genderId: number;
    lastName: string;
    firstName: string;
    middleName: string;
    dateOfBirth: string;
    city: string;
    region: string;
    isDateOfBirthValid: boolean;
};

/** переписать на формик и новое апи создания/редактирования спортсменов */
class AthletePopup extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            athleteId: this.props.selectedAthlete ? this.props.selectedAthlete.id : 0,
            lastName: this.props.selectedAthlete ? this.props.selectedAthlete.lastName : "",
            firstName: this.props.selectedAthlete ? this.props.selectedAthlete.firstName : "",
            middleName: this.props.selectedAthlete ? this.props.selectedAthlete.middleName : "",
            genderId: this.props.selectedAthlete ? this.props.selectedAthlete.genderId : 1,
            dateOfBirth: this.props.selectedAthlete
                ? moment(this.props.selectedAthlete.dateOfBirth, "YYYY-MM-DD").format("DD.MM.YYYY")
                : "",
            city: this.props.selectedAthlete ? this.props.selectedAthlete.city : "",
            region: this.props.selectedAthlete?.region ?? "",
            isDateOfBirthValid: true,
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onShow = this.onShow.bind(this);
        this.genderChanged = this.genderChanged.bind(this);
    }

    inputChanged(event) {
        const eventTargetName = event.target.name;
        const eventTargetValue = event.target.value;
        const stateChange = {};
        stateChange[eventTargetName] = eventTargetValue;
        this.setState(stateChange);
        if (eventTargetName === "dateOfBirth") {
            const date = moment(eventTargetValue, "DD.MM.YYYY");
            this.setState({
                isDateOfBirthValid: date.isValid() || eventTargetValue.indexOf("_") !== -1,
            });
        }
    }

    async handleSubmit() {
        const { athleteId } = this.state;
        const { t, onUpdate } = this.props;
        const date = moment(this.state.dateOfBirth, "DD.MM.YYYY");

        if (!date.isValid()) {
            NotificationManager.error(t("wrongDateOfBirth"));
            return;
        }

        const data = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            middleName: this.state.middleName,
            genderId: this.state.genderId,
            dateOfBirth: this.state.dateOfBirth,
            city: this.state.city,
            region: this.state.region,
        };

        try {
            if (athleteId) {
                const { message } = await athleteApiService.update(athleteId, data);
                NotificationManager.success(message);
            } else {
                const { message } = await athleteApiService.create(data);
                NotificationManager.success(message);
            }

            this.closeModal();
            onUpdate();
        } catch (err: any) {
            const message = err.response?.data?.message;
            NotificationManager.error(message);
        }
    }

    closeModal() {
        this.props.modalHide();
    }

    onShow() {
        if (this.props.selectedAthlete) {
            this.setState({
                athleteId: this.props.selectedAthlete.id,
                lastName: this.props.selectedAthlete.lastName,
                firstName: this.props.selectedAthlete.firstName,
                middleName: this.props.selectedAthlete.middleName,
                genderId: this.props.selectedAthlete.genderId,
                dateOfBirth: moment(this.props.selectedAthlete.dateOfBirth, "YYYY-MM-DD").format(
                    "DD.MM.YYYY"
                ),
                city: this.props.selectedAthlete.city,
                region: this.props.selectedAthlete.region,
                isDateOfBirthValid: true,
            });
        } else {
            this.setState({
                athleteId: 0,
                lastName: "",
                firstName: "",
                middleName: "",
                genderId: 1,
                dateOfBirth: "",
                city: "",
                region: "",
                isDateOfBirthValid: true,
            });
        }
    }

    genderChanged(event) {
        this.setState({
            genderId: +event.target.value,
        });
    }

    render() {
        const { t, selectedAthlete } = this.props;

        return (
            <Dialog
                onEnter={this.onShow}
                open={this.props.show}
                onClose={this.closeModal}
                aria-labelledby="ModalHeader"
                fullWidth
            >
                <DialogTitle id="ModalHeader">
                    {selectedAthlete ? t("editParticipant") : t("newParticipant")}
                </DialogTitle>

                <DialogContent>
                    <form onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <TextField
                                type="text"
                                name="lastName"
                                margin="dense"
                                value={this.state.lastName}
                                onChange={this.inputChanged}
                                label={t("lastName")}
                            />
                        </FormGroup>

                        <FormGroup>
                            <TextField
                                type="text"
                                name="firstName"
                                margin="dense"
                                value={this.state.firstName}
                                onChange={this.inputChanged}
                                label={t("firstName")}
                            />
                        </FormGroup>

                        <FormGroup>
                            <TextField
                                type="text"
                                name="middleName"
                                margin="dense"
                                value={this.state.middleName}
                                onChange={this.inputChanged}
                                label={t("middleName")}
                            />
                        </FormGroup>

                        <FormGroup style={{ marginTop: "1rem" }}>
                            <FormLabel>{`${t("gender")}:`}</FormLabel>
                            <RadioGroup
                                row
                                name="gender"
                                value={this.state.genderId.toString()}
                                onChange={this.genderChanged}
                            >
                                <FormControlLabel
                                    name="gender"
                                    value="1"
                                    control={<Radio color="primary" />}
                                    label={t("maleAbbreviation")}
                                />
                                <FormControlLabel
                                    name="gender"
                                    value="2"
                                    control={<Radio color="primary" />}
                                    label={t("femaleAbbreviation")}
                                />
                            </RadioGroup>
                        </FormGroup>

                        <FormGroup>
                            <InputMask
                                value={this.state.dateOfBirth}
                                mask="99.99.9999"
                                onChange={this.inputChanged}
                            >
                                {() => (
                                    <TextField
                                        label={t("dateOfBirth")}
                                        margin="dense"
                                        placeholder="ДД.ММ.ГГГГ"
                                        name="dateOfBirth"
                                    />
                                )}
                            </InputMask>
                        </FormGroup>

                        <FormGroup>
                            <TextField
                                name="city"
                                margin="dense"
                                onChange={this.inputChanged}
                                label={t("city(region)")}
                                value={this.state.city}
                            />
                        </FormGroup>

                        <FormGroup>
                            <TextField
                                name="region"
                                margin="dense"
                                onChange={this.inputChanged}
                                label={t("region")}
                                value={this.state.region}
                            />
                        </FormGroup>

                        {/* <FormGroup controlId="coachName">
                            <TextField
                                name="coachName"
                                margin="dense"
                                onChange={this.inputChanged}
                                label={t("coachName")}
                                value={this.state.coachName}
                            />
                        </FormGroup> */}
                    </form>
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.closeModal} color="default">
                        {t("cancel")}
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary" variant="contained">
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation("adminPage")(AthletePopup);
