import React from "react";
import { useTranslation } from "react-i18next";
import { Athlete, BeamerSlideObject } from "../../Modules/Beamer/types";
import styles from "./rating-view.module.scss";

const showValueOrDash = (value): string => {
    if (typeof value === "string") return value.length ? value : "-";
    if (typeof value === "number") return value !== 0 ? value.toString() : "-";
    return "-";
};

type performanceTableRowProps = {
    performanceFromProps: BeamerSlideObject | undefined;
    place: number;
};

const getAthletesNames = (athletes: Athlete[]): string => {
    if (athletes.length === 1)
        return `${athletes[0].lastName.trimEnd()} ${athletes[0].firstName.trimEnd()}`;
    const lastNames = athletes.map((athlete) => athlete.lastName.trimEnd());
    return lastNames.join(", ");
};

const PerformanceTableRow = (props: performanceTableRowProps): JSX.Element => {
    const { performanceFromProps: performance, place } = props;
    return (
        <tr key={place}>
            <td className={styles["rank-column"]}>{place.toString()}</td>
            <td className={styles["athlete-column"]}>
                <p className={styles["atheles-names"]}>
                    {performance && performance.athletes
                        ? getAthletesNames(performance.athletes)
                        : "-"}
                </p>
            </td>
            <td className={styles["scores"]}>
                {showValueOrDash(performance?.scores?.averageExecution)}
            </td>
            <td className={styles["scores"]}>
                {showValueOrDash(performance?.scores?.averageArtistry)}
            </td>
            <td className={styles["scores"]}>
                {showValueOrDash(performance?.scores?.averageComplexity)}
            </td>
            <td className={styles["penalties-columns"]}>
                {showValueOrDash(performance?.scores?.linePenalty)}
            </td>
            <td className={styles["penalties-columns"]}>
                {showValueOrDash(performance?.scores?.complexityPenalty)}
            </td>
            <td className={styles["penalties-columns"]}>
                {showValueOrDash(performance?.scores?.brigadePenalty)}
            </td>
            <td className={styles["scores"]}>{showValueOrDash(performance?.scores?.totalScore)}</td>
        </tr>
    );
};

const toTranslate = (code: string) => {
    switch (code) {
        case "IW":
            return "Individual Woman";
        case "IM":
            return "Individual Man";
        case "MP":
            return "Mix Pair";
        case "TR":
            return "Trio";
        case "GR":
            return "Group";
        case "AD":
            return "Aero Dance";
        case "AS":
            return "Aero Step";
        default:
            return code;
    }
};

type Props = {
    performances: BeamerSlideObject[];
    places: number[];
    perfsPerSlideCount: number;
};

const RatingView = (props: Props): JSX.Element => {
    const { performances, places, perfsPerSlideCount } = props;
    const { t } = useTranslation("beamers");

    const rowsProps: performanceTableRowProps[] = [...new Array(perfsPerSlideCount)].map((_, i) => {
        return { performanceFromProps: performances[i], place: places[i] };
    });

    if (performances.length)
        return (
            <div className={styles["rating-view-main"]}>
                <div className={styles["table-header"]}>
                    <div className={styles["age-category"]}>{performances[0]?.ageCategoryCode}</div>
                    <div className={styles["world-code"]}>
                        {toTranslate(performances[0].worldCode)}
                    </div>
                </div>
                <table className={styles["rating-table"]}>
                    <thead>
                        <tr>
                            <th className={styles["rank-column"]}>RANK</th>
                            <th>ATHLETE</th>
                            <th>EXE</th>
                            <th>ART</th>
                            <th>DIF</th>
                            <th className={styles["penalties-headers"]}>LINE</th>
                            <th className={styles["penalties-headers"]}>DD</th>
                            <th className={styles["penalties-headers"]}>CHAIR</th>
                            <th>SCORE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {[...new Array(perfsPerSlideCount)].map((_, i) => {
                            return <PerformanceTableRow key={i} {...rowsProps[i]} />;
                        })}
                    </tbody>
                </table>
            </div>
        );
    else return <div className={styles["rating-view-main"]}>{t("noSlideData")}</div>;
};

export default RatingView;
