import BaseApiService from "@/api/Base";
import { TokenInterceptor } from "@/api/Http/Interceptors";
import { Settings } from "@/modules/Constants";
import { Category } from "../../../interfaces/category";

class CategoryApiService extends BaseApiService<Category> {
    constructor(config) {
        super(config);
        this.url = "/categories";
        this.http.interceptors.request.use(TokenInterceptor);
    }
}

export default new CategoryApiService({
    baseURL: Settings.MAIN_API_URL_V2,
});
