import React from "react";
import Index from "../Controls/OnlineResultsPage/index";

export default function OnlineResultsPage(props: any) {
    return (
        <section className="online-results-page">
            <Index />
        </section>
    );
}
