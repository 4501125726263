import React from "react";
import { NotificationManager } from "react-notifications";
import { withTranslation } from "react-i18next";
import { Grid, Fab, Tooltip, Toolbar, Typography, Button } from "@material-ui/core";
import NoteAddIcon from "@material-ui/icons/NoteAdd";
import GetAppIcon from "@material-ui/icons/GetApp";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import moment from "moment";
import Auth from "@/modules/Auth";
import RequestManager from "@/modules/RequestManager";
import Helpers from "@/modules/Helpers";
import MuiDataTable from "../../../Common/MuiDataTable";
import RequestPopup from "./RequestPopup";
import { withSnackbar } from "notistack";
import EntryFeeDialog from "./EntryFeeDialog";
import ImportRequestsDialog from "./ImportRequestsDialog";
import ReportsManager from "../../../../Modules/ReportsManager";
import LicenseStatus from "../../../../Views/LicenseStatus";
import "./RequestsList.css";

class PlanRequestsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            planRequests: [],
            requestPopupShown: false,
            selectedRequest: null,
            isLoading: true,
            dialogIsOpen: false,
            importDialogIsOpen: false,
            requestsCount: 0,
        };

        this.handleNewRequest = this.handleNewRequest.bind(this);
        this.modalHide = this.modalHide.bind(this);
        this.applyChanges = this.applyChanges.bind(this);
        this.requestPopupRender = this.requestPopupRender.bind(this);
    }

    componentDidMount = async () => {
        await this.updateRequests();
        await this.getRequestsCount();
    };

    getRequestsCount = async () => {
        const result = await RequestManager.fetch(
            `v2/requestsCount/${this.props.competitionId}`,
            "get"
        );
        this.setState({
            requestsCount: result.body.data.count,
        });
    };

    getAllMusic = async () => {
        await RequestManager.fetchFile(
            `v2/requestsMusicByCompetitionId/${this.props.competitionId}?planId=${this.props.selectedPlanId}`,
            "competition-music"
        );
    };

    updateRequests = async () => {
        this.setState({ isLoading: true });

        const res = await RequestManager.fetch(
            `v2/requestsByPlanId/${this.props.selectedPlanId}`,
            "get"
        );

        if (res.body.data.error) this.props.enqueueSnackbar(res.body.message, { variant: "error" });

        this.setState({
            planRequests: res.body.data.requests,
            isLoading: false,
        });
    };

    deleteRequest(requestId) {
        const { t } = this.props;
        RequestManager.fetch(`requests/${requestId}`, "delete")
            .then((response) => {
                NotificationManager.success(t("reqDeleted", { requestId: requestId }));
                this.updateRequests();
            })
            .catch((err) => {
                if (err.status && err.status === 409) {
                    NotificationManager.error(t("relatedDataError"));
                } else {
                    NotificationManager.error(t("reqDeleteError"));
                }
            });
    }

    downloadSingleMusic = async (requestId) => {
        await RequestManager.fetchFile(`v2/requestMusic/${requestId}`, `id-${requestId}`);
    };

    onEditClick = (data) => {
        this.setState({
            selectedRequestId: +data.id,
            requestPopupShown: true,
        });
    };

    onDeleteClick = (data) => {
        this.deleteRequest(data.id);
    };

    onDownloadMusic = (data) => {
        if (data.musicId) this.downloadSingleMusic(data.id);
    };

    handleNewRequest() {
        this.setState({
            selectedRequestId: null,
            requestPopupShown: true,
        });
    }

    modalHide() {
        this.setState({
            requestPopupShown: false,
        });
    }

    applyChanges() {
        this.modalHide();
        this.updateRequests();
    }

    requestPopupRender() {
        return (
            this.state.requestPopupShown && (
                <RequestPopup
                    onModalClose={this.modalHide}
                    onModalSubmit={this.applyChanges}
                    competitionRequestId={this.state.selectedRequestId}
                    competitionPlanId={this.props.selectedPlanId}
                />
            )
        );
    }

    handleImportRequestsShow = () => {
        this.setState({
            importDialogIsOpen: true,
        });
    };

    handleImportRequestsHide = () => {
        this.setState({
            importDialogIsOpen: false,
        });
    };

    handleEntryFeeShow = () => {
        this.setState({
            dialogIsOpen: true,
        });
    };

    handleEntryFeeClose = () => {
        this.setState({
            dialogIsOpen: false,
        });
    };

    handleExportRequests = () => {
        ReportsManager.getRequestsReport(this.props.competitionId);
    };

    render() {
        const { t } = this.props;
        const columns = [
            { title: "#", field: "id" },
            {
                title: t("coachName"),
                field: "author",
                render: (rowData) => rowData.author || "-",
            },
            {
                title: t("date"),
                field: "issueDate",
                type: "date",
                render: (rowData) => moment(rowData.issueDate).format("DD.MM.YYYY"),
            },
            {
                title: t("participants"),
                field: "members",
                render: (rowData) =>
                    rowData.details.map((detail) => {
                        return (
                            <div key={detail.id}>
                                {Helpers.getFullNameForAthlete(detail.athlete)}
                            </div>
                        );
                    }),
            },
            {
                title: t("yearOfBirth"),
                field: "dateOfBirth",
                render: (rowData) =>
                    rowData.details.map((detail) => {
                        const { dateOfBirth } = detail.athlete;
                        if (!dateOfBirth) return <div>-</div>;
                        return <div key={detail.id}>{moment(dateOfBirth).format("YYYY")}</div>;
                    }),
            },
            {
                title: t("athleteRank"),
                field: "rank",
                render: (rowData) =>
                    rowData.details.map((detail) => {
                        return (
                            <Typography variant="body2" key={detail.id}>
                                {detail.athlete.rank ? detail.athlete.rank : "-"}
                            </Typography>
                        );
                    }),
            },
            {
                title: t("city"),
                field: "city",
                render: (rowData) =>
                    rowData.details.map((detail) => {
                        return (
                            <Typography variant="body2" key={detail.id}>
                                {detail.athlete.city || "-"}
                            </Typography>
                        );
                    }),
            },
            {
                title: t("region"),
                field: "region",
                render: (rowData) =>
                    rowData.details.map((detail) => {
                        return (
                            <Typography variant="body2" key={detail.id}>
                                {detail.athlete.region || "-"}
                            </Typography>
                        );
                    }),
            },
            {
                title: t("license"),
                field: "license",
                sorting: false,
                render: (rowData) =>
                    rowData.details.map((detail) => (
                        <LicenseStatus key={detail.athlete.id} license={detail.athlete.license} />
                    )),
            },
            {
                title: t("musicAccompaniment"),
                sorting: false,
                field: "music",
                render: (rowData) =>
                    rowData.musicId !== null ? (
                        <Grid container className="request-music-status">
                            <CheckCircleIcon className="extended-icon-fab-margin icon-color--success" />
                            {t("yes")}
                        </Grid>
                    ) : (
                        <Grid container className="request-music-status">
                            <CancelIcon className="extended-icon-fab-margin icon-color--failure" />
                            {t("no")}
                        </Grid>
                    ),
            },
        ];

        return (
            <React.Fragment>
                <Grid container item justify="space-between" alignItems="center">
                    <Typography>
                        {t("numberOfRequestsInCategory", {
                            selectedCategory: this.props.selectedCategories,
                        })}
                        : <b>{this.state.planRequests.length}</b>
                    </Typography>
                    <div className="age-tabs-actions">
                        <Button onClick={this.handleEntryFeeShow}>{t("viewFees")}</Button>
                        <Button
                            onClick={this.getAllMusic}
                            disabled={this.state.requestsCount === 0}
                        >
                            {t("downloadAllMusic")}
                        </Button>
                        <Button onClick={this.handleImportRequestsShow}>
                            {t("importRequests")}
                        </Button>
                        <Button onClick={this.handleExportRequests}>{t("exportRequests")}</Button>
                    </div>
                </Grid>
                <Toolbar disableGutters className="add-request-fixed-bar">
                    <Tooltip arrow title={t("newRequest")}>
                        <Fab onClick={this.handleNewRequest} color="primary">
                            <NoteAddIcon />
                        </Fab>
                    </Tooltip>
                </Toolbar>
                {this.requestPopupRender()}

                <MuiDataTable
                    enableGrouping={false}
                    showToolbar={false}
                    columns={columns}
                    data={this.state.planRequests}
                    canEdit={Auth.getAuthUser().isAdmin || Auth.getAuthUser().isCompetitionManager}
                    canDelete={
                        Auth.getAuthUser().isAdmin || Auth.getAuthUser().isCompetitionManager
                    }
                    onEditClick={this.onEditClick}
                    onDeleteClick={this.onDeleteClick}
                    customActions={[
                        {
                            name: "download_music",
                            icon: () => <GetAppIcon />,
                            onClick: this.onDownloadMusic,
                            tooltip: t("downloadMusic"),
                        },
                    ]}
                    isLoading={this.state.isLoading}
                />

                <EntryFeeDialog
                    open={this.state.dialogIsOpen}
                    onClose={this.handleEntryFeeClose}
                    data={{ competitionId: this.props.competitionId }}
                />

                <ImportRequestsDialog
                    open={this.state.importDialogIsOpen}
                    onClose={this.handleImportRequestsHide}
                    competitionId={this.props.competitionId}
                />
            </React.Fragment>
        );
    }
}

export default withSnackbar(withTranslation("adminPage")(PlanRequestsList));
