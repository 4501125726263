import { Box, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import RequestManager from "../../../Modules/RequestManager";
import RequestItem from "./RequestItem";

const PlanRequestsList = ({ competitionPlanId }) => {
    const [loading, setLoading] = useState(true);
    const [requests, setRequests] = useState([] as any[]);

    useEffect(() => {
        const fetch = async () => {
            const res = await RequestManager.getRequestsByCompetitionPlanId(competitionPlanId);

            setRequests(res.body.Requests);
            setLoading(false);
        };
        fetch();
    }, [competitionPlanId]);

    if (loading) return null;

    return (
        <Grid container direction="column">
            {requests?.map((request) => {
                return (
                    <Box key={request.competitionRequestId} my={1}>
                        <RequestItem competitionRequestId={request.competitionRequestId} />
                    </Box>
                );
            })}
        </Grid>
    );
};

export default PlanRequestsList;
