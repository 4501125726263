import React, { FC } from "react";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    Paper,
    TextField,
    Typography,
} from "@material-ui/core";
import Helpers from "@/modules/Helpers";
import { TrainerRefereeSelectable } from "../../../store/StoreModules/RefereeLotteryModule";
import { Add, Delete, Sort } from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import { trainerRefereeApiService } from "@/api/Services";
import { useQuery } from "react-query";
import { RefereeListSerializer } from "../utils";
import clsx from "clsx";

import styles from "./styles.module.scss";

export type RefereeSelectHandler = (id: number, selected: boolean) => void;
export type RefereeSelectAllHandler = (_, selected: boolean) => void;
export type RefereeAddHandler = () => void;
export type RefereeDeleteHandler = (id: number) => void;
export type RefereeDeleteAllHandler = () => void;
export type RefereeAssignHandler = (id: number, referee: TrainerRefereeSelectable | null) => void;

interface RefereesProps {
    referees: TrainerRefereeSelectable[];
    onSortByCategory: () => void;
    onSelect: RefereeSelectHandler;
    onSelectAll: RefereeSelectAllHandler;
    onAdd: RefereeAddHandler;
    onDelete: RefereeDeleteHandler;
    onDeleteAll: RefereeDeleteAllHandler;
    onAssign: RefereeAssignHandler;
}

const Referees: FC<RefereesProps> = ({
    referees = [],
    onSortByCategory,
    onSelect,
    onSelectAll,
    onAdd,
    onDelete,
    onDeleteAll,
    onAssign,
}) => {
    const { data: otherReferees = [] } = useQuery(
        ["trainer-referees"],
        () => trainerRefereeApiService.getAll(),
        {
            select: (data) => RefereeListSerializer(data.result.rows),
        }
    );

    const refereesIds: number[] = referees.map((r) => r.id);

    return (
        <Box component={Paper} borderRadius={10} marginTop={1} marginBottom={1} padding={1}>
            <Box>
                <Grid container alignItems="center">
                    <Grid item xs={1}>
                        <IconButton
                            color="secondary"
                            size="small"
                            disabled={!referees.length}
                            onClick={onDeleteAll}
                        >
                            <Delete />
                        </IconButton>
                    </Grid>
                    <Grid item xs={3}>
                        <Typography>
                            <b>ФИО</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>
                            <b>Регион</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>
                            <b>Город</b>
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        className={clsx(styles["category-rf"], styles["category-item"])}
                    >
                        <Typography>
                            <b>РФ</b>
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={1}
                        className={clsx(styles["category-item"], styles["category-fig"])}
                    >
                        <Typography>
                            <b>FIG</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={1} className={styles["category-item"]}>
                        <Typography>
                            <b>№</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Checkbox
                            color="primary"
                            checked={!!referees.length && referees.every((e) => e.selected)}
                            onChange={onSelectAll}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Divider />

            <List
                subheader={
                    <Box display="flex">
                        <Typography variant="h6">Судьи</Typography>
                        <Button
                            style={{ marginLeft: 20 }}
                            color="primary"
                            size="small"
                            startIcon={<Sort />}
                            onClick={onSortByCategory}
                            disabled={!referees.length}
                        >
                            Упорядочить по категории
                        </Button>
                    </Box>
                }
            >
                {!referees.length && <Typography>Список пуст</Typography>}

                {referees.map((r, i) => {
                    return (
                        <ListItem dense disableGutters key={r.id}>
                            <Grid container alignItems="center" className="grid-item-clear">
                                <Grid item xs={1}>
                                    <IconButton
                                        color="secondary"
                                        size="small"
                                        onClick={() => onDelete(r.id)}
                                    >
                                        <Delete />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={3}>
                                    {r.id > 0 ? (
                                        <Typography>{Helpers.getFullNameForUser(r)}</Typography>
                                    ) : (
                                        <Autocomplete
                                            fullWidth
                                            options={otherReferees}
                                            getOptionLabel={(option) =>
                                                `${Helpers.getFullNameForUser(option) || ""} (${
                                                    option.categoryReferee
                                                } | ${option.categoryFig})`
                                            }
                                            value={r.id > 0 ? r : null}
                                            onChange={(_, value) => {
                                                onAssign(r.id, value);
                                            }}
                                            getOptionDisabled={(option) =>
                                                refereesIds.includes(option.id)
                                            }
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    margin="dense"
                                                    label="Введите ФИО"
                                                />
                                            )}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>{r.region}</Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <Typography>{r.city}</Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    className={clsx(styles["category-rf"], styles["category-item"])}
                                >
                                    <Typography>{r.categoryReferee}</Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={1}
                                    className={clsx(
                                        styles["category-item"],
                                        styles["category-fig"]
                                    )}
                                >
                                    <Typography>{r.categoryFig}</Typography>
                                </Grid>
                                <Grid item xs={1} className={styles["category-item"]}>
                                    <Typography>{i + 1}</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    <Checkbox
                                        color="primary"
                                        checked={r.selected}
                                        onChange={(e) => onSelect(r.id, e.target.checked)}
                                    />
                                </Grid>
                            </Grid>
                        </ListItem>
                    );
                })}

                <ListItem disableGutters>
                    <Button size="small" onClick={onAdd} startIcon={<Add />}>
                        Добавить еще
                    </Button>
                </ListItem>
            </List>
        </Box>
    );
};

export default Referees;
