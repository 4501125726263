import React from "react";
import PropTypes from "prop-types";
import { Grid, Tabs, Tab, Typography } from "@material-ui/core";
import TabPanel from "../../Common/TabPanel";
import { withRouter } from "react-router";
import RequestManager from "../../../Modules/RequestManager";
import CompetitionPlansList from "../CompetitionPlansList/CompetitionPlansList";
import RequestsList from "./Requests/RequestsList";
import SchedulesList from "../SchedulesList/SchedulesList";
import RefereeAssignmentList from "../RefereeAssignmentList/RefereeAssignmentList";
import RefereeBrigadesList from "../RefereeBrigadesList/RefereeBrigadesList";
import PerformancesList from "../PerformancesList/PerformancesList";
// import Invites from "./Invites/Invites";
import Reports from "./Reports/Reports";
import Configuration from "./Configuration/Configuration";
import AthletesTransferContainer from "../AthletesTransfer/TransferContainer";
import Auth from "../../../Modules/Auth";
import { withTranslation } from "react-i18next";

class Competition extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            competitionId: +this.props.match.params.id,
            selectedTabKey: 0,
            isLoaded: false,
        };

        this.handleSelect = this.handleSelect.bind(this);
    }

    handleSelect(event, selectedTabKey) {
        this.setState({ selectedTabKey: selectedTabKey });
    }

    componentDidMount() {
        RequestManager.fetch(`competitions/${this.state.competitionId}`, "get").then((response) => {
            this.setState({
                competition: response.body.Competition[0],
                isLoaded: true,
            });
        });
    }

    render() {
        const { t } = this.props;
        if (this.state.isLoaded === false) return <div>{t("loading")}</div>;

        const { selectedTabKey } = this.state;

        return (
            <Grid container>
                <Grid item xs={12}>
                    <Typography variant="h4" className="competition-title">
                        {this.state.competition.competitionName}
                    </Typography>
                    <Tabs
                        variant="scrollable"
                        id="competition-tabs"
                        value={selectedTabKey}
                        onChange={this.handleSelect}
                        className="sub-menu"
                    >
                        <Tab value={0} index={0} label={t("configuration")} />
                        {Auth.isUserAdmin() && (
                            <Tab value={1} index={1} label={t("competitionPlan")} />
                        )}
                        <Tab value={2} index={2} label={t("requests")}></Tab>
                        {Auth.isUserAdmin() && (
                            <Tab value={3} index={3} label={t("refereeBrigades")}></Tab>
                        )}
                        {Auth.isUserAdmin() && (
                            <Tab value={4} index={4} label={t("startProtocol")}></Tab>
                        )}
                        <Tab value={5} index={5} label={t("schedule")}></Tab>
                        <Tab value={6} index={6} label={t("sortition")}></Tab>
                        <Tab value={7} index={7} label={t("reports")}></Tab>
                        {Auth.isUserAdmin() && (
                            <Tab value={8} index={8} label={t("transferToFinal")}></Tab>
                        )}
                        {/* <Tab eventKey="Invites" title="Приглашения"></Tab> */}
                    </Tabs>
                    <Grid item xs={12} className="main-views">
                        <TabPanel value={selectedTabKey} index={0}>
                            <Configuration />
                        </TabPanel>
                        <TabPanel value={selectedTabKey} index={1}>
                            <CompetitionPlansList competitionId={this.state.competitionId} />
                        </TabPanel>
                        <TabPanel value={selectedTabKey} index={2}>
                            <RequestsList competitionId={this.state.competitionId} />
                        </TabPanel>
                        <TabPanel value={selectedTabKey} index={3}>
                            <RefereeBrigadesList competitionId={this.state.competitionId} />
                        </TabPanel>
                        <TabPanel value={selectedTabKey} index={4}>
                            <SchedulesList competitionId={this.state.competitionId} />
                        </TabPanel>
                        <TabPanel value={selectedTabKey} index={5}>
                            <RefereeAssignmentList competitionId={this.state.competitionId} />
                        </TabPanel>
                        <TabPanel value={selectedTabKey} index={6}>
                            <PerformancesList competitionId={this.state.competitionId} />
                        </TabPanel>
                        <TabPanel value={selectedTabKey} index={7}>
                            <Reports competitionId={this.state.competitionId} />
                        </TabPanel>
                        {Auth.isUserAdmin() && (
                            <TabPanel value={selectedTabKey} index={8}>
                                <AthletesTransferContainer
                                    competitionId={this.state.competitionId}
                                />
                            </TabPanel>
                        )}
                        {/* <TabPanel value="Invites" index={'Invites'}>
                        {this.state.selectedTabKey === 'Invites' && <Invites competition={this.state.competition} />}
                    </TabPanel> */}
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

Competition.propTypes = {
    history: PropTypes.object,
    competitionId: PropTypes.number,

    // Props from router
    match: PropTypes.object,
};

export default withRouter(withTranslation("adminPage")(Competition));
