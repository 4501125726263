import React from "react";
import PropTypes from "prop-types";
import DataTable from "../../Common/DataTable";
import "./ScoresTables.css";
import RefereeRoleManager from "../../../Modules/RefereeRoleManager";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import Helpers from "../../../Modules/Helpers";
import { withTranslation } from "react-i18next";

class RefereesScoresTable extends React.Component {
    getDataForDetailTable() {
        const refereeScores = this.props.refereeScores.slice(0);

        refereeScores.sort((a, b) => {
            if (+a.refereeNumber > +b.refereeNumber) {
                return 1;
            }

            return -1;
        });

        let scores = [];
        for (let item of refereeScores) {
            let scoreObj = {
                score: "-",
                penalty: "-",
            };
            if (item.lastScore !== null) {
                scoreObj.score =
                    item.lastScore.totalScore === null
                        ? null
                        : item.lastScore.totalScore.toFixed(1);
                scoreObj.penalty =
                    item.lastScore.totalPenalty === null
                        ? null
                        : item.lastScore.totalPenalty.toFixed(1);
            }
            scoreObj.refereeRoleId = item.refereeRoleId;
            scoreObj.refereeNumber = item.refereeNumber;
            scoreObj.editButton = (
                <IconButton
                    size="small"
                    href={"/Protocol/" + item.performanceRefereeId + "/" + item.refereeRoleId}
                >
                    <EditIcon />
                </IconButton>
            );

            switch (item.refereeRoleId) {
                case RefereeRoleManager.ROLES.artistryJudge.id:
                    scoreObj.title = `Артистизм (Судья ${item.refereeNumber})`;
                    scores.push(scoreObj);
                    break;
                case RefereeRoleManager.ROLES.complexityJudge.id:
                    scoreObj.title = `Сложность (Судья ${item.refereeNumber})`;
                    scores.push(scoreObj);
                    break;
                case RefereeRoleManager.ROLES.executionJudge.id:
                    scoreObj.title = `Исполнение (Судья ${item.refereeNumber})`;
                    scores.push(scoreObj);
                    break;
                default:
                    break;
            }
        }
        let totalScores = [];
        for (let i = 1; i < 4; i++) {
            let refereeAverageScore = this.props.totalScores.find(function (item) {
                return item.refereeRoleId === i;
            });
            if (refereeAverageScore) {
                totalScores.push(refereeAverageScore);
            } else {
                totalScores.push({ refereeRoleId: i, totalScore: null, totalPenalty: null });
            }
        }

        totalScores = totalScores.map(function (item) {
            const { t } = this.props;
            const role = RefereeRoleManager.getRoleById(item.refereeRoleId);
            item.score = <b>{Helpers.getNullOrFixedNumber(item.totalScore, 3)}</b>;
            item.penalty = <b>{Helpers.getNullOrFixedNumber(item.totalPenalty, 1)}</b>;
            item.title = (
                <b>
                    {`${role.criterionName} / ${role.criterionNameEn}
                        (${t("avgScore")})`}
                </b>
            );
            return item;
        });

        let rusult = [];
        rusult = rusult.concat(this.getScoresGroup(scores, totalScores, 3));
        rusult = rusult.concat(this.getScoresGroup(scores, totalScores, 1));
        rusult = rusult.concat(this.getScoresGroup(scores, totalScores, 2));

        return rusult;
    }

    getScoresGroup(scores, totalScores, refereeRoleId) {
        let resultScores = scores.filter(function (item) {
            return item.refereeRoleId === refereeRoleId;
        });

        let resultTotalScores = totalScores.filter(function (item) {
            return item.refereeRoleId === refereeRoleId;
        });

        return resultScores.concat(resultTotalScores);
    }

    render() {
        const { t } = this.props;
        const columns = [
            { title: "", fieldName: "title" },
            { title: t("score"), fieldName: "score" },
            { title: t("penalty"), fieldName: "penalty" },
            { title: "", fieldName: "editButton" },
        ];

        return (
            <div className="scores-table">
                <DataTable
                    columns={columns}
                    data={this.getDataForDetailTable()}
                    uniqueFieldOfItem="refereeNumber"
                    useFieldNameAsClassForColumn={true}
                />
            </div>
        );
    }
}

RefereesScoresTable.propTypes = {
    refereeScores: PropTypes.arrayOf(
        PropTypes.shape({
            lastScore: PropTypes.object, // last score of referee
            referee: PropTypes.referee, // referee
        })
    ),
    totalScores: PropTypes.arrayOf(
        PropTypes.shape({
            refereeRoleId: PropTypes.number,
            totalPenalty: PropTypes.number,
            totalScore: PropTypes.number,
        })
    ),
};

export default withTranslation("common")(RefereesScoresTable);
