import React from "react";
import PropTypes from "prop-types";
import PeformanceDisplay from "../Controls/PublicDisplay/PeformanceDisplay";
import CategoryDisplay from "../Controls/PublicDisplay/CategoryDisplay";
import ScheduleDisplay from "../Controls/PublicDisplay/ScheduleDisplay";
import RequestManager from "../Modules/RequestManager";
import { STAGES } from "../Modules/Enums";
import i18n from "i18next";

class PublicDisplayPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            shownPerformances: [],
            tick: 0,
        };

        this.updateLastCategory = this.updateLastCategory.bind(this);
        this.updateLastPerformance = this.updateLastPerformance.bind(this);
        this.updateSchedule = this.updateSchedule.bind(this);
        this.getCompetition = this.getCompetition.bind(this);
        this.toggleDisplay = this.toggleDisplay.bind(this);
    }

    componentDidMount() {
        this.getCompetition();
        this.updateLastPerformance().then(() => {
            this.toggleDisplay();
        });

        this.setState({
            intervalForTick: setInterval(() => this.tick(), 1000),
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalForTick);
    }

    tick() {
        this.setState({
            tick: this.state.tick + 1,
        });

        if (this.state.tick % 10 === 0) {
            this.updateLastPerformance();
        }

        if (this.state.tick % 50 === 0) {
            this.updateLastCategory();
        }
    }

    getCompetition() {
        return RequestManager.fetch(
            `competitions/${this.props.match.params.competitionId}`,
            "get"
        ).then((response) => {
            this.setState({
                competitionName: response.body.Competition[0].competitionName,
            });
        });
    }

    updateLastPerformance() {
        return RequestManager.getLastPerformanceScores(this.props.match.params.competitionId).then(
            (response) => {
                if (!response.body.lastPerformanceScores) {
                    return this.updateSchedule();
                }

                let isPerformanceChanged =
                    !this.state.lastPerformance ||
                    this.state.lastPerformance.performanceId !==
                        response.body.lastPerformanceScores.performanceId;
                this.setState(
                    {
                        lastPerformance: response.body.lastPerformanceScores,
                    },
                    () => {
                        if (isPerformanceChanged) {
                            this.updateLastCategory();
                        }
                    }
                );
            }
        );
    }

    updateLastCategory() {
        if (
            !this.state.lastPerformance ||
            !this.state.lastPerformance.competitionPlanId ||
            !this.state.lastPerformance.competitionStageId
        ) {
            return;
        }
        return RequestManager.getPerformancesByPlanIdAndStageId(
            this.state.lastPerformance.competitionPlanId,
            this.state.lastPerformance.competitionStageId
        ).then((response) => {
            this.setState({
                lastCategory: response.sort((a, b) => {
                    return b.totalPoints - a.totalPoints || a.place - b.place;
                }),
            });
        });
    }

    updateSchedule() {
        return RequestManager.getPerformancesForSchedule(
            this.props.match.params.competitionId
        ).then((response) => {
            this.setState({
                schedules: response,
                show: "ScheduleDisplay",
            });
        });
    }

    toggleDisplay() {
        if (!this.state.lastPerformance) {
            this.setState({
                show: "ScheduleDisplay",
            });
            return;
        }
        if (!this.state.shownPerformances.includes(this.state.lastPerformance.performanceId)) {
            let newShownPerformances = this.state.shownPerformances;
            newShownPerformances.push(this.state.lastPerformance.performanceId);
            this.setState({
                show: "PeformanceDisplay",
                shownPerformances: newShownPerformances,
            });
        } else {
            this.setState({
                show: "CategoryDisplay",
                competitionStageName: i18n.t(
                    `modules:${STAGES[this.state.lastPerformance.competitionStageId]}`
                ),
                performances: [...this.state.lastCategory],
            });
        }
    }

    render() {
        return (
            <div className="display">
                {this.state.show === "PeformanceDisplay" && (
                    <PeformanceDisplay
                        performance={this.state.lastPerformance}
                        onPerformanceScoresAreShown={this.toggleDisplay}
                    />
                )}
                {this.state.show === "CategoryDisplay" && (
                    <CategoryDisplay
                        performances={this.state.performances}
                        competitionName={this.state.competitionName}
                        competitionStageName={this.state.competitionStageName}
                        onAllPagesAreShown={this.toggleDisplay}
                    />
                )}
                {this.state.show === "ScheduleDisplay" && (
                    <ScheduleDisplay
                        schedules={this.state.schedules}
                        competitionName={this.state.competitionName}
                        onPageIsShown={this.toggleDisplay}
                    />
                )}
            </div>
        );
    }
}

PublicDisplayPage.propTypes = {
    match: PropTypes.object,
    location: PropTypes.object,
};

export default PublicDisplayPage;
