import {
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    Grid,
    ExpansionPanelDetails,
    FormControl,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    Button,
    ExpansionPanelActions,
    Checkbox,
    TextField,
    FormGroup,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import ReportsManager, { BunchReportParams } from "../../../../Modules/ReportsManager";
import RequestManager from "../../../../Modules/RequestManager";
import { useTranslation } from "react-i18next";

type Props = { competitionId: number; onSuccess(): void; onFailure(): void };

const BunchPerformancesReport = ({ competitionId, onSuccess, onFailure }: Props) => {
    const [state, setState] = useState({
        regionView: "region",
        plans: [] as any[],
        competitionName: "",
        place: "",
        dateFrom: "",
        dateTo: "",
        docType: "pdf",
    });
    const { t } = useTranslation("adminPage");

    useEffect(() => {
        const fetch = async () => {
            const { CompetitionPlans } = await RequestManager.getCompetitionPlans(competitionId);

            setState((prevState) => ({
                ...prevState,
                plans: CompetitionPlans.map((item) => {
                    item.qualification = false;
                    item.final = false;
                    return item;
                }),
            }));
        };

        fetch();
    }, [competitionId]);

    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    const handlePlanSelect = (e, id) => {
        const { name, checked } = e.target;
        const newCompetitionPlans = state.plans;
        const index = newCompetitionPlans.findIndex((e) => e.competitionPlanId === id);
        newCompetitionPlans[index][name] = checked;

        setState((prevState) => ({ ...prevState, plans: newCompetitionPlans }));
    };

    const download = async () => {
        const plans = state.plans.reduce((result, item) => {
            if (item.qualification) {
                result.push({
                    cp: item.competitionPlanId,
                    cs: 1,
                });
            }
            if (item.final) {
                result.push({
                    cp: item.competitionPlanId,
                    cs: 2,
                });
            }
            return result;
        }, [] as { cp: number; cs: number }[]);

        try {
            const data: BunchReportParams = {
                plans,
                regionView: state.regionView as BunchReportParams["regionView"],
                competitionName: state.competitionName,
                place: state.place,
                dateFrom: state.dateFrom,
                dateTo: state.dateTo,
                docType: state.docType as BunchReportParams["docType"],
            };
            await ReportsManager.getBunchPerformancesReport(data);
            onSuccess();
        } catch {
            onFailure();
        }
    };

    return (
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6">{`${t("resultsByCategory")}:`}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container>
                    <Grid item xs={7}>
                        <Grid container>
                            <Grid item xs={4}>
                                <b>{t("competitionPlan")}</b>
                            </Grid>
                            <Grid item xs={4}>
                                <b>{t("qualification")}</b>
                            </Grid>
                            <Grid item xs={4}>
                                <b>{t("final")}</b>
                            </Grid>

                            {state.plans?.map((item) => (
                                <CompetitionPlanItem
                                    key={item.id}
                                    item={item}
                                    onSelect={handlePlanSelect}
                                />
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={5}>
                        <FormGroup>
                            <FormControl component="fieldset" margin="dense">
                                <FormLabel component="p">{t("uploadOptions")}</FormLabel>

                                <TextField
                                    name="competitionName"
                                    value={state.competitionName}
                                    variant="outlined"
                                    label={t("competitionName")}
                                    margin="dense"
                                    onChange={handleChangeInput}
                                    multiline
                                />

                                <TextField
                                    name="place"
                                    value={state.place}
                                    label={t("place")}
                                    variant="outlined"
                                    margin="dense"
                                    onChange={handleChangeInput}
                                    multiline
                                />

                                <TextField
                                    name="dateFrom"
                                    value={state.dateFrom}
                                    label={t("dateFrom")}
                                    variant="outlined"
                                    margin="dense"
                                    onChange={handleChangeInput}
                                />
                                <TextField
                                    name="dateTo"
                                    value={state.dateTo}
                                    label={t("dateTo")}
                                    variant="outlined"
                                    margin="dense"
                                    onChange={handleChangeInput}
                                />
                            </FormControl>

                            <FormControl component="fieldset" margin="dense">
                                <FormLabel component="p">{t("viewSettings")}</FormLabel>
                                <RadioGroup
                                    name="regionView"
                                    value={state.regionView}
                                    onChange={handleChangeInput}
                                >
                                    <FormControlLabel
                                        value="region"
                                        control={<Radio color="primary" size="small" />}
                                        label={t("region")}
                                    />
                                    <FormControlLabel
                                        value="city"
                                        control={<Radio color="primary" size="small" />}
                                        label={t("city")}
                                    />
                                </RadioGroup>
                            </FormControl>

                            <FormControl component="fieldset" margin="dense">
                                <FormLabel component="p">{t("documentType")}</FormLabel>
                                <RadioGroup
                                    name="docType"
                                    value={state.docType}
                                    onChange={handleChangeInput}
                                >
                                    <FormControlLabel
                                        value="pdf"
                                        control={<Radio color="primary" size="small" />}
                                        label="pdf"
                                    />
                                    <FormControlLabel
                                        value="xlsx"
                                        control={<Radio color="primary" size="small" />}
                                        label="xlsx"
                                    />
                                </RadioGroup>
                            </FormControl>
                        </FormGroup>
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
            <ExpansionPanelActions>
                <Button onClick={download} color="primary" variant="contained">
                    {t("download")}
                </Button>
            </ExpansionPanelActions>
        </ExpansionPanel>
    );
};

const CompetitionPlanItem = ({ item, onSelect }) => {
    return (
        <Grid container alignItems="center">
            <Grid item xs={4}>
                {item.competitionPlanWorldName}
            </Grid>
            <Grid item xs={4}>
                {item.hasQualification && (
                    <Checkbox
                        name="qualification"
                        color="primary"
                        checked={item.qualification}
                        onChange={(e) => onSelect(e, item.competitionPlanId)}
                        data-cp-id={item.competitionPlanId}
                        size="small"
                    />
                )}
            </Grid>
            <Grid item xs={4}>
                {item.hasFinal && (
                    <Checkbox
                        name="final"
                        color="primary"
                        checked={item.final}
                        onChange={(e) => onSelect(e, item.competitionPlanId)}
                        data-cp-id={item.competitionPlanId}
                        size="small"
                    />
                )}
            </Grid>
        </Grid>
    );
};

export default BunchPerformancesReport;
