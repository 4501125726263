import React from "react";
import {
    Button,
    Typography,
    MenuItem,
    Grid,
    InputLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    Box,
    TextField,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import DatePicker from "../../Common/InputControls/DatePicker";
import RequestManager from "../../../Modules/RequestManager";
import { useSnackbar } from "notistack";
import { subjects } from "../../../Modules/russia-subjects";
import { countries } from "../../../Modules/Countries";
import { Formik } from "formik";
import { getAthleteSchema } from "../../../Schema/AthleteSchema";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import "../../../Css/TrainerPage.css";

const ATHLETE_RANKS = ["3 взрослый", "2 взрослый", "1 взрослый", "КМС", "МС", "МСМК", "ЗМС"];

const AthleteForm = ({ data, onSubmit, onCancelEdit }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("trainerPage");

    const initialData = {
        id: data.athlete?.id ?? null,
        firstName: data.athlete?.firstName ?? "",
        middleName: data.athlete?.middleName ?? "",
        lastName: data.athlete?.lastName ?? "",
        genderId: data.athlete?.genderId ?? 1,
        dateOfBirth: data.athlete?.dateOfBirth ?? "",
        city: data.athlete?.city ?? "",
        region: data.athlete?.region ?? "",
        rank: data.athlete?.rank ?? null,
        trainerId: data.trainerId,
        country: data.athlete?.country ?? "",
        club: data.athlete?.club ?? "",
    };

    const type = data.athlete ? "put" : "post";
    const title = data.athlete ? t("athleteForm.athleteChange") : t("athleteForm.athleteAdd");

    const handleSubmit = async (values) => {
        const params = type === "put" ? `/${values.id}` : "";

        try {
            const { body } = await RequestManager.fetch(
                `trainer/athletes${params}`,
                type,
                {
                    athlete: values,
                },
                false
            );

            if (body.status == "Success") {
                enqueueSnackbar(body.message, {
                    variant: "success",
                });
                await onSubmit();
            } else {
                enqueueSnackbar(body.message, {
                    variant: "error",
                });
            }
        } catch (error: any) {
            enqueueSnackbar(error.message, { variant: "error" });
        }
    };

    return (
        <div className="athlete-form">
            <Typography variant="h6">{title}</Typography>
            <Formik
                validationSchema={getAthleteSchema}
                initialValues={initialData}
                enableReinitialize
                onSubmit={async (values, { resetForm }) => {
                    await handleSubmit(values);
                    resetForm();
                }}
            >
                {({
                    values,
                    handleChange,
                    errors,
                    setFieldValue,
                    submitForm,
                    touched,
                    resetForm,
                }) => {
                    return (
                        <>
                            <Grid container>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        type="text"
                                        name="lastName"
                                        label={t("athleteForm.surname")}
                                        fullWidth
                                        margin="dense"
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            const val =
                                                value.charAt(0).toUpperCase() + value.slice(1);

                                            setFieldValue("lastName", val.trimLeft().trimRight());
                                        }}
                                        value={values.lastName || ""}
                                        error={touched.lastName && !!errors.lastName}
                                        helperText={touched.lastName ? errors.lastName : ""}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        type="text"
                                        name="firstName"
                                        label={t("athleteForm.firstName")}
                                        fullWidth
                                        margin="dense"
                                        onChange={(e) => {
                                            const { value } = e.target;
                                            const val =
                                                value.charAt(0).toUpperCase() + value.slice(1);

                                            setFieldValue("firstName", val.trimLeft().trimRight());
                                        }}
                                        value={values.firstName || ""}
                                        error={touched.firstName && !!errors.firstName}
                                        helperText={touched.firstName ? errors.firstName : ""}
                                    />
                                </Grid>
                                {i18n.language === "ru" && (
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            type="text"
                                            name="middleName"
                                            label={t("athleteForm.middleName")}
                                            fullWidth
                                            margin="dense"
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                const val =
                                                    value.charAt(0).toUpperCase() + value.slice(1);

                                                setFieldValue(
                                                    "middleName",
                                                    val.trimLeft().trimRight()
                                                );
                                            }}
                                            value={values.middleName || ""}
                                            error={touched.middleName && !!errors.middleName}
                                            helperText={touched.middleName ? errors.middleName : ""}
                                        />
                                    </Grid>
                                )}
                            </Grid>

                            <Grid container>
                                <Grid item xs={6}>
                                    <InputLabel shrink>{t("athleteForm.gender")}</InputLabel>
                                    <RadioGroup
                                        onChange={handleChange}
                                        row
                                        value={+values.genderId || ""}
                                        name="genderId"
                                    >
                                        <FormControlLabel
                                            value={1}
                                            label={t("athleteForm.labelMale")}
                                            control={<Radio color="primary" size="small" />}
                                        />
                                        <FormControlLabel
                                            value={2}
                                            label={t("athleteForm.labelFemale")}
                                            control={<Radio color="primary" size="small" />}
                                        />
                                    </RadioGroup>
                                </Grid>

                                <Grid item xs={6}>
                                    <DatePicker
                                        clearable
                                        label={t("athleteForm.dateOfBirth")}
                                        placeholder={t("athleteForm.datePlaceholder")}
                                        name="dateOfBirth"
                                        value={values.dateOfBirth}
                                        onChange={setFieldValue}
                                        fullWidth
                                        margin="dense"
                                        maxDate={new Date()}
                                        views={["year", "month", "date"]}
                                        openTo="year"
                                        cancelLabel={t("cancel")}
                                        okLabel={t("ok")}
                                        clearLabel={t("reset")}
                                        invalidDateMessage={t("athleteForm.incorrectDate")}
                                        maxDateMessage={t("athleteForm.incorrectDate")}
                                        minDateMessage={t("athleteForm.incorrectDate")}
                                        error={touched.dateOfBirth && !!errors.dateOfBirth}
                                        helperText={touched.dateOfBirth ? errors.dateOfBirth : ""}
                                    />
                                </Grid>
                                {i18n.language !== "ru" && (
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            fullWidth
                                            options={countries}
                                            getOptionLabel={(option) => option}
                                            value={
                                                countries.find((el) => el === values.country) || ""
                                            }
                                            onChange={(e, value) => {
                                                setFieldValue("country", value || "");
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    margin="dense"
                                                    required
                                                    label={t("athleteForm.country")}
                                                    name="country"
                                                    error={touched.country && !!errors.country}
                                                    helperText={
                                                        touched.country ? errors.country : ""
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        type="text"
                                        name="city"
                                        label={t("athleteForm.city")}
                                        fullWidth
                                        margin="dense"
                                        onChange={(e) => {
                                            const { value } = e.target;

                                            setFieldValue("city", value.trimLeft().trimRight());
                                        }}
                                        value={values.city || ""}
                                        error={touched.city && !!errors.city}
                                        helperText={touched.city ? errors.city : ""}
                                    />
                                </Grid>
                                {i18n.language === "ru" && (
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            fullWidth
                                            options={subjects}
                                            getOptionLabel={(option) => option.title}
                                            value={
                                                subjects.find((el) => el.title === values.region) ||
                                                null
                                            }
                                            onChange={(e, value) => {
                                                setFieldValue("region", value?.title || "");
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    margin="dense"
                                                    required
                                                    label={t("athleteForm.region")}
                                                    name="region"
                                                    error={touched.region && !!errors.region}
                                                    helperText={touched.region ? errors.region : ""}
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}

                                {i18n.language !== "ru" && (
                                    <Grid item xs={12}>
                                        <TextField
                                            type="text"
                                            name="club"
                                            label={t("athleteForm.club")}
                                            fullWidth
                                            margin="dense"
                                            onChange={(e) => {
                                                const { value } = e.target;

                                                setFieldValue("club", value.trimLeft().trimRight());
                                            }}
                                            value={values.club || null}
                                            error={touched.club && !!errors.club}
                                            helperText={touched.club ? errors.club : ""}
                                        />
                                    </Grid>
                                )}
                                {i18n.language === "ru" && (
                                    <Grid item xs={12}>
                                        <TextField
                                            select
                                            name="rank"
                                            label={t("athleteForm.rank")}
                                            fullWidth
                                            margin="dense"
                                            onChange={handleChange}
                                            value={values.rank || ""}
                                        >
                                            <MenuItem key={0} value="">
                                                {t("athleteForm.notChosen")}
                                            </MenuItem>
                                            {ATHLETE_RANKS.map((rank, index) => {
                                                return (
                                                    <MenuItem key={index + 1} value={rank}>
                                                        {rank}
                                                    </MenuItem>
                                                );
                                            })}
                                        </TextField>
                                    </Grid>
                                )}
                            </Grid>

                            <Grid container justify="flex-end" component={Box} mt={3}>
                                {type === "put" && (
                                    <Button
                                        onClick={() => {
                                            onCancelEdit();
                                            resetForm();
                                        }}
                                    >
                                        {t("athleteForm.cancel")}
                                    </Button>
                                )}
                                <Button
                                    color="primary"
                                    type="submit"
                                    variant="contained"
                                    onClick={submitForm}
                                >
                                    {type === "post" ? t("athleteForm.add") : t("athleteForm.save")}
                                </Button>
                            </Grid>
                        </>
                    );
                }}
            </Formik>
        </div>
    );
};

export default AthleteForm;
