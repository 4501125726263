import React, { FunctionComponent } from "react";
import { withRouter, Route, Switch } from "react-router-dom";
import AthletesList from "./AthletesList";
import TeamsList from "./TeamsList/TeamsList";
import AgeCategoriesList from "./AgeCategoriesList/AgeCategoriesList";
import UsersList from "./UsersList/UsersList";
import RefereesList from "./RefereesList";
import CategoriesList from "./Categories/CategoriesList";
import CompetitionsList from "./CompetitionsList/CompetitionsList";
import Competition from "./CompetitionsList/Competition";

const MainContent: FunctionComponent = () => {
    return (
        <Switch>
            <Route exact path={`/admin/competitions`} component={CompetitionsList} />
            <Route path={"/admin/competitions/:id"} component={Competition} />
            <Route path={`/admin/athletes`} component={AthletesList} />
            <Route path={`/admin/referees`} component={RefereesList} />
            <Route path={`/admin/teams`} component={TeamsList} />
            <Route path={`/admin/ageCategories`} component={AgeCategoriesList} />
            <Route path={`/admin/users`} component={UsersList} />
            <Route path={`/admin/categories`} component={CategoriesList} />
        </Switch>
    );
};

export default withRouter(MainContent);
