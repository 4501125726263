import Auth from "./Auth";
import i18n from "i18next";
import moment from "moment";
/**
 * Module for making row request to server
 * Use this from other, more specialized servises
 */
class RequestManager {
    static ROOT_URL = `${window.location.protocol}//` + window.location.host;
    /**
     * Url of server api
     */
    static API_URL = RequestManager.ROOT_URL + "/api/";

    static headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
    };

    /**
     * Makes request to server
     * @param {string} route          route - part of url after '...api/'
     * @param {string} method         http method - 'GET' or 'POST'
     * @param {object} body           request body - object (in case of POST - body of request, in case of GET - params object)
     * @param {boolean} unauthorized  if true - makes request without using token from Auth module
     * @param {number} apiVersion     1 - first version, 2 - second version(recommended)
     * @return {Promise}              returns response as Promise
     */
    static fetch(route, method, body = {}, unauthorized = false, apiVersion = 1) {
        const headers = RequestManager._addSecurityHeader(RequestManager.headers, unauthorized);
        headers["TZ-Offset"] = moment().utcOffset();
        headers["Accept-Language"] = i18n.language.substring(0, 2);
        const apiPrefix = apiVersion === 2 ? "v2/" : "";

        return RequestManager._makeFetchForMethod(apiPrefix + route, method, body, headers).then(
            (response) => {
                const result = response.json().then((json) => {
                    if (response.ok) {
                        return {
                            status: response.status,
                            body: json,
                        };
                    }
                    return {
                        status: response.status,
                        message: json.message
                            ? json.message
                            : i18n.t("modules:requestExecutionError"),
                    };
                });
                if (response.ok) {
                    return result;
                }
                return result.then((err) => {
                    throw err;
                });
            }
        );
    }

    static _makeFetchForMethod(route, method, body, headers) {
        method = method.toLowerCase();

        let requestStr = RequestManager.API_URL + route;

        if (method === "get") {
            requestStr += `?${RequestManager._getParamsInString(body)}`;

            return fetch(requestStr, {
                method: method,
                headers: headers,
            });
        }
        if (method === "post") {
            return fetch(requestStr, {
                method: method,
                body: JSON.stringify(body),
                headers: headers,
            });
        }
        if (method === "put") {
            return fetch(requestStr, {
                method: method,
                body: JSON.stringify(body),
                headers: headers,
            });
        }
        if (method === "patch") {
            return fetch(requestStr, {
                method: method,
                body: JSON.stringify(body),
                headers: headers,
            });
        }
        if (method === "delete") {
            return fetch(requestStr, {
                method: method,
                headers: headers,
            });
        }
        throw new Error(`incorrect method of request: "${method}"`);
    }

    static _addSecurityHeader(headers, unauthorized) {
        if (!unauthorized) {
            const token = Auth.getToken();

            if (token !== null) {
                return Object.assign(headers, {
                    Authorization: `bearer ${Auth.getToken()}`,
                });
            }
        }
        return headers;
    }

    static fetchFile(
        route,
        fileName,
        unauthorized = false,
        { method = "GET", data = {}, apiVersion = 1 } = {}
    ) {
        const apiPrefix = apiVersion === 2 ? "v2/" : "";

        return RequestManager._makeFetchForMethod(
            apiPrefix + route,
            method,
            data,
            RequestManager._addSecurityHeader(RequestManager.headers, unauthorized)
        )
            .then(function (resp) {
                return resp.blob();
            })
            .then((blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.download = fileName;
                a.click();
            });
    }

    static sendFile(route, formData, params, unauthorized) {
        return fetch(
            RequestManager.API_URL + route + "?" + RequestManager._getParamsInString(params),
            {
                method: "POST",
                headers: RequestManager._addSecurityHeader({}, unauthorized),
                body: formData,
            }
        ).then((res) => {
            return res.json().then((result) => {
                return {
                    status: res.status,
                    body: result,
                };
            });
        });
    }

    static _getParamsInString(bodyObj) {
        let bodyStrArray = [];
        for (let p in bodyObj) {
            if (Object.prototype.hasOwnProperty.call(bodyObj, p)) {
                bodyStrArray.push(encodeURIComponent(p) + "=" + encodeURIComponent(bodyObj[p]));
            }
        }
        return bodyStrArray.join("&");
    }

    /**
     * Returns performance by id.
     *
     * @param {number} perfId performance id.
     *
     * @return {Promise<Array<Performance>>}
     */
    static getPerformance(perfId) {
        return RequestManager.fetch(`performance/${perfId}`, "get")
            .then((response) => {
                return response.body.performance;
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    /**
     * Returns performance by performanceRefereeId.
     *
     * @param {number} performanceRefereeId performance referee id.
     *
     * @return {Promise<Array<Performance>>}
     */
    static getPerformanceByPerformanceRefereeId(performanceRefereeId) {
        return RequestManager.fetch(
            `performanceByPerformanceRefereeId/${performanceRefereeId}`,
            "get"
        )
            .then((response) => {
                return response.body.performance;
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    /**
     * Returns total score by criteria.
     *
     * @param {number} perfId performance id.
     *
     * @return {Promise<Array<object>>} [{refereeRoleId=1, totalScore=1.1, totalPenalty=1.1 }, ...]
     */
    static getTotalScoreByCriteriaForPerformance(perfId) {
        return RequestManager.fetch(`performance/${perfId}/TotalScoreByCriteria`, "get")
            .then((response) => {
                return response.body.totalScores;
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    /**
     * Returns athletes by criterias.
     *
     * @param {number} perfId performance id.
     *
     * @return {Promise<Array<Athlete>>}
     */
    static getAthletesByPerformanceId(perfId) {
        return RequestManager.fetch(`performance/${perfId}/athletes`, "get")
            .then((response) => response.body.athletes)
            .catch((err) => {
                throw new Error(err);
            });
    }

    /**
     * Returns object with criteria groups and criteria inside them
     * @param {number} performanceId   referee role id
     *
     * @return {Promise<Array<object>>}
     */
    static getRefereeScoresForPerformance(performanceId) {
        return RequestManager.fetch(
            `performance/${performanceId}/refereesScores`,
            "GET",
            null,
            false
        )
            .then((res) => {
                return res.body.refereesInPerformance;
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    /**
     * Returns competition by performance id
     * @param {number} performanceId   referee role id
     *
     * @return {Promise<Performance>}
     */
    static getCompetitionByPerformanceId(performanceId) {
        return RequestManager.fetch(`performance/${performanceId}/competition`, "GET", null, false)
            .then((res) => {
                return res.body.competition;
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    static getCompetitionStages() {
        return RequestManager.fetch("competitionStages", "get")
            .then((result) => {
                return result.body;
            })
            .catch((error) => {
                throw new Error(error);
            });
    }

    static getCompetitionPlans(competitionId) {
        return RequestManager.fetch(`competitionPlans/${competitionId}`, "get")
            .then((result) => {
                return result.body;
            })
            .catch((error) => {
                throw new Error(error);
            });
    }

    // static getPerformanceStatuses() {
    //     return RequestManager.fetch("performanceStatuses", "get")
    //         .then((result) => {
    //             return result.body.PerformanceStatuses;
    //         })
    //         .catch((error) => {
    //             throw new Error(error);
    //         });
    // }

    static getPerformances(
        competitionId,
        isAllRecords,
        isSortByDate,
        competitionStageId,
        competitionPlanId
    ) {
        return RequestManager.fetch("performances", "get", {
            competitionId: competitionId,
            isAllRecords: isAllRecords,
            isSortByDate: isSortByDate,
            competitionStageId: competitionStageId,
            competitionPlanId: competitionPlanId,
        })
            .then((result) => {
                return result.body.Performances;
            })
            .catch((error) => {
                throw new Error(error);
            });
    }

    static getPerformancesForSchedule(competitionId) {
        return RequestManager.fetch("performancesForSchedule", "get", {
            competitionId: competitionId,
        })
            .then((result) => {
                return result.body.Performances;
            })
            .catch((error) => {
                throw new Error(error);
            });
    }

    static getPerformancesByPlanIdAndStageId(competitionPlanId, competitionStageId) {
        return RequestManager.fetch("performancesByPlanIdAndStageId", "get", {
            competitionPlanId: competitionPlanId,
            competitionStageId: competitionStageId,
        })
            .then((result) => {
                return result.body.Performances;
            })
            .catch((error) => {
                throw new Error(error);
            });
    }

    static getBeforehandFinalShuffle(competitionPlanId) {
        return RequestManager.fetch(`beforehandFinalShuffle/${competitionPlanId}`, "get")
            .then((result) => {
                return result.body.BeforehandFinalShuffle;
            })
            .catch((error) => {
                throw new Error(error);
            });
    }

    static getPerformanceInfoByPerformanceRefereeId(performanceRefereeId) {
        return RequestManager.fetch(
            `getPerformanceInfoByPerformanceRefereeId/${performanceRefereeId}`,
            "get"
        )
            .then((res) => {
                return res.body.result;
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    static getPerformanceInfoByPerformanceId(performanceId) {
        return RequestManager.fetch(`getPerformanceInfoByPerformanceId/${performanceId}`, "get")
            .then((res) => {
                return res.body.result;
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    static getCategoryResults(competitionPlanId, competitionStageId, competitionResultDetailId) {
        return RequestManager.fetch("categoryResults", "get", {
            competitionPlanId: competitionPlanId,
            competitionStageId: competitionStageId,
            competitionResultDetailId: competitionResultDetailId,
        })
            .then((res) => {
                return res.body.CategoryResults;
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    static setPerformanceStatus(performanceId, statusId) {
        const data = {
            performance_id: performanceId,
            status_id: statusId,
        };
        return RequestManager.fetch("setPerformanceStatus", "POST", data, false);
    }

    static getUsers() {
        return RequestManager.fetch("users", "get").then((response) => {
            return response.body.Users;
        });
    }

    static getRequests(competitionId) {
        return RequestManager.fetch(`requestsByCompetitionId/${competitionId}`, "get").then(
            (res) => {
                return res.body.Requests;
            }
        );
    }

    static saveRequest(competitionPlanId, requests) {
        const data = {
            competitionPlanId: competitionPlanId,
            requests: requests,
        };
        return RequestManager.fetch("requests", "POST", data, false);
    }

    static getAthletesByCompetitionRequestId(requestId) {
        return RequestManager.fetch(`requests/${requestId}/athletes`, "get").then((res) => {
            return res.body.Athletes;
        });
    }

    static saveAthlete(athlete) {
        return RequestManager.fetch("athletes", "POST", athlete, false);
    }

    static saveAgeCategory(ageCategory) {
        return RequestManager.fetch("ageCategories", "POST", ageCategory, false);
    }

    static saveCategory(category) {
        return RequestManager.fetch("categories", "POST", category, false, 2);
    }

    static saveTeam(team) {
        return RequestManager.fetch("teams", "POST", team, false);
    }

    static getPerformanceScoresSearchResults(searchName, searchBy) {
        return RequestManager.fetch("performanceScoresSearchResults", "get", {
            searchName: searchName,
            searchBy: searchBy,
        });
    }

    static getPerformanceScoresForCompetitionFromArchive(competitionResultId) {
        return RequestManager.fetch("performanceScoresForCompetitionFromArchive", "get", {
            competitionResultId: competitionResultId,
        });
    }
    static getPreviousPerformanceScores(performanceId) {
        return RequestManager.fetch(`previousPerformanceScores/${performanceId}`, "get");
    }

    static getCurrentPerformanceScores(performanceId) {
        return RequestManager.fetch(`currentPerformanceScores/${performanceId}`, "get");
    }

    static getLastPerformanceScores(competitionId) {
        return RequestManager.fetch(`lastPerformanceScores/${competitionId}`, "get");
    }

    static categoryByCompetitionPlanId(competitionPlanId) {
        return RequestManager.fetch(`/categoryByCompetitionPlanId/${competitionPlanId}`, "get");
    }

    static swapPerformances(firstPerformanceId, secondPerformanceId) {
        return RequestManager.fetch(
            "swapPerformances",
            "POST",
            {
                firstPerformanceId: firstPerformanceId,
                secondPerformanceId: secondPerformanceId,
            },
            false
        );
    }

    static getRefereesByCompetitionId(competitionId) {
        return RequestManager.fetch(`competition/${competitionId}/referees`, "get");
    }

    /** Currently unused */
    static sendEmailNotificationForReferee(refereeArray, competition) {
        return RequestManager.fetch(
            "sendEmailNotificationForReferee",
            "POST",
            { refereeArray, competition },
            false
        );
    }

    static async getRequestsByCompetitionPlanId(competitionPlanId) {
        return await RequestManager.fetch(`requestsByPlanId/${competitionPlanId}`, "get");
    }
    static async getAllCompetitions({ page = 1, size = 20, sort = "id", order = "asc" } = {}) {
        return await RequestManager.fetch(
            `competitions`,
            "get",
            { page, size, sort, order },
            false,
            2
        );
    }
    static async getAllActiveCompetitions({ page = 1, size = 20 } = {}) {
        return await RequestManager.fetch(
            `competitions`,
            "get",
            { type: "active", page, size },
            false,
            2
        );
    }
    static async getAllActiveCompetitionsForBeamer(args) {
        const { id } = args;
        return await RequestManager.fetch(
            `competitions`,
            "get",
            { type: "active", beamer_user_id: id },
            false,
            2
        );
    }
}

export default RequestManager;
