import React from "react";
import PropTypes from "prop-types";
import {
    Modal,
    FormGroup,
    ControlLabel,
    FormControl,
    Button,
    ButtonToolbar,
} from "react-bootstrap";
import RequestManager from "../../../Modules/RequestManager";
import { NotificationManager } from "react-notifications";
import { withTranslation } from "react-i18next";

class TeamPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            teamName: this.props.selectedTeam ? this.props.selectedTeam.teamName : "",
            city: this.props.selectedTeam ? this.props.selectedTeam.city : "",
            region: this.props.selectedTeam ? this.props.selectedTeam.region : "",
            country: this.props.selectedTeam ? this.props.selectedTeam.country : "",
            coachName: this.props.selectedTeam ? this.props.selectedTeam.coachName : "",
        };

        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.onShow = this.onShow.bind(this);
    }

    closeModal() {
        this.props.modalHide();
    }

    handleSubmit() {
        RequestManager.saveTeam({
            teamId: this.state.teamId,
            teamName: this.state.teamName,
            city: this.state.city,
            region: this.state.region,
            country: this.state.country,
            coachName: this.state.coachName,
        })
            .then((result) => {
                if (result.body.data[0][0].RETURN_VALUE === 0) {
                    NotificationManager.success("Данные сохранены!");
                    this.closeModal();
                } else {
                    NotificationManager.warning("Команда с таким именем и городом уже существует!");
                }
            })
            .catch((err) => NotificationManager.error("Ошибка при сохранении!"));
    }

    inputChanged(event) {
        let stateChange = {};
        stateChange[event.target.name] = event.target.value;
        this.setState(stateChange);
    }

    onShow() {
        if (this.props.selectedTeam) {
            this.setState({
                teamId: this.props.selectedTeam.teamId,
                teamName: this.props.selectedTeam.teamName,
                city: this.props.selectedTeam.city,
                region: this.props.selectedTeam.region,
                country: this.props.selectedTeam.country,
                coachName: this.props.selectedTeam.coachName,
            });
        } else {
            this.setState({
                teamId: 0,
                teamName: "",
                city: "",
                region: "",
                country: "",
                coachName: "",
            });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Modal
                    onShow={this.onShow}
                    show={this.props.show}
                    onHide={this.closeModal}
                    aria-labelledby="ModalHeader"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="ModalHeader">{this.props.header}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <ControlLabel>{t("teamName")}:</ControlLabel>
                                <FormControl
                                    value={this.state.teamName}
                                    name="teamName"
                                    placeholder={t("teamName")}
                                    onChange={this.inputChanged}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{t("city")}:</ControlLabel>
                                <FormControl
                                    value={this.state.city}
                                    name="city"
                                    placeholder={t("city")}
                                    onChange={this.inputChanged}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{t("region")}:</ControlLabel>
                                <FormControl
                                    value={this.state.region}
                                    name="region"
                                    placeholder={t("region")}
                                    onChange={this.inputChanged}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{t("country")}:</ControlLabel>
                                <FormControl
                                    value={this.state.country}
                                    name="country"
                                    placeholder={t("country")}
                                    onChange={this.inputChanged}
                                />
                            </FormGroup>
                            <FormGroup>
                                <ControlLabel>{t("coachName")}:</ControlLabel>
                                <FormControl
                                    value={this.state.coachName}
                                    name="coachName"
                                    placeholder={t("coachName")}
                                    onChange={this.inputChanged}
                                />
                            </FormGroup>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="form-group">
                            <ButtonToolbar>
                                <Button
                                    className="pull-right btn-primary"
                                    onClick={this.closeModal}
                                >
                                    {t("cancel")}
                                </Button>
                                <Button
                                    className="pull-right  btn-primary"
                                    onClick={this.handleSubmit}
                                >
                                    <span className="glyphicon glyphicon-floppy-disk"></span>
                                    {t("save")}
                                </Button>
                            </ButtonToolbar>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

TeamPopup.propTypes = {
    header: PropTypes.string,
    show: PropTypes.bool,
    modalHide: PropTypes.func,
    selectedTeam: PropTypes.object,
};

export default withTranslation("adminPage")(TeamPopup);
