import React from "react";
import { ListGroupItem, Row, Col, ControlLabel } from "react-bootstrap";
import PropTypes from "prop-types";
import Input from "../../../Common/InputControls/Input";
import CriterionTypesManager from "../../../../Modules/CriterionTypesManager";
import "./Criterion.css";

class Criterion extends React.Component {
    constructor(props) {
        super(props);
        this.criterion = this.props.criterion;
        this.onChange = this.onChange.bind(this);
    }

    onChange(value) {
        const criterion = this.criterion;
        if (
            this.props.criterion.criterionTypeId ===
            CriterionTypesManager.CRITERION_TYPES.penalty.id
        ) {
            if (this.props.criterion.controlTypeId === 2) {
                // 2 is number input
                criterion.penalty = +value.target.value;
            } else {
                criterion.penalty = value;
            }
        } else {
            if (this.props.criterion.controlTypeId === 2) {
                // 2 is number input
                criterion.score = +value.target.value;
            } else {
                criterion.score = value;
            }
        }

        this.setState({ criterion: criterion });

        this.props.onChange(value, this.props.criterion.criterionId);
    }

    render() {
        const { criterion } = this;
        const label = `${criterion.criterionNumber}) ${criterion.criterionName} / ${criterion.criterionNameEn}`;
        const value =
            criterion.criterionTypeId === CriterionTypesManager.CRITERION_TYPES.penalty.id
                ? criterion.penalty
                : criterion.score;

        return (
            <ListGroupItem className="criterion">
                <Row className="criterion-row">
                    <Col xs={6} md={8}>
                        <ControlLabel>
                            {criterion.criterionName} {<br />} {criterion.criterionNameEn}
                        </ControlLabel>
                    </Col>
                    <Col xs={6} md={4} className="akv_center col-input">
                        <Input
                            onChange={this.onChange}
                            controlTypeId={criterion.controlTypeId}
                            value={value}
                            label={label}
                            maxValue={criterion.maxValue}
                            minValue={criterion.minValue}
                            step={criterion.worth}
                        />
                    </Col>
                </Row>
            </ListGroupItem>
        );
    }
}

Criterion.propTypes = {
    criterion: PropTypes.object.isRequired,
    onChange: PropTypes.func,
};

export default Criterion;
