import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    TextField,
    FormGroup,
    DialogActions,
    Select,
    MenuItem,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    FormLabel,
    Checkbox,
} from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import { withTranslation, WithTranslation } from "react-i18next";
import { Category } from "@/interfaces";
import { categoryApiService } from "@/api/Services";

type Props = WithTranslation<"adminPage"> & {
    selectedCategory?: Category | null;
    show: boolean;
    modalHide: () => void;
    onUpdate(): void;
};

type State = {
    categoryId: number;
    genderId: number;
    categoryName: string;
    countFrom: number;
    countTo: number;
    complexityDivider: string;
    categoryCode: string;
    worldCategoryCode: string;
    isGroup: boolean;
    hasCustomComplexityDivider: boolean;
};

class CategoryPopup extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            categoryId: this.props.selectedCategory ? this.props.selectedCategory.id : 0,
            genderId: this.props.selectedCategory ? this.props.selectedCategory.genderId! : 0,
            categoryName: this.props.selectedCategory ? this.props.selectedCategory.name : "",
            countFrom: this.props.selectedCategory ? this.props.selectedCategory.countFrom : 0,
            countTo: this.props.selectedCategory ? this.props.selectedCategory.countTo : 0,
            complexityDivider: this.props.selectedCategory
                ? this.props.selectedCategory.complexityDivider
                : "",
            categoryCode: this.props.selectedCategory ? this.props.selectedCategory.code : "",
            worldCategoryCode: this.props.selectedCategory
                ? this.props.selectedCategory.worldCode
                : "",
            isGroup: this.props.selectedCategory ? this.props.selectedCategory.isGroup : false,
            hasCustomComplexityDivider: this.props.selectedCategory
                ? this.props.selectedCategory.hasCustomComplexityDivider
                : false,
        };

        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.complexityChanged = this.complexityChanged.bind(this);
        this.onShow = this.onShow.bind(this);
    }

    closeModal() {
        this.props.modalHide();
    }

    async handleSubmit() {
        const { categoryId } = this.state;
        const { t, onUpdate } = this.props;
        if (this.state.categoryName === "") {
            NotificationManager.error(t("emptyCategoryNameErr"));
            return;
        }
        if (+this.state.genderId === 0) {
            NotificationManager.error(t("chooseGenderErr"));
            return;
        }
        if (+this.state.countTo < 1 || +this.state.countFrom < 1) {
            NotificationManager.error(t("minMaxCountErr"));
            return;
        }
        if (+this.state.countTo < +this.state.countFrom) {
            NotificationManager.error(t("maxLessMinCountErr"));
            return;
        }

        if (+this.state.complexityDivider === 0) {
            NotificationManager.error(t("noDiffCoefErr"));
            return;
        }

        try {
            const data = {
                genderId: this.state.genderId,
                name: this.state.categoryName,
                countFrom: Number(this.state.countFrom),
                countTo: Number(this.state.countTo),
                complexityDivider: this.state.complexityDivider,
                code: this.state.categoryCode,
                worldCode: this.state.worldCategoryCode,
                isGroup: this.state.isGroup,
                hasCustomComplexityDivider: this.state.hasCustomComplexityDivider,
            };

            if (categoryId) {
                const { message } = await categoryApiService.update(categoryId, data);
                NotificationManager.success(message);
            } else {
                const { message } = await categoryApiService.create(data);
                NotificationManager.success(message);
            }

            this.closeModal();
            onUpdate();
        } catch (err: any) {
            const message = err.response?.data?.message;
            NotificationManager.error(message);
        }
    }

    inputChanged(event) {
        const name = event.target.name;
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        const stateChange = {};
        stateChange[name] = value;
        this.setState(stateChange);
    }

    complexityChanged(event) {
        this.setState({
            complexityDivider: event.target.value,
        });
    }

    onShow() {
        if (this.props.selectedCategory) {
            this.setState({
                categoryId: this.props.selectedCategory.id,
                genderId: this.props.selectedCategory.genderId!,
                categoryName: this.props.selectedCategory.name,
                countFrom: this.props.selectedCategory.countFrom,
                countTo: this.props.selectedCategory.countTo,
                complexityDivider: this.props.selectedCategory.complexityDivider.toString(),
                categoryCode: this.props.selectedCategory.code,
                worldCategoryCode: this.props.selectedCategory.worldCode,
                isGroup: this.props.selectedCategory.isGroup,
                hasCustomComplexityDivider: this.props.selectedCategory.hasCustomComplexityDivider,
            });
        } else {
            this.setState({
                categoryId: 0,
                genderId: 0,
                categoryName: "",
                countFrom: 0,
                countTo: 0,
                complexityDivider: "2.0",
                categoryCode: "",
                worldCategoryCode: "",
                isGroup: false,
                hasCustomComplexityDivider: false,
            });
        }
    }

    render() {
        const { t, selectedCategory } = this.props;

        return (
            <Dialog
                onEnter={this.onShow}
                open={this.props.show}
                onClose={this.closeModal}
                aria-labelledby="ModalHeader"
                fullWidth
            >
                <DialogTitle id="ModalHeader">
                    {selectedCategory ? t("editCategory") : t("newCategory")}
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <TextField
                                value={this.state.categoryName}
                                name="categoryName"
                                label={t("categoryName")}
                                onChange={this.inputChanged}
                                margin="dense"
                            />
                        </FormGroup>

                        <FormGroup>
                            <InputLabel shrink>{t("gender")}</InputLabel>
                            <Select
                                displayEmpty
                                value={this.state.genderId || ""}
                                name="genderId"
                                onChange={this.inputChanged}
                                margin="dense"
                            >
                                <MenuItem value="" key={0} disabled>
                                    {t("notChosen")}
                                </MenuItem>
                                <MenuItem value={1} key={1}>
                                    {t("maleAbbreviation")}
                                </MenuItem>
                                <MenuItem value={2} key={2}>
                                    {t("femaleAbbreviation")}
                                </MenuItem>
                                <MenuItem value={3} key={3}>
                                    {t("maleFemaleAbbreviation")}
                                </MenuItem>
                            </Select>
                        </FormGroup>

                        <FormGroup>
                            <TextField
                                value={this.state.countFrom}
                                type="number"
                                name="countFrom"
                                label={t("minCount")}
                                onChange={this.inputChanged}
                                margin="dense"
                            />
                        </FormGroup>

                        <FormGroup>
                            <TextField
                                value={this.state.countTo}
                                type="number"
                                name="countTo"
                                label={t("maxCount")}
                                onChange={this.inputChanged}
                                margin="dense"
                            />
                        </FormGroup>

                        <FormGroup id="complexity" style={{ marginTop: "1rem" }}>
                            <FormLabel>{`${t("complexityDivider")}`}</FormLabel>
                            <RadioGroup
                                row
                                name="complexityDivider"
                                value={this.state.complexityDivider || ""}
                                onChange={this.complexityChanged}
                            >
                                <FormControlLabel
                                    name="complexity"
                                    value="2.0"
                                    control={<Radio color="primary" />}
                                    label="2.0"
                                />
                                <FormControlLabel
                                    name="complexity"
                                    value="1.8"
                                    control={<Radio color="primary" />}
                                    label="1.8"
                                />
                            </RadioGroup>
                        </FormGroup>

                        <FormGroup>
                            <TextField
                                value={this.state.categoryCode}
                                name="categoryCode"
                                label={t("categoryCode")}
                                onChange={this.inputChanged}
                                margin="dense"
                            />
                        </FormGroup>

                        <FormGroup>
                            <TextField
                                value={this.state.worldCategoryCode}
                                name="worldCategoryCode"
                                label={t("worldCategoryCode")}
                                onChange={this.inputChanged}
                                margin="dense"
                            />
                        </FormGroup>

                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="isGroup"
                                        checked={this.state.isGroup}
                                        onChange={this.inputChanged}
                                        color="primary"
                                    />
                                }
                                label={t("isGroup")}
                            />
                        </FormGroup>

                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="hasCustomComplexityDivider"
                                        checked={this.state.hasCustomComplexityDivider}
                                        onChange={this.inputChanged}
                                        color="primary"
                                    />
                                }
                                label={t("hasCustomComplexityDivider")}
                            />
                        </FormGroup>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeModal} color="default">
                        {t("cancel")}
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary" variant="contained">
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation("adminPage")(CategoryPopup);
