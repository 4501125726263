import React from "react";
import PropTypes from "prop-types";
import { Panel } from "react-bootstrap";
import Gallery from "react-grid-gallery";
import { Redirect, Switch, Route } from "react-router";
import { withRouter } from "react-router-dom";

import CompetitionsManager from "../Modules/CompetitionsManager";
import Auth from "../Modules/Auth";
import SearchAthleteCoach from "../Controls/SearchAthleteCoach";
import PerformanceScoresForCompetitionFromArchive from "../Controls/Performances/PerformanceScoresForCompetitionFromArchive";
import { withTranslation } from "react-i18next";
import moment from "moment";

class ArchivePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            competitions: [],
        };

        this.onItemClick = this.onItemClick.bind(this);
    }

    componentDidMount() {
        if (Auth.isUserAuthenticated()) {
            CompetitionsManager.getAllCompetitionsFromArchive()
                .then(function (response) {
                    return CompetitionsManager.replaceDatesStrToObject(response.Competitions);
                })
                .then((competitions) => {
                    competitions = competitions.map((competition) => {
                        competition.dateFrom = moment(competition.dateFrom).format("DD.MM.YYYY");
                        competition.dateTo = moment(competition.dateTo).format("DD.MM.YYYY");
                        competition.date =
                            competition.dateFrom === competition.dateTo
                                ? competition.dateFrom
                                : competition.dateFrom + " - " + competition.dateTo;
                        return competition;
                    });

                    this.setState({
                        competitions: competitions,
                    });
                })
                .catch((err) => {
                    throw err;
                });
        }
    }

    onItemClick(item) {
        this.props.history.push(`/archive/${item.competitionResultId}/performances`);
    }

    render() {
        if (!Auth.isUserAuthenticated()) {
            return <Redirect to="/login" />;
        }
        return (
            <div className="container">
                <h2>{this.props.t("competitionArchive")}</h2>
                <SearchAthleteCoach />
                <br />
                <br />
                <Switch>
                    <Route
                        path="/archive"
                        exact
                        render={() => {
                            return (
                                <div>
                                    {this.state.competitions.map((item) => {
                                        const images =
                                            item.images &&
                                            item.images.map(function (image) {
                                                return {
                                                    src:
                                                        "/images/" +
                                                        item.competitionResultId +
                                                        "/big/" +
                                                        image,
                                                    thumbnail:
                                                        "/images/" +
                                                        item.competitionResultId +
                                                        "/small/" +
                                                        image,
                                                    thumbnailWidth: 200,
                                                    thumbnailHeight: 133,
                                                    caption: item.competitionName,
                                                };
                                            });

                                        return (
                                            <Panel key={item.competitionResultId}>
                                                <h4>
                                                    <a
                                                        role="button"
                                                        onClick={() => this.onItemClick(item)}
                                                    >
                                                        {item.competitionName}, {item.place},{" "}
                                                        {item.date}
                                                    </a>
                                                </h4>
                                                <div className="galery-wraper">
                                                    {images && (
                                                        <Gallery
                                                            images={images}
                                                            enableImageSelection={false}
                                                            maxRows={2}
                                                            showLightboxThumbnails={true}
                                                        />
                                                    )}
                                                </div>
                                            </Panel>
                                        );
                                    })}
                                </div>
                            );
                        }}
                    />
                    <Route
                        path="/archive/:id/performances"
                        component={PerformanceScoresForCompetitionFromArchive}
                    />
                </Switch>
            </div>
        );
    }
}

ArchivePage.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    // Props from router
    match: PropTypes.object,
};

export default withRouter(withTranslation("pages")(ArchivePage));
