const Config = {
    MAIN_API_URL: process.env.REACT_APP_MAIN_API_URL,
    LICENSES_URL: process.env.REACT_APP_LICENSES_URL,
};

const Settings = {
    MAIN_API_URL_V1: Config.MAIN_API_URL + "api/v1/",
    MAIN_API_URL_V2: Config.MAIN_API_URL + "api/v2/",
};

const Paths = {
    EVENTS: "/events",
    EVENT_DETAIL: "/events/:id",
    REFEREE_LOTTERY: "/lottery",
};

export { Config, Settings, Paths };
