import BaseApiService from "@/api/Base";
import { TokenInterceptor } from "@/api/Http/Interceptors";
import { Settings } from "@/modules/Constants";
import { Athlete } from "../../../interfaces/athlete";

class AthleteApiService extends BaseApiService<Athlete> {
    constructor(config) {
        super(config);
        this.url = "/athletes";
        this.http.interceptors.request.use(TokenInterceptor);
    }
}

export default new AthleteApiService({
    baseURL: Settings.MAIN_API_URL_V2,
});
