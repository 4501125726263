import { Competition } from "@/interfaces";
import { StoreonModule } from "storeon";
import { CompetitionPlan } from "../../interfaces/competition-plan";
import { Performance } from "../../interfaces/performance";
import { PerformanceReferee } from "../../interfaces/performance-referee";

export interface AuthReferee {
    firstName: string;
    lastName: string;
    middleName: string;
    phoneNumber: string;
    rank: string;
    email: string;
    city: string;
    userId: number;
    refereeId: number;
}

export interface Referee {
    id: number;
    roleId: number;
    brigadeNumber: number;
    refereeNumber: number;
}

export type RefereeViewBy = "" | "Referee" | "MainReferee" | "Judge";

export interface RefereeModuleState {
    competition: Competition | null;
    referee: Referee | null;
    socket: SocketIOClient.Socket | null;
    viewBy: RefereeViewBy;
    performances: Performance[];
    plans: CompetitionPlan[];
    selectedPlan: CompetitionPlan | null;
    performanceReferees: PerformanceReferee[];
    performanceDetails: Performance | null;
    error: string | null;
}

export interface RefereeModuleEvents {
    "referee/set/competition": Competition | null;
    "referee/set/referee": Referee | null;
    "referee/set/socket": SocketIOClient.Socket | null;
    "referee/set/view_by": RefereeViewBy;
    "referee/set/plans": CompetitionPlan[];
    "referee/set/selected_plan": CompetitionPlan;
    "referee/set/performance_referees": PerformanceReferee[];
    "referee/set/performance_details": Performance;
    "referee/update/plan": CompetitionPlan;
    "referee/set/performances": Performance[];
    "referee/update/performance": Performance;
    "referee/update/performance_status": any;
    "referee/set/error": string | null;
    "referee/reset/state";
}

const initialState: RefereeModuleState = {
    competition: null,
    referee: null,
    socket: null,
    viewBy: "",
    performances: [],
    plans: [],
    selectedPlan: null,
    performanceReferees: [],
    performanceDetails: null,
    error: null,
};

export const refereeModule: StoreonModule<RefereeModuleState, RefereeModuleEvents> = (store) => {
    store.on("@init", () => initialState);

    store.on("referee/set/competition", (state, competition) => ({ competition }));

    store.on("referee/set/referee", (state, referee) => ({ referee }));

    store.on("referee/set/socket", (state, socket) => ({ socket }));

    store.on("referee/set/view_by", (state, viewBy) => ({ viewBy }));

    store.on("referee/set/plans", (state, plans) => {
        const selectedPlan = state.selectedPlan;
        const index = selectedPlan ? plans.findIndex((item) => item.id === selectedPlan.id) : 0;
        const newSelectedPlan = plans[index];

        return {
            plans,
            selectedPlan: newSelectedPlan,
        };
    });

    store.on("referee/set/selected_plan", (state, selectedPlan) => ({ selectedPlan }));

    store.on("referee/set/performance_referees", (state, performanceReferees) => ({
        performanceReferees,
    }));

    store.on("referee/set/performance_details", (state, performanceDetails) => ({
        performanceDetails,
    }));

    store.on("referee/update/plan", (state, plan) => {
        const { plans, viewBy, selectedPlan } = state;

        if (viewBy === "MainReferee" || viewBy === "Referee") {
            const index = plans.findIndex((item) => item.id === plan.id);
            const updatedPlans = [...plans];
            updatedPlans[index] = plan;

            const selectedIndex = selectedPlan
                ? updatedPlans.findIndex((item) => item.id === selectedPlan.id)
                : 0;
            const newSelectedPlan = updatedPlans[selectedIndex];

            return { plans: updatedPlans, selectedPlan: newSelectedPlan };
        }
    });

    store.on("referee/set/performances", (state, performances) => ({ performances }));

    store.on("referee/update/performance", (state, performance) => {
        const { viewBy, performances } = state;

        if (viewBy === "MainReferee" || viewBy === "Referee") {
            const updatedPerformances: Performance[] = [...performances];

            updatedPerformances.forEach((perf) => {
                if (perf.id === performance.id) {
                    perf.totalPoints = performance.totalPoints;
                    perf.performanceReferees = performance.performanceReferees;
                    perf.otherScores = performance.otherScores;
                    perf.isScoresShown = performance.isScoresShown;
                    return;
                }
            });

            return {
                performances: updatedPerformances,
            };
        } else {
            return {
                performanceReferees: performance.performanceReferees,
            };
        }
    });

    store.on("referee/update/performance_status", (state, payload) => {
        const { viewBy, performances } = state;

        if (viewBy === "MainReferee" || viewBy === "Referee") {
            const { error } = payload;

            if (error) {
                return { error };
            }
            const modifyPerformances = [...performances];

            modifyPerformances.forEach((performance) => {
                if (performance.id === payload.performanceId) {
                    performance.statusId = payload.performanceStatusId;
                }
            });

            return { performances, error: null };
        } else {
            return {
                performanceDetails: payload.performanceDetails,
                performanceReferees: payload.performanceReferees,
                error: null,
            };
        }
    });

    store.on("referee/reset/state", () => initialState);
};
