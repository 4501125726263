import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";

import { StoreContext } from "storeon/react";
import store from "./store";

import "react-notifications/lib/notifications.css";

import App from "./App";
import registerServiceWorker from "./RegisterServiceWorker";
import "./i18n";
import "./index.css";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient({
    defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <StoreContext.Provider value={store}>
                <BrowserRouter>
                    <Suspense fallback={<div>Loading</div>}>
                        <Route path="/" component={App} />
                    </Suspense>
                </BrowserRouter>
            </StoreContext.Provider>
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

registerServiceWorker();
