import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import Helpers from "../../../../Modules/Helpers";
import RefereeRoleManager from "../../../../Modules/RefereeRoleManager";
import { PerformanceReferee } from "../../../../interfaces/performance-referee";
import { Performance } from "../../../../interfaces/performance";
import RefereeHeader from "./referee-header";
import { GENDERS, REFEREE_ROLES } from "../../../../Modules/Enums";
import { EVENTS } from "../../../../Modules/Referee/Events";
import i18n from "i18next";
import ProtocolArtistry from "./ProtocolArtistry/ProtocolArtistry";
import ProtocolExecution from "./ProtocolExecution/ProtocolExecution";
import ProtocolComplexity from "./ProtocolComplexity/ProtocolComplexity";
import ProtocolReferee from "./ProtocolReferee/ProtocolReferee";
import ProtocolMainReferee from "./ProtocolMainReferee/ProtocolMainReferee";
import ProtocolLineReferee from "./ProtocolLineReferee/ProtocolLineReferee";
import PreviousPerformanceScores from "../../Protocols/PreviousPerformanceScores";
import { useStoreon } from "storeon/react";
import {
    Referee,
    RefereeModuleEvents,
    RefereeModuleState,
} from "../../../../store/StoreModules/RefereeModule";

interface Props {
    show: boolean;
    referee: Referee;
    onlyCriterionGroups?: number[];
    performanceDetails: Performance;
    performanceReferee: PerformanceReferee;
    onClose: () => void;
}

const getJudgePageView = (roleName) => {
    const PAGE_CLASSES = new Map<any, any>([
        [REFEREE_ROLES.artistryJudge.systemName, ProtocolArtistry],
        [REFEREE_ROLES.executionJudge.systemName, ProtocolExecution],
        [REFEREE_ROLES.complexityJudge.systemName, ProtocolComplexity],
        [REFEREE_ROLES.referee.systemName, ProtocolReferee],
        [REFEREE_ROLES.general.systemName, ProtocolMainReferee],
        [REFEREE_ROLES.lineReferee.systemName, ProtocolLineReferee],
    ]);

    return PAGE_CLASSES.get(roleName);
};

const getRoleName = (roleId) => {
    const role = RefereeRoleManager.getRoleById(+roleId);
    return role && role.systemName ? role.systemName : null;
};

export default function ProtocolModal(props: Props) {
    const { performanceDetails } = props;
    const { socket } = useStoreon<RefereeModuleState, RefereeModuleEvents>("socket");

    const roleName = getRoleName(props.performanceReferee.refereeRoleId);

    const renderTitle = (roleName) => {
        const protocolName = i18n.t(
            `modules:${Helpers.getRefereeProtocolNameByRoleName(roleName)}`
        );
        return protocolName;
    };

    const handleSaveScores = async (scores) => {
        socket?.emit(EVENTS.REFEREE_RATE, performanceDetails.id, scores);
        props.onClose();
    };

    const makePageInstance = (pageClass) => {
        if (!pageClass) {
            return null;
        }

        const pageProps = {
            performanceReferee: props.performanceReferee,
            onlyCriterionGroups: props.onlyCriterionGroups,
            onSave: handleSaveScores,
            onClose: props.onClose,
        };

        return React.createElement(pageClass, pageProps, null);
    };

    return (
        <Dialog open onClose={props.onClose} fullWidth disableBackdropClick>
            <DialogTitle>{renderTitle(roleName)}</DialogTitle>
            <DialogContent>
                {performanceDetails && (
                    <PreviousPerformanceScores performanceId={performanceDetails.id} />
                )}

                {performanceDetails.competitionRequest?.details && (
                    <RefereeHeader
                        nomination={
                            performanceDetails.schedule?.plan?.category?.worldCode +
                            " " +
                            performanceDetails.schedule?.plan?.ageCategory?.name
                        }
                        athletes={performanceDetails.competitionRequest?.details?.map((detail) => {
                            return Helpers.getShortNameForAthlete(detail.athlete);
                        })}
                    />
                )}
                {makePageInstance(getJudgePageView(roleName))}
            </DialogContent>
        </Dialog>
    );
}
