import React, { useEffect } from "react";
import SocketManager from "../../Modules/Beamer/socket-manager";
import { useAuth } from "../../Hooks";
import { useHistory, useParams } from "react-router";
import { useStoreon } from "storeon/react";
import { BeamerModuleEvents, BeamerModuleState } from "../../store/StoreModules/BeamerModule";
import { useTranslation } from "react-i18next";
import { SlideType } from "../../Modules/Beamer/types";
import RatingSlide from "./rating-slide";
import PerformanceSlide from "./performance-slide";
import styles from "./beamer.module.scss";
import { useCallback } from "react";

const socket = new SocketManager();

const initSocket = (roomId: number, userId: number): void => {
    socket.initSocketConnection(roomId, userId);
    socket.setupSocketListeners();
};

const Beamer = (): JSX.Element => {
    const history = useHistory();
    const { competitionId } = useParams<{ competitionId?: string }>();
    const { user } = useAuth();
    const { slideType, options, dispatch } = useStoreon<BeamerModuleState, BeamerModuleEvents>(
        "slideType",
        "options"
    );
    const { t } = useTranslation("beamers");

    const escFunction = useCallback(
        (event: KeyboardEvent) => {
            if (event.key === "Escape") history.push(`/beamer/settings/${competitionId}`);
        },
        [history, competitionId]
    );

    /** clear store on unmount */
    useEffect(() => {
        return () => {
            dispatch("setSlideType", null);
        };
    }, [dispatch]);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        if (competitionId) {
            initSocket(Number(competitionId), user.userId);
            socket.setUpdateSignal(options);
            socket.setReadySignal(true);
            return () => {
                socket.closeSocketConnection();
                document.removeEventListener("keydown", escFunction, false);
            };
        } else console.log("competitionId is undefined");
    }, [competitionId, escFunction, user, options]);

    if (slideType) {
        switch (slideType) {
            case SlideType.PERFORMANCE:
                return <PerformanceSlide />;
            case SlideType.RATING:
                return <RatingSlide />;
            default:
                return <div className={styles["beamer-main"]}>{t("noBeamerSlide")}</div>;
        }
    } else return <div className={styles["beamer-main"]}>{t("noBeamerSlide")}</div>;
};

export default Beamer;
