import React from "react";
import PropTypes from "prop-types";
import { Button, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const SaveCancelPanel = (props) => {
    const { t } = useTranslation("refereePage");
    return (
        <Grid item className="akv_center">
            <Button onClick={props.onCancel}>{t("cancel")}</Button>
            <Button onClick={props.onSave} color="primary" variant="contained">
                {t("send")}
            </Button>
        </Grid>
    );
};

SaveCancelPanel.propTypes = {
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
};

export default SaveCancelPanel;
