import { Container } from "@material-ui/core";
import React from "react";
import CompetitionsList from "../Controls/Events/CompetitionsList";

const EventsPage: React.FunctionComponent = () => {
    return (
        <Container>
            <CompetitionsList />
        </Container>
    );
};

export default EventsPage;
