import React from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    Button,
    TextField,
    Select,
    FormControl,
    InputLabel,
    Input,
    MenuItem,
    Typography,
} from "@material-ui/core";
import RequestManager from "../../../Modules/RequestManager";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { performanceApiService } from "@/api/Services";

class PerformancesPopup extends React.Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.state = {
            performanceStatuses: [],
            planRequests: [],
            selectedPerformance: this.props.selectedPerformance,
            requests: [],
            isLoaded: false,
        };

        this.refreshPerformanceStatuses = this.refreshPerformanceStatuses.bind(this);
    }

    componentDidMount() {
        this.refreshAll();
    }

    refreshAll() {
        Promise.all([this.refreshPerformanceStatuses(), this.refreshPlanRequests()]).then(() => {
            this.setState({ isLoaded: true });
        });
    }

    async refreshPlanRequests() {
        const { t } = this.props;
        try {
            const response = await RequestManager.fetch(
                `requestsByPlanId/${this.props.selectedPlan.competitionPlanId}`,
                "get"
            );
            this.setState({
                planRequests: response.body.Requests,
            });
        } catch (err) {
            NotificationManager.error(t("requestsLoadingError"));
        }
    }

    async refreshPerformanceStatuses() {
        const { t } = this.props;
        try {
            const data = await performanceApiService.getAllStatuses();
            this.setState({
                performanceStatuses: data.result,
            });
        } catch (e) {
            NotificationManager.error(t("performanceStatusesLoadingError"));
        }
    }

    inputChanged(event) {
        let updatedPerformance = this.state.selectedPerformance;
        updatedPerformance[event.target.name] = event.target.value;
        this.setState({ selectedPerformance: updatedPerformance });
    }

    handleSubmit(event) {
        const { t } = this.props;
        const performance = this.state.selectedPerformance;
        if (+performance.competitionRequestId === 0) {
            NotificationManager.error(t("selectRequest"));
            return;
        }

        if (+performance.performanceStatusId === 0) {
            NotificationManager.error(t("selectPerfomanceStatus"));
            return;
        }

        let postedData = {
            performanceId: performance.performanceId,
            competitionRequestId: performance.competitionRequestId,
            performanceStatusId: performance.performanceStatusId,
        };

        RequestManager.fetch("performanceAssignRequest", "POST", postedData, false).then(() => {
            NotificationManager.success(t("dataSaved"));
            this.props.modalSubmit();
        });
    }

    render() {
        const { t } = this.props;
        if (!this.state.isLoaded) {
            return null;
        }

        return (
            <Dialog
                onEnter={this.onShow}
                open={this.props.modalIsOpen}
                onClose={this.handleCancelClick}
                aria-labelledby="ModalHeader"
                fullWidth
            >
                <DialogTitle id="ModalHeader" disableTypography>
                    {t("perfomance")}
                </DialogTitle>

                <DialogContent>
                    <form onSubmit={this.handleSubmit}>
                        <Typography>
                            <b>{`${t("category")}:`}</b>{" "}
                            {this.props.selectedPlan.competitionPlanName}
                        </Typography>
                        <Typography>
                            <b> {`${t("perfomanceTime")}:`}</b>{" "}
                            {moment(this.props.selectedPerformance.dateTime).format(
                                "DD.MM.YYYY HH:mm"
                            )}
                        </Typography>

                        <FormControl fullWidth margin="dense">
                            <InputLabel shrink>{`${t("request")}:`}</InputLabel>
                            <Select
                                displayEmpty
                                value={this.state.selectedPerformance.competitionRequestId || ""}
                                name="competitionRequestId"
                                onChange={this.inputChanged}
                            >
                                <MenuItem value="" key={0} disabled>
                                    {t("selectRequest")}
                                </MenuItem>

                                {this.state.planRequests.map((item) => {
                                    return (
                                        <MenuItem
                                            value={item.competitionRequestId}
                                            key={item.competitionRequestId}
                                        >
                                            {item.competitor}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth margin="dense">
                            <InputLabel shrink>{`${t("perfomanceStatus")}:`}</InputLabel>
                            <Select
                                displayEmpty
                                value={this.state.selectedPerformance.performanceStatusId || ""}
                                name="performanceStatusId"
                                onChange={this.inputChanged}
                            >
                                <MenuItem value="" disabled key={0}>
                                    {t("selectPerfomanceStatus")}
                                </MenuItem>

                                {this.state.performanceStatuses.map((item) => {
                                    return (
                                        <MenuItem
                                            value={item.performanceStatusId}
                                            key={item.performanceStatusId}
                                        >
                                            {item.performanceStatusName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.modalCancel} color="default">
                        {t("cancel")}
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary" variant="contained">
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

PerformancesPopup.propTypes = {
    modalIsOpen: PropTypes.bool,
    modalSubmit: PropTypes.func,
    modalCancel: PropTypes.func,
    selectedPerformance: PropTypes.object,
    selectedPlan: PropTypes.object,
};

export default withTranslation("adminPage")(PerformancesPopup);
