import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import CompetitionAutocomplete from "./CompetitionAutocomplete";
import { useTranslation } from "react-i18next";

const SelectCompetition = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("adminPage");

    const [value, setValue] = useState<number | undefined>(
        Number(localStorage.getItem("competition_target")) || undefined
    );

    useEffect(() => {
        return () => localStorage.removeItem("competition_target");
    }, []);

    const handleSelectCompetition = (value) => {
        setValue(value);
        localStorage.setItem("competition_target", value);
        enqueueSnackbar(t("competitionSelected"), {
            variant: "success",
        });
    };

    return (
        <CompetitionAutocomplete
            textFieldProps={{
                name: "competition_target",
                helperText: t("chooseOneOfOpts"),
            }}
            onChange={handleSelectCompetition}
            value={value}
        />
    );
};

export default SelectCompetition;
