import React from "react";
import MuiDataTable from "../../Common/MuiDataTable";
import RequestManager from "../../../Modules/RequestManager";
import RefereeAssignmentPopup from "./RefereeAssignmentPopup";
import RefereeClonePopup from "./RefereeClonePopup";
import moment from "moment";
import { withTranslation } from "react-i18next";

class RefereeAssignmentList extends React.Component {
    TIME_STATUS = {
        NORMAL: "normal",
        CRITICAL: "critical",
    };

    constructor(props) {
        super(props);

        this.state = {
            refereeAssignments: [],
            shown: false,
            isLoading: true,
            isClonePopupShown: false,
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.modalHide = this.modalHide.bind(this);
        this.updateRefereeAssignmentList = this.updateRefereeAssignmentList.bind(this);
    }

    componentDidMount() {
        this.updateRefereeAssignmentList();
    }

    updateRefereeAssignmentList() {
        this.setState({ isLoading: true });
        RequestManager.fetch(`refereeAssignments/${this.props.competitionId}`, "get")
            .then((response) => {
                this.setState({
                    refereeAssignments: this.setTimeMarkers(response.body.RefereeAssignments),
                    isLoading: false,
                });
            })
            .catch((err) => {
                throw err;
            });
    }

    setTimeMarkers(refereeAssignments) {
        const newRefereeAssignments = [...refereeAssignments];

        for (const [i1, p1] of refereeAssignments.entries()) {
            const a1 = p1.athleteName?.split(", ");
            const time1 = moment(p1.dateTime);

            for (const [i2, p2] of refereeAssignments.entries()) {
                const a2 = p2.athleteName?.split(", ");
                const time2 = moment(p2.dateTime);

                if (!a1 || !a2) {
                    continue;
                }

                const isSameAthlete = a1.some((val) => a2.includes(val));

                if (
                    isSameAthlete &&
                    p1.competitionPlanName !== p2.competitionPlanName &&
                    p1.performanceId !== p2.performanceId
                ) {
                    const timeDiff = time2.diff(time1, "minutes");
                    if (timeDiff < 0) {
                        continue;
                    }

                    if (timeDiff <= 10) {
                        newRefereeAssignments[i1].timeStatus = this.TIME_STATUS.CRITICAL;
                        newRefereeAssignments[i2].timeStatus = this.TIME_STATUS.CRITICAL;
                    }

                    if (timeDiff > 10 && timeDiff <= 15) {
                        newRefereeAssignments[i1].timeStatus = this.TIME_STATUS.NORMAL;
                        newRefereeAssignments[i2].timeStatus = this.TIME_STATUS.NORMAL;
                    }
                }
            }
        }

        return newRefereeAssignments;
    }

    handleOpen(e) {
        this.onItemClick(e);
        this.setState({
            shown: true,
        });
    }

    modalHide() {
        this.setState({
            shown: false,
            isClonePopupShown: false,
        });
        this.updateRefereeAssignmentList();
    }

    render() {
        const { t } = this.props;
        const columns = [
            { title: "#", field: "performanceId" },
            {
                title: t("athleteOrTeam"),
                field: "athleteName",
                render: (rowData) =>
                    rowData.athleteName
                        ? rowData.athleteName.split(", ").map((athlete, i) => {
                              return <div key={i}>{athlete}</div>;
                          })
                        : this.props.t("notAssigned"),
            },
            { title: t("category"), field: "competitionPlanName" },
            {
                title: t("dateOrTime"),
                field: "dateTime",
                render: (rowData) => moment(rowData.dateTime).format("DD.MM.YYYY HH:mm"),
            },
            { title: t("brigadeNumber"), field: "brigadeNumber" },
        ];

        return (
            <div className="margin-top-bottom-20">
                {this.state.shown ? (
                    <RefereeAssignmentPopup
                        header={t("assignReferees")}
                        show={this.state.shown}
                        modalHide={this.modalHide}
                        selectedRefereeAssignment={this.state.selectedRefereeAssignment}
                        competitionId={this.props.competitionId}
                    />
                ) : null}

                {this.state.isClonePopupShown ? (
                    <RefereeClonePopup
                        header={t("copyAssignReferees")}
                        show={this.state.isClonePopupShown}
                        modalHide={this.modalHide}
                        selectedRefereeAssignment={this.state.selectedRefereeAssignment}
                    />
                ) : null}

                <MuiDataTable
                    columns={columns}
                    data={this.state.refereeAssignments}
                    options={{
                        sorting: false,
                        draggable: false,
                        rowStyle: (data) => {
                            if (data.timeStatus === this.TIME_STATUS.CRITICAL) {
                                return { backgroundColor: "#FF7D70", fontSize: 12 };
                            }
                            if (data.timeStatus === this.TIME_STATUS.NORMAL) {
                                return { backgroundColor: "#FFFA70", fontSize: 12 };
                            }
                        },
                    }}
                    isLoading={this.state.isLoading}
                />
            </div>
        );
    }
}

export default withTranslation("adminPage")(RefereeAssignmentList);
