import React from "react";
import RequestManager from "../../Modules/RequestManager";
import PerformancesCategoryGroup from "./PerformancesCategoryGroup";
import { Grid, Typography } from "@material-ui/core";
import "./Performances.css";
import PropTypes from "prop-types";
import CategoriesManager from "../../Modules/CategoriesManager";
import { GENDERS } from "../../Modules/Enums";
import { withTranslation } from "react-i18next";

class Performances extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            performances: [],
            competitionId: this.props.competitionId || this.props.match.params.id,
        };
    }

    componentDidMount() {
        Promise.all([this.updatePerformances(), this.getCategories()]).then(() =>
            this.setState({ isLoading: false })
        );
    }

    getCategories() {
        return CategoriesManager.findAll().then((c) => {
            this.setState({
                categories: c,
            });
        });
    }

    updatePerformances() {
        return RequestManager.getPerformances(this.state.competitionId, 1, 0, 0, 0).then((p) => {
            this.setState({
                performances: p,
            });
        });
    }

    groupBy(initialArray, key) {
        let result = initialArray.reduce(function (accumulator, currentValue) {
            (accumulator[currentValue[key]] = accumulator[currentValue[key]] || []).push(
                currentValue
            );
            return accumulator;
        }, {});
        return result;
    }

    getPerformances(performances) {
        let groupedByCategories = this.groupBy(performances, "categoryId");
        Object.keys(groupedByCategories).forEach((category) => {
            groupedByCategories[category] = this.groupBy(
                groupedByCategories[category],
                "ageCategoryName"
            );

            Object.keys(groupedByCategories[category]).forEach((ageCategory) => {
                groupedByCategories[category][ageCategory] = this.groupBy(
                    groupedByCategories[category][ageCategory],
                    "competitionStageId"
                );
            });
        });

        return groupedByCategories;
    }

    render() {
        const { t } = this.props;
        if (this.state.isLoading) {
            return null;
        }
        let groupedByCategories = this.getPerformances(this.state.performances);
        let keys = Object.keys(groupedByCategories);

        return (
            <Grid item>
                <Typography variant="h6">{t("resultsOfPerfomances")}</Typography>
                <Grid item>
                    {keys.map((categoryId) => {
                        let header;
                        this.state.categories
                            .filter((c) => {
                                return c.categoryId === +categoryId;
                            })
                            .forEach((c) => {
                                header =
                                    c.categoryName +
                                    " (" +
                                    t(`modules:${GENDERS[c.genderId].name}`) +
                                    ")";
                                return;
                            });
                        return (
                            <PerformancesCategoryGroup
                                category={groupedByCategories[categoryId]}
                                key={categoryId}
                                header={header}
                            />
                        );
                    })}
                </Grid>
            </Grid>
        );
    }
}

Performances.propTypes = {
    competitionId: PropTypes.string,

    // Props from router
    match: PropTypes.object,
};

export default withTranslation()(Performances);
