import { createStoreon, StoreonModule } from "storeon";
import { storeonDevtools } from "storeon/devtools";
import { beamerModule } from "./StoreModules/BeamerModule";
import { authModule } from "./StoreModules/AuthModule";
import { refereeModule } from "./StoreModules/RefereeModule";
import { onlineResultsModule } from "./StoreModules/OnlineResults";
import { tableModule } from "./StoreModules/TableModule";
import { refereeLotteryModule } from "./StoreModules/RefereeLotteryModule";

import { UnionToIntersection } from "@/modules/Types";

type ExtractStoreonTypes<T, R extends "state" | "events"> = UnionToIntersection<
    T extends StoreonModule<infer S, infer E> ? (R extends "state" ? S : E) : never
>;

const storeModules = [
    authModule,
    beamerModule,
    refereeModule,
    onlineResultsModule,
    tableModule,
    refereeLotteryModule,
];

type StoreState = ExtractStoreonTypes<typeof storeModules[0], "state">;
type StoreEvents = ExtractStoreonTypes<typeof storeModules[0], "events">;

export const store = createStoreon<StoreState, StoreEvents>([
    ...storeModules,
    process.env.NODE_ENV !== "production" && storeonDevtools,
]);

export default store;
