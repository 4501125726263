import React, { useEffect } from "react";
import { Container, Typography } from "@material-ui/core";
import PageTabs from "./tabs";
import { useTranslation } from "react-i18next";
import SocketManager from "../../Modules/OnlineResults/socket-manager";

import "./styles.scss";

const socketInstance = new SocketManager();

const initSocket = (): void => {
    socketInstance.initSocketConnection();
    socketInstance.setupSocketListeners();
};

const Index = () => {
    useEffect(() => {
        initSocket();
    }, []);
    const { t } = useTranslation("common");
    return (
        <Container className="online-results">
            <Typography variant="h4">{t("onlineResults")}</Typography>
            <PageTabs />
        </Container>
    );
};

export default Index;
