import {
    ExpansionPanel,
    ExpansionPanelSummary,
    Typography,
    ExpansionPanelDetails,
    FormControl,
    FormLabel,
    FormControlLabel,
    RadioGroup,
    Radio,
    Button,
    ExpansionPanelActions,
    Grid,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import RequestManager from "../../../../Modules/RequestManager";
import { useTranslation } from "react-i18next";

type Props = { competitionId: number; onSuccess(): void; onFailure(): void };

const ScheduleReport = ({ competitionId, onSuccess, onFailure }: Props) => {
    const [state, setState] = useState({
        regionView: "region",
        docType: "pdf",
    });
    const { t } = useTranslation("adminPage");

    useEffect(() => {
        const fetch = async () => {
            const { CompetitionPlans } = await RequestManager.getCompetitionPlans(competitionId);

            setState((prevState) => ({
                ...prevState,
                plans: CompetitionPlans.map((item) => {
                    item.qualification = false;
                    item.final = false;
                    return item;
                }),
            }));
        };

        fetch();
    }, [competitionId]);

    const handleChangeInput = (e) => {
        const { name, value } = e.target;

        setState((prevState) => ({ ...prevState, [name]: value }));
    };

    const download = async () => {
        const { docType, regionView } = state;

        try {
            await RequestManager.fetchFile(`scheduleReport`, `report.${docType}`, false, {
                data: { competitionId, docType, regionView },
            });

            onSuccess();
        } catch {
            onFailure();
        }
    };

    return (
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMore />}>
                <Typography variant="h6">{`${t("perfomancesByDay")}:`}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Grid container>
                    <Grid xs={12} item md={3}>
                        <FormControl component="fieldset" margin="dense">
                            <FormLabel component="p">{t("viewSettings")}</FormLabel>
                            <RadioGroup
                                name="regionView"
                                value={state.regionView}
                                onChange={handleChangeInput}
                            >
                                <FormControlLabel
                                    value="region"
                                    control={<Radio color="primary" size="small" />}
                                    label={t("region")}
                                />
                                <FormControlLabel
                                    value="city"
                                    control={<Radio color="primary" size="small" />}
                                    label={t("city")}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl component="fieldset" margin="dense">
                            <FormLabel component="p">{t("documentType")}</FormLabel>
                            <RadioGroup
                                name="docType"
                                value={state.docType}
                                onChange={handleChangeInput}
                            >
                                <FormControlLabel
                                    value="pdf"
                                    control={<Radio color="primary" size="small" />}
                                    label="pdf"
                                />
                                <FormControlLabel
                                    value="xlsx"
                                    control={<Radio color="primary" size="small" />}
                                    label="xlsx"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </ExpansionPanelDetails>
            <ExpansionPanelActions>
                <Button onClick={download} color="primary" variant="contained">
                    {t("download")}
                </Button>
            </ExpansionPanelActions>
        </ExpansionPanel>
    );
};

export default ScheduleReport;
