/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useState } from "react";
// syncronous state
export default function useTrait(initialValue) {
    const [trait, updateTrait] = useState(initialValue);

    let current = trait;

    const get = () => current;

    const set = (newValue) => {
        current = newValue;
        updateTrait(newValue);
        return current;
    };

    return {
        get,
        set,
    };
}
