import io from "socket.io-client";
import { EVENTS } from "./Events";
import store from "../../store";
import { CompetitionPlan } from "../../interfaces/competition-plan";
import { Referee, RefereeViewBy } from "../../store/StoreModules/RefereeModule";
import { PerformanceReferee } from "../../interfaces/performance-referee";
import { Performance } from "../../interfaces/performance";
import { Competition } from "@/interfaces";

interface RefereeViewResponse {
    referee: Referee;
    performanceReferees: PerformanceReferee[];
    performanceDetails: Performance;
    viewBy: RefereeViewBy;
    plans: CompetitionPlan[];
    performances: Performance[];
    competition: Competition;
}

class SocketManager {
    socket: SocketIOClient.Socket;

    constructor() {
        this.socket = io.Socket;
    }

    initSocketConnection = (roomId: number, refereeId: number) => {
        this.socket = io.connect(`/referee`, {
            reconnection: true,
            reconnectionAttempts: Infinity,
            autoConnect: true,
            reconnectionDelay: 1 * 1000,
            reconnectionDelayMax: 10 * 1000,
            rejectUnauthorized: true,
            path: "/sockets",
            query: { roomId, refereeId },
        });

        store.dispatch("referee/set/socket", this.socket);
    };

    closeSocketConnection = () => {
        this.socket.close();
    };

    // Здесь инициализируются не все слушатели. Некоторые вешаются напрямую в компоненты
    setupSocketListeners = () => {
        this.socket.on(
            EVENTS.REFEREE_CONNECTED,
            (token: string, res: RefereeViewResponse | null) => {
                this.socket.io.opts.query = { ...(this.socket.io.opts?.query || {}), token };

                if (!res) return;
                store.dispatch("referee/set/referee", res.referee);
                store.dispatch("referee/set/competition", res.competition);

                if (res.viewBy === "Judge") {
                    store.dispatch("referee/set/performance_referees", res.performanceReferees);
                    store.dispatch("referee/set/performance_details", res.performanceDetails);
                    store.dispatch("referee/set/view_by", "Judge");
                }
                if (res.viewBy === "Referee") {
                    store.dispatch("referee/set/plans", res.plans);
                    store.dispatch("referee/set/performances", res.performances);
                    store.dispatch("referee/set/view_by", "Referee");
                }

                if (res.viewBy === "MainReferee") {
                    store.dispatch("referee/set/plans", res.plans);
                    store.dispatch("referee/set/performances", res.performances);
                    store.dispatch("referee/set/view_by", "MainReferee");
                }
            }
        );

        this.socket.on(EVENTS.REFEREE_DISCONNECTED, () => ({}));

        this.socket.on(EVENTS.REFEREE_CHANGED_PERFORMANCE_STATUS, (viewObj: any) => {
            store.dispatch("referee/update/performance_status", viewObj);
        });

        this.socket.on(EVENTS.REFEREE_SHOWED_SCORES, (performance: Performance) => {
            store.dispatch("referee/update/performance", performance);
        });

        this.socket.on(EVENTS.REFEREE_RATED, (performance: Performance) => {
            store.dispatch("referee/update/performance", performance);
        });
        this.socket.on(EVENTS.REFEREE_CHANGED_PLAN, (performances) => {
            store.dispatch("referee/set/performances", performances);
        });
        this.socket.on(EVENTS.REFEREE_CALCULATED_PLACES, (competitionPlanId, performances) => {
            const selectedPlan = store.get().selectedPlan;

            if (selectedPlan?.id === competitionPlanId) {
                store.dispatch("referee/set/performances", performances);
            }
        });
        this.socket.on(EVENTS.REFEREE_CHANGED_PLAN_STATUS, (plan: CompetitionPlan) => {
            store.dispatch("referee/update/plan", plan);
        });
    };
}

export default SocketManager;
