import React from "react";
import PropTypes from "prop-types";
import { Grid, Tabs, Tab } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import SportsIcon from "@material-ui/icons/Sports";
import LibraryMusicIcon from "@material-ui/icons/LibraryMusic";
import { withTranslation } from "react-i18next";
class TrainerMenu extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    static get menuItems() {
        return {
            athletes: "athletes",
            referees: "referees",
            competitions: "competitions",
            musicLibrary: "music",
        };
    }

    handleChange = (event, value) => {
        this.setState({
            selectedItem: value,
        });
        this.props.onChange(value);
    };

    render() {
        const { t } = this.props;
        return (
            <Grid container justify="center">
                <Tabs
                    value={this.props.selectedItem}
                    onChange={this.handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    variant="scrollable"
                >
                    <Tab
                        value={TrainerMenu.menuItems.athletes}
                        label={t("trainerMenu.athletes")}
                        icon={<GroupIcon />}
                    />
                    <Tab
                        value={TrainerMenu.menuItems.referees}
                        label={t("trainerMenu.referees")}
                        icon={<GroupIcon />}
                    />
                    <Tab
                        value={TrainerMenu.menuItems.musicLibrary}
                        label={t("trainerMenu.musicLibrary")}
                        icon={<LibraryMusicIcon />}
                    />
                    <Tab
                        value={TrainerMenu.menuItems.competitions}
                        label={t("trainerMenu.registration")}
                        icon={<SportsIcon />}
                    />
                </Tabs>
            </Grid>
        );
    }
}

TrainerMenu.propTypes = {
    onChange: PropTypes.func,
    selectedItem: PropTypes.string,
};

export default withTranslation("trainerPage")(TrainerMenu);
