import i18n from "i18next";

class PerformanceManager {
    static PERFORMANCE_STATUSES = {
        PLANNED: { id: 1, name: i18n.t("modules:planned") },
        IN_PROGRESS: { id: 2, name: i18n.t("modules:performs") },
        COMPLETED: { id: 3, name: i18n.t("modules:performanceComplete") },
        DISQUALIFIED: { id: 4, name: i18n.t("modules:disqualification") },
    };

    static getStatusById(id) {
        return Object.keys(PerformanceManager.PERFORMANCE_STATUSES).find(
            (status) => status.id === id
        );
    }

    static getStatusNameById(id) {
        for (let item in PerformanceManager.PERFORMANCE_STATUSES) {
            if (
                Object.prototype.hasOwnProperty.call(PerformanceManager.PERFORMANCE_STATUSES, item)
            ) {
                if (PerformanceManager.PERFORMANCE_STATUSES[item].id === id)
                    return PerformanceManager.PERFORMANCE_STATUSES[item].name;
            }
        }
        return null;
    }

    static getAllStatuses() {
        return Object.keys(PerformanceManager.PERFORMANCE_STATUSES).map(
            (key) => PerformanceManager.PERFORMANCE_STATUSES[key]
        );
    }
}

export default PerformanceManager;
