import React, { useEffect, FunctionComponent } from "react";
import { CompetitionPlan } from "../../../interfaces/competition-plan";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Container,
    SelectProps,
} from "@material-ui/core";
import RefereeTable, { ACTIONS_BY } from "../components/referee-table/referee-table";
import { EVENTS } from "../../../Modules/Referee/Events";
import { PLAN_STATUSES } from "../../../Modules/Enums";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useStoreon } from "storeon/react";
import { RefereeModuleEvents, RefereeModuleState } from "../../../store/StoreModules/RefereeModule";

const MainRefereeView: FunctionComponent = () => {
    const { t } = useTranslation("refereePage");
    const { enqueueSnackbar } = useSnackbar();

    const { plans, selectedPlan, performances, socket, error, dispatch } = useStoreon<
        RefereeModuleState,
        RefereeModuleEvents
    >("plans", "selectedPlan", "performances", "socket", "error");

    useEffect(() => {
        if (!error) return;
        enqueueSnackbar(<h5>{error}</h5>, {
            variant: "error",
        });
    }, [error]);

    const handleChangeCompetitionPlan: SelectProps["onChange"] = (e) => {
        const competitionPlanId: number = e.target.value as number;

        socket?.emit(EVENTS.REFEREE_CHANGE_PLAN, competitionPlanId);

        dispatch(
            "referee/set/selected_plan",
            plans.find((item) => item.id === competitionPlanId) || ({} as CompetitionPlan)
        );
    };

    const handleChangeCompetitionPlanStatus: SelectProps["onChange"] = (e) => {
        if (!selectedPlan) return;

        const competitionPlanStatusId: number = e.target.value as number;

        socket?.emit(EVENTS.REFEREE_CHANGE_PLAN_STATUS, selectedPlan.id, competitionPlanStatusId);

        enqueueSnackbar(<h5>{t("planStatusChanged")}</h5>, {
            variant: "info",
        });
    };

    return (
        <div>
            {selectedPlan ? (
                <Container>
                    <Grid container justify="space-between" spacing={2}>
                        <Grid item xs={7} md={5}>
                            <FormControl fullWidth>
                                <InputLabel>{t("selectPlan")}</InputLabel>
                                <Select
                                    value={selectedPlan.id}
                                    onChange={handleChangeCompetitionPlan}
                                >
                                    {plans.map((plan: CompetitionPlan) => {
                                        return (
                                            <MenuItem key={plan.id} value={plan.id}>
                                                {plan.category?.name} - {plan.ageCategory?.name} (
                                                {plan.category?.worldCode})
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5} md={3}>
                            <FormControl fullWidth>
                                <InputLabel>{t("selectPlanStatus")}</InputLabel>

                                <Select
                                    value={selectedPlan.statusId}
                                    onChange={handleChangeCompetitionPlanStatus}
                                >
                                    {PLAN_STATUSES.map((item) => {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>
                                                {t(`modules:${item.locale}`)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Container>
            ) : null}
            <RefereeTable actionsBy={ACTIONS_BY.MainReferee} performances={performances} />
        </div>
    );
};

export default MainRefereeView;
