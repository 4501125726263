import React, { useEffect } from "react";
import JudgeView from "./judge-view";
import MainRefereeView from "./main-referee-view";
import RefereeView from "./referee-view";
import { Grid, Typography, Snackbar, Slide } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { useTranslation } from "react-i18next";
import SocketManager from "../../../Modules/Referee/SocketManager";
import { useState } from "react";
import { useStoreon } from "storeon/react";
import {
    AuthReferee,
    RefereeModuleEvents,
    RefereeModuleState,
} from "../../../store/StoreModules/RefereeModule";

type MainViewProps = { competitionId: number; referee: AuthReferee };

const MainView: React.FunctionComponent<MainViewProps> = ({
    competitionId,
    referee,
}: MainViewProps) => {
    const { t } = useTranslation("refereePage");

    const { viewBy, dispatch } = useStoreon<RefereeModuleState, RefereeModuleEvents>("viewBy");

    const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
    const [snackbarType, setSnackbarType] = useState<"error" | "success">("error");

    useEffect(() => {
        const socketInstance = new SocketManager();
        socketInstance.initSocketConnection(competitionId, referee.refereeId);
        socketInstance.setupSocketListeners();

        socketInstance.socket.on("reconnecting", () => {
            if (!showSnackbar) {
                setSnackbarType("error");
                setShowSnackbar(true);
            }
        });
        socketInstance.socket.on("connect", () => {
            setSnackbarType("success");

            setTimeout(() => {
                setShowSnackbar(false);
            }, 3000);
        });

        return () => {
            socketInstance.closeSocketConnection();
            dispatch("referee/reset/state");
        };
    }, [competitionId, referee]);

    const snakText =
        snackbarType === "success" ? t("connectrionRestablished") : t("connectionLost");

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={showSnackbar}
                TransitionComponent={(props) => <Slide {...props} direction="up" />}
            >
                <MuiAlert severity={snackbarType} variant="filled">
                    {snakText}
                </MuiAlert>
            </Snackbar>
            {viewBy === "Judge" && <JudgeView />}
            {viewBy === "Referee" && <RefereeView />}
            {viewBy === "MainReferee" && <MainRefereeView />}
            {viewBy === "" && (
                <Grid container justifyContent="center">
                    <Typography variant="h6">{t("youHaveNoAppointments")}</Typography>
                </Grid>
            )}
        </>
    );
};

export default MainView;
