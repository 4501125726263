import BaseApiService from "@/api/Base";
import { TokenInterceptor } from "@/api/Http/Interceptors";
import { Settings } from "@/modules/Constants";
import { Schedule } from "@/interfaces";

class ScheduleApiService extends BaseApiService<Schedule> {
    constructor(config) {
        super(config);
        this.url = "/schedule";
        this.http.interceptors.request.use(TokenInterceptor);
    }
}

export default new ScheduleApiService({
    baseURL: Settings.MAIN_API_URL_V2,
});
