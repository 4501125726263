import React, { useEffect, useState } from "react";
import { Tabs, Tab, Typography } from "@material-ui/core";
import TabPanel from "../../Common/TabPanel";
import { CompetitionPlan } from "../../../interfaces/competition-plan";
import { CompetitionRequestDetails } from "../../../interfaces/competition-request-details";
import { EVENTS } from "../../../Modules/OnlineResults/events";
import Helpers from "../../../Modules/Helpers";
import MuiDataTable from "../../Common/MuiDataTable";
import { useTranslation } from "react-i18next";
import { useStoreon } from "storeon/react";
import {
    OnlineResultsModuleState,
    OnlineResultsModuleEvents,
} from "../../../store/StoreModules/OnlineResults";

const Complete = () => {
    const { t } = useTranslation("common");
    const [isLoading, setLoadingStatus] = useState(true);

    const { socket, completedPlans, performances, dispatch } = useStoreon<
        OnlineResultsModuleState,
        OnlineResultsModuleEvents
    >("socket", "completedPlans", "performances");

    const [selectedPlan, setSelectedPlan] = useState<CompetitionPlan>(
        completedPlans[0] || ({} as CompetitionPlan)
    );

    useEffect((): any => {
        let index = completedPlans.findIndex((item) => item.id === selectedPlan.id);

        if (!completedPlans.length) return;

        if (index === -1) index = 0;
        setSelectedPlan(completedPlans[index]);
        socket?.emit(EVENTS.USER_CHANGE_COMPLETED_PLAN, completedPlans[index].id);
    }, [completedPlans]);

    useEffect((): any => {
        socket?.emit(EVENTS.USER_CHANGE_COMPLETED_PLAN, selectedPlan.id);

        return () => dispatch("online/set/performances", []);
    }, []);

    useEffect((): any => {
        socket?.on(EVENTS.USER_CHANGED_COMPLETED_PLAN, () => {
            setLoadingStatus(false);
        });
    }, []);

    const handleChangePlan = (planId) => {
        dispatch("online/set/performances", []);
        setSelectedPlan(
            completedPlans.find((item) => item.id === planId) || ({} as CompetitionPlan)
        );
        setLoadingStatus(true);

        socket?.emit(EVENTS.USER_CHANGE_COMPLETED_PLAN, planId);
    };

    if (!completedPlans.length) return <p>{t("noCompetitionsAtMoment")}</p>;

    const columns = [
        {
            title: t("fullName"),
            field: "athleteFullName",
            render: (rowData) =>
                rowData.competitionRequest.details.map((detail: CompetitionRequestDetails) => {
                    return <div key={detail.id}>{Helpers.getNameForAthlete(detail.athlete)}</div>;
                }),
        },
        {
            title: t("execution"),
            field: "averageExecution",
            render: (rowData) => rowData.otherScores.averageExecution,
        },
        {
            title: t("artistry"),
            field: "averageArtistry",
            render: (rowData) => rowData.otherScores.averageArtistry,
        },
        {
            title: t("complexity"),
            field: "averageComplexity",
            render: (rowData) => rowData.otherScores.averageComplexity,
        },
        {
            title: t("penalties"),
            field: "totalPenalty",
            render: (rowData) => rowData.otherScores.totalPenalty,
        },
        { title: t("totalPoints"), field: "totalPoints" },
        { title: t("place"), field: "place" },
    ];

    return (
        <>
            {completedPlans.length !== 0 && (
                <Typography variant="h5">{completedPlans[0].competition.name}</Typography>
            )}
            <Tabs
                variant="scrollable"
                value={selectedPlan.id}
                onChange={(e, tabKey) => handleChangePlan(tabKey)}
                indicatorColor="primary"
            >
                {completedPlans.map((plan) => {
                    return (
                        <Tab
                            key={plan.id}
                            value={plan.id}
                            label={`${plan.category?.worldCode} (${plan.ageCategory?.name})`}
                        ></Tab>
                    );
                })}
            </Tabs>

            {completedPlans.map((plan) => {
                return (
                    <TabPanel value={selectedPlan.id} index={plan.id} key={plan.id}>
                        <MuiDataTable
                            columns={columns}
                            data={performances}
                            showSearch={false}
                            showToolbar={false}
                            enableGrouping={false}
                            isLoading={isLoading}
                        />
                    </TabPanel>
                );
            })}
        </>
    );
};

export default Complete;
