import React from "react";
import PerformanceResultDetails from "../../../../PerformanceResultDetails/PerformanceResultDetails";
import { Button, DropdownButton, MenuItem, ControlLabel } from "react-bootstrap";
import PerformanceManager from "../../../../../Modules/PerformanceManager";
import RequestManager from "../../../../../Modules/RequestManager";
import PropTypes from "prop-types";
import "./ProtocolMainReferee.css";
import { NotificationManager } from "react-notifications";
import { withTranslation } from "react-i18next";

class ProtocolMainReferee extends React.Component {
    constructor(props) {
        super(props);

        this.performanceRefereeId = props.match.params.perfRefId;

        this.state = {
            performance: null,
            performanceStatus: {
                performanceStatusId: null,
                performanceStatusName: null,
            },
            isLoading: true,
        };

        this.dropDownChanged = this.dropDownChanged.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onClose = this.onClose.bind(this);
        this.getPerformance = this.getPerformance.bind(this);
    }

    updateProtocol() {
        return Promise.all([this.getPerformance()]);
    }

    componentDidMount() {
        this.updateProtocol().then(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    getPerformance() {
        return RequestManager.getPerformanceByPerformanceRefereeId(this.performanceRefereeId).then(
            (performance) => {
                this.setState({
                    performance: performance,
                    performanceStatus: {
                        performanceStatusId: performance.performanceStatusId,
                        performanceStatusName: PerformanceManager.getStatusNameById(
                            performance.performanceStatusId
                        ),
                    },
                });
            }
        );
    }

    dropDownChanged(eventKey, event) {
        this.setState(
            {
                performanceStatus: {
                    performanceStatusId: eventKey.id,
                    performanceStatusName: eventKey.name,
                },
            },
            function () {
                this.onSave();
                if (this.state.performanceStatus.performanceStatusId === 3) {
                    this.onClose();
                }
            }
        );
    }

    performanceStatusesTag() {
        return (
            <DropdownButton
                title={this.state.performanceStatus.performanceStatusName}
                id="bg-nested-dropdown"
                onSelect={this.dropDownChanged}
                className="drop-down"
                key={this.state.performanceStatus.performanceStatusId}
                dropup
            >
                {PerformanceManager.getAllStatuses().map((status) => (
                    <MenuItem key={status.id} eventKey={status}>
                        {status.name}
                    </MenuItem>
                ))}
            </DropdownButton>
        );
    }

    onSave() {
        RequestManager.setPerformanceStatus(
            this.state.performance.performanceId,
            this.state.performanceStatus.performanceStatusId
        )
            .then(() => {
                NotificationManager.success(this.props.t("dataSaved"));
            })
            .catch((err) => {
                NotificationManager.error(err);
            });
    }

    onClose() {
        this.props.history.push("/referee");
    }

    render() {
        if (this.state.isLoading) {
            return null;
        }
        const { t } = this.props;

        const match = { params: { id: this.state.performance.performanceId } };
        return (
            <div id="protocolMainReferee">
                <div className="status">
                    <div className="save-status-element">
                        <ControlLabel>{t("status")}: &nbsp;</ControlLabel>
                        {this.performanceStatusesTag()}
                    </div>
                    <div className="buttons-wraper">
                        <Button
                            className="save-status-btn save-status-element"
                            onClick={this.onClose}
                        >
                            {t("status")}
                        </Button>
                    </div>
                </div>
                <hr />
                <PerformanceResultDetails match={match} />
            </div>
        );
    }
}

ProtocolMainReferee.propTypes = {
    performance: PropTypes.object,
    athletes: PropTypes.arrayOf(PropTypes.string),

    // Props from router
    match: PropTypes.object,
    history: PropTypes.object,
};

export default withTranslation("refereePage")(ProtocolMainReferee);
