import React, { useState } from "react";
import { Tabs, Tab } from "@material-ui/core";
import TabPanel from "../Common/TabPanel";
import { useTranslation } from "react-i18next";
// tabs
import Online from "./tabs/online";
import Complete from "./tabs/complete";

const PageTabs = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const { t } = useTranslation("common");
    return (
        <>
            <Tabs
                variant="scrollable"
                id="competition-tabs"
                value={selectedTab}
                onChange={(e, tabKey) => setSelectedTab(tabKey)}
                className="sub-menu"
            >
                <Tab value={0} label={t("online")}></Tab>
                <Tab value={1} label={t("completedPerfomances")}></Tab>
                {/* <Tab value={2} label="Архив"></Tab> // сделать позже */}
            </Tabs>

            <TabPanel value={selectedTab} index={0}>
                <Online />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
                <Complete />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
                3
            </TabPanel>
        </>
    );
};

export default PageTabs;
