import { StoreonModule } from "storeon";
import Auth from "../../Modules/Auth";

export interface AuthUser {
    userId: number;
    userName: any;
    login: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    city: string;
    roleIds: number[];
    roles: any;
    isAdmin: boolean;
    isReferee: boolean;
    isCompetitionManager: boolean;
    isTrainer: boolean;
    isBeamer: boolean;
    trainerId: number;
}

export interface AuthModuleState {
    user: AuthUser;
}

export interface AuthModuleEvents {
    "auth/set/user": any;
}

export const authModule: StoreonModule<AuthModuleState, AuthModuleEvents> = (store) => {
    store.on("@init", () => ({
        user: Auth.getAuthUser(),
    }));

    store.on("auth/set/user", (state, user) => ({ user }));
};
