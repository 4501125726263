import { TableBody } from "@material-ui/core";
import React from "react";
import { Performance } from "../../../../interfaces/performance";
import { ACTIONS_BY } from "./referee-table";
import RefereeTableBodyRow from "./referee-table-body-row";

interface Props {
    performances: Performance[];
    shownPerformanceScores?: any[];
    rowsPerPage: number;
    page: number;
    onChangePerformanceStatus: (statusId: number, performanceId: number) => Promise<void>;
    actionsBy: ACTIONS_BY;
    onShowScores: (performanceId: number) => void;
    onSendSignalToJudge: (refereeId: number, value) => void;
    activeSignalArrows: any[];
    onEditPerformance: any;
}

const RefereeTableBody = ({
    performances,
    rowsPerPage,
    page,
    onChangePerformanceStatus,
    actionsBy,
    onShowScores,
    onSendSignalToJudge,
    activeSignalArrows,
    onEditPerformance,
}: Props) => {
    return (
        <TableBody>
            {(rowsPerPage > 0
                ? performances.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : performances
            ).map((performance: Performance, index: number) => {
                return (
                    <RefereeTableBodyRow
                        index={index + 1}
                        key={index}
                        performance={performance}
                        onChangePerformanceStatus={onChangePerformanceStatus}
                        actionsBy={actionsBy}
                        onShowScores={onShowScores}
                        shownScores={performance.isScoresShown}
                        onSendSignalToJudge={onSendSignalToJudge}
                        activeSignalArrows={activeSignalArrows}
                        onEditPerformance={onEditPerformance}
                    />
                );
            })}
        </TableBody>
    );
};

export default RefereeTableBody;
