import React from "react";
import Auth from "../Modules/Auth";
import CompetitionList from "../Controls/RefereePage/competitions/competitions-list";
import { withAuth } from "../Hoc";

import "../Css/Common.css";

const RefereePage: React.FunctionComponent<unknown> = () => {
    return (
        <section className="referee_page">
            <CompetitionList />
        </section>
    );
};

export default withAuth(RefereePage, [Auth.ROLES.referee]);
