import React from "react";
import MainGrid from "../Controls/AdminPage/MainGrid";
import { withAuth } from "../Hoc";
import Auth from "../Modules/Auth";

const AdminPage: React.FunctionComponent = () => {
    return (
        <div>
            <MainGrid />
        </div>
    );
};

export default withAuth(AdminPage, [Auth.ROLES.admin]);
