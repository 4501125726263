import io from "socket.io-client";
import { EVENTS } from "./events";
import { EVENTS as RefereeEvents } from "../Referee/Events";
import store from "../../store";
import { Performance } from "../../interfaces/performance";

class SocketManager {
    socket: SocketIOClient.Socket;

    constructor() {
        this.socket = io.Socket;
    }

    initSocketConnection = () => {
        this.socket = io.connect(`/online-results`, {
            reconnection: true,
            reconnectionAttempts: Infinity,
            autoConnect: true,
            reconnectionDelay: 1 * 1000,
            reconnectionDelayMax: 10 * 1000,
            rejectUnauthorized: true,
            path: "/sockets",
        });

        store.dispatch("online/set/socket", this.socket);
    };

    closeSocketConnection = () => {
        this.socket.close();
    };

    setupSocketListeners = () => {
        this.socket.on(EVENTS.USER_JOINED, (dtoObj: any) => {
            store.dispatch("online/set/online_plans", dtoObj.onlinePlans);
            store.dispatch("online/set/completed_plans", dtoObj.completedPlans);
        });

        this.socket.on(EVENTS.USER_CHANGED_COMPLETED_PLAN, (performances: Performance[]) => {
            store.dispatch("online/set/performances", performances);
        });

        this.socket.on(
            EVENTS.USER_GOT_ONLINE_PLAN_PERFORMANCES,
            (onlinePerformances: Array<Performance[]>) => {
                store.dispatch("online/set/online_performances", onlinePerformances);
            }
        );

        this.socket.on(RefereeEvents.REFEREE_CHANGED_PLAN_STATUS, (plansObj: any) => {
            store.dispatch("online/set/online_plans", plansObj.onlinePlans);
            store.dispatch("online/set/completed_plans", plansObj.completedPlans);
        });

        this.socket.on(RefereeEvents.REFEREE_RATED, (performance: Performance) => {
            store.dispatch("online/update/online_performance", performance);
        });
    };
}

export default SocketManager;
