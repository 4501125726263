import i18next from "i18next";
import { HttpOptions } from "..";

const TokenInterceptor = (config: HttpOptions): HttpOptions => {
    const token = localStorage.getItem("token");
    if (token) {
        config.headers.Authorization = "Bearer " + token;
    }

    return config;
};

const LanguageInterceptor = (config: HttpOptions): HttpOptions => {
    const language = i18next.language;

    config.headers["Accept-Language"] = language;

    return config;
};

export { TokenInterceptor, LanguageInterceptor };
