/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import io from "socket.io-client";
import { INCOMING_EVENT_TYPES, OUTGOING_EVENT_TYPES } from "./events";
import store from "../../store/index";
import { BeamerOptions, BeamerSlideObject, RatingSlideObject, SlideType } from "./types";

const SOCKET_NAMESPACE = "/beamer";

class SocketManager {
    socket: SocketIOClient.Socket;

    constructor() {
        this.socket = io.Socket;
    }

    initSocketConnection = (roomId: number, userId: number) => {
        this.socket = io.connect(SOCKET_NAMESPACE, {
            reconnection: true,
            reconnectionAttempts: Infinity,
            autoConnect: true,
            reconnectionDelay: 1 * 1000,
            reconnectionDelayMax: 10 * 1000,
            rejectUnauthorized: true,
            path: "/sockets",
            query: { roomId: roomId, userId: userId },
        });

        store.dispatch("initSocketInstance", this.socket);
    };

    closeSocketConnection = () => {
        this.socket.close();
    };

    setupSocketListeners = () => {
        this.socket.on(INCOMING_EVENT_TYPES.BEAMER_CONNECTED, (message: string, token: string) => {
            this.socket.io.opts.query = { ...(this.socket.io.opts?.query || {}), token };
        });

        this.socket.on(
            INCOMING_EVENT_TYPES.BEAMER_SHOW_SLIDE,
            (slideType: SlideType | null, dto: BeamerSlideObject | RatingSlideObject) => {
                if (slideType) {
                    switch (slideType) {
                        case SlideType.PERFORMANCE:
                            store.dispatch("setPerfomanceObject", dto as BeamerSlideObject);
                            break;
                        case SlideType.RATING:
                            store.dispatch("setRatingSlide", dto as RatingSlideObject);
                            break;
                        default:
                            break;
                    }
                }
                store.dispatch("setSlideType", slideType);
            }
        );
        if (process.env.NODE_ENV !== "production") {
            this.socket.on(INCOMING_EVENT_TYPES.BEAMER_CONNECTED, (message: string) => {
                console.log(message);
            });
            this.socket.on(INCOMING_EVENT_TYPES.BEAMER_JOINED_TO_THE_ROOM, (message) => {
                console.log(message);
            });
            this.socket.on(INCOMING_EVENT_TYPES.BEAMER_LEFT_THE_ROOM, (message) => {
                console.log(message);
            });
            this.socket.on(INCOMING_EVENT_TYPES.BEAMER_OPTIONS_UPDATED, (message) => {
                console.log(message);
            });
            this.socket.on(INCOMING_EVENT_TYPES.BEAMER_READY_STATUS_UPDATED, (message) => {
                console.log(message);
            });
            this.socket.on(INCOMING_EVENT_TYPES.BEAMER_DISCONNECTED, (message) => {
                console.log(message);
            });
        }
    };

    setReadySignal = (status: boolean) => {
        this.socket.emit(OUTGOING_EVENT_TYPES.BEAMER_SET_READY_STATUS, status);
    };

    setUpdateSignal = (options: BeamerOptions) => {
        this.socket.emit(OUTGOING_EVENT_TYPES.BEAMER_UPDATE_OPTIONS, options);
    };
}

export default SocketManager;
