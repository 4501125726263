import React from "react";
import { useAuth } from "../../../Hooks";
import MobileHeader from "../../MobileHeader";

const Layout: React.FC = (props) => {
    const { children } = props;

    const { user, deauthenticate } = useAuth();

    return (
        <>
            <MobileHeader onLogout={deauthenticate} authUser={user} />
            {children}
        </>
    );
};

export default Layout;
