import React from "react";
import Beamer from "../Controls/Beamers/beamer";
import { withAuth } from "../Hoc";
import Auth from "../Modules/Auth";

const BeamerActivityPage: React.FunctionComponent = () => {
    return <Beamer />;
};

export default withAuth(BeamerActivityPage, [Auth.ROLES.beamer]);
