module.exports = {
    GENDERS: {
        1: {
            id: 1,
            name: "maleAbbreviation",
        },
        2: {
            id: 2,
            name: "femaleAbbreviation",
        },
        3: {
            id: 3,
            name: "maleFemaleAbbreviation",
        },
    },

    PLAN_STATUSES: [
        { id: 1, locale: "preparing" },
        { id: 2, locale: "perfomancesInProgress" },
        { id: 3, locale: "completed" },
    ],

    REFEREE_ROLES: {
        artistryJudge: {
            id: 1,
            refereeName: "artistryJudge",
            criterionName: "artistry",
            systemName: "Artistry",
        },
        complexityJudge: {
            id: 2,
            refereeName: "complexityJudge",
            criterionName: "complexity",
            systemName: "Complexity",
        },
        executionJudge: {
            id: 3,
            refereeName: "executionJudge",
            criterionName: "execution",
            systemName: "Execution",
        },
        referee: {
            id: 4,
            refereeName: "referee",
            systemName: "Referee",
        },
        general: {
            id: 5,
            refereeName: "mainReferee",
            systemName: "MainReferee",
        },
        lineReferee: {
            id: 6,
            refereeName: "lineReferee",
            systemName: "LineReferee",
        },
    },
    STAGES: {
        1: "qualification",
        2: "final",
    },

    PERFORMANCE_STATUSES: {
        PLANNED: { id: 1, name: "planned" },
        IN_PROGRESS: { id: 2, name: "performs" },
        COMPLETED: { id: 3, name: "performanceComplete" },
    },
};
