import { Formik, FormikProps, Form, FormikConfig } from "formik";
import * as Yup from "yup";
import React, { forwardRef } from "react";
import { Button, TextField, Typography, Link as MuiLink, FormHelperText } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import styles from "./login-form.module.scss";

export interface LoginUser {
    login: string;
    password: string;
}

const LoginSchema = Yup.object().shape({
    login: Yup.string().trim().required("Пожалуйста, укажите логин"),
    password: Yup.string().trim().required("Пожалуйста, укажите пароль"),
});

export type LoginFormProps = Pick<FormikConfig<LoginUser>, "onSubmit"> & Partial<{ error: string }>;

const LoginForm = forwardRef<FormikProps<LoginUser>, LoginFormProps>(
    ({ onSubmit, error }: LoginFormProps, ref) => {
        const { t } = useTranslation();

        return (
            <Formik<LoginUser>
                initialValues={{
                    login: "",
                    password: "",
                }}
                innerRef={ref}
                validationSchema={LoginSchema}
                onSubmit={onSubmit}
                validateOnMount
            >
                {({ touched, errors, handleChange, values }) => {
                    return (
                        <Form noValidate>
                            <TextField
                                name="login"
                                label={t("userName")}
                                type="text"
                                value={values.login}
                                onChange={handleChange}
                                autoFocus
                                required
                                fullWidth
                                margin="dense"
                                helperText={touched.login ? errors.login : ""}
                                error={touched.login && !!errors.login}
                            />
                            <TextField
                                name="password"
                                label={t("password")}
                                type="password"
                                value={values.password}
                                onChange={handleChange}
                                required
                                fullWidth
                                margin="dense"
                                helperText={touched.password ? errors.password : ""}
                                error={touched.password && !!errors.password}
                            />

                            {error && <FormHelperText error>{error}</FormHelperText>}

                            <div className={styles["info"]}>
                                <Typography component="p">
                                    {t("noAccountQuestion")}
                                    <strong>
                                        <Link to="/register">{t("register")}</Link>
                                    </strong>
                                </Typography>
                                <Typography component="span" variant="caption" color="error">
                                    {t("forgotPassword")}
                                    <strong>
                                        <MuiLink href="mailto:ajsystem@mail.ru">
                                            ajsystem@mail.ru
                                        </MuiLink>
                                    </strong>
                                </Typography>
                            </div>

                            <Button type="submit" color="primary" variant="contained" fullWidth>
                                {t("enter")}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
);

export default LoginForm;
