import RequestManager from "./RequestManager";

class TransferManager {
    static transferCompetitionRequest = async (
        competitionRequestId: number,
        targetCompetitionId: number
    ) => {
        await RequestManager.fetch(
            "/transfer",
            "POST",
            {
                target: targetCompetitionId,
                competitionRequestId,
            },
            false,
            2
        );
    };
}

export default TransferManager;
