import React from "react";
import PropTypes from "prop-types";
import { Container, LinearProgress, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { withTranslation } from "react-i18next";
import "./LoadingIndicator.css";

class LoadingIndicator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: this.props.show,
            showProgress: true,
            showSnackbar: false,
        };
    }

    componentDidMount() {
        this.timer = setTimeout(() => {
            this.setState({ showSnackbar: true, showProgress: false });
        }, 10000);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    handleClose = () => {
        this.setState({
            showSnackbar: false,
        });
    };

    renderSnackbarStatus = () => {
        return (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                open={this.state.showSnackbar}
            >
                <MuiAlert onClose={this.handleClose} severity="error" variant="filled">
                    {this.props.t("connectionProblems")}
                </MuiAlert>
            </Snackbar>
        );
    };
    render() {
        if (!this.state.show) return null;

        return (
            <Container className="indicator-container">
                {this.renderSnackbarStatus()}
                {this.state.showProgress && <LinearProgress />}
            </Container>
        );
    }
}

LoadingIndicator.propTypes = {
    show: PropTypes.bool,
};

export default withTranslation("common")(LoadingIndicator);
