import { REFEREE_ROLES } from "./Enums";

class Helpers {
    static getShortNameForAthlete(athlete) {
        if (athlete === null) {
            return null;
        }

        return `${athlete.lastName} ${
            athlete.firstName ? athlete.firstName[0].toUpperCase() + "." : ""
        }${athlete.middleName ? athlete.middleName[0].toUpperCase() + "." : ""}`;
    }

    static getNameForAthlete(athlete) {
        if (!athlete) {
            return null;
        }

        return `${athlete.lastName} ${athlete.firstName}`;
    }

    static getFullNameForAthlete(athlete) {
        if (athlete === null) {
            return null;
        }

        return `${athlete.lastName} ${athlete.firstName} ${
            athlete.middleName ? athlete.middleName : ""
        }`;
    }

    static getShortNameForUser(user) {
        if (user === null) {
            return null;
        }

        return `${user.lastName} ${user.firstName ? user.firstName[0] + "." : ""}${
            user.middleName ? user.middleName[0] + "." : ""
        }`;
    }

    static getFullNameForUser(user) {
        if (!user) {
            return null;
        }

        let fullName = `${user.lastName} ${user.firstName}`;
        if (user.middleName) {
            fullName += ` ${user.middleName}`;
        }

        return fullName;
    }

    static getNullOrFixedNumber(value, fixedValue) {
        return value === null ? null : (+value).toFixed(fixedValue);
    }

    static getRefereeProtocolNameByRoleName(roleName) {
        const PROTOCOL_NAMES = new Map([
            [REFEREE_ROLES.artistryJudge.systemName, "refereeArtistryProtocol"],
            [REFEREE_ROLES.executionJudge.systemName, "refereeExecutionProtocol"],
            [REFEREE_ROLES.complexityJudge.systemName, "refereeComplexityProtocol"],
            [REFEREE_ROLES.referee.systemName, "refereeArbiterProtocol"],
            [REFEREE_ROLES.general.systemName, "mainRefereeProtocol"],
            [REFEREE_ROLES.lineReferee.systemName, "lineRefereeProtocol"],
        ]);

        return PROTOCOL_NAMES.get(roleName);
    }

    static getAgeCategoryString(ageFrom, ageTo) {
        let from = ageFrom === null ? "" : ageFrom;

        const API_NO_UPPER_LIMIT_VALUE = 99;
        if (ageTo === API_NO_UPPER_LIMIT_VALUE) {
            return `${from}+`;
        }

        let to = ageTo === null ? "" : ageTo;
        return `${from} - ${to}`;
    }
}

export default Helpers;
