import React from "react";
import { Panel, ListGroup } from "react-bootstrap";
import PropTypes from "prop-types";
import Criterion from "./Criterion";
import CriterionTypesManager from "../../../../Modules/CriterionTypesManager";

class CriterionGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            group: props.criterionGroup,
        };

        this.onChange = this.onChange.bind(this);
    }

    onChange(value, criterionId) {
        this.setState({
            group: this.state.group,
        });

        this.props.onChange(value, this.state.group.criterionGroupId, criterionId);
    }

    getCriteria(criteriaArray) {
        return criteriaArray.map((criterion) => {
            return (
                <Criterion
                    onChange={this.onChange}
                    key={criterion.criterionId}
                    criterion={criterion}
                />
            );
        });
    }

    calcGroupScoreValue() {
        let result = 0;

        this.state.group.criteria.forEach((criterion) => {
            if (criterion.criterionTypeId === CriterionTypesManager.CRITERION_TYPES.penalty.id) {
                result -= criterion.penalty;
            } else {
                result += criterion.score;
            }
        });

        return result;
    }

    render() {
        return (
            <Panel
                header={`${this.state.group.criterionGroupName} / ${
                    this.state.group.criterionGroupNameEn
                } (${this.calcGroupScoreValue().toFixed(2)})`}
            >
                <ListGroup>{this.getCriteria(this.state.group.criteria)}</ListGroup>
            </Panel>
        );
    }
}

CriterionGroup.propTypes = {
    criterionGroup: PropTypes.object.isRequired,
    onChange: PropTypes.func,
};

export default CriterionGroup;
