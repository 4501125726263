import React from "react";
import PropTypes from "prop-types";
import ControlTypesManager from "../../../Modules/ControlTypes";
import IncDecNumberInput from "./IncDecNumberInput";
import RatingCheckbox from "./RatingCheckbox";
import NumberInput from "./NumberInput";
import "../../RefereePage/components/protocols/Protocol.css";

class Input extends React.Component {
    constructor(props) {
        super(props);

        this.controlType = ControlTypesManager.getControlType(this.props.controlTypeId);
    }

    getControl() {
        switch (this.controlType.name) {
            case ControlTypesManager.types.incDecNumberInput:
                return (
                    <IncDecNumberInput
                        minValue={this.controlType.minValue}
                        maxValue={this.controlType.maxValue}
                        step={this.controlType.step}
                        value={this.props.value}
                        onChange={this.props.onChange}
                        {...this.props}
                    />
                );
            case ControlTypesManager.types.checkBox:
                return (
                    <RatingCheckbox
                        value={this.props.value}
                        onChange={this.props.onChange}
                        worth={this.props.step}
                        {...this.props}
                    />
                );
            case ControlTypesManager.types.numberInput:
                return (
                    <NumberInput
                        className="number-input"
                        defaultValue={this.props.value}
                        onChange={this.props.onChange}
                        worth={this.props.step}
                        accuracy={1}
                        {...this.props}
                    />
                );
            default:
                throw new Error(`Control '${this.controlType.name}' is not implemented.`);
        }
    }

    render() {
        return this.getControl();
    }
}

Input.propTypes = {
    controlTypeId: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
    maxValue: PropTypes.number.isRequired,
    minValue: PropTypes.number.isRequired,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
};

export default Input;
