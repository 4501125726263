import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router";
import { withRouter } from "react-router-dom";
import AthletesList from "./Athletes/AthletesList";
import CompetitionsList from "./Competitions/CompetitionsList";
import MusicLibrary from "./MusicLibrary/MusicLibrary";
import RefereesList from "./Referees/RefereesList";

const Content = () => {
    return (
        <Switch>
            <Route
                path={`/trainer/athletes`}
                component={() => {
                    return <AthletesList />;
                }}
            />
            <Route
                path={`/trainer/referees`}
                component={() => {
                    return <RefereesList />;
                }}
            />
            <Route
                path={`/trainer/competitions`}
                component={() => {
                    return <CompetitionsList />;
                }}
            />
            <Route
                path={`/trainer/music`}
                component={() => {
                    return <MusicLibrary />;
                }}
            />
        </Switch>
    );
};

Content.propTypes = {
    history: PropTypes.object,
};

export default withRouter(Content);
