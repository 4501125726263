import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { LocalesEnum } from "./Modules/enums";

const detecionOptions = {
    order: ["localStorage", "navigator"],
    caches: ["localStorage"],
};

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: detecionOptions,
        ns: [
            "header",
            "trainerPage",
            "adminPage",
            "pages",
            "common",
            "refereePage",
            "modules",
            "home",
        ],
        load: "languageOnly",
        supportedLngs: [...Object.values(LocalesEnum)],
        defaultNS: "common",
        fallbackLng: LocalesEnum.RU,
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    });
export default i18n;
