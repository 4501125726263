import { StoreonModule } from "storeon";
import { TrainerReferee } from "@/interfaces";
import shortid from "shortid";
import Utils from "@/modules/Utils";

export type TrainerRefereeSelectable = Omit<TrainerReferee, "id"> & {
    id: number;
    selected: boolean;
};

export enum RefereeMemberPosition {
    MAIN_REFEREE = "MAIN_REFEREE",
    DEPUTY_MAIN_REFEREE = "DEPUTY_MAIN_REFEREE",
    MAIN_SECRETARY = "MAIN_SECRETARY",
    DEPUTY_MAIN_SECRETARY = "DEPUTY_MAIN_SECRETARY",
    BRIGADE_REFEREE = "BRIGADE_REFEREE",
    COMPLEXITY = "COMPLEXITY",
    EXPERT_COMPLEXITY = "EXPERT_COMPLEXITY",
    EXECUTION = "EXECUTION",
    EXPERT_EXECUTION = "EXPERT_EXECUTION",
    ARTISTRY = "ARTISTRY",
    EXPERT_ARTISTRY = "EXPERT_ARTISTRY",
    TIMEKEEPER = "TIMEKEEPER",
    LINE_REFEREE = "LINE_REFEREE",
    WITH_PARTICIPANTS = "WITH_PARTICIPANTS",
}

export interface RefereeMember {
    id: string;
    brigadeNumber: number;
    position: RefereeMemberPosition;
    referee: TrainerRefereeSelectable | null;
    number: number | null;
    isFixed: boolean;
    selected: boolean;
}

export interface RefereeLotteryModuleState {
    members: RefereeMember[];
    referees: TrainerRefereeSelectable[];
}

export interface RefereeLotteryModuleEvents {
    "members/add": RefereeMember;
    "members/assign": { id: string; number: number | null };
    "members/select": { id: string; selected: boolean };
    "members/select_all": { brigadeNumber: number; selected: boolean };
    "members/delete": { id: string };
    "referees/set": TrainerRefereeSelectable[];
    "referees/add": TrainerRefereeSelectable;
    "referees/delete": { id: number };
    "referees/delete_all";
    "referees/assign": { id: number; referee: TrainerRefereeSelectable | null };
    "referees/select": { id: number; selected: boolean };
    "referees/select_all": { selected: boolean };
    "referees/sort_by_category";
    "members/draw";
}

const defaultMembers: RefereeMember[] = [
    {
        id: shortid(),
        brigadeNumber: 1,
        position: RefereeMemberPosition.MAIN_REFEREE,
        referee: null,
        number: null,
        isFixed: true,
        selected: false,
    },
    {
        id: shortid(),
        brigadeNumber: 1,
        position: RefereeMemberPosition.MAIN_SECRETARY,
        referee: null,
        number: null,
        isFixed: true,
        selected: false,
    },
    {
        id: shortid(),
        brigadeNumber: 1,
        position: RefereeMemberPosition.BRIGADE_REFEREE,
        referee: null,
        number: null,
        isFixed: false,
        selected: false,
    },
    {
        id: shortid(),
        brigadeNumber: 2,
        position: RefereeMemberPosition.MAIN_REFEREE,
        referee: null,
        number: null,
        isFixed: true,
        selected: false,
    },
    {
        id: shortid(),
        brigadeNumber: 2,
        position: RefereeMemberPosition.MAIN_SECRETARY,
        referee: null,
        number: null,
        isFixed: true,
        selected: false,
    },
    {
        id: shortid(),
        brigadeNumber: 2,
        position: RefereeMemberPosition.BRIGADE_REFEREE,
        referee: null,
        number: null,
        isFixed: false,
        selected: false,
    },
];

function drawReferees(
    members: RefereeMember[],
    referees: TrainerRefereeSelectable[]
): RefereeMember[] {
    // получаем список доступных судей для подстановки
    const availableReferees = SortedCategories.map((c) =>
        referees.filter((el) => el.categoryReferee === c[0] && el.categoryFig === c[1])
    );

    // порядок заполнения по позициям
    const memberPositions: RefereeMemberPosition[] = [
        RefereeMemberPosition.MAIN_REFEREE,
        RefereeMemberPosition.MAIN_SECRETARY,
        RefereeMemberPosition.BRIGADE_REFEREE,
        RefereeMemberPosition.COMPLEXITY,
        RefereeMemberPosition.EXECUTION,
        RefereeMemberPosition.ARTISTRY,
        RefereeMemberPosition.TIMEKEEPER,
        RefereeMemberPosition.LINE_REFEREE,
        RefereeMemberPosition.WITH_PARTICIPANTS,
        RefereeMemberPosition.EXPERT_COMPLEXITY,
        RefereeMemberPosition.EXPERT_EXECUTION,
        RefereeMemberPosition.EXPERT_ARTISTRY,
    ];

    // формируем список позиций для 1 и 2 бригады
    const sortedMembers = memberPositions.map((rmp) => members.filter((m) => m.position === rmp));

    // перемешиваем для правильной подстановки в 2 бригады
    sortedMembers.map(Utils.swapArray);

    const execIndex = memberPositions.findIndex((p) => p === RefereeMemberPosition.EXECUTION);
    const artIndex = memberPositions.findIndex((p) => p === RefereeMemberPosition.ARTISTRY);

    // у назначения судей исполнения и артистичности логика подстановки другая (по очереди)
    const swapping = swapExectionArtistry(sortedMembers[execIndex], sortedMembers[artIndex]);
    sortedMembers[execIndex] = swapping[0];
    sortedMembers[artIndex] = swapping[1];

    // боже прости меня за такой код
    function swapExectionArtistry(arr1: RefereeMember[], arr2: RefereeMember[]) {
        const hasMoreOneBrigade =
            (arr1.findIndex((el) => el.brigadeNumber === 2 && el.selected) ||
                arr2.findIndex((el) => el.brigadeNumber === 2 && el.selected)) !== -1;

        if (!hasMoreOneBrigade) {
            arr1.forEach((_, i) => {
                const a = arr1[i];
                const b = arr2[i - 1];

                if ((i + 1) % 2 == 0) {
                    arr2[i - 1] = a;
                    arr1[i] = b;
                }
            });

            return [arr1, arr2];
        }

        const arrayA = [
            arr1.filter((el) => el.brigadeNumber === 1),
            arr1.filter((el) => el.brigadeNumber === 2),
        ];
        const arrayB = [
            arr2.filter((el) => el.brigadeNumber === 1),
            arr2.filter((el) => el.brigadeNumber === 2),
        ];

        const result = arrayA[0].map((_, i) => {
            const r: any[] = [];
            r.push(arrayA[0][i]);
            r.push(arrayA[1][i]);
            r.push(arrayB[0][i]);
            r.push(arrayB[1][i]);

            return r.flat();
        });

        return Utils.sliceToChunks(result.flat(), arrayA[0].length + arrayA[1].length);
    }

    const flatSortedMembers = sortedMembers.flat();

    const flatAvailableReferees = availableReferees.flat();

    // подставляем судей
    flatSortedMembers.forEach((sm) => {
        if (sm.isFixed || !sm.selected) return;

        let number = 0;
        let referee: TrainerRefereeSelectable | null = null;

        const { item, index } = selectRefereeByRestrictions(
            [...flatAvailableReferees],
            flatSortedMembers,
            sm.position,
            sm.brigadeNumber
        );

        referee = item;
        number = referees.findIndex((el) => el.id === referee?.id);

        flatAvailableReferees.splice(index, 1);

        sm.referee = referee ?? null;
        sm.number = referee ? number + 1 : null;
    });

    return flatSortedMembers;
}

// возможно переделать выборку не только в рамках категории
function selectRefereeByRestrictions(
    referees: TrainerRefereeSelectable[],
    previousMembers: RefereeMember[],
    position: RefereeMemberPosition,
    brigadeNumber: number
) {
    let extraCities: string[] = [];
    let extraRegions: string[] = [];

    if (position === RefereeMemberPosition.COMPLEXITY) {
        extraCities = previousMembers
            .filter(
                (el) =>
                    el.position === RefereeMemberPosition.BRIGADE_REFEREE &&
                    el.brigadeNumber === brigadeNumber
            )
            .map((el) => el.referee?.city ?? "");

        extraRegions = previousMembers
            .filter(
                (el) =>
                    el.position === RefereeMemberPosition.BRIGADE_REFEREE &&
                    el.brigadeNumber === brigadeNumber
            )
            .map((el) => el.referee?.region ?? "");
    }

    const usedCities = previousMembers
        .filter((el) => el.position === position && el.brigadeNumber === brigadeNumber)
        .map((el) => el.referee?.city)
        .concat(extraCities)
        .filter((el) => el);

    const usedRegions = previousMembers
        .filter((el) => el.position === position && el.brigadeNumber === brigadeNumber)
        .map((el) => el.referee?.region)
        .concat(extraRegions)
        .filter((el) => el);

    // находим подходяшие по городу и региону индексы судей из общего списка
    let refereeByFreeRegionIndex = referees.findIndex((el) => !usedRegions.includes(el.region));
    let refereeByFreeCityIndex = referees.findIndex((el) => !usedCities.includes(el.city));

    // берем всех судей с самой высокой доступной категорией
    const highRankReferees = referees.filter(
        (r) =>
            r.categoryReferee === referees[0].categoryReferee &&
            r.categoryFig === referees[0].categoryFig
    );

    // находим подходяшие по городу и региону индексы судей из списка с самой высокой категорией
    if (highRankReferees.length) {
        const refereesByFreeCity = highRankReferees.filter((el) => !usedCities.includes(el.city));
        const rndHighRefereeByFreeCityIndex = refereesByFreeCity.length
            ? Math.floor(Math.random() * refereesByFreeCity.length)
            : -1;

        const highRankRefereeByFreeCityIndex =
            rndHighRefereeByFreeCityIndex !== -1
                ? referees.findIndex(
                      (r) => r.id === refereesByFreeCity[rndHighRefereeByFreeCityIndex].id
                  )
                : refereeByFreeCityIndex;

        if (highRankRefereeByFreeCityIndex !== -1) {
            refereeByFreeCityIndex = highRankRefereeByFreeCityIndex;
        }

        const refereesByFreeRegion = highRankReferees.filter(
            (el) => !usedRegions.includes(el.region)
        );
        const rndHighRefereeByFreeRegionIndex = refereesByFreeRegion.length
            ? Math.floor(Math.random() * refereesByFreeRegion.length)
            : -1;

        const highRankRefereeByFreeRegionIndex =
            rndHighRefereeByFreeRegionIndex !== -1
                ? referees.findIndex(
                      (r) => r.id === refereesByFreeRegion[rndHighRefereeByFreeRegionIndex].id
                  )
                : refereeByFreeRegionIndex;

        if (highRankRefereeByFreeRegionIndex !== -1) {
            refereeByFreeRegionIndex = highRankRefereeByFreeRegionIndex;
        }
    }

    let rndIndx = Math.floor(Math.random() * referees.length);
    let referee: TrainerRefereeSelectable | null = null;

    if (refereeByFreeCityIndex !== -1) {
        rndIndx = refereeByFreeCityIndex;
    }

    if (refereeByFreeRegionIndex !== -1) {
        rndIndx = refereeByFreeRegionIndex;
    }

    referee = referees[rndIndx];

    return { item: referee, index: rndIndx };
}

/**
 * 1 - category referee
 * 2 - category fig
 */
export const SortedCategories = [
    ["СВК", "1"],
    ["СВК", "2"],
    ["СВК", "3"],
    ["СВК", "4"],
    ["1", "1"],
    ["1", "2"],
    ["1", "3"],
    ["1", "4"],
    ["2", "1"],
    ["2", "2"],
    ["2", "3"],
    ["2", "4"],
    ["3", "1"],
    ["3", "2"],
    ["3", "3"],
    ["3", "4"],
    ["4", "1"],
    ["4", "2"],
    ["4", "3"],
    ["4", "4"],
    ["СВК", "Б/К"],
    ["1", "Б/К"],
    ["2", "Б/К"],
    ["3", "Б/К"],
    ["4", "Б/К"],
    ["Б/К", "1"],
    ["Б/К", "2"],
    ["Б/К", "3"],
    ["Б/К", "4"],
    ["Б/К", "Б/К"],
];

function sortRefereesByCategory(referees: TrainerRefereeSelectable[]): TrainerRefereeSelectable[] {
    return SortedCategories.flatMap((category) => {
        const filteredReferees = referees.filter(
            (r) => r.categoryReferee === category[0] && r.categoryFig === category[1]
        );
        return filteredReferees.sort((a, b) => {
            if (a.lastName < b.lastName) {
                return -1;
            }
            if (a.lastName > b.lastName) {
                return 1;
            }
            return 0;
        });
    });
}

export const refereeLotteryModule: StoreonModule<
    RefereeLotteryModuleState,
    RefereeLotteryModuleEvents
> = (store) => {
    store.on("@init", () => ({
        members: defaultMembers,
        referees: [],
    }));

    store.on("members/add", (state, member: RefereeMember) => ({
        members: [...state.members, member],
    }));

    store.on("members/delete", (state, { id }) => {
        const copy = [...state.members];
        const index = copy.findIndex((el) => el.id === id);
        copy.splice(index, 1);

        return {
            members: copy,
        };
    });

    store.on("members/assign", (state, { id, number }) => {
        const { members, referees } = state;

        const copy = [...members];
        const index = copy.findIndex((el) => el.id === id);

        if (number) {
            const referee = referees[+number - 1];
            copy[index].referee = referee?.selected ? referee : null;
        } else {
            copy[index].referee = null;
        }

        copy[index].number = number;

        return {
            members: copy,
        };
    });

    store.on("members/select", (state, { id, selected }) => {
        const copy = [...state.members];
        const index = copy.findIndex((el) => el.id === id);

        copy[index].selected = selected;

        return {
            members: copy,
        };
    });

    store.on("members/select_all", (state, { brigadeNumber, selected }) => {
        const copy = [...state.members];

        copy.forEach((el) => {
            if (el.brigadeNumber === brigadeNumber && !el.isFixed) {
                el.selected = selected;
            }
        });

        return {
            members: copy,
        };
    });

    store.on("referees/set", (state, referees) => ({ referees }));

    store.on("referees/add", (state, referee) => ({
        referees: [...state.referees, referee],
    }));

    store.on("referees/delete", (state, { id }) => {
        const copy = [...state.referees];
        const index = copy.findIndex((el) => el.id === id);
        copy.splice(index, 1);

        return {
            referees: copy,
        };
    });

    store.on("referees/delete_all", (state) => {
        return {
            referees: [],
        };
    });

    store.on("referees/assign", (state, { id, referee }) => {
        const { referees } = state;

        const copy = [...referees];
        const index = copy.findIndex((el) => el.id === id);

        if (referee) {
            copy[index] = referee;
        }

        return {
            referees: copy,
        };
    });

    store.on("referees/select", (state, { id, selected }) => {
        const copy = [...state.referees];
        const index = copy.findIndex((el) => el.id === id);

        copy[index].selected = selected;
        return {
            referees: copy,
        };
    });

    store.on("referees/select_all", (state, { selected }) => {
        const copy = [...state.referees];

        copy.forEach((el) => {
            el.selected = selected;
        });

        return {
            referees: copy,
        };
    });

    store.on("referees/sort_by_category", (state) => {
        const copy = [...state.referees];

        return {
            referees: sortRefereesByCategory(copy),
        };
    });

    store.on("members/draw", (state) => {
        const { members, referees } = state;

        const copyReferees = [...referees].filter((r) => r.selected);
        const copyMembers = [...members].map((el) => ({ ...el, number: null, referee: null }));

        return {
            members: drawReferees(copyMembers, copyReferees),
        };
    });
};
