import React from "react";
import MuiDataTable from "../../Common/MuiDataTable";
import RequestManager from "../../../Modules/RequestManager";
import UserPopup from "./UserPopup";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import { Grid, Fab, Typography } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { withTranslation } from "react-i18next";

class UsersList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            users: [],
            modalIsOpen: false,
        };

        this.handleNewUserClick = this.handleNewUserClick.bind(this);
        this.modalCancel = this.modalCancel.bind(this);
        this.refreshUsersList = this.refreshUsersList.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentDidMount() {
        this.refreshUsersList();
    }

    refreshUsersList() {
        this.setState({ isLoading: true });
        RequestManager.fetch("users", "get")
            .then((response) => {
                this.setState({
                    isLoading: false,
                    users: response.body.data,
                });
            })
            .catch((err) => {
                throw err;
            });
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
        });
    }

    onEditClick = (user) => {
        const users = this.state.users;
        const itemId = user.id;

        users.map((userItem) => {
            if (userItem.id === +itemId) {
                this.setState({
                    selectedUser: userItem,
                });
            }
            return null;
        });
        this.setState({
            modalIsOpen: true,
        });
    };

    modalCancel(event) {
        this.setState({
            modalIsOpen: false,
        });
    }

    handleNewUserClick(event) {
        this.setState({
            modalIsOpen: true,
            selectedUser: {
                id: -1, // new user
                userName: "",
                login: "",
                password: "",
                userRoles: [],
                refereeRank: `1 ${this.props.t("category")}`,
            },
        });
    }

    render() {
        const { t } = this.props;
        this.state.users.map((item) => {
            item.userFullName = item.lastName + " " + item.firstName;
            return item;
        });

        const columns = [
            { title: "#", field: "id" },
            { title: t("userName"), field: "userFullName" },
            { title: t("login"), field: "login" },
            { title: t("email"), field: "email" },
        ];

        return (
            <React.Fragment>
                <Grid container justify="space-between" className="main-view-head">
                    <Grid item>
                        <Typography variant="h4">{t("users")}</Typography>
                    </Grid>
                    <Grid item>
                        <Fab variant="extended" size="small" onClick={this.handleNewUserClick}>
                            <PersonAddIcon className="extended-icon-fab-margin" />
                            {t("newUser")}
                        </Fab>
                    </Grid>
                </Grid>

                {this.state.modalIsOpen && (
                    <UserPopup
                        header={t("newUser")}
                        onSubmit={this.refreshUsersList}
                        modalCancel={this.modalCancel}
                        data={this.state.selectedUser}
                        modalIsOpen={this.state.modalIsOpen}
                    ></UserPopup>
                )}
                <MuiDataTable
                    showToolbar
                    showSearch
                    columns={columns}
                    data={this.state.users}
                    isLoading={this.state.isLoading}
                    canEdit
                    onEditClick={this.onEditClick}
                    pageSize={10}
                    enableGrouping={false}
                />
            </React.Fragment>
        );
    }
}

UsersList.propTypes = {
    history: PropTypes.object,
};

export default withRouter(withTranslation("adminPage")(UsersList));
