import React from "react";
import RequestManager from "../../../Modules/RequestManager";
import Helpers from "../../../Modules/Helpers";
import {
    Button,
    Grid,
    List,
    ListItem,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import { NotificationManager } from "react-notifications";
import "./CompetitionPlansList.css";
import { TFunction, withTranslation } from "react-i18next";
import { AgeCategory, Category, CompetitionPlan } from "../../../interfaces";
import { ageCategoryApiService, categoryApiService } from "@/api/Services";

type CompetitionPlansListProps = {
    competitionId: number;
    t: TFunction;
};

type CompetitionPlansListState = {
    competitionPlans: CompetitionPlan[];
    isLoading: boolean;
    ageCategories: AgeCategory[];
    categories: Category[];
    dialogIsOpen: boolean;
    selectedPlan?: CompetitionPlan;
    categoryId: number;
    ageCategoryId: number;
    entryFee: number;
    selectedButtonState: number;
    selectedPlanId?: number;
};

class CompetitionPlansList extends React.Component<
    CompetitionPlansListProps,
    CompetitionPlansListState
> {
    constructor(props) {
        super(props);

        this.state = {
            competitionPlans: [],
            isLoading: true,
            ageCategories: [],
            categories: [],
            dialogIsOpen: false,
            categoryId: -1,
            ageCategoryId: -1,
            entryFee: 0,
            selectedButtonState: 0,
        };

        this.getPlanList = this.getPlanList.bind(this);
        this.getAgeButtons = this.getAgeButtons.bind(this);
        this.getButtonState = this.getButtonState.bind(this);
        this.savePlanItem = this.savePlanItem.bind(this);
        this.updateLists = this.updateLists.bind(this);
        this.onSelectPlan = this.onSelectPlan.bind(this);
        this.getCompetitionPlan = this.getCompetitionPlan.bind(this);
    }

    componentDidMount() {
        this.updateLists();
    }

    updateLists() {
        this.updateCompetitionPlans();
        this.updateAgeCategories();
        this.updateCategories();
    }

    updateAgeCategories() {
        this.setState({ isLoading: true });
        ageCategoryApiService.getAll().then(({ result }) =>
            this.setState({
                ageCategories: result.rows,
                isLoading: false,
            })
        );
    }

    updateCategories() {
        this.setState({ isLoading: true });
        categoryApiService.getAll().then(({ result }) =>
            this.setState({
                categories: result.rows,
                isLoading: false,
            })
        );
    }

    updateCompetitionPlans() {
        this.setState({ isLoading: true });
        RequestManager.fetch(`v2/competitionPlans/${this.props.competitionId}`, "get")
            .then((response) => {
                this.setState({
                    competitionPlans: response.body.CompetitionPlans,
                    isLoading: false,
                });
            })
            .catch((err) => {
                throw err;
            });
    }

    savePlanItem(categoryId, ageCategoryId, state) {
        const data = {
            competitionPlanId: this.state.selectedPlan ? this.state.selectedPlan.id : null,
            competitionId: this.props.competitionId,
            ageCategoryId: ageCategoryId,
            categoryId: categoryId,
            entryFee: this.state.entryFee,
            state: state,
        };

        return RequestManager.fetch("competitionPlans", "POST", data, false)
            .then((result) => {
                this.updateLists();
            })
            .catch((err) => NotificationManager.error(this.props.t("saveError")));
    }

    getCompetitionPlan(categoryId: number, ageCategoryId: number) {
        let competitionPlan: CompetitionPlan | undefined;
        this.state.competitionPlans.forEach((cp) => {
            if (categoryId == cp.categoryId && ageCategoryId == cp.ageCategoryId)
                competitionPlan = cp;
        });
        return competitionPlan;
    }

    onSelectPlan(category: Category, ageCategory: AgeCategory) {
        const selectedPlan = this.getCompetitionPlan(category.id, ageCategory.id);
        const state = this.getButtonState(category.id, ageCategory.id);

        this.setState({
            dialogIsOpen: true,
            categoryId: category.id,
            ageCategoryId: ageCategory.id,
            selectedButtonState: state,
            selectedPlan: selectedPlan,
            entryFee: selectedPlan ? selectedPlan.entryFee : 0,
        });
    }

    closeDialog = () => {
        this.setState({
            selectedPlanId: undefined,
            dialogIsOpen: false,
            entryFee: 0,
        });
    };

    changeInput = (event) => {
        this.setState({
            entryFee: event.target.value,
        });
    };

    handleDeletePlan = () => {
        RequestManager.fetch(`competitionPlans/${this.state.selectedPlan?.id}`, "delete").then(
            (result) => {
                if (!result.body.error) {
                    NotificationManager.success(this.props.t("deleteSuccess"));
                    this.updateLists();
                } else {
                    NotificationManager.error(this.props.t("deleteError"));
                }
            }
        );
        this.closeDialog();
    };

    handleSubmit = () => {
        const { categoryId, ageCategoryId, selectedButtonState } = this.state;

        this.savePlanItem(categoryId, ageCategoryId, selectedButtonState);
        this.closeDialog();
    };

    getButtonState(categoryId: number, ageCategoryId: number) {
        let isFound = 0;
        this.state.competitionPlans.find((element) => {
            if (element.categoryId === categoryId && element.ageCategoryId === ageCategoryId) {
                isFound = 1;
                return 1;
            }
            return 0;
        });
        return isFound;
    }
    getPlanList() {
        return this.state.categories.map((category) => (
            <ListItem key={category.id} disableGutters divider>
                <Grid container alignItems="center" justify="space-between" spacing={3}>
                    <Grid item md={6}>
                        <Typography>{category.worldCode}</Typography>
                    </Grid>
                    <Grid container item md={6} wrap="wrap" className="age-category-btn-list">
                        {this.getAgeButtons(category)}
                    </Grid>
                </Grid>
            </ListItem>
        ));
    }

    getAgeButtons(category: Category) {
        return this.state.ageCategories.map((ageCategory, i) => (
            <Button
                key={`${ageCategory.id}&${category.id}&${i}`}
                className={`age-category-btn ${
                    this.getButtonState(category.id, ageCategory.id) === 0 ? "default" : "success"
                }`}
                onClick={() => this.onSelectPlan(category, ageCategory)}
            >
                <Typography>
                    {Helpers.getAgeCategoryString(ageCategory.ageFrom, ageCategory.ageTo)}
                </Typography>

                <Typography variant="body2" display="block">
                    {
                        this.state.competitionPlans.find(
                            (plan) =>
                                plan.categoryId == category.id &&
                                ageCategory.id == plan.ageCategoryId
                        )?.entryFee
                    }
                </Typography>
            </Button>
        ));
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <List disablePadding>{this.getPlanList()}</List>
                {this.state.dialogIsOpen && (
                    <Dialog fullWidth open={this.state.dialogIsOpen}>
                        <ValidatorForm onSubmit={this.handleSubmit}>
                            <DialogTitle disableTypography>
                                {this.state.selectedButtonState === 0
                                    ? t("createPlan")
                                    : t("editPlan")}
                            </DialogTitle>
                            <DialogContent>
                                <TextValidator
                                    label={t("specifyEntryFee")}
                                    fullWidth
                                    type="number"
                                    name="entryFee"
                                    validators={["required"]}
                                    errorMessages={[t("fillField")]}
                                    onChange={this.changeInput}
                                    value={this.state.entryFee}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.closeDialog}>{t("cancel")}</Button>
                                {this.state.selectedButtonState === 1 && (
                                    <Button onClick={this.handleDeletePlan} color="secondary">
                                        {t("delete")}
                                    </Button>
                                )}
                                <Button type="submit" color="primary" variant="contained">
                                    {t("save")}
                                </Button>
                            </DialogActions>
                        </ValidatorForm>
                    </Dialog>
                )}
            </>
        );
    }
}

export default withTranslation("adminPage")(CompetitionPlansList);
