import { Grid, Tab, Tabs } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import RequestManager from "../../../Modules/RequestManager";
import LoadingIndicator from "../../Common/LoadingIndicator";
import TabPanel from "../../Common/TabPanel";
import PlanRequestsList from "./PlanRequestsList";

const CompetitionPlansList = ({ competitionId }) => {
    const [loading, setLoading] = useState(true);
    const [plans, setPlans] = useState([] as any[]);
    const [tab, setTab] = React.useState(0);

    useEffect(() => {
        const fetch = async () => {
            const res = await RequestManager.getCompetitionPlans(competitionId);
            setPlans(res.CompetitionPlans);
            setLoading(false);
        };
        fetch();
    }, [competitionId]);

    if (loading) return <LoadingIndicator show={loading} />;

    return (
        <Grid container spacing={4}>
            <Grid item xs={2}>
                <Tabs
                    orientation="vertical"
                    indicatorColor="primary"
                    variant="scrollable"
                    value={tab}
                    onChange={(e, v) => setTab(v)}
                >
                    {plans.map((plan, index) => {
                        return (
                            <Tab
                                key={plan.competitionPlanId}
                                label={plan.competitionPlanWorldName}
                            />
                        );
                    })}
                </Tabs>
            </Grid>
            <Grid item xs>
                {plans.map((plan, index) => {
                    return (
                        <TabPanel key={plan.competitionPlanId} value={tab} index={index}>
                            <PlanRequestsList competitionPlanId={plan.competitionPlanId} />
                        </TabPanel>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default CompetitionPlansList;
