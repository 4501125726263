import {
    competitionApiService,
    competitionAttachmentApiService,
    fileApiService,
} from "@/api/Services";
import {
    Competition,
    CompetitionAttachment,
    CreateCompetitionAttachment,
    File as FileType,
} from "@/interfaces";
import CompetitionsManager from "@/modules/CompetitionsManager";
import Helpers from "@/modules/Helpers";
import {
    Box,
    Button,
    FormControlLabel,
    FormGroup,
    Grid,
    IconButton,
    MenuItem,
    Paper,
    Switch,
    TextField,
    Tooltip,
    Typography,
    List,
    ListItem,
    ListItemSecondaryAction,
} from "@material-ui/core";
import { Close, Delete, InsertDriveFile } from "@material-ui/icons";
import { FieldArray, Form, Formik, FormikConfig } from "formik";
import { useSnackbar } from "notistack";
import React, { ChangeEventHandler, FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import DatePicker from "../../../Common/InputControls/DatePicker";
import LoadingIndicator from "../../../Common/LoadingIndicator";

type CompetitionConfiguration = Omit<
    Competition,
    | "id"
    | "isAllowedRequests"
    | "beamerUserId"
    | "ownerUserId"
    | "cover"
    | "brigadeVariant"
    | "attachments"
    | "statusId"
> & {
    cover: FileType | File | null;
    attachments: Array<CompetitionAttachment | File>;
    attachmentsToRemove: number[];
};

const Configuration: FunctionComponent = () => {
    const { t } = useTranslation("adminPage");
    const { t: commonT } = useTranslation("common");
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams<{ id: string }>();

    const { data, isFetching } = useQuery(["competition", id], () =>
        competitionApiService.getOne(Number(id))
    );

    const { data: referees } = useQuery(["referees"], CompetitionsManager.getReferees, {
        select: (data) => data.Referees,
    });

    const { data: statuses } = useQuery(["statuses"], CompetitionsManager.getCompetitionStatuses, {
        select: (data) => data.Statuses,
    });

    const uploadAttachment: (file: File) => Promise<void> = async (file) => {
        try {
            const { result } = await fileApiService.upload({ file, displayName: file.name });
            const dto: CreateCompetitionAttachment = {
                competitionId: Number(id),
                fileId: result.id,
            };
            await competitionAttachmentApiService.create(dto);
        } catch (err) {
            const message = err.response?.data?.message;
            enqueueSnackbar(message, {
                variant: "error",
            });
        }
    };

    const removeAttachment: (id: number) => Promise<void> = async (id) => {
        try {
            await competitionAttachmentApiService.delete(id);
        } catch (err) {
            const message = err.response?.data?.message;
            enqueueSnackbar(message, {
                variant: "error",
            });
        }
    };

    const submit: FormikConfig<CompetitionConfiguration>["onSubmit"] = async (values, actions) => {
        const dto: CompetitionConfiguration = { ...values };

        if (dto.cover instanceof File) {
            if (dto.coverFileId) {
                await fileApiService.reUpload(dto.coverFileId, { file: dto.cover });
            } else {
                const { result } = await fileApiService.upload({ file: dto.cover });
                dto.coverFileId = result.id;
            }
        }

        const attachmentPromisesToRemove: PromiseLike<void>[] = [];
        for (const attachmentId of dto.attachmentsToRemove) {
            attachmentPromisesToRemove.push(removeAttachment(attachmentId));
        }
        await Promise.all(attachmentPromisesToRemove);

        const promises: PromiseLike<void>[] = [];
        for (const attachment of dto.attachments) {
            if (attachment instanceof File) {
                promises.push(uploadAttachment(attachment));
            }
        }
        await Promise.all(promises);

        try {
            const { message } = await competitionApiService.update(Number(id), dto);
            enqueueSnackbar(message, {
                variant: "success",
            });
        } catch (err) {
            const message = err.response?.data?.message;
            enqueueSnackbar(message, {
                variant: "error",
            });
        }

        actions.setFieldValue("attachmentsToRemove", []);
    };

    if (isFetching) return <LoadingIndicator show={isFetching} />;

    const initialData: CompetitionConfiguration = {
        name: data?.result.name ?? "",
        description: data?.result.description ?? "",
        coverFileId: data?.result.coverFileId ?? null,
        cover: data?.result.cover ?? null,
        place: data?.result.place ?? "",
        dateFrom: data?.result.dateFrom ?? null,
        dateTo: data?.result.dateTo ?? null,
        mainRefereeId: data?.result.mainRefereeId ?? null,
        deputyId: data?.result.deputyId ?? null,
        secretaryId: data?.result.secretaryId ?? null,
        secretaryDeputyId: data?.result.secretaryDeputyId ?? null,
        isPublic: data?.result.isPublic ?? false,
        canChairRateAll: data?.result.canChairRateAll ?? false,
        // statusId: data?.result.statusId ?? 1,
        attachments: data?.result.attachments ?? [],
        attachmentsToRemove: [],
    };

    return (
        <Formik<CompetitionConfiguration>
            initialValues={initialData}
            onSubmit={submit}
            validateOnChange={false}
            validateOnBlur
            enableReinitialize
        >
            {({ handleChange, values, setFieldValue, handleSubmit }) => {
                const handleRemoveAttachment: (id: number) => void = (id) => {
                    const arr: number[] = [...values.attachmentsToRemove];
                    arr.push(id);
                    setFieldValue("attachmentsToRemove", arr);
                };

                const handleSelect: ChangeEventHandler<HTMLInputElement> = (e) => {
                    const { name, value } = e.target;
                    setFieldValue(name, typeof value === "string" ? null : value);
                };

                return (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={12} md={6}>
                                <TextField
                                    name="name"
                                    value={values.name}
                                    label={t("name")}
                                    onChange={handleChange}
                                    fullWidth
                                    margin="dense"
                                />
                                <TextField
                                    name="description"
                                    value={values.description}
                                    onChange={handleChange}
                                    label={t("description")}
                                    fullWidth
                                    margin="dense"
                                    multiline
                                />
                                <TextField
                                    name="place"
                                    value={values.place}
                                    onChange={handleChange}
                                    label={t("place")}
                                    fullWidth
                                    margin="dense"
                                />

                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            name="dateFrom"
                                            value={values.dateFrom}
                                            onChange={setFieldValue}
                                            label={t("dateFrom")}
                                            margin="dense"
                                            fullWidth
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <DatePicker
                                            name="dateTo"
                                            value={values.dateTo}
                                            onChange={setFieldValue}
                                            label={t("dateTo")}
                                            margin="dense"
                                            fullWidth
                                        />
                                    </Grid>
                                </Grid>

                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="isPublic"
                                                checked={values.isPublic}
                                                onChange={(e, checked) =>
                                                    setFieldValue(e.target.name, checked)
                                                }
                                                color="primary"
                                            />
                                        }
                                        label={t("makePublic")}
                                    />

                                    <FormControlLabel
                                        control={
                                            <Switch
                                                name="canChairRateAll"
                                                checked={values.canChairRateAll}
                                                onChange={(e, checked) =>
                                                    setFieldValue(e.target.name, checked)
                                                }
                                                color="primary"
                                            />
                                        }
                                        label={t("canChairRateAll")}
                                    />
                                </FormGroup>

                                <TextField
                                    select
                                    value={values.mainRefereeId ?? ""}
                                    name="mainRefereeId"
                                    label={t("mainReferee")}
                                    fullWidth
                                    margin="dense"
                                    onChange={handleSelect}
                                >
                                    <MenuItem value="">{t("notAssigned")}</MenuItem>
                                    {referees?.map((referee) => (
                                        <MenuItem value={referee.refereeId} key={referee.refereeId}>
                                            {Helpers.getFullNameForUser(referee)}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    select
                                    value={values.deputyId ?? ""}
                                    name="deputyId"
                                    label={t("deputy")}
                                    fullWidth
                                    margin="dense"
                                    onChange={handleSelect}
                                >
                                    <MenuItem value="">{t("notAssigned")}</MenuItem>
                                    {referees?.map((referee) => (
                                        <MenuItem value={referee.refereeId} key={referee.refereeId}>
                                            {Helpers.getFullNameForUser(referee)}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    select
                                    value={values.secretaryId ?? ""}
                                    name="secretaryId"
                                    label={t("secretary")}
                                    fullWidth
                                    margin="dense"
                                    onChange={handleSelect}
                                >
                                    <MenuItem value="">{t("notAssigned")}</MenuItem>
                                    {referees?.map((referee) => (
                                        <MenuItem value={referee.refereeId} key={referee.refereeId}>
                                            {Helpers.getFullNameForUser(referee)}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                <TextField
                                    select
                                    value={values.secretaryDeputyId ?? ""}
                                    name="secretaryDeputyId"
                                    label={t("secretaryDeputy")}
                                    onChange={handleSelect}
                                    fullWidth
                                    margin="dense"
                                >
                                    <MenuItem value="">{t("notAssigned")}</MenuItem>
                                    {referees?.map((referee) => (
                                        <MenuItem value={referee.refereeId} key={referee.refereeId}>
                                            {Helpers.getFullNameForUser(referee)}
                                        </MenuItem>
                                    ))}
                                </TextField>

                                {/* <TextField
                                    margin="dense"
                                    fullWidth
                                    label={t("status")}
                                    select
                                    value={values.statusId}
                                    name="statusId"
                                    onChange={handleChange}
                                >
                                    {statuses?.map((item) => {
                                        return (
                                            <MenuItem
                                                value={item.competitionStatusId}
                                                key={item.competitionStatusId}
                                            >
                                                {item.competitionStatusName}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField> */}
                            </Grid>

                            <Grid item xs={12} md={5}>
                                <Box
                                    component={Paper}
                                    height={350}
                                    width={500}
                                    style={{ position: "relative" }}
                                >
                                    {values.cover ? (
                                        <img
                                            src={
                                                values.cover instanceof File
                                                    ? URL.createObjectURL(values.cover)
                                                    : values.cover.path
                                            }
                                            alt={commonT("competitionCover")}
                                            width="100%"
                                            height={350}
                                        />
                                    ) : null}

                                    <label
                                        htmlFor="cover"
                                        style={{
                                            position: "absolute",
                                            top: "50%",
                                            left: "50%",
                                            transform: `translate(-50%, -50%)`,
                                            textAlign: "center",
                                        }}
                                    >
                                        <input
                                            name="cover"
                                            id="cover"
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={(e) =>
                                                setFieldValue("cover", e.target.files?.[0])
                                            }
                                        />
                                        {!values.cover ? (
                                            <Typography variant="h5">{commonT("image")}</Typography>
                                        ) : null}
                                        <Button variant="contained" component="span">
                                            {values.cover ? t("edit") : "Выбрать"}
                                        </Button>
                                    </label>
                                    {values.cover ? (
                                        <Tooltip title={t("delete").toString()} arrow>
                                            <IconButton
                                                onClick={() => {
                                                    setFieldValue("cover", null);
                                                    setFieldValue("coverFileId", null);
                                                }}
                                                style={{
                                                    position: "absolute",
                                                    top: "0",
                                                    right: "0",
                                                }}
                                            >
                                                <Close />
                                            </IconButton>
                                        </Tooltip>
                                    ) : null}
                                </Box>
                                <FieldArray name="attachments">
                                    {({ handlePush, remove }) => (
                                        <Box marginTop={2}>
                                            <Grid container justifyContent="space-between">
                                                <Typography variant="h6">
                                                    {commonT("attachments")}
                                                </Typography>
                                                <Button
                                                    variant="text"
                                                    color="primary"
                                                    onClick={handlePush(null)}
                                                >
                                                    {t("add")}
                                                </Button>
                                            </Grid>
                                            <List>
                                                {!values.attachments.length && (
                                                    <Typography>
                                                        {commonT("attachmentsNotFound")}
                                                    </Typography>
                                                )}
                                                {values.attachments.map((attachment, i) => {
                                                    return (
                                                        <ListItem key={i} disableGutters>
                                                            <InsertDriveFile />
                                                            <label htmlFor={`attachment-${i}`}>
                                                                <input
                                                                    name={`attachments[${i}]`}
                                                                    id={`attachment-${i}`}
                                                                    type="file"
                                                                    style={{
                                                                        display: "none",
                                                                    }}
                                                                    onChange={(e) =>
                                                                        setFieldValue(
                                                                            e.target.name,
                                                                            e.target.files?.[0]
                                                                        )
                                                                    }
                                                                    disabled={!!attachment}
                                                                />
                                                                {attachment ? (
                                                                    <Typography variant="body2">
                                                                        {attachment instanceof File
                                                                            ? attachment.name
                                                                            : attachment.file
                                                                                  .displayName}
                                                                    </Typography>
                                                                ) : null}
                                                                {!attachment && (
                                                                    <Button component="span">
                                                                        {t("selectFile")}
                                                                    </Button>
                                                                )}
                                                            </label>
                                                            <ListItemSecondaryAction>
                                                                <Tooltip
                                                                    title={t("delete").toString()}
                                                                    arrow
                                                                >
                                                                    <IconButton
                                                                        onClick={() => {
                                                                            if (
                                                                                attachment &&
                                                                                "id" in attachment
                                                                            ) {
                                                                                handleRemoveAttachment(
                                                                                    attachment.id
                                                                                );
                                                                            }
                                                                            remove(i);
                                                                        }}
                                                                    >
                                                                        <Delete />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </ListItemSecondaryAction>
                                                        </ListItem>
                                                    );
                                                })}
                                            </List>
                                        </Box>
                                    )}
                                </FieldArray>
                                <Box display="flex" justifyContent="flex-end" marginTop={2}>
                                    <Button color="primary" type="submit" variant="contained">
                                        {t("saveChanges")}
                                    </Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Form>
                );
            }}
        </Formik>
    );
};

export default Configuration;
