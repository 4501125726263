import clsx from "clsx";
import React from "react";
import { Athlete, BeamerSlideObject } from "../../Modules/Beamer/types";
import styles from "./performance-view.module.scss";

type Props = {
    perfomanceObject: BeamerSlideObject;
};

const showValueOrDash = (value): string => {
    if (typeof value === "string") return value.length ? value : "-";
    if (typeof value === "number") return value !== 0 ? value.toString() : "-";
    return "-";
};

const isIndividualPerformance = (performanceTypeCode: string) => {
    if (performanceTypeCode === "IW") return true;
    if (performanceTypeCode === "IM") return true;
    return false;
};

const getAthleteName = (perfWorldCode: string, athlete: Athlete) => {
    let resultName = athlete.lastName.replace(/\s/g, "");
    if (isIndividualPerformance(perfWorldCode))
        resultName += `\t${athlete.firstName.replace(/\s/g, "")}`;
    return resultName;
};

const showedAthletesCount = 8;

const PerformanceView = (props: Props): JSX.Element => {
    const { perfomanceObject } = props;
    const { athletes } = perfomanceObject;

    return (
        <div className={styles["performance-view-main"]}>
            <div className={styles["first-line"]}>
                <div>{showValueOrDash(perfomanceObject.worldCode)}</div>
                <div>{showValueOrDash(perfomanceObject.ageCategoryCode)}</div>
            </div>

            <div className={styles["athletes"]}>
                <div
                    className={clsx({
                        [styles["marquee"]]: !isIndividualPerformance(perfomanceObject.worldCode),
                    })}
                >
                    {athletes
                        ?.slice(0, showedAthletesCount)
                        .map(
                            (athlete, i) =>
                                `${showValueOrDash(
                                    getAthleteName(perfomanceObject.worldCode, athlete)
                                )}${athletes.length > 1 && i < athletes.length - 1 ? "," : ""} `
                        )}
                </div>
            </div>

            <div className={styles["score-rank-line"]}>
                <div>
                    <div>SCORE</div>
                    <div className={styles["score-value"]}>
                        {showValueOrDash(perfomanceObject.scores.totalScore) ?? "-"}
                    </div>
                </div>
                <div>
                    <div>RANK</div>
                    <div className={styles["rank-value"]}>
                        {showValueOrDash(perfomanceObject.place) ?? "-"}
                    </div>
                </div>
            </div>
            <div className={styles["table-line"]}>
                <div className={styles["just-2-table-columns"]}>
                    <div>EXE</div>
                    <div className={styles["scores"]}>
                        {showValueOrDash(perfomanceObject.scores?.averageExecution)}
                    </div>
                </div>
                <div className={styles["just-2-table-columns"]}>
                    <div>ART</div>
                    <div className={styles["scores"]}>
                        {showValueOrDash(perfomanceObject.scores?.averageArtistry)}
                    </div>
                </div>
                <div className={styles["just-2-table-columns"]}>
                    <div>DIF</div>
                    <div className={styles["scores"]}>
                        {showValueOrDash(perfomanceObject.scores?.averageComplexity)}
                    </div>
                </div>
                <div className={styles["just-2-table-columns"]}>
                    <div>L</div>
                    <div className={styles["performance-penalties"]}>
                        {showValueOrDash(perfomanceObject.scores?.linePenalty)}
                    </div>
                </div>
                <div className={styles["just-2-table-columns"]}>
                    <div>DD</div>
                    <div className={styles["performance-penalties"]}>
                        {showValueOrDash(perfomanceObject.scores?.complexityPenalty)}
                    </div>
                </div>
                <div className={styles["last-2-table-columns"]}>
                    <div>CH</div>
                    <div className={styles["performance-penalties"]}>
                        {showValueOrDash(perfomanceObject.scores?.brigadePenalty)}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PerformanceView;
