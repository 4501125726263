export enum EVENTS {
    REFEREE_CONNECTED = "REFEREE_CONNECTED",
    REFEREE_DISCONNECTED = "REFEREE_DISCONNECTED",
    REFEREE_RATE = "REFEREE_RATE",
    REFEREE_RATED = "REFEREE_RATED",
    REFEREE_CHANGE_PERFORMANCE_STATUS = "REFEREE_CHANGE_PERFORMANCE_STATUS",
    REFEREE_CHANGED_PERFORMANCE_STATUS = "REFEREE_CHANGED_PERFORMANCE_STATUS",
    REFEREE_CHANGE_PLAN = "REFEREE_CHANGE_PLAN",
    REFEREE_CHANGED_PLAN = "REFEREE_CHANGED_PLAN",
    REFEREE_CALCULATE_PLACES = "REFEREE_CALCULATE_PLACES",
    REFEREE_CALCULATED_PLACES = "REFEREE_CALCULATED_PLACES",
    REFEREE_SEND_SIGNAL = "REFEREE_SEND_SIGNAL",
    REFEREE_GOT_SIGNAL = "REFEREE_GOT_SIGNAL",
    REFEREE_CHANGE_PLAN_STATUS = "REFEREE_CHANGE_PLAN_STATUS",
    REFEREE_CHANGED_PLAN_STATUS = "REFEREE_CHANGED_PLAN_STATUS",
    REFEREE_RESPOND_SIGNAL = "REFEREE_RESPOND_SIGNAL",
    REFEREE_RESPONDED_SIGNAL = "REFEREE_RESPONDED_SIGNAL",
    REFEREE_SHOW_SCORES = "REFEREE_SHOW_SCORES",
    REFEREE_SHOWED_SCORES = "REFEREE_SHOWED_SCORES",
}
