import { createTheme } from "@material-ui/core/styles";
import { ruRU, enUS } from "@material-ui/core/locale";
import { LocalesEnum } from "./Modules/enums";

const themeLocales = { ru: ruRU, en: enUS };

export const getTheme = (locale: LocalesEnum) =>
    createTheme(
        {
            palette: {
                primary: {
                    main: "#1b6bd3",
                },
                secondary: {
                    main: "#f50057",
                },
            },
            typography: {
                h3: {
                    fontWeight: 700,
                },
                h6: {
                    fontWeight: 600,
                },
            },
        },
        themeLocales[locale]
    );
