import React from "react";
import PropTypes from "prop-types";
import { Grid, IconButton, Typography } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { withRouter } from "react-router";
import Utils from "../../../Modules/Utils.js";
import SheduleLabel from "./SheduleLabel.js";
import { NotificationManager } from "react-notifications";
import RequestManager from "../../../Modules/RequestManager";
import { withTranslation } from "react-i18next";
import moment from "moment";

class ScheduleItem extends React.Component {
    constructor(props) {
        super(props);
        this.deleteSchedule = this.deleteSchedule.bind(this);
    }

    deleteSchedule(scheduleId) {
        const { t } = this.props;
        let confirm = window.confirm(t("deleteEntryQuestion"));
        if (confirm) {
            RequestManager.fetch(`schedules/${scheduleId}`, "delete")
                .then(() => {
                    NotificationManager.success(
                        t("entryRemovedFromSchedule", {
                            scheduleId: scheduleId,
                        })
                    );
                    this.props.callback();
                })
                .catch(() => {
                    NotificationManager.error(t("dataRemoveError"));
                });
        }
    }

    updateSchedule(scheduleId, isDate, data) {
        const { t } = this.props;
        function parseData(data, isDate) {
            if (isDate) {
                const splitData = data.split(".");
                return {
                    date: splitData[0],
                    month: splitData[1] - 1, // month start with 0
                    year: splitData[2],
                };
            } else {
                const splitData = data.split(":");
                return {
                    hour: splitData[0],
                    minute: splitData[1],
                    second: splitData[2],
                };
            }
        }

        const parsedData = parseData(data, isDate),
            datePatch = moment(this.props.tileData.dateTime).set(parsedData).toISOString();

        if (this.props.tileData.dateTime === datePatch) return;

        let confirm = window.confirm(t("changeEntryQuestion"));
        if (confirm) {
            RequestManager.fetch(`schedules/${scheduleId}`, "post", {
                datePatch,
            })
                .then(() => {
                    NotificationManager.success(t("entryChanged", { scheduleId: scheduleId }));
                    this.props.callback();
                })
                .catch(() => {
                    NotificationManager.error(t("dataChangeError"));
                });
        }
    }

    render() {
        return (
            <div className="schedule-item">
                <Grid container justify="space-between">
                    <Grid item xs={6} align="start">
                        <SheduleLabel
                            mask="99.99.9999"
                            data={this.props.tileData.date}
                            placeholder=""
                            isCompetition={this.props.tileData.competitionStageId > 0}
                            updateData={this.updateSchedule.bind(
                                this,
                                this.props.tileData.scheduleId,
                                true
                            )}
                        />
                    </Grid>
                    <Grid item xs={6} align="end">
                        <SheduleLabel
                            mask="99:99:99"
                            data={this.props.tileData.time}
                            placeholder=""
                            isCompetition={this.props.tileData.competitionStageId > 0}
                            updateData={this.updateSchedule.bind(
                                this,
                                this.props.tileData.scheduleId,
                                false
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container className="schedule-item-category-stage" justify="space-between">
                    {this.props.tileData.brigadeNumber && (
                        <Grid
                            item
                            xs={2}
                            style={{
                                backgroundColor: "#d0d0d0",
                            }}
                        >
                            <Typography color="textPrimary">
                                <b>{this.props.tileData.brigadeNumber}</b>
                            </Typography>
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={6}
                        style={{
                            backgroundColor: Utils.getCategoryColor(
                                this.props.tileData.competitionPlanWorldName
                            ),
                        }}
                    >
                        <b>{this.props.tileData.competitionPlanWorldName}</b>
                    </Grid>

                    {this.props.tileData.competitionStageId > 0 && (
                        <Grid
                            xs
                            item
                            style={{
                                backgroundColor: Utils.getStageColor(
                                    this.props.tileData.competitionStageName
                                ),
                            }}
                        >
                            <b>
                                {this.props.tileData.competitionStageId === 1 && "Q"}
                                {this.props.tileData.competitionStageId === 2 && "F"}
                            </b>
                        </Grid>
                    )}
                </Grid>
                <Grid container justify="space-between">
                    <Grid item className="schedule-item-duration">
                        <span>{Utils.convertSecToMin(this.props.tileData.duration)}</span>
                    </Grid>
                    {this.props.tileData.competitionStageId > 0 && (
                        <Grid item>
                            <IconButton
                                size="small"
                                onClick={() => this.deleteSchedule(this.props.tileData.scheduleId)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            </div>
        );
    }
}

ScheduleItem.propTypes = {
    tileData: PropTypes.object,
    callback: PropTypes.func,
};

export default withRouter(withTranslation("adminPage")(ScheduleItem));
