import React from "react";
import PropTypes from "prop-types";
import "./PeformanceDisplay.css";

class CategoryDisplay extends React.Component {
    constructor(props) {
        super(props);

        const performancesPerPage = 10;
        this.state = {
            numberOfPages: this.props.performances
                ? Math.ceil(this.props.performances.length / performancesPerPage)
                : 1,
            currentPage: 0,
            performancesPerPage: performancesPerPage,
        };

        this.togglePage = this.togglePage.bind(this);
    }

    componentDidMount() {
        this.setState({
            intervalForToggle: setInterval(() => this.togglePage(), 10000),
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalForToggle);
    }

    togglePage() {
        let nextPage = this.state.currentPage + 1;
        if (nextPage > this.state.numberOfPages - 1) {
            nextPage = 0;
            this.props.onAllPagesAreShown();
        }
        this.setState({
            currentPage: nextPage,
        });
    }

    render() {
        return (
            <div>
                <div className="header-row">{this.props.competitionName}</div>
                {this.props.performances && (
                    <div>
                        <div className="performance-row">
                            {this.props.performances[0].competitionPlanWorldName +
                                ", " +
                                this.props.competitionStageName}
                        </div>
                        {this.props.performances.map((item, index) => {
                            if (
                                index >= this.state.performancesPerPage * this.state.currentPage &&
                                index <
                                    this.state.performancesPerPage * (this.state.currentPage + 1)
                            ) {
                                return (
                                    <div className="performance-row" key={index}>
                                        <div className="athlete-place">{item.place}</div>
                                        <div
                                            className={"name-scores-container score-" + (index % 2)}
                                        >
                                            <div className="athlete-name">
                                                {item.competitorName}
                                            </div>
                                            <div className="athlete-scores">
                                                {Number(item.totalPoints).toFixed(3)}
                                            </div>
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                )}
            </div>
        );
    }
}

CategoryDisplay.propTypes = {
    competitionName: PropTypes.string,
    competitionStageName: PropTypes.string,
    performances: PropTypes.array,
    onAllPagesAreShown: PropTypes.func,
};

export default CategoryDisplay;
