import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";
import TrainerMenu from "./TrainerMenu";
import Content from "./Content";
import "../../Css/TrainerPage.css";
import "../../Css/MainGrid.css";

class MainGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedItem: "athletes",
        };
    }

    componentDidMount() {
        if (this.props.history.location.pathname === "/trainer") {
            this.props.history.push("/trainer/athletes");
        }
    }

    componentDidUpdate() {
        if (this.props.history.location.pathname === "/trainer") {
            this.props.history.push("/trainer/athletes");
        }
    }

    changeMenuItem = (value) => {
        this.props.history.push(`/trainer/${value ? value : "athletes"}`);
        this.setState({
            selectedItem: value,
        });
    };

    render() {
        return (
            <Grid container>
                <Grid item xs={12} className="main-menu">
                    <Container>
                        <TrainerMenu
                            onChange={this.changeMenuItem}
                            selectedItem={this.state.selectedItem}
                        />
                    </Container>
                </Grid>
                <Grid item xs={12} className="main-content">
                    <Container>
                        <Content selectedItem={this.state.selectedItem} />
                    </Container>
                </Grid>
            </Grid>
        );
    }
}

MainGrid.propTypes = {
    history: PropTypes.object,
};

export default withRouter(MainGrid);
