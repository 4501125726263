import React from "react";
import { FormControl, InputGroup, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import Utils from "../../../Modules/Utils";
import Helpers from "../../../Modules/Helpers";
import "./IncDecNumberInput.css";

class IncDecNumberInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value || 0,
        };
    }

    onValueChange(newValue) {
        if (Utils.isValueInRange(newValue, this.props.minValue, this.props.maxValue)) {
            this.setState({
                value: newValue,
            });
            this.props.onChange(newValue);
        }
    }

    render() {
        return (
            <InputGroup className="inc-dec-Input">
                <InputGroup.Button>
                    <Button onClick={() => this.onValueChange(this.state.value - this.props.step)}>
                        -
                    </Button>
                </InputGroup.Button>
                <FormControl
                    readOnly
                    className="penalties-input"
                    maxLength={3}
                    name="value"
                    type="text"
                    value={Helpers.getNullOrFixedNumber(this.state.value, 1)}
                />
                <InputGroup.Button>
                    <Button onClick={() => this.onValueChange(this.state.value + this.props.step)}>
                        +
                    </Button>
                </InputGroup.Button>
            </InputGroup>
        );
    }
}

IncDecNumberInput.propTypes = {
    value: PropTypes.number,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    step: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default IncDecNumberInput;
