import { Box, Typography } from "@material-ui/core";
import React from "react";
import Helpers from "../../../Modules/Helpers";

const RequestAthletes = ({ requestAthletes }) => {
    return (
        <Box px={3}>
            {requestAthletes.map((athlete) => {
                return (
                    <Typography key={athlete.id}>
                        {Helpers.getFullNameForAthlete(athlete)}
                    </Typography>
                );
            })}
        </Box>
    );
};

export default RequestAthletes;
