import React, { useState } from "react";
import { Grid, TextField, InputLabel, IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { withSnackbar, WithSnackbarProps } from "notistack";
import RequestManager from "../../../Modules/RequestManager";
import Auth from "../../../Modules/Auth";
import { useTranslation } from "react-i18next";

interface Props {
    onUpdate: () => Promise<void>;
}

const AddingAthleteByLicenseId = (props: Props & WithSnackbarProps) => {
    const trainerId: number = Auth.getAuthUser().trainerId;
    const [licenseId, setLicenseId] = useState<null | number>(null);
    const [isLoading, setLoadingStatus] = useState<boolean>(false);
    const { t } = useTranslation("trainerPage");
    const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setLicenseId(+value);
    };

    const handleAddAthlete = async (): Promise<void> => {
        setLoadingStatus(true);
        const res = await RequestManager.fetch(
            `trainer/athletes/license/${licenseId}`,
            "post",
            { trainerId },
            false
        ).catch((err) => {
            props.enqueueSnackbar(err.message, { variant: "error" });
            setLicenseId(null);
            setLoadingStatus(false);
        });

        if (res && res.body.status === "Success") {
            props.enqueueSnackbar(res.body.message, { variant: "success" });
            setLicenseId(null);
            setLoadingStatus(false);
            await props.onUpdate();
        }
    };

    return (
        <Grid container className="adding-athlete-by-license-id__form">
            <div>
                <InputLabel shrink>{t("addingAthleteByLicenseId.addByLicenseId")}</InputLabel>
                <TextField
                    name="licenseId"
                    type="number"
                    inputProps={{ min: 1 }}
                    placeholder={t("addingAthleteByLicenseId.enterLicenseNumber")}
                    value={licenseId || ""}
                    onChange={onChangeValue}
                />
            </div>
            <div>
                <IconButton
                    className="adding-athlete-by-license-id__add-button"
                    size="medium"
                    onClick={handleAddAthlete}
                    disabled={isLoading || !licenseId}
                >
                    <AddIcon />
                </IconButton>
            </div>
        </Grid>
    );
};

export default withSnackbar(AddingAthleteByLicenseId);
