import React from "react";
import RequestManager from "../../../Modules/RequestManager";
import PropTypes from "prop-types";
import {
    Grid,
    Tabs,
    Tab,
    MenuList,
    MenuItem,
    Toolbar,
    List,
    ListItem,
    IconButton,
    Divider,
    Fab,
    Tooltip,
} from "@material-ui/core";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import EditIcon from "@material-ui/icons/Edit";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import PersonIcon from "@material-ui/icons/Person";
import { NotificationManager } from "react-notifications";
import { withRouter } from "react-router";
import PerformancesPopup from "./PerformancesPopup";
import moment from "moment";
import { withTranslation } from "react-i18next";
import "./PerformancesList.css";

class PerformancesList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            performances: [],
            finalShuffle: [],
            competitionPlans: [],
            selectedStageId: 0,
            selectedPlan: {},
            modalIsOpen: false,
            isLoaded: false,
        };

        this.onPerformanceEditClick = this.onPerformanceEditClick.bind(this);
        this.handleFillAndShuffleClick = this.handleFillAndShuffleClick.bind(this);
        this.handleShuffleAgainClick = this.handleShuffleAgainClick.bind(this);
        this.modalCancel = this.modalCancel.bind(this);
        this.modalSubmit = this.modalSubmit.bind(this);
        this.refreshPerformancesList = this.refreshPerformancesList.bind(this);
        this.refreshCompetitionPlans = this.refreshCompetitionPlans.bind(this);
        this.competitionPlanSelected = this.competitionPlanSelected.bind(this);
        this.stageSelected = this.stageSelected.bind(this);
        this.noRequestsInPerormances = this.noRequestsInPerormances.bind(this);
    }

    componentDidMount() {
        this.refreshCompetitionPlans();
    }

    refreshCompetitionPlans() {
        return RequestManager.getCompetitionPlans(this.props.competitionId)
            .then((response) => {
                this.setState(
                    {
                        competitionPlans: response.CompetitionPlans,
                        selectedPlan: response.CompetitionPlans[0],
                        selectedStageId: response.CompetitionPlans[0].hasQualification ? 1 : 2,
                        isLoaded: true,
                    },
                    () => {
                        this.refreshPerformancesList();
                    }
                );
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    refreshPerformancesList() {
        return RequestManager.getPerformancesByPlanIdAndStageId(
            this.state.selectedPlan.competitionPlanId,
            this.state.selectedStageId
        )
            .then(
                function (response) {
                    if (
                        this.state.selectedStageId === 2 &&
                        this.state.selectedPlan.hasQualification &&
                        response.every((item) => !item.competitorName)
                    ) {
                        RequestManager.getBeforehandFinalShuffle(
                            this.state.selectedPlan.competitionPlanId
                        ).then((response) => {
                            this.setState({
                                finalShuffle: response,
                                performances: [],
                            });
                        });
                    } else {
                        this.setState({
                            performances: response,
                            finalShuffle: [],
                        });
                    }
                }.bind(this)
            )
            .catch((err) => {
                throw new Error(err);
            });
    }

    onPerformanceEditClick(e) {
        let selectedPerformance = this.state.performances.find((item) => {
            return item.performanceId === +e.currentTarget.value;
        });

        this.setState({
            selectedPerformance: selectedPerformance,
            modalIsOpen: true,
        });
    }

    modalCancel(event) {
        this.setState({
            modalIsOpen: false,
        });
    }

    modalSubmit(event) {
        this.refreshPerformancesList();
        this.setState({
            modalIsOpen: false,
        });
    }

    noRequestsInPerormances() {
        if (this.state.performances.length !== 0) {
            for (let i = 0; i < this.state.performances.length; i++) {
                if (this.state.performances[i].competitionRequestId) {
                    return false;
                }
            }
        }
        return true;
    }

    handleFillAndShuffleClick(event) {
        const { t } = this.props;
        if (
            this.noRequestsInPerormances() ||
            window.confirm(`${t("speechesFilled")}. ${t("remake")}?'`)
        ) {
            return RequestManager.fetch(
                "performanceShuffle",
                "get",
                {
                    competitionPlanId: this.state.selectedPlan.competitionPlanId,
                    competitionStageId: this.state.selectedStageId,
                },
                false
            )
                .then((response) => {
                    NotificationManager.success(t("fillingCompleted"));
                    this.refreshPerformancesList();
                })
                .catch((err) => {
                    NotificationManager.error(t("fillingFailed"));
                });
        }
    }

    handleShuffleAgainClick(event) {
        const { t } = this.props;
        if (
            this.state.performances.length === 0 &&
            !(this.state.selectedStageId === 2 && this.state.selectedPlan.hasQualification)
        ) {
            NotificationManager.error(t("completeBeforeResort"));
        }
        return RequestManager.fetch(
            `performanceShuffleAgain`,
            "get",
            {
                competitionPlanId: this.state.selectedPlan.competitionPlanId,
                competitionStageId: this.state.selectedStageId,
            },
            false
        )
            .then((response) => {
                NotificationManager.success(t("sortitionCompleted"));
                this.refreshPerformancesList();
            })
            .catch((err) => {
                NotificationManager.error(t("sortFailed"));
            });
    }

    competitionPlanSelected(event) {
        let selectedPlan = this.state.competitionPlans.find((item) => {
            return item.competitionPlanId === event.target.value;
        });
        this.setState(
            {
                selectedPlan: selectedPlan,
                selectedStageId: selectedPlan.hasQualification ? 1 : 2,
            },
            () => {
                this.refreshPerformancesList();
            }
        );
    }

    stageSelected(event, value) {
        this.setState(
            {
                selectedStageId: value,
            },
            () => {
                this.refreshPerformancesList();
            }
        );
    }

    movePerformance(direction, performanceIndex) {
        let firstPerformanceId = this.state.performances[performanceIndex].performanceId;
        let secondPerformanceId =
            direction === "up"
                ? this.state.performances[performanceIndex - 1].performanceId
                : this.state.performances[performanceIndex + 1].performanceId;
        RequestManager.swapPerformances(firstPerformanceId, secondPerformanceId)
            .then((response) => {
                this.refreshPerformancesList();
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    render() {
        const { t } = this.props;
        if (!this.state.isLoaded) {
            return null;
        }

        return (
            <React.Fragment>
                <Grid container>
                    <Grid item xs={2}>
                        <MenuList
                            variant="selectedMenu"
                            value={+this.state.selectedPlan.competitionPlanId}
                            className="plan-list"
                        >
                            {this.state.competitionPlans.map((item, index) => {
                                return (
                                    <MenuItem
                                        key={item.competitionPlanId}
                                        value={item.competitionPlanId}
                                        onClick={this.competitionPlanSelected}
                                        className={`plan-btn ${
                                            item.competitionPlanId ===
                                            this.state.selectedPlan.competitionPlanId
                                                ? "active"
                                                : ""
                                        }`}
                                    >
                                        {item.competitionPlanWorldName}
                                    </MenuItem>
                                );
                            })}
                        </MenuList>
                    </Grid>
                    <Grid item xs={10}>
                        <Tabs
                            id="stage-tabs"
                            value={this.state.selectedStageId}
                            onChange={this.stageSelected}
                            indicatorColor="primary"
                            textColor="primary"
                        >
                            {this.state.selectedPlan.hasQualification && (
                                <Tab key={1} value={1} label={t("qualification")} />
                            )}
                            {this.state.selectedPlan.hasFinal && (
                                <Tab key={2} value={2} label={t("final")} />
                            )}
                        </Tabs>

                        <Toolbar disableGutters className="shuffle-buttons">
                            <Tooltip arrow title={t("fillSpeeches")}>
                                <Fab onClick={this.handleFillAndShuffleClick} color="primary">
                                    <PersonIcon />
                                </Fab>
                            </Tooltip>
                            <Tooltip arrow title={t("drawLots")}>
                                <Fab onClick={this.handleShuffleAgainClick} color="primary">
                                    <ShuffleIcon />
                                </Fab>
                            </Tooltip>
                        </Toolbar>

                        <List className="shuffle-list" disablePadding>
                            {this.state.selectedStageId === 2 &&
                                this.state.selectedPlan.hasQualification &&
                                this.state.finalShuffle.map((item, index) => {
                                    return (
                                        <ListItem key={index} divider>
                                            <Grid item xs={4}>
                                                <span>{index + 1}.&nbsp;</span>
                                                <b>
                                                    {item}&nbsp;
                                                    {t("qualificationPlace")}
                                                </b>
                                            </Grid>
                                            <Grid item xs={2}>
                                                {item.competitorCity}
                                            </Grid>
                                        </ListItem>
                                    );
                                })}

                            {this.state.performances.length > 0 &&
                                this.state.performances.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <ListItem key={index} disableGutters>
                                                <Grid item xs={5}>
                                                    <span>{index + 1}.&nbsp;</span>
                                                    <b>{item.competitorName}</b>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    {item.competitorCity}
                                                </Grid>
                                                <Grid item xs={3}>
                                                    {moment(item.dateTime).format(
                                                        "DD.MM.YYYY HH:mm:ss"
                                                    )}
                                                </Grid>
                                                <Grid item xs={2} className="pull-right">
                                                    {index > 0 && (
                                                        <Tooltip arrow title={t("up")}>
                                                            <IconButton
                                                                value={item.performanceId}
                                                                onClick={() =>
                                                                    this.movePerformance(
                                                                        "up",
                                                                        index
                                                                    )
                                                                }
                                                                size="small"
                                                            >
                                                                <ArrowUpwardIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                    {index + 1 < this.state.performances.length && (
                                                        <Tooltip arrow title={t("down")}>
                                                            <IconButton
                                                                value={item.performanceId}
                                                                onClick={() =>
                                                                    this.movePerformance(
                                                                        "down",
                                                                        index
                                                                    )
                                                                }
                                                                size="small"
                                                            >
                                                                <ArrowDownwardIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                    <Tooltip arrow title={t("edit")}>
                                                        <IconButton
                                                            value={item.performanceId}
                                                            onClick={this.onPerformanceEditClick}
                                                            size="small"
                                                        >
                                                            <EditIcon />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Grid>
                                            </ListItem>
                                            <Divider />
                                        </React.Fragment>
                                    );
                                })}
                        </List>
                    </Grid>
                </Grid>
                {this.state.modalIsOpen && (
                    <PerformancesPopup
                        modalIsOpen={this.state.modalIsOpen}
                        modalHide={this.modalCancel}
                        modalCancel={this.modalCancel}
                        modalSubmit={this.modalSubmit}
                        selectedPerformance={this.state.selectedPerformance}
                        selectedPlan={this.state.selectedPlan}
                    />
                )}
            </React.Fragment>
        );
    }
}

PerformancesList.propTypes = {
    history: PropTypes.object,
    competitionId: PropTypes.number,
};

export default withRouter(withTranslation("adminPage")(PerformancesList));
