import { useCallback } from "react";
import { TableModuleEvents, TableModuleState } from "../store/StoreModules/TableModule";
import { useStoreon } from "storeon/react";

type UseTableControls = { setRef(ref): void; updateData(): void };

const useTable = (): UseTableControls => {
    const { ref, dispatch } = useStoreon<TableModuleState, TableModuleEvents>("ref");

    const setRef = useCallback(
        (newRef) => {
            dispatch("table/set/ref", newRef);
        },
        [dispatch]
    );

    const updateData = useCallback(() => {
        ref.current?.onQueryChange();
    }, [ref]);

    return { setRef, updateData };
};

export default useTable;
