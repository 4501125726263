class ControlTypesManager {
    static types = {
        ratingScale: "RatingScale",
        numberInput: "NumberInput",
        incDecNumberInput: "IncDecNumberInput",
        checkBox: "CheckBox",
    };

    static controlTypes = [
        { id: 1, name: ControlTypesManager.types.ratingScale, minValue: 0, maxValue: 1, step: 0.1 },
        { id: 2, name: ControlTypesManager.types.numberInput, minValue: 0 },
        { id: 3, name: ControlTypesManager.types.incDecNumberInput, minValue: 0, step: 0.1 },
        { id: 4, name: ControlTypesManager.types.incDecNumberInput, minValue: 0, step: 0.2 },
        { id: 5, name: ControlTypesManager.types.incDecNumberInput, minValue: 0, step: 0.5 },
        { id: 6, name: ControlTypesManager.types.incDecNumberInput, minValue: 0, step: 1 },
        { id: 7, name: ControlTypesManager.types.incDecNumberInput, minValue: 0, step: 2 },
        { id: 8, name: ControlTypesManager.types.checkBox },
    ];

    static getControlType(id) {
        return ControlTypesManager.controlTypes.find((item) => item.id === id);
    }
}

export default ControlTypesManager;
