import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import LoginForm, { LoginFormProps } from "../../Forms/LoginForm";
import { useAuth, useLoadingStatus } from "../../../Hooks";
import LoginManager from "../../../Modules/LoginManager";

const LoginModal: React.FC = () => {
    const { t } = useTranslation();
    const history = useHistory();
    const { authenticate } = useAuth();
    const { errors, setErrors } = useLoadingStatus("idle");

    const closeModal = () => {
        history.push("/");
    };

    const handleSubmit: LoginFormProps["onSubmit"] = async (values) => {
        try {
            const { body } = await LoginManager.login(values);

            authenticate(body.token, body.user);
        } catch (err) {
            const { message } = err as Error;
            if (message) setErrors([message as string]);
        }
    };

    return (
        <Dialog open onClose={closeModal} fullWidth>
            <DialogTitle>{t("logIn")}</DialogTitle>
            <DialogContent>
                <LoginForm onSubmit={handleSubmit} error={errors[0]} />
            </DialogContent>
        </Dialog>
    );
};

export default LoginModal;
