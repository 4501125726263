import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { useAuth, useLoadingStatus, useQueryParams } from "../Hooks";
import qs from "query-string";

const withAuth = (
    WrappedComponent: React.ComponentType,
    roles: number[] = [],
    redirectPath = "/"
) => (props) => {
    const params = useQueryParams<Record<string, string | number>>();
    const [access, setAccess] = useState<boolean>(false);
    const { loadingStatus, setLoadingStatus } = useLoadingStatus();
    const { user } = useAuth();

    useEffect(() => {
        setLoadingStatus("loading");
        setAccess(() => {
            let match = false;
            if (!user) return match;

            for (const role of roles) {
                match = user.roleIds.includes(role);
                if (match) break;
            }

            return match;
        });
        setLoadingStatus("loaded");
    }, [user, roles]);

    if (loadingStatus === "loading") return null;
    if (!access) return <Redirect to={`${redirectPath}?${qs.stringify(params)}`} />;
    return <WrappedComponent {...props} />;
};

export default withAuth;
