import BaseApiService from "@/api/Base";
import { TokenInterceptor } from "@/api/Http/Interceptors";
import { QueryResult } from "@/api/types";
import { Settings } from "@/modules/Constants";

class CompetitionRequestApiService extends BaseApiService<any> {
    constructor(config) {
        super(config);
        this.http.interceptors.request.use(TokenInterceptor);
    }

    importRequests = async (
        competitionId: number,
        payload: FormData
    ): Promise<QueryResult<never>> => {
        const { data } = await this.http.post(`/requests/${competitionId}/import`, payload);

        return data;
    };
}

export default new CompetitionRequestApiService({
    baseURL: Settings.MAIN_API_URL_V2,
});
