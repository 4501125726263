import React, { useMemo } from "react";
import { useHistory } from "react-router";
import { Route, Switch } from "react-router";
import Competition from "../Controls/AdminPage/CompetitionsList/Competition";
import { Grid, Fab, Container } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Auth from "../Modules/Auth";
import { withAuth } from "../Hoc";
import CompetitionsList from "../Controls/AdminPage/CompetitionsList/CompetitionsList";

const CompetitionManagerPage = () => {
    const { t } = useTranslation("pages");
    const history = useHistory();

    const backToList = () => {
        history.push("/manager");
    };

    return (
        <Container>
            <Switch>
                <Route exact path="/manager" component={CompetitionsList} />
                <Route
                    path={"/manager/:id"}
                    component={() => (
                        <Grid container className="main-view-head">
                            <Fab variant="extended" size="small" onClick={backToList}>
                                ← {t("backToCompetitionsList")}
                            </Fab>
                            <Competition />
                        </Grid>
                    )}
                />
            </Switch>
        </Container>
    );
};

export default withAuth(CompetitionManagerPage, [Auth.ROLES.competitionManager]);
