import React from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    IconButton,
    Tooltip,
    TableSortLabel,
} from "@material-ui/core";
import LoadingIndicator from "./LoadingIndicator";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import PropTypes from "prop-types";
import "./DataTable.css";
import { withTranslation } from "react-i18next";

class DataTable extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPagination: true,
            currentPage: 0,
            rowsPerPage: 10,
            order: this.props.order ? this.props.order : "asc",
            orderBy: this.props.orderBy ? this.props.orderBy : "",
        };
    }

    componentDidMount() {
        if (!this.props.showPagination && this.props.showPagination !== undefined) {
            this.setState({
                showPagination: false,
            });
        }
    }

    getTd(column, item) {
        let className = this.props.useFieldNameAsClassForColumn ? column.fieldName : "";
        if (column.singleLine) {
            className += "single-line";
        }
        return (
            <TableCell
                className={className}
                key={column.fieldName}
                itemID={item[this.props.uniqueFieldOfItem]}
            >
                {item[column.fieldName]}
            </TableCell>
        );
    }

    handleChangePage = (event, page) => {
        this.setState({
            currentPage: page,
        });
    };

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: +event.target.value,
            currentPage: 0,
        });
    };

    onRequestSort = (property, isDate, dateOriginal) => {
        const { order, orderBy } = this.state;
        const isAsc = orderBy === property && order === "asc";

        if (!isAsc) {
            this.props.data.sort((a, b) => {
                if (isDate) {
                    if (new Date(a[dateOriginal]) > new Date(b[dateOriginal])) return 1;
                    if (new Date(a[dateOriginal]) < new Date(b[dateOriginal])) return -1;
                } else {
                    if (a[property] < b[property]) return 1;
                    if (a[property] > b[property]) return -1;
                }
                return 0;
            });
        } else {
            this.props.data.sort((a, b) => {
                if (isDate) {
                    if (new Date(a[dateOriginal]) < new Date(b[dateOriginal])) return 1;
                    if (new Date(a[dateOriginal]) > new Date(b[dateOriginal])) return -1;
                } else {
                    if (a[property] > b[property]) return 1;
                    if (a[property] < b[property]) return -1;
                }
                return 0;
            });
        }

        this.setState({
            order: isAsc ? "desc" : "asc",
            orderBy: property,
        });
    };

    render() {
        const { t } = this.props;
        const { currentPage, rowsPerPage, orderBy, order } = this.state;

        if (this.props.isLoading) {
            return <LoadingIndicator show={this.props.isLoading} />;
        }
        if (!this.props.data || this.props.data.length === 0) {
            return <div>{t("nothingHere")}...</div>;
        }
        return (
            <React.Fragment>
                <TableContainer>
                    <Table className="data-table">
                        {this.props.header || (
                            <TableHead>
                                <TableRow>
                                    {this.props.columns.map((column) => (
                                        <TableCell
                                            key={column.fieldName}
                                            sortDirection={
                                                orderBy === column.fieldName ? order : false
                                            }
                                            className={column.fieldName}
                                            style={{ width: column.width }}
                                        >
                                            {this.props.allowSorting ? (
                                                <Tooltip
                                                    arrow
                                                    title={`${t("sortBy")} <<${column.title}>>`}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === column.fieldName}
                                                        direction={
                                                            orderBy === column.fieldName
                                                                ? order
                                                                : "asc"
                                                        }
                                                        onClick={() =>
                                                            this.onRequestSort(
                                                                column.fieldName,
                                                                column.isDate,
                                                                column.dateOriginal
                                                            )
                                                        }
                                                    >
                                                        {column.title}
                                                    </TableSortLabel>
                                                </Tooltip>
                                            ) : (
                                                column.title
                                            )}
                                        </TableCell>
                                    ))}
                                    {this.props.editable ? (
                                        <TableCell className="edit-cell" />
                                    ) : null}
                                    {this.props.deletable ? (
                                        <TableCell className="remove-cell" />
                                    ) : null}
                                    {this.props.custom ? (
                                        <TableCell className="details-cell" />
                                    ) : null}
                                </TableRow>
                            </TableHead>
                        )}
                        <TableBody>
                            {(rowsPerPage > 0
                                ? this.props.data.slice(
                                      currentPage * rowsPerPage,
                                      currentPage * rowsPerPage + rowsPerPage
                                  )
                                : this.props.data
                            ).map((item) => (
                                <TableRow
                                    key={item[this.props.uniqueFieldOfItem]}
                                    onClick={this.props.onItemClick}
                                    className={this.props.clickable ? "clickable" : null}
                                >
                                    {this.props.columns.map((column) => this.getTd(column, item))}
                                    {this.props.editable ? (
                                        <TableCell
                                            className="button-cell"
                                            itemID={item[this.props.uniqueFieldOfItem]}
                                            command="edit"
                                        >
                                            <Tooltip arrow title={t("edit")}>
                                                <IconButton
                                                    aria-label="edit"
                                                    itemID={item[this.props.uniqueFieldOfItem]}
                                                    command="edit"
                                                    size="small"
                                                >
                                                    <EditIcon
                                                        itemID={item[this.props.uniqueFieldOfItem]}
                                                        command="edit"
                                                    ></EditIcon>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    ) : null}
                                    {this.props.deletable ? (
                                        <TableCell
                                            className="button-cell"
                                            itemID={item[this.props.uniqueFieldOfItem]}
                                            command="delete"
                                        >
                                            <Tooltip arrow title={t("delete")}>
                                                <IconButton
                                                    aria-label="delete"
                                                    itemID={item[this.props.uniqueFieldOfItem]}
                                                    command="delete"
                                                    size="small"
                                                >
                                                    <ClearIcon
                                                        itemID={item[this.props.uniqueFieldOfItem]}
                                                        command="delete"
                                                    ></ClearIcon>
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    ) : null}
                                    {this.props.custom ? (
                                        <TableCell
                                            className="button-cell"
                                            itemID={item[this.props.uniqueFieldOfItem]}
                                            command="custom"
                                        >
                                            <Tooltip arrow title={this.props.customTooltip}>
                                                <IconButton
                                                    itemID={item[this.props.uniqueFieldOfItem]}
                                                    command="custom"
                                                    size="small"
                                                >
                                                    {this.props.customIcon}
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    ) : null}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {this.state.showPagination && this.props.data.length > 10 ? (
                    <TablePagination
                        rowsPerPageOptions={[10, 30, 50, { label: t("all"), value: -1 }]}
                        component="div"
                        count={this.props.data.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.currentPage}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                ) : null}
            </React.Fragment>
        );
    }
}

DataTable.propTypes = {
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            fieldName: PropTypes.string.isRequired,
            singleLine: PropTypes.bool,
            isDate: PropTypes.bool,
            dateOriginal: PropTypes.string,
        })
    ).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    uniqueFieldOfItem: PropTypes.string.isRequired,
    deletable: PropTypes.bool,
    editable: PropTypes.bool,
    custom: PropTypes.bool,
    useFieldNameAsClassForColumn: PropTypes.bool,
    onItemClick: PropTypes.func,
    clickable: PropTypes.bool,
    header: PropTypes.object,
    isLoading: PropTypes.bool,
    customIcon: PropTypes.object,
    customTooltip: PropTypes.string,
    showPagination: PropTypes.bool,
    allowSorting: PropTypes.bool,
    order: PropTypes.oneOf(["asc", "desc"]),
    orderBy: PropTypes.string,
};

export default withTranslation("adminPage")(DataTable);
