import React from "react";
import { Table, TableContainer, TablePagination } from "@material-ui/core";
import { Performance } from "../../../../interfaces/performance";
import { useTranslation } from "react-i18next";
import "./referee-table.scss";
import RefereeTableHead from "./referee-table-head";
import RefereeTableBody from "./referee-table-body";

export enum ACTIONS_BY {
    "Referee",
    "MainReferee",
}

interface Props {
    performances: Performance[];
    onChangePerformanceStatus?: (performanceId, statusId) => Promise<void>;
    onEditPerformance?: (performanceId: number, refereeId: number) => void;
    onSendSignal?: (refereeId: any, value: "up" | "down") => void;
    actionsBy: ACTIONS_BY;
    activeSignalArrows?: any[];
    shownPerformanceScores?: any[];
    onShowScores?: (performanceId: number) => void;
}

const RefereeTable: React.FC<Props> = (props: Props) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(-1);
    const { t } = useTranslation("refereePage");
    const handleChangePerformanceStatus = async (statusId, performanceId) => {
        if (props.onChangePerformanceStatus) {
            await props.onChangePerformanceStatus(performanceId, statusId);
        }
    };

    const sendSignalToJudge = (refereeId: number, value) => {
        if (props.onSendSignal) props.onSendSignal(refereeId, value);
    };

    const handleShowScores = (performanceId: number) => {
        if (props.onShowScores) props.onShowScores(performanceId);
    };

    return (
        <>
            <Table className="data-table scores-table" stickyHeader>
                <RefereeTableHead />
                <RefereeTableBody
                    performances={props.performances}
                    shownPerformanceScores={props.shownPerformanceScores}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePerformanceStatus={handleChangePerformanceStatus}
                    actionsBy={props.actionsBy}
                    onShowScores={handleShowScores}
                    onSendSignalToJudge={sendSignalToJudge}
                    activeSignalArrows={props.activeSignalArrows || []}
                    onEditPerformance={props.onEditPerformance}
                />
            </Table>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: t("all"), value: -1 }]}
                component="div"
                count={props.performances.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={(e, newPage) => setPage(newPage)}
                onRowsPerPageChange={(e) => {
                    setRowsPerPage(parseInt(e.target.value, 10));
                    setPage(0);
                }}
            />
        </>
    );
};

export default RefereeTable;
