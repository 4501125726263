import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Container, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CompetitionCard from "../../../Views/CompetitionCard";
import ContentLayout from "../../../Views/ContentLayout";
import { Skeleton } from "@material-ui/lab";

import "./competitions-list.scss";
import { useQuery } from "react-query";
import { competitionApiService } from "@/api/Services";

const CompetitionList = () => {
    const history = useHistory();
    const { t } = useTranslation("refereePage");

    const { data, isFetching, isFetched } = useQuery(["referee-competitions"], () =>
        competitionApiService.getAllActive()
    );

    const handleCardClick = (competition) => {
        history.push(`/referee/${competition.id}`);
    };

    return (
        <div className="referee__competitions">
            <Container>
                <Typography variant="h3">{t("selectCompetition")}</Typography>
                <ContentLayout>
                    {!isFetching && !data?.result.count && (
                        <Typography>{t("noCompetitionsAvailable")}</Typography>
                    )}
                    <Grid container spacing={2}>
                        {isFetching &&
                            [...new Array(3)].map((_, i) => (
                                <Grid key={i} item xs={12} md={4}>
                                    <Skeleton width="100%" height={300} />
                                </Grid>
                            ))}

                        {isFetched &&
                            data?.result.rows.map((competition) => {
                                return (
                                    <Grid item xs={12} md={4} key={competition.id}>
                                        <CompetitionCard
                                            {...competition}
                                            onClick={handleCardClick}
                                            hideAttachments
                                            hideActions
                                        />
                                    </Grid>
                                );
                            })}
                    </Grid>
                </ContentLayout>
            </Container>
        </div>
    );
};

export default CompetitionList;
