import { athleteApiService } from "@/api/Services";
import { Fab, Grid, Typography } from "@material-ui/core";
import { PersonAdd } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable } from "../../../Hooks";
import { Athlete } from "../../../interfaces/athlete";
import DataTable, { DataTableColumn, OnEditClick, OnDeleteClick } from "../../Common/MuiDataTable";
import AthleteDialog from "./AthletePopup";

const AthletesList: React.FC = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("adminPage");
    const { updateData } = useTable();

    const [selectedAthlete, setSelectedAthlete] = useState<any>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleHide = () => {
        setOpen(false);
        setSelectedAthlete(null);
    };

    const handleEditClick: OnEditClick<Athlete> = (rowData) => {
        if (rowData instanceof Array) return;
        setSelectedAthlete(rowData);
        handleOpen();
    };

    const handleDeleteClick: OnDeleteClick<Athlete> = async (rowData: Athlete) => {
        try {
            const { message } = await athleteApiService.delete(rowData.id);

            enqueueSnackbar(message, {
                variant: "success",
            });
        } catch (err: any) {
            const message = err.response?.data?.message;

            enqueueSnackbar(message || err.message, {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<Athlete>[] = [
        { title: "#", field: "id", defaultSort: "asc" },
        { title: t("lastName"), field: "lastName" },
        { title: t("firstName"), field: "firstName" },
        { title: t("middleName"), field: "middleName" },
        { title: t("gender"), field: "genderId", lookup: { 1: "M", 2: "Ж" } },
        { title: t("city"), field: "city" },
        { title: t("region"), field: "region" },
        { title: t("dateOfBirth"), field: "dateOfBirth", type: "date" },
        // { title: t("coachName"), field: "trainerId" },
    ];

    return (
        <>
            <Grid container justify="space-between" className="main-view-head">
                <Grid item>
                    <Typography variant="h4">{t("participants")}</Typography>
                </Grid>
                <Grid item>
                    <Fab variant="extended" size="small" onClick={handleOpen}>
                        <PersonAdd className="extended-icon-fab-margin" />
                        {t("newParticipant")}
                    </Fab>
                </Grid>
            </Grid>

            <AthleteDialog
                show={open}
                modalHide={handleHide}
                selectedAthlete={selectedAthlete}
                onUpdate={updateData}
            />

            <DataTable<Athlete>
                columns={columns}
                fetchFn={athleteApiService.getAll}
                canEdit
                canDelete
                onEditClick={handleEditClick}
                onDeleteClick={handleDeleteClick}
                showToolbar
                showSearch
            />
        </>
    );
};

export default AthletesList;
