import React from "react";
import PropTypes from "prop-types";
import "./PreviousPerformanceScores.css";
import RequestManager from "../../../Modules/RequestManager";
import { withTranslation } from "react-i18next";

class PreviousPerformanceScores extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            athletes: "",
            averageArtistryScores: "",
            averageExecutionScores: "",
            averageComplexityScores: "",
            totalScores: "",
        };

        this.getPreviousPerformanceScores = this.getPreviousPerformanceScores.bind(this);
    }

    componentDidMount() {
        this.getPreviousPerformanceScores();
    }

    getPreviousPerformanceScores() {
        return RequestManager.getPreviousPerformanceScores(this.props.performanceId).then(
            (response) => {
                let prevPerformanceScores = response.body.prevPerformanceScores;
                if (prevPerformanceScores) {
                    this.setState({
                        athletes: prevPerformanceScores.athletes,
                        averageArtistryScores: Number(
                            prevPerformanceScores.averageArtistryScores
                        ).toFixed(3),
                        averageExecutionScores: Number(
                            prevPerformanceScores.averageExecutionScores
                        ).toFixed(3),
                        averageComplexityScores: Number(
                            prevPerformanceScores.averageComplexityScores
                        ).toFixed(3),
                        totalScores: Number(prevPerformanceScores.totalScores).toFixed(3),
                    });
                }
            }
        );
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                {this.state.athletes && (
                    <div className="previous-performance-scores-container">
                        <div className="previous-performance-scores-header">
                            {t("averageScores")}: <strong>{this.state.athletes}</strong>
                        </div>
                        <div className="previous-performance-scores-body">
                            <div className="previous-performance-scores-body-item">
                                <div className="score-label">{t("execution")}</div>
                                <div className="previous-performance-scores">
                                    {this.state.averageExecutionScores}
                                </div>
                            </div>
                            <div className="vertical-line"></div>
                            <div className="previous-performance-scores-body-item">
                                <div className="score-label">{t("artistry")}</div>
                                <div className="previous-performance-scores">
                                    {this.state.averageArtistryScores}
                                </div>
                            </div>
                            <div className="vertical-line"></div>
                            <div className="previous-performance-scores-body-item">
                                <div className="score-label">{t("complexity")}</div>
                                <div className="previous-performance-scores">
                                    {this.state.averageComplexityScores}
                                </div>
                            </div>
                            <div className="vertical-line"></div>
                            <div className="previous-performance-scores-body-item">
                                <div className="score-label">{t("general")}</div>
                                <div className="previous-performance-scores">
                                    {this.state.totalScores}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

PreviousPerformanceScores.propTypes = {
    performanceId: PropTypes.number,
};

export default withTranslation("refereePage")(PreviousPerformanceScores);
