import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import styles from "./home.module.scss";

const Home: React.FC = () => {
    const { t, i18n } = useTranslation("home");
    const { language } = i18n;

    return (
        <section className={styles["home"]}>
            <div className={styles["contacts"]}>
                <div className={styles["cost"]}>{t("cost")}</div>
                <div className={styles["phone"]}>{t("contact")}</div>
            </div>
            <h2 className={clsx(styles["title"], styles["bordered"])}>{t("title")}</h2>

            <div className={styles["feature"]}>
                <div className={styles["image"]}>
                    <img
                        src="./assets/home/online-reg.jpg"
                        alt="online-reg"
                        height="80px"
                        width="200px"
                    />
                </div>
                <div className={styles["text"]}>
                    <span>{t("feature1.text1")}</span>
                </div>
            </div>

            {language === "ru" ? (
                <div className={styles["feature"]}>
                    <div className={styles["image"]}>
                        <img
                            src="./assets/home/no-license.jpg"
                            alt="no-license"
                            height="90px"
                            width="150px"
                        />
                    </div>
                    <div className={styles["text"]}>
                        <span>{t("feature2.text1")}</span>
                    </div>
                </div>
            ) : null}

            <div className={styles["feature"]}>
                <div className={styles["image"]}>
                    <img src="./assets/home/hello.jpg" alt="hello" height="150px" width="150px" />
                </div>
                <div className={styles["text"]}>
                    <span>{t("feature3.text1")}</span>
                    <span>{t("feature3.text2")}</span>
                </div>
            </div>

            <div className={styles["feature"]}>
                <div className={styles["image"]}>
                    <img
                        src="./assets/home/cloud-computing.jpg"
                        alt="cloud-computing"
                        height="130px"
                        width="200px"
                    />
                </div>
                <div className={styles["text"]}>
                    <span>{t("feature4.text1")}</span>
                    <span>{t("feature4.text2")}</span>
                </div>
            </div>

            <div className={styles["feature"]}>
                <div className={styles["image"]}>
                    <img src="./assets/home/result.jpg" alt="result" height="100px" width="200px" />
                </div>
                <div className={styles["text"]}>
                    <span>{t("feature5.text1")}</span>
                    <span>{t("feature5.text2")}</span>
                </div>
            </div>

            <p className={clsx(styles["bordered"], styles["title"])}>
                <span>{t("make_better")}</span>
            </p>
        </section>
    );
};

export default Home;
