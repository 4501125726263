import React from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    FormGroup,
    FormControl,
    Grid,
    InputLabel,
    FormLabel,
    Select,
    MenuItem,
    Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import InputMask from "react-input-mask";
import RequestManager from "../../../Modules/RequestManager";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
class SchedulecreatePopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            timeSlotsCount: 0,
            performanceTime: "",
            performanceDate: "",
            competitionStageId: 1,
            performanceDuration: "",
            slotsCount: 1,
            planRequests: 0,
            competitionPlan: {
                brigadeNumber: 1,
            },
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
    }

    componentDidMount = async () => {
        this.getRequests();
        await this.getPlan();
    };

    getPlan = async () => {
        const res = await RequestManager.fetch(
            `v2/competition-plans/${this.props.data.competitionPlanId}`,
            "get"
        );
        this.setState({ competitionPlan: res.body.data });
    };

    updateBrigadeNumber = async (brigadeNumber) => {
        const res = await RequestManager.fetch(
            `v2/competition-plans/${this.props.data.competitionPlanId}/brigade-number`,
            "put",
            { brigadeNumber }
        );

        this.setState({ competitionPlan: res.body.data });
    };

    getRequests() {
        RequestManager.fetch(`requestsByPlanId/${this.props.data.competitionPlanId}`, "get")
            .then((response) => {
                this.setState({
                    planRequests: response.body.Requests.length,
                });
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    inputChanged(event) {
        let stateChange = {};
        stateChange[event.target.name] = event.target.value;
        this.setState(stateChange);
    }

    handleBrigadeChange = async (e) => {
        await this.updateBrigadeNumber(e.target.value);
    };

    handleSubmit(event) {
        const { t, data } = this.props;
        // validation
        if (data.competitionPlanId < 1) {
            NotificationManager.error(t("competitionPlanNotSelectedError"));
            return;
        }

        if (this.state.competitionStageId < 1) {
            NotificationManager.error(t("indicateStageCompetitionError"));
            return;
        }

        if (!moment(this.state.performanceDate, "DD.MM.YYYY").isValid()) {
            // check date in range
            NotificationManager.error(t("incorrectDateCompetitionError"));
            return;
        }
        let isTimeValid = /^([0-1][0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])$/.test(
            this.state.performanceTime
        );
        if (!isTimeValid) {
            NotificationManager.error(t("incorrectTimeError"));
            return;
        }

        let isDurationValid = /^([0-5][0-9])(:[0-5][0-9])$/.test(this.state.performanceDuration);
        if (!isDurationValid) {
            NotificationManager.error(t("incorrectDurationPerfomanceError"));
            return;
        }

        let payload = {
            competitionPlanId: data.competitionPlanId,
            stageId: +this.state.competitionStageId,

            scheduleStartDate: +moment(
                this.state.performanceDate + "T" + this.state.performanceTime,
                "DD-MM-YYYYTHH:mm:ss"
            ).format("X"),
            performanceDuration: this.stringDurationToInt(this.state.performanceDuration),

            slotsCount: +("" + this.state.slotsCount).replace(/[^0-9.]/g, ""),
        };
        RequestManager.fetch("schedulesGenerate", "POST", payload, false)
            .then(() => {
                NotificationManager.success(t("dataSaved"));
                this.props.onSubmit(null, data.competitionPlanWorldName);
            })
            .catch(() => {
                NotificationManager.error(t("scheduleGenerationError"));
            });
    }

    handleCancelClick(event) {
        this.props.onClose();
    }

    stringDurationToInt(stringDuration) {
        let ms = stringDuration.substring(0, 2);
        let msch = +ms;
        let ss = stringDuration.substring(3);
        let ssch = +ss;
        return msch * 60 + ssch;
    }

    render() {
        const { t } = this.props;
        if (this.props.modalIsOpen === false) return null;

        if (this.props.competitionDates.length === 0) return null;
        return (
            <Dialog
                open={this.props.modalIsOpen}
                onClose={this.handleCancelClick}
                aria-labelledby="ModalHeader"
            >
                <DialogTitle id="ModalHeader" disableTypography>
                    {t("scheduleGeneration")}
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={this.handleSubmit}>
                        <Alert severity="info">
                            <Typography>
                                <strong>{t("attention")}!</strong>{" "}
                                {t("createPerfomancesWindowExplanation")}.
                            </Typography>
                        </Alert>
                        <Grid container>
                            <Grid item xs={6}>
                                <FormControl>
                                    <FormLabel>{t("competitionPlan")}:</FormLabel>
                                    <Typography variant="h6">
                                        {this.props.data.competitionPlanWorldName}
                                    </Typography>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl fullWidth>
                                    <InputLabel shrink>{t("competitionStage")}</InputLabel>
                                    <Select
                                        value={this.state.competitionStageId}
                                        name="competitionStageId"
                                        placeholder={t("competitionStage")}
                                        onChange={this.inputChanged}
                                    >
                                        {this.props.competitionStages.map((item) => {
                                            return (
                                                <MenuItem
                                                    value={item.competitionStageId}
                                                    key={item.competitionStageId}
                                                >
                                                    {" "}
                                                    {item.competitionStageName}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormControl fullWidth>
                                    <InputLabel shrink>{t("dateOfPerfomance")}</InputLabel>
                                    <Select
                                        displayEmpty
                                        value={this.state.performanceDate || ""}
                                        name="performanceDate"
                                        onChange={this.inputChanged}
                                    >
                                        <MenuItem value="" key={0} disabled>
                                            {t("selectDate")}
                                        </MenuItem>

                                        {this.props.competitionDates.map((item) => {
                                            let itemDate = moment(item.dat).format("DD.MM.YYYY");
                                            return (
                                                <MenuItem value={itemDate} key={item.id}>
                                                    {itemDate}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={3}>
                                <FormGroup controlId="time">
                                    <InputMask
                                        mask="99:99:99"
                                        value={this.state.performanceTime}
                                        size="8"
                                        onChange={this.inputChanged}
                                    >
                                        {() => (
                                            <TextField
                                                label={t("startTime")}
                                                name="performanceTime"
                                                placeholder={t("timePlaceholder")}
                                            />
                                        )}
                                    </InputMask>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={2}>
                                <FormGroup controlId="performanceDuration">
                                    <InputMask
                                        mask="99:99"
                                        name="performanceDuration"
                                        value={this.state.performanceDuration}
                                        size="5"
                                        onChange={this.inputChanged}
                                    >
                                        {() => (
                                            <TextField
                                                placeholder="мм:сс"
                                                name="performanceDuration"
                                                label={t("interval")}
                                            />
                                        )}
                                    </InputMask>
                                </FormGroup>
                            </Grid>
                            <Grid item xs={3}>
                                <FormGroup controlId="slotsCount">
                                    <InputMask
                                        mask="999"
                                        value={this.state.slotsCount}
                                        size="3"
                                        onChange={this.inputChanged}
                                    >
                                        {() => (
                                            <TextField
                                                label={t("amount")}
                                                name="slotsCount"
                                                placeholder={t("amount")}
                                            />
                                        )}
                                    </InputMask>
                                </FormGroup>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={12}>
                                <Typography variant="subtitle1">
                                    {t("numberOfRequests")}:{" "}
                                    <strong>{this.state.planRequests}</strong>
                                </Typography>

                                <FormControl fullWidth>
                                    <InputLabel shrink>{t("specifyBrigade")}</InputLabel>
                                    <Select
                                        value={this.state.competitionPlan.brigadeNumber}
                                        name="brigadeNumber"
                                        onChange={this.handleBrigadeChange}
                                    >
                                        <MenuItem value={1} key={1}>
                                            {t("brigade")} {i18n.language === "ru" ? 1 : "A"}
                                        </MenuItem>
                                        ;
                                        <MenuItem value={2} key={2}>
                                            {t("brigade")} {i18n.language === "ru" ? 2 : "B"}
                                        </MenuItem>
                                        ;
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCancelClick} color="default">
                        {t("cancel")}
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary" variant="contained">
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

SchedulecreatePopup.propTypes = {
    header: PropTypes.string,
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    data: PropTypes.object,
    modalIsOpen: PropTypes.bool,
    competitionStages: PropTypes.array,
    competitionDates: PropTypes.array,
};

export default withTranslation("adminPage")(SchedulecreatePopup);
