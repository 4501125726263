import { CompetitionAttachment } from "./competition-attachment";
import { File } from "./file";

export enum BrigadeVariant {
    "DEFAULT" = "4",
    "EXTENDED" = "6",
}

export enum CompetitionSearchType {
    ACTIVE = "active",
    ARCHIVE = "archive",
}

export interface Competition {
    id: number;
    name: string;
    description: string;
    coverFileId: number | null;
    cover: File | null;
    attachments: CompetitionAttachment[];
    dateFrom: string | null;
    dateTo: string | null;
    place: string;
    isAllowedRequests: boolean;
    statusId: number;
    mainRefereeId: number | null;
    deputyId: number | null;
    secretaryId: number | null;
    secretaryDeputyId: number | null;
    ownerUserId: number | null;
    beamerUserId: number | null;
    brigadeVariant: BrigadeVariant;
    isPublic: boolean;
    canChairRateAll: boolean;
}
