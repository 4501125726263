import React from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Grid,
    InputLabel,
    FormGroup,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
} from "@material-ui/core";
import RequestManager from "../../../../Modules/RequestManager";
import Helpers from "../../../../Modules/Helpers";
import AthletesSearch from "../../../Common/AthletesSearch";
import { NotificationManager } from "react-notifications";
import InputMask from "react-input-mask";
import { withTranslation } from "react-i18next";
import moment from "moment";

/** Это буллщит переписать, невозможно править */
class RequestPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            requests: [],
            header:
                this.props.competitionRequestId > 0
                    ? this.props.t("requestEditing")
                    : this.props.t("requestCreating"),
            showAddRequestButton: this.props.competitionRequestId > 0 ? false : true,
            confirmation: null,
            anyInputChanged: false,
            athleteSearch: false,
        };

        this.getRequestDetails = this.getRequestDetails.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.eventChanged = this.eventChanged.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.renderRequest = this.renderRequest.bind(this);
        this.renderAthlete = this.renderAthlete.bind(this);
        this.addRequest = this.addRequest.bind(this);
        this.deleteRequest = this.deleteRequest.bind(this);
        this.addAthlete = this.addAthlete.bind(this);
        this.deleteAthlete = this.deleteAthlete.bind(this);
        this.getValidationStateForRequiredField = this.getValidationStateForRequiredField.bind(
            this
        );
        this.checkAllRequiredFieldsAreFilled = this.checkAllRequiredFieldsAreFilled.bind(this);
        this.getValidationStateForDate = this.getValidationStateForDate.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.renderAthleteSearch = this.renderAthleteSearch.bind(this);
        this.eventFocus = this.eventFocus.bind(this);
        this.athleteSearchCallback = this.athleteSearchCallback.bind(this);
        this.hideAthleteSearchPopup = this.hideAthleteSearchPopup.bind(this);
    }

    componentDidMount() {
        RequestManager.categoryByCompetitionPlanId(this.props.competitionPlanId).then(
            (response) => {
                this.setState({
                    category: response.body.Category,
                    // If gender is not man (id == 1) or woman (id == 2) then we default gender to woman (id == 2)
                    defaultGenderId:
                        response.body.Category.genderId > 2 ? 2 : response.body.Category.genderId,
                });
                if (!+this.props.competitionRequestId) {
                    this.addRequest();
                }
            }
        );

        if (this.props.competitionRequestId > 0) {
            this.getRequestDetails();
        }
    }

    getRequestDetails() {
        RequestManager.getAthletesByCompetitionRequestId(this.props.competitionRequestId).then(
            (response) => {
                this.setState({
                    requests: [
                        {
                            competitionRequestId: this.props.competitionRequestId,
                            athletes: response,
                        },
                    ],
                });
            }
        );
    }

    addRequest() {
        if (this.checkAllRequiredFieldsAreFilled()) {
            let athletesArray = [];
            for (let i = 0; i < this.state.category.countFrom; i++) {
                athletesArray.push({
                    firstName: "",
                    lastName: "",
                    genderId: this.state.defaultGenderId,
                    city:
                        this.state.requests.length > 0
                            ? this.state.requests[0].athletes[0].city
                            : "",
                    region:
                        this.state.requests > 0 ? this.state.requests[0].athletes[0].region : "",
                    dateOfBirth: "",
                });
            }
            this.setState({
                showValidation: false,
                requests: this.state.requests.concat({
                    competitionRequestId: -1,
                    athletes: athletesArray,
                }),
            });
        } else {
            this.setState({
                showValidation: true,
            });
            NotificationManager.error(this.props.t("fillAllFields"));
        }
    }

    deleteRequest(event) {
        let isRequestEmpty = this.state.requests[event.target.dataset.request].athletes.every(
            (athlete) => {
                return athlete.firstName.length === 0 && athlete.lastName.length === 0;
            }
        );

        if (isRequestEmpty || window.confirm(this.props.t("youShureQuesting"))) {
            let newRequests = this.state.requests;
            newRequests.splice(event.target.dataset.request, 1);
            this.setState({
                requests: newRequests,
            });
        }
    }

    addAthlete(event) {
        let newRequests = this.state.requests;
        const index = event.currentTarget.getAttribute("data-request");
        let firstAthlete = newRequests[index]?.athletes[0];
        newRequests[index].athletes.push({
            firstName: "",
            lastName: "",
            genderId: this.state.defaultGenderId,
            city: firstAthlete ? firstAthlete.city : "",
            region: firstAthlete ? firstAthlete.region : "",
            dateOfBirth: "",
        });
        this.setState({
            requests: newRequests,
        });
    }

    deleteAthlete(event) {
        let newRequests = this.state.requests;
        newRequests[event.target.form.dataset.request].athletes.splice(
            event.target.form.dataset.athlete,
            1
        );
        this.setState({
            requests: newRequests,
            anyInputChanged: true,
        });
    }

    eventChanged(event) {
        let ds = event.target.form.dataset;
        let athlete = this.state.requests[ds.request].athletes[ds.athlete];
        athlete[event.target.name] = event.target.value;
        this.setState({
            anyInputChanged: true,
        });
    }

    inputChanged(event) {
        this.eventChanged(event);
        let ds = event.target.form.dataset;
        let athlete = this.state.requests[ds.request].athletes[ds.athlete];
        let searchParams = {
            FirstName: athlete.firstName,
            LastName: athlete.lastName,
            City: athlete.city,
            Region: athlete.region,
            // GenderId: newRequests[eventRequest].athletes[eventAthlete].genderID,
            LimitRows: 5,
        };
        this.setState({
            athleteSearch: true,
            athleteRelatedToSearch: athlete,
            athleteSearchTarget: event.target.parentNode.parentNode,
            athleteSearchParams: searchParams,
        });
    }

    eventFocus(event) {
        if (this.state.athleteSearch) {
            this.hideAthleteSearchPopup();
        }
    }

    hideAthleteSearchPopup() {
        this.setState({
            athleteSearch: false,
        });
    }

    getValidationStateForRequiredField(value) {
        if (this.state.showValidation && !(value && value.trim().length > 0)) {
            return "error";
        }
        return null;
    }

    getValidationStateForDate(value) {
        if (this.state.showValidation && value && value.indexOf("_") !== -1) {
            return "error";
        }

        if (this.state.showValidation && !moment(value, "DD-MM-YYYY").isValid()) {
            return "error";
        }
        return null;
    }

    checkAllRequiredFieldsAreFilled() {
        return this.state.requests.every((request) => {
            return request.athletes.every((athlete) => {
                return (
                    athlete.firstName &&
                    athlete.firstName.trim().length > 0 &&
                    athlete.lastName &&
                    athlete.lastName.trim().length > 0 &&
                    athlete.city &&
                    athlete.city.trim().length > 0 &&
                    athlete.region &&
                    athlete.region.trim().length > 0 &&
                    athlete.dateOfBirth &&
                    athlete.dateOfBirth.indexOf("_") === -1 &&
                    moment(athlete.dateOfBirth, "DD-MM-YYYY").isValid()
                );
            });
        });
    }

    handleSubmit() {
        const { t } = this.props;
        if (this.checkAllRequiredFieldsAreFilled()) {
            RequestManager.saveRequest(this.props.competitionPlanId, this.state.requests)
                .then((response) => {
                    if (response.body.success) {
                        NotificationManager.success(t("dataSaved"));
                        this.props.onModalSubmit();
                    } else {
                        NotificationManager.error(
                            t("requestSaveError", {
                                NumberOfRequestWithError:
                                    response.body.NumberOfRequestWithError + 1,
                            })
                        );
                    }
                })
                .catch(() => NotificationManager.error(t("addRequestError")));
        } else {
            this.setState({
                showValidation: true,
            });
            NotificationManager.error(t("fillAllFields"));
        }
    }

    handleClose() {
        const { t } = this.props;
        if (
            !this.state.anyInputChanged ||
            window.confirm(`${t("thereUnsavedData")}. ${t("youShureQuesting")}`)
        ) {
            this.props.onModalClose();
        }
    }

    athleteSearchCallback(e) {
        let curAthlete = this.state.athleteRelatedToSearch;
        curAthlete.lastName = e.lastName;
        curAthlete.firstName = e.firstName;
        curAthlete.genderId = e.genderId;
        curAthlete.dateOfBirth = e.dateOfBirth;
        curAthlete.city = e.city;
        curAthlete.region = e.region;
        this.setState({
            athleteSearch: false,
        });
    }

    renderRequest(request, requestIndex) {
        if (!this.state.category) {
            return null;
        }
        const { t } = this.props;
        let showDeleteButton = request.athletes.length > this.state.category.countFrom;
        return (
            <div key={requestIndex}>
                {request.athletes.map((athlete, athleteIndex) =>
                    this.renderAthlete(athlete, requestIndex, athleteIndex, showDeleteButton)
                )}
                {request.athletes.length < this.state.category.countTo && (
                    <Button data-request={requestIndex} onClick={this.addAthlete}>
                        {t("anotherAthlete")}
                    </Button>
                )}
                {this.state.requests && this.state.requests.length > 1 && (
                    <Button
                        onClick={this.deleteRequest}
                        data-request={requestIndex}
                        color="secondary"
                    >
                        {t("deleteRequest")}
                    </Button>
                )}
                {!this.props.competitionRequestId > 0 && <hr />}
            </div>
        );
    }

    renderAthlete(athlete, requestIndex, athleteIndex, showDeleteButton) {
        const { t } = this.props;
        return (
            <div key={requestIndex + athleteIndex}>
                <form data-request={requestIndex} data-athlete={athleteIndex}>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FormGroup
                                controlId=""
                                validationState={this.getValidationStateForRequiredField(
                                    athlete.lastName
                                )}
                            >
                                <TextField
                                    type="text"
                                    name="lastName"
                                    value={athlete.lastName}
                                    onChange={this.inputChanged}
                                    onFocus={this.eventFocus}
                                    label={t("lastName")}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup
                                controlId=""
                                validationState={this.getValidationStateForRequiredField(
                                    athlete.firstName
                                )}
                            >
                                <TextField
                                    type="text"
                                    name="firstName"
                                    value={athlete.firstName}
                                    onChange={this.inputChanged}
                                    onFocus={this.eventFocus}
                                    label={t("firstName")}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup
                                controlId=""
                                validationState={this.getValidationStateForDate(
                                    athlete.dateOfBirth
                                )}
                            >
                                <InputMask
                                    mask="99.99.9999"
                                    value={athlete.dateOfBirth}
                                    size="10"
                                    onChange={this.inputChanged}
                                    onFocus={this.eventFocus}
                                >
                                    {() => (
                                        <TextField
                                            name="dateOfBirth"
                                            label={t("dateOfBirth")}
                                            placeholder={t("dateFormat")}
                                        />
                                    )}
                                </InputMask>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup
                                data-request={requestIndex}
                                data-athlete={athleteIndex}
                                onChange={this.eventChanged}
                                onFocus={this.eventFocus}
                            >
                                <InputLabel>{t("gender")}</InputLabel>
                                <RadioGroup
                                    onChange={() => ({})}
                                    row
                                    name="genderId"
                                    type="radio"
                                    value={+athlete.genderId}
                                >
                                    <FormControlLabel
                                        value={2}
                                        label={t("femaleAbbreviation")}
                                        control={<Radio color="primary" />}
                                    />
                                    <FormControlLabel
                                        value={1}
                                        label={t("maleAbbreviation")}
                                        control={<Radio color="primary" />}
                                    />
                                </RadioGroup>
                            </FormGroup>
                        </Grid>

                        <Grid item xs={4}>
                            <FormGroup
                                controlId=""
                                validationState={this.getValidationStateForRequiredField(
                                    athlete.region
                                )}
                            >
                                <TextField
                                    name="region"
                                    margin="dense"
                                    onChange={this.inputChanged}
                                    label={t("region")}
                                    value={athlete.region}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={4}>
                            <FormGroup
                                controlId=""
                                validationState={this.getValidationStateForRequiredField(
                                    athlete.city
                                )}
                            >
                                <TextField
                                    type="text"
                                    name="city"
                                    margin="dense"
                                    value={athlete.city}
                                    onChange={this.inputChanged}
                                    onFocus={this.eventFocus}
                                    label={t("city")}
                                />
                            </FormGroup>
                        </Grid>
                        <Grid item xs={1}>
                            {showDeleteButton && (
                                <Button
                                    onClick={this.deleteAthlete}
                                    className="glyphicon glyphicon-remove"
                                    color="primary"
                                ></Button>
                            )}
                        </Grid>
                    </Grid>
                </form>
            </div>
        );
    }

    renderAthleteSearch() {
        return this.state.athleteSearch ? (
            <AthletesSearch
                show={this.state.athleteSearch}
                target={this.state.athleteSearchTarget}
                container={this}
                onSelect={this.athleteSearchCallback}
                onClose={this.hideAthleteSearchPopup}
                searchParams={this.state.athleteSearchParams}
            />
        ) : (
            ""
        );
    }

    render() {
        const { t } = this.props;
        return (
            <Dialog
                open={true}
                onClose={this.handleClose}
                onFocus={this.eventFocus}
                aria-labelledby="ModalHeader"
                fullWidth
            >
                <DialogTitle id="ModalHeader" disableTypography>
                    {this.state.header}
                    {this.state.category &&
                        " " +
                            this.state.category.worldCategoryCode +
                            " " +
                            Helpers.getAgeCategoryString(
                                this.state.category.ageFrom,
                                this.state.category.ageTo
                            )}
                </DialogTitle>

                <DialogContent>
                    <Grid container>
                        {this.state.requests.map((request, index) =>
                            this.renderRequest(request, index)
                        )}
                        {this.state.showAddRequestButton && (
                            <Button onClick={this.addRequest} color="default">
                                {t("anotherRequest")}
                            </Button>
                        )}
                        {this.renderAthleteSearch()}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="default">
                        {t("cancel")}
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary">
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

RequestPopup.propTypes = {
    onModalClose: PropTypes.func,
    onModalSubmit: PropTypes.func,
    competitionRequestId: PropTypes.number,
    competitionPlanId: PropTypes.number,
};

export default withTranslation("adminPage")(RequestPopup);
