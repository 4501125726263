import * as Yup from "yup";
import i18n from "i18next";

export const UnivesalTrainerRefereeSchema = Yup.object().shape({
    firstName: Yup.string().required("Пожалуйста, укажите имя"),
    lastName: Yup.string().required("Пожалуйста, укажите фамилию"),
    city: Yup.string().required("Пожалуйста, укажите город"),
    trainerId: Yup.number().nullable().required("Пожалуйста, укажите тренера"),
});

export const getTrainerRefereeSchema = () => {
    if (i18n.language === "ru")
        return Yup.object().shape({
            firstName: Yup.string().required("Пожалуйста, укажите имя"),
            middleName: Yup.string().required("Пожалуйста, укажите отчество"),
            lastName: Yup.string().required("Пожалуйста, укажите фамилию"),
            city: Yup.string().required("Пожалуйста, укажите город"),
            region: Yup.string().required("Пожалуйста, укажите регион"),
        });
    else
        return Yup.object().shape({
            firstName: Yup.string().required("Please state the name"),
            lastName: Yup.string().required("Please state the last name"),
            country: Yup.string().required("Please specify the country"),
            city: Yup.string().required("Please specify the city"),
        });
};
