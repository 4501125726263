import React from "react";
import { Grid, Typography, Container } from "@material-ui/core";
import PropTypes from "prop-types";
import RefereesScoresTable from "./ScoresTables/RefereesScoresTable";
import TotalScoresTable from "./ScoresTables/TotalScoresTable";
import RequestManager from "../../Modules/RequestManager";

class PerformanceResultDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            performanceId: +this.props.match.params.id,
            competition: null,
            refereeScores: null,
            totalScores: null,
            performance: null,
            isLoading: true,
            athletes: [],
        };
    }

    componentDidMount() {
        this.updateData().then(() =>
            this.setState({
                isLoading: false,
                intervalId: setInterval(() => this.updateData(), 10000),
            })
        );
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    updateData() {
        return Promise.all([
            this.loadRefereeScores(),
            this.loadTotalScores(),
            this.loadPerformance(),
            this.loadAthletes(),
        ]);
    }

    loadAthletes() {
        return RequestManager.getAthletesByPerformanceId(this.state.performanceId).then(
            (athletes) => {
                this.setState({
                    athletes: athletes,
                });
            }
        );
    }

    loadRefereeScores() {
        return RequestManager.getRefereeScoresForPerformance(this.state.performanceId).then(
            (result) => {
                this.setState({
                    refereeScores: result,
                });
            }
        );
    }

    loadPerformance() {
        return RequestManager.getPerformance(this.state.performanceId).then((performance) => {
            this.setState({
                performance: performance,
            });
        });
    }

    loadTotalScores() {
        return RequestManager.getTotalScoreByCriteriaForPerformance(this.state.performanceId).then(
            (totalScore) => {
                this.setState({
                    totalScores: totalScore,
                });
            }
        );
    }

    render() {
        if (this.state.isLoading) {
            return null;
        }

        return (
            <Container>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Оценки судей</Typography>
                        <RefereesScoresTable
                            refereeScores={this.state.refereeScores}
                            totalScores={this.state.totalScores}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6">Итоговые оценки</Typography>
                        <TotalScoresTable
                            totalScores={this.state.totalScores}
                            performance={this.state.performance}
                        />
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

PerformanceResultDetails.propTypes = {
    performance: PropTypes.object,
    athletes: PropTypes.arrayOf(PropTypes.string),

    // Props from router
    match: PropTypes.object,
};

export default PerformanceResultDetails;
