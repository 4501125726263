import React from "react";
import { Panel } from "react-bootstrap";
import FormControl from "react-bootstrap/es/FormControl";
import "./ProtocolComplexity.css";
import ControlLabel from "react-bootstrap/es/ControlLabel";
import "../Protocol.css";
import CriteriaManager from "../../../../../Modules/CriteriaManager";
import Utils from "../../../../../Modules/Utils";
import { REFEREE_ROLES } from "../../../../../Modules/Enums";
import NumberInput from "../../../../Common/InputControls/NumberInput";
import IncDecNumberInput from "../../../../Common/InputControls/IncDecNumberInput";
import CriterionTypesManager from "../../../../../Modules/CriterionTypesManager";
import SaveCancelPanel from "../../../Protocols/SaveCancelPanel";
import { PerformanceReferee } from "../../../../../interfaces/performance-referee";
import { withTranslation } from "react-i18next";

interface Props {
    performanceReferee: PerformanceReferee;
    onSave: (scores) => Promise<void>;
    onClose: () => void;
    t: any;
}

interface Score {
    performanceRefereeId: number;
    criterionId: number;
    score: {};
    penalty: {};
}

class ProtocolComplexity extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);

        this.state = {
            criterionGroups: null,
        };
    }

    componentDidMount() {
        this.loadCriteria();
    }

    roundGrade(g) {
        return (+g).toFixed(1);
    }

    onCriteriaChange(newValue, groupIdx, criterionIdx) {
        const criterionGroups = this.state.criterionGroups;
        const criterion = criterionGroups[groupIdx].criteria[criterionIdx];

        if (Utils.isValueInRange(newValue, criterion.minValue, criterion.maxValue)) {
            if (criterion.criterionTypeId === CriterionTypesManager.CRITERION_TYPES.penalty.id) {
                criterion.penalty = newValue;
                criterion.score = null;
            } else {
                criterion.score = newValue;
                criterion.penalty = null;
            }

            this.setState({
                criterionGroups: criterionGroups,
            });
        }
    }

    loadCriteria() {
        CriteriaManager.getCriteriaAndGroups(REFEREE_ROLES.complexityJudge.id)
            .then((response) => {
                const criterionGroups = response.body.criterionGroups;
                const criterionGroupsWithScores = criterionGroups.map((group) => {
                    group.score = 0;
                    group.penalty = 0;
                    group.criteria = group.criteria.map((criterion) => {
                        criterion.score = 0;
                        criterion.penalty = 0;
                        return criterion;
                    });
                    return group;
                });
                this.setState({
                    criterionGroups: criterionGroupsWithScores,
                });
            })
            .then(() => {
                this.loadCriteriaScores();
            });
    }

    loadCriteriaScores() {
        CriteriaManager.loadScores(this.props.performanceReferee.id).then((response) => {
            const scores = response.body;
            const criterionGroups = this.state.criterionGroups;
            criterionGroups.forEach((group) => {
                group.criteria.forEach((criterion) => {
                    if (scores[criterion.criterionId] !== undefined) {
                        criterion.score = scores[criterion.criterionId].score;
                        criterion.penalty = scores[criterion.criterionId].penalty;
                    } else {
                        criterion.score = null;
                        criterion.penalty = null;
                    }
                    return criterion;
                });
                return group;
            });
            this.setState({
                criterionGroups: criterionGroups,
                scoresLoaded: true,
            });
        });
    }

    getTotalPenalty() {
        let totalPenalty = 0;
        if (!this.state.criterionGroups) {
            return 0;
        }

        for (const group of this.state.criterionGroups) {
            let criteriaPenalty = 0;
            for (const criterion of group.criteria) {
                criteriaPenalty += criterion.penalty;
            }
            group.penalty = criteriaPenalty;

            totalPenalty += group.penalty;
        }

        return totalPenalty;
    }
    getTotal() {
        return (
            <tr>
                <td>
                    <ControlLabel>{this.props.t("totalRebate")}:</ControlLabel>
                </td>
                <td>
                    <FormControl
                        type="number"
                        readOnly
                        className="field penalties-input"
                        value={this.getTotalPenalty().toFixed(1)}
                    />
                </td>
            </tr>
        );
    }
    getCriteria(criteriaArray, groupIdx) {
        return criteriaArray.map((criterion, idx) => {
            const defValue =
                criterion.criterionTypeId === CriterionTypesManager.CRITERION_TYPES.penalty.id
                    ? criterion.penalty
                    : criterion.score;
            let inputControl;

            if (criterion.criterionTypeId === CriterionTypesManager.CRITERION_TYPES.penalty.id) {
                inputControl = (
                    <IncDecNumberInput
                        step={criterion.worth ?? 1}
                        value={+defValue}
                        minValue={criterion.minValue}
                        maxValue={criterion.maxValue}
                        onChange={(value) => this.onCriteriaChange(value, groupIdx, idx)}
                    />
                );
            } else {
                inputControl = (
                    <NumberInput
                        className="penalties-input"
                        onChange={(value) =>
                            this.onCriteriaChange(value.target.value, groupIdx, idx)
                        }
                        accuracy={1}
                        defaultValue={Number(defValue)}
                        minValue={criterion.minValue}
                        maxValue={criterion.maxValue}
                    />
                );
            }

            return (
                <tr key={criterion.criterionId}>
                    <td className="complexity-td">
                        <ControlLabel>
                            {criterion.criterionName} {<br />} {criterion.criterionNameEn}
                        </ControlLabel>
                    </td>
                    <td className="complexity-td">{inputControl}</td>
                </tr>
            );
        });
    }

    getGroups() {
        if (!this.state.criterionGroups || !this.state.scoresLoaded) {
            return null;
        }
        return this.state.criterionGroups.map((group, idx) => {
            return (
                <Panel id="penalties-panel" key={idx}>
                    <p className="title">
                        {group.criterionGroupName} {<br />} {group.criterionGroupNameEn}
                    </p>
                    <table id="complexity-penalties-table">
                        <tbody>
                            {this.getCriteria(group.criteria, idx)}
                            {/* {group.criterionGroupNumber > 1 && (
                                <>
                                    <tr className="division-row">
                                        <td />
                                        <td />
                                    </tr>
                                    {this.getTotal()}
                                </>
                            )} */}
                        </tbody>
                    </table>
                </Panel>
            );
        });
    }

    getScoresReqObject = () => {
        const scoresReqObject: Score[] = [];

        this.state.criterionGroups.forEach((group) => {
            group.criteria.forEach((criterion) => {
                scoresReqObject.push({
                    performanceRefereeId: this.props.performanceReferee.id,
                    criterionId: criterion.criterionId,
                    score: criterion.score,
                    penalty: criterion.penalty,
                });
            });
        });
        return scoresReqObject;
    };

    onSave = async () => {
        const scores = this.getScoresReqObject();
        await this.props.onSave(scores);
    };

    render() {
        return (
            <div id="protocol-complexity-container">
                {this.getGroups()}
                <SaveCancelPanel onSave={this.onSave} onCancel={this.props.onClose} />
            </div>
        );
    }
}

export default withTranslation("refereePage")(ProtocolComplexity);
