import React from "react";
import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import PropTypes from "prop-types";

class RatingScale extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            values: props.values,
            label: props.label,
        };
    }

    shouldComponentUpdate(nextProps) {
        return nextProps.values !== this.props.values;
    }

    render() {
        const defaultValues = [];
        if (this.props.defaultValue !== null) {
            defaultValues.push(this.props.defaultValue.toFixed(1));
        }
        const label = this.state.label !== undefined ? this.state.label : "";
        return (
            <ToggleButtonGroup
                name={this.state.label}
                onChange={this.props.onChange}
                defaultValue={defaultValues}
            >
                <div>{label}</div>
                {this.state.values.map((value, idx) => {
                    return (
                        <ToggleButton value={value} key={value}>
                            {value}
                        </ToggleButton>
                    );
                })}
            </ToggleButtonGroup>
        );
    }
}

RatingScale.propTypes = {
    values: PropTypes.array.isRequired,
    label: PropTypes.string,
    onChange: PropTypes.func,
    defaultValue: PropTypes.number,
};

export default RatingScale;
