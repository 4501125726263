import React, { FC, useState } from "react";
import { ListItem, List, DialogTitle, Dialog, Button, CardMedia } from "@material-ui/core";
import styles from "./SelectLanguageModal.module.scss";
import { LocalesEnum } from "@/modules/enums";
import { useTranslation } from "react-i18next";
import moment from "moment";

type Props = {
    handleLanguageSelecting: () => void;
};

export const SelectLanguageModal: FC<Props> = ({ handleLanguageSelecting }) => {
    const [isOpen, setIsOpen] = useState(true);
    const { i18n } = useTranslation();

    const handleSelectLanguage = (locale: LocalesEnum) => {
        i18n.changeLanguage(locale, (err, t) => {
            if (err) return console.log("something went wrong loading", err);
            t("key");
        });
        moment.locale(locale);
        setIsOpen(false);
        handleLanguageSelecting();
    };

    return (
        <Dialog aria-labelledby="Language selector" open={isOpen}>
            <DialogTitle id="select-language">Please, select a language</DialogTitle>
            <List>
                <ListItem>
                    {
                        <CardMedia
                            component="img"
                            className={styles["MuiCardMedia-img"]}
                            image={`/assets/country-flags/${LocalesEnum.RU}.svg`}
                        />
                    }
                    <Button
                        onClick={() => handleSelectLanguage(LocalesEnum.RU)}
                        color="primary"
                        variant="contained"
                        fullWidth
                    >
                        Русский
                    </Button>
                </ListItem>
                <ListItem>
                    {
                        <CardMedia
                            component="img"
                            className={styles["MuiCardMedia-img"]}
                            image={`/assets/country-flags/${LocalesEnum.EN}.svg`}
                        />
                    }
                    <Button
                        onClick={() => handleSelectLanguage(LocalesEnum.EN)}
                        color="primary"
                        variant="contained"
                        fullWidth
                    >
                        English
                    </Button>
                </ListItem>
            </List>
        </Dialog>
    );
};
