import {
    DatePicker as MatDatePicker,
    DatePickerProps as MatMatDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";

type DatePickerProps = Omit<MatMatDatePicker, "onChange"> & {
    onChange(name: string, value: any): void;
};

const DatePicker = (props: DatePickerProps) => {
    const { t, i18n } = useTranslation("common");
    const format = "YYYY-MM-DD";

    const handleChange = (date) => {
        const formattedDate = moment.isMoment(date) ? moment(date).format(format) : null;

        props.onChange(props.name || "", formattedDate);
    };

    const value = props.value ? moment(props.value).format(format) : null;
    return (
        <MatDatePicker
            {...props}
            clearable
            value={value}
            label={props.label || t("selectDate")}
            fullWidth={props.fullWidth}
            onChange={handleChange}
            format={i18n.language === "ru" ? "DD/MM/yyyy" : "MM/DD/yyyy"}
            okLabel={t("ok")}
            clearLabel={t("clear")}
            cancelLabel={t("cancel")}
        />
    );
};

export default DatePicker;
