import BaseApiService from "@/api/Base";
import { TokenInterceptor } from "@/api/Http/Interceptors";
import { CompetitionTrainerReferee } from "@/interfaces";
import { Settings } from "@/modules/Constants";

class CompetitionTrainerRefereeApiService extends BaseApiService<CompetitionTrainerReferee> {
    constructor(config) {
        super(config);
        this.url = "/competitions-trainer-referees";
        this.http.interceptors.request.use(TokenInterceptor);
    }
}

export default new CompetitionTrainerRefereeApiService({
    baseURL: Settings.MAIN_API_URL_V2,
});
