import React from "react";
import PropTypes from "prop-types";
import { Container, Grid, Paper, Typography, List, ListItem } from "@material-ui/core";
import { Redirect, Switch, Route } from "react-router";
import { withRouter } from "react-router-dom";

import CompetitionsManager from "../Modules/CompetitionsManager";
import Auth from "../Modules/Auth";
import SearchAthleteCoach from "../Controls/SearchAthleteCoach";
import PerformanceScoresSearchResults from "./PerformanceScoresSearchResults";
import Performances from "../Controls/Performances/Performances";
import { withTranslation } from "react-i18next";
import "./CompetitionsListPage.css";

import moment from "moment";

class CompetitionsListPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            competitions: [],
        };

        this.onItemClick = this.onItemClick.bind(this);
    }

    componentDidMount() {
        if (Auth.isUserAuthenticated()) {
            CompetitionsManager.findAllCompetitions()
                .then(CompetitionsManager.replaceDatesStrToObject)
                .then((competitions) => {
                    competitions = competitions.map((competition) => {
                        competition.dateFrom = moment(competition.dateFrom).format("DD.MM.YYYY");
                        competition.dateTo = moment(competition.dateTo).format("DD.MM.YYYY");
                        competition.date =
                            competition.dateFrom === competition.dateTo
                                ? competition.dateFrom
                                : competition.dateFrom + " - " + competition.dateTo;
                        return competition;
                    });

                    this.setState({
                        competitions: competitions,
                    });
                })
                .catch((err) => {
                    throw err;
                });
        }
    }

    onItemClick(item) {
        this.props.history.push(`/competitionsList/${item.competitionId}/Performances`);
    }

    getHeader() {
        const { t } = this.props;
        const currentUrl = this.props.location.pathname;
        if (currentUrl.indexOf("PerformanceScoresSearchResults") > 0) {
            return t("searchResults");
        }
        if (currentUrl.indexOf("Performances") > 0) {
            return this.getCompetitionName(currentUrl);
        }
        return t("competitions");
    }

    getCompetitionName(currentUrl) {
        let result;
        this.state.competitions.forEach((c) => {
            if (c.competitionId === +currentUrl.match(/\d+/)) {
                result = c.competitionName;
            }
        });
        return result;
    }

    render() {
        if (!Auth.isUserAuthenticated()) {
            return <Redirect to="/login" />;
        }
        return (
            <Container className="competitions-list-page">
                <Grid
                    container
                    alignItems="center"
                    justify="space-between"
                    className="competitions-list-page-header"
                >
                    <Typography variant="h4">{this.getHeader()}</Typography>
                    <SearchAthleteCoach />
                </Grid>

                <Grid item xs={12} className="competitions-list-page-content">
                    <Switch>
                        <Route
                            path="/competitionsList"
                            exact
                            render={() => {
                                return (
                                    <List>
                                        {this.state.competitions.map((item) => {
                                            return (
                                                <ListItem
                                                    key={item.competitionId}
                                                    onClick={() => this.onItemClick(item)}
                                                >
                                                    <Typography variant="subtitle1">
                                                        {item.competitionName}, {item.place},{" "}
                                                        {item.date}
                                                    </Typography>
                                                </ListItem>
                                            );
                                        })}
                                    </List>
                                );
                            }}
                        />
                        <Route
                            path="/competitionsList/PerformanceScoresSearchResults/:searchName/:searchBy"
                            component={PerformanceScoresSearchResults}
                        />
                        <Route path="/competitionsList/:id/Performances" component={Performances} />
                    </Switch>
                </Grid>
            </Container>
        );
    }
}

CompetitionsListPage.propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    // Props from router
    match: PropTypes.object,
};

export default withRouter(withTranslation("pages")(CompetitionsListPage));
