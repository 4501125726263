import { competitionApiService } from "@/api/Services";
import { Paths } from "@/modules/Constants";
import Utils from "@/modules/Utils";
import {
    Box,
    Breadcrumbs,
    Button,
    Grid,
    Link,
    List,
    ListItem,
    Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Place, Event, InsertDriveFile } from "@material-ui/icons";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useParams, Link as RouterLink } from "react-router-dom";
import ContentLayout from "../../../Views/ContentLayout";

const useStyles = makeStyles({
    card: {
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    bold: {
        fontWeight: "bold",
    },
    media: {
        height: 160,
    },
    iconLabel: {
        display: "flex",
        alignContent: "center",
        alignItems: "center",
    },
    icon: {
        marginRight: 5,
    },
    meta: {
        "& .MuiGrid-item": {
            paddingTop: 5,
            paddingBottom: 5,
        },
    },
    divider: {
        marginTop: 3,
        marginBottom: 3,
    },
});

const CompetitionDetail: React.FunctionComponent = () => {
    const { t } = useTranslation("common");
    const { t: trainerPageT } = useTranslation("trainerPage");
    const classes = useStyles();

    const { id } = useParams<{ id: string }>();

    const { data, isFetching, isFetched } = useQuery(["event-competition", id], () =>
        competitionApiService.getOne(Number(id))
    );

    return (
        <ContentLayout component="section">
            <ContentLayout component="section" padding={[2, 0]}>
                <Breadcrumbs>
                    <Link underline="hover" component={RouterLink} to={Paths.EVENTS}>
                        {t("events")}
                    </Link>
                    <Typography>{data?.result.name}</Typography>
                </Breadcrumbs>
            </ContentLayout>
            <Grid container spacing={3}>
                <Grid item md={5}>
                    <Box maxHeight={700}>
                        {isFetching ? (
                            <Skeleton width="100%" height={300} />
                        ) : (
                            <img
                                src={data?.result.cover?.path || "/assets/competition.png"}
                                alt={t("competitionCover")}
                                width="100%"
                            />
                        )}
                    </Box>
                </Grid>
                <Grid item md={6}>
                    {isFetching ? (
                        <Skeleton width="100%" height={100} />
                    ) : (
                        <Typography variant="h3" component="h1">
                            {data?.result.name}
                        </Typography>
                    )}

                    {isFetching ? (
                        <Skeleton width="100%" height={100} />
                    ) : (
                        <Grid container justify="space-between" className={classes.meta}>
                            <Grid item>
                                <Typography
                                    className={classes.iconLabel}
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    <Place className={classes.icon} />
                                    {data?.result.place ||
                                        trainerPageT("competitionCard.venueNotSpecified")}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography
                                    className={classes.iconLabel}
                                    color="textSecondary"
                                    variant="body2"
                                >
                                    <Event className={classes.icon} />
                                    {Utils.getFormattedDates(
                                        data?.result.dateFrom,
                                        data?.result.dateTo
                                    ) || "Даты не указаны"}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}

                    <Box paddingTop={2} flexWrap>
                        {isFetching ? (
                            <Skeleton width="100%" height={200} />
                        ) : (
                            <Typography>
                                {data?.result.description ||
                                    trainerPageT("competitionCard.noDescription")}
                            </Typography>
                        )}
                    </Box>

                    {data?.result.isAllowedRequests && (
                        <Box paddingTop={2} flexWrap>
                            <Button href="/trainer" color="primary" variant="outlined" fullWidth>
                                {trainerPageT("competitionCard.apply")}
                            </Button>
                        </Box>
                    )}

                    <Box paddingTop={2}>
                        {isFetching ? (
                            <Skeleton width="100%" height={100} />
                        ) : (
                            <Typography variant="h6">{t("attachments")}</Typography>
                        )}

                        <List>
                            {isFetched && !data?.result.attachments.length && (
                                <Typography>{t("attachmentsNotFound")}</Typography>
                            )}
                            {data?.result.attachments.map((attachment) => (
                                <ListItem disableGutters key={attachment.id}>
                                    <InsertDriveFile />
                                    <Link
                                        href={attachment.file.path}
                                        target="_blank"
                                        download={attachment.file.displayName}
                                    >
                                        {attachment.file.displayName ??
                                            attachment.file.originalName}
                                    </Link>
                                </ListItem>
                            ))}
                        </List>
                    </Box>
                </Grid>
            </Grid>
        </ContentLayout>
    );
};

export default CompetitionDetail;
