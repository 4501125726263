import { TableCell, TableRow, Typography } from "@material-ui/core";
import { Performance } from "../../../../interfaces/performance";
import React, { CSSProperties, ReactNode, useEffect, useMemo, useState } from "react";
import RefereeTableDefaultCell from "./referee-table-cells/referee-table-default-cell";
import RefereeTableIndexCell from "./referee-table-cells/referee-table-index-cell";
import { CompetitionRequestDetails } from "../../../../interfaces/competition-request-details";
import RefereeTableScoreCell from "./referee-table-cells/referee-table-score-cell";
import { red } from "@material-ui/core/colors";
import { OtherScores } from "../../../../interfaces/other-scores";
import { BrigadeVariant } from "../../../../interfaces/competition";
import { useStoreon } from "storeon/react";
import { RefereeModuleEvents, RefereeModuleState } from "src/store/StoreModules/RefereeModule";
import { REFEREE_ROLES } from "@/modules/Enums";

interface Column {
    fieldName: string;
    type: "default" | "index" | "score";
    refereeRoleId?: number;
    refereeNumber?: number;
    content: ReactNode;
}

type GetColumns = (brigadeVariant: BrigadeVariant) => Column[];
interface Props {
    index: number;
    performance: Performance;
    onChangePerformanceStatus: (statusId: number, performanceId: number) => Promise<void>;
    actionsBy: any;
    onShowScores: any;
    shownScores: boolean;
    activeSignalArrows: any[];
    onSendSignalToJudge: any;
    onEditPerformance: any;
}

const COLOR_STATUSES = {
    1: "",
    2: "darkseagreen",
    3: "goldenrod",
} as const;

const STYLED_COLUMNS = ["averageExecution", "averageArtistry", "averageComplexity"];

const CELL_TYPES = {
    index: (props) => <RefereeTableIndexCell {...props} />,
    default: (props) => <RefereeTableDefaultCell {...props} />,
    score: (props) => <RefereeTableScoreCell {...props} />,
};

const RefereeTableBodyRow = ({
    index,
    performance,
    onChangePerformanceStatus,
    actionsBy,
    onShowScores,
    shownScores,
    activeSignalArrows,
    onSendSignalToJudge,
    onEditPerformance,
}: Props) => {
    const { competition } = useStoreon<RefereeModuleState, RefereeModuleEvents>("competition");

    const [bgColor, setBgColor] = useState(COLOR_STATUSES[performance.statusId]);

    const brigadeVariant: BrigadeVariant = competition?.brigadeVariant ?? BrigadeVariant.DEFAULT;
    const canChairRateAll: boolean = competition?.canChairRateAll ?? false;

    useEffect(() => {
        setBgColor(COLOR_STATUSES[performance.statusId]);
    }, [performance.statusId]);

    const refereeScores = useMemo(() => {
        const scores = {} as any;
        performance.performanceReferees?.forEach((pr) => {
            scores[`referee${pr.refereeNumber}-${pr.refereeRoleId}`] = pr.performanceScores
                ? pr.performanceScores[0]
                    ? +pr.performanceScores[0].totalScore
                    : null
                : null;
        });

        return scores;
    }, [performance.performanceReferees]);

    const handleChangePerformanceStatus = async () => {
        let status = 0;

        switch (performance.statusId) {
            case 2: {
                status = 3;
                break;
            }
            case 3: {
                status = 1;
                break;
            }
            case 1:
            default: {
                status = 2;
            }
        }

        await onChangePerformanceStatus(status, performance.id);
    };

    const getExecutionRefereesColumns: GetColumns = (brigadeVariant) => {
        const count = Number(brigadeVariant);
        return [...new Array(Number(count))].map((_, i) => {
            const index: number = i + 1;
            const refereeRoleId = REFEREE_ROLES.executionJudge.id;
            const key = `referee${index}-${refereeRoleId}`;

            return {
                fieldName: key,
                type: "score",
                refereeRoleId,
                refereeNumber: index,
                content: refereeScores[key] || null,
            };
        });
    };

    const getArtisticRefereesColumns: GetColumns = (brigadeVariant) => {
        const count = Number(brigadeVariant);
        return [...new Array(count)].map((_, i) => {
            const index: number = i + 1;
            const refereeRoleId = REFEREE_ROLES.artistryJudge.id;
            const key = `referee${index}-${refereeRoleId}`;

            return {
                fieldName: key,
                type: "score",
                refereeRoleId,
                refereeNumber: index,
                content: refereeScores[key] || null,
            };
        });
    };

    const getComplexityRefereesColumns: GetColumns = () => {
        return [...new Array(2)].map((_, i) => {
            const index: number = i + 1;
            const refereeRoleId = REFEREE_ROLES.complexityJudge.id;
            const key = `referee${index}-${refereeRoleId}`;

            return {
                fieldName: key,
                type: "score",
                refereeRoleId,
                refereeNumber: index,
                content: refereeScores[key] || null,
            };
        });
    };

    const columns: Column[] = [
        { fieldName: "number", type: "index", content: index },
        {
            fieldName: "athletes",
            type: "default",
            content: (
                <div onClick={handleChangePerformanceStatus}>
                    {performance.competitionRequest?.details?.map(
                        (detail: CompetitionRequestDetails) => {
                            return (
                                <div key={detail.id}>
                                    <Typography>{`${detail.athlete?.lastName} ${detail.athlete?.firstName}`}</Typography>
                                </div>
                            );
                        }
                    )}
                </div>
            ),
        },
        {
            fieldName: "region",
            type: "default",
            content:
                performance.competitionRequest?.details &&
                performance.competitionRequest?.details[0].athlete?.region,
        },
        ...getExecutionRefereesColumns(brigadeVariant),
        {
            fieldName: "averageExecution",
            type: "score",
            content: performance.otherScores?.averageExecution || null,
        },
        ...getArtisticRefereesColumns(brigadeVariant),
        {
            fieldName: "averageArtistry",
            type: "default",
            content: performance.otherScores?.averageArtistry || null,
        },
        ...getComplexityRefereesColumns(brigadeVariant),
        {
            fieldName: "averageComplexity",
            type: "default",
            content: performance.otherScores?.averageComplexity || null,
        },
        {
            fieldName: "complexityPenalty",
            type: "default",
            content: performance.otherScores?.complexityPenalty || null,
        },
        {
            fieldName: "brigadePenalty",
            type: "score",
            refereeRoleId: REFEREE_ROLES.referee.id,
            refereeNumber: 1,
            content: performance.otherScores?.brigadePenalty || "-",
        },
        {
            fieldName: "linePenalty",
            type: "score",
            refereeRoleId: REFEREE_ROLES.lineReferee.id,
            refereeNumber: 1,
            content: performance.otherScores?.linePenalty || "-",
        },
        {
            fieldName: "totalPoints",
            type: "default",
            content: performance.totalPoints,
        },
        {
            fieldName: "place",
            type: "default",
            content: performance.place,
        },
    ];

    const findMinMaxScores = (refereesArr: string[], refereeScores) => {
        return refereesArr.reduce(
            (acc, curr) => {
                const currReferee = refereeScores[curr];

                acc.max = refereeScores[acc.max] > currReferee ? acc.max : curr;
                acc.min = refereeScores[acc.min] < currReferee ? acc.min : curr;

                return acc;
            },
            { min: "", max: "" }
        );
    };

    const getCellStyles = (
        refereeScores,
        brigadeVariant: BrigadeVariant,
        otherScores?: OtherScores
    ) => {
        const styles: { [key: string]: CSSProperties } = {};

        const exeReferees = [...new Array(brigadeVariant === BrigadeVariant.DEFAULT ? 4 : 6)].map(
            (_, i) => {
                return `referee${i + 1}-${REFEREE_ROLES.executionJudge.id}`;
            }
        );

        const artReferees = [...new Array(brigadeVariant === BrigadeVariant.DEFAULT ? 4 : 6)].map(
            (_, i) => {
                return `referee${i + 1}-${REFEREE_ROLES.artistryJudge.id}`;
            }
        );

        const compReferees = [...new Array(2)].map((_, i) => {
            return `referee${i + 1}-${REFEREE_ROLES.complexityJudge.id}`;
        });

        const executionMaxMin = findMinMaxScores(exeReferees, refereeScores);
        if (executionMaxMin.max === executionMaxMin.min) {
            executionMaxMin.max = "";
            executionMaxMin.min = "";
        }
        const artistryMaxMin = findMinMaxScores(artReferees, refereeScores);
        if (artistryMaxMin.max === artistryMaxMin.min) {
            artistryMaxMin.max = "";
            artistryMaxMin.min = "";
        }
        const complexityMaxMin = findMinMaxScores(compReferees, refereeScores);

        const greyColor = "#dddddd";
        const redColor = red[500];

        const borderStyle: CSSProperties = {
            borderWidth: 1.5,
        };

        if (otherScores) {
            if (otherScores.averageExecutionCalcMethod === 1) {
                styles[executionMaxMin.max] = { backgroundColor: greyColor };
                styles[executionMaxMin.min] = { backgroundColor: greyColor };

                if (refereeScores[executionMaxMin.max] - refereeScores[executionMaxMin.min] >= 1) {
                    styles[executionMaxMin.max] = {
                        ...borderStyle,
                        borderColor: redColor,
                        backgroundColor: greyColor,
                    };
                    styles[executionMaxMin.min] = {
                        ...borderStyle,
                        borderColor: redColor,
                        backgroundColor: greyColor,
                    };
                }
            }

            if (otherScores.averageExecutionCalcMethod === 2) {
                if (refereeScores[executionMaxMin.max] - refereeScores[executionMaxMin.min] >= 1) {
                    styles[executionMaxMin.max] = {
                        ...borderStyle,
                        borderColor: redColor,
                    };
                    styles[executionMaxMin.min] = {
                        ...borderStyle,
                        borderColor: redColor,
                    };
                }
            }

            if (otherScores.averageArtistryCalcMethod === 1) {
                styles[artistryMaxMin.max] = { backgroundColor: greyColor };
                styles[artistryMaxMin.min] = { backgroundColor: greyColor };

                if (refereeScores[artistryMaxMin.max] - refereeScores[artistryMaxMin.min] >= 1) {
                    styles[artistryMaxMin.max] = {
                        ...borderStyle,
                        borderColor: redColor,
                        backgroundColor: greyColor,
                    };
                    styles[artistryMaxMin.min] = {
                        ...borderStyle,
                        borderColor: redColor,
                        backgroundColor: greyColor,
                    };
                }
            }

            if (otherScores.averageArtistryCalcMethod === 2) {
                if (refereeScores[artistryMaxMin.max] - refereeScores[artistryMaxMin.min] >= 1) {
                    styles[artistryMaxMin.max] = {
                        ...borderStyle,
                        borderColor: redColor,
                    };
                    styles[artistryMaxMin.min] = {
                        ...borderStyle,
                        borderColor: redColor,
                    };
                }
            }
        }

        if (refereeScores[complexityMaxMin.max] - refereeScores[complexityMaxMin.min] >= 1) {
            styles[complexityMaxMin.max] = {
                ...borderStyle,
                borderColor: redColor,
            };
            styles[complexityMaxMin.min] = {
                ...borderStyle,
                borderColor: redColor,
            };
        }

        return styles;
    };

    const styles = useMemo(
        () => getCellStyles(refereeScores, brigadeVariant, performance.otherScores),
        [refereeScores, performance.otherScores, brigadeVariant]
    );

    return (
        <TableRow style={{ backgroundColor: bgColor }}>
            {columns.map((column) => {
                const cellStyles = styles[column.fieldName];

                return (
                    <TableCell
                        key={column.fieldName}
                        className={`${
                            STYLED_COLUMNS.includes(column.fieldName) && `styled-cell-row`
                        }`}
                    >
                        {CELL_TYPES[column.type]({
                            column,
                            content: column.content,
                            refereeRoleId: column.refereeRoleId,
                            refereeNumber: column.refereeNumber,
                            performance,
                            activeSignalArrows,
                            actionsBy,
                            shownScores,
                            onShowScores,
                            onSendSignalToJudge,
                            onEditPerformance,
                            cellStyles,
                            canChairRateAll,
                            brigadeVariant,
                        })}
                    </TableCell>
                );
            })}
        </TableRow>
    );
};

export default RefereeTableBodyRow;
