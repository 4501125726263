import React, { useEffect, useState, useMemo } from "react";
import { useStoreon } from "storeon/react";
import { useInterval } from "../../Hooks";
import useTrait from "../../Hooks/useTrait";
import { BeamerSlideObject } from "../../Modules/Beamer/types";
import { BeamerModuleEvents, BeamerModuleState } from "../../store/StoreModules/BeamerModule";
import RatingView from "../../Views/Beamer/rating-view";

const comparePerformances = (p1, p2) => {
    if (p1.place && p2.place) {
        if (p1.place < p2.place) {
            return -1;
        }
        if (p1.place > p2.place) {
            return 1;
        }
        return 0;
    }
    if (p1.place) return -1;
    return 1;
};

const toRange = (start: number, end: number) => {
    const ans: number[] = [];
    for (let i = start; i <= end; i++) {
        ans.push(i);
    }
    return ans;
};

const RatingSlide = (): JSX.Element => {
    const { updateTimeInterval, ratingSlide } = useStoreon<BeamerModuleState, BeamerModuleEvents>(
        "updateTimeInterval",
        "ratingSlide"
    );
    const performances = useMemo(
        () => ratingSlide.performances.filter((p) => p.place).sort(comparePerformances),
        [ratingSlide]
    );
    const perfsPerSlideCount = 8;
    const [performancesInQueue, setPerformancesInQueue] = useState<BeamerSlideObject[]>([]);
    const performancesNumber = useTrait(0);

    const updatePerformancesInQueue = () => {
        setPerformancesInQueue(
            performances.slice(
                performancesNumber.get(),
                performancesNumber.get() + perfsPerSlideCount
            )
        );
    };

    const updatePerformancesNumber = () => {
        if (performancesInQueue.length === perfsPerSlideCount)
            performancesNumber.set(performancesNumber.get() + perfsPerSlideCount);
        else performancesNumber.set(0);
    };

    useEffect(() => {
        updatePerformancesInQueue();
    }, [performances]);

    useInterval(
        () => {
            updatePerformancesNumber();
            updatePerformancesInQueue();
        },
        performances.length > perfsPerSlideCount ? updateTimeInterval : null
    );

    const ratingSlideProps = {
        performances: performancesInQueue,
        places: toRange(
            performancesNumber.get() + 1,
            performancesNumber.get() + perfsPerSlideCount
        ),
        perfsPerSlideCount: perfsPerSlideCount,
    };

    return <RatingView {...ratingSlideProps} />;
};
export default RatingSlide;
