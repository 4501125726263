import React from "react";
import PropTypes from "prop-types";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    Select,
    Divider,
    FormControl,
    FormGroup,
    Typography,
    MenuItem,
    Grid,
    InputLabel,
    CircularProgress,
    Box,
} from "@material-ui/core";
import RequestManager from "../../../Modules/RequestManager";
import { NotificationManager } from "react-notifications";
import Utils from "../../../Modules/Utils";
import Helpers from "../../../Modules/Helpers";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import { competitionApiService } from "@/api/Services";
import { BrigadeVariant } from "../../../interfaces/competition";

class RefereeAssignmentPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            performanceId: this.props.selectedRefereeAssignment
                ? this.props.selectedRefereeAssignment.performanceId
                : -1,
            athleteName: this.props.selectedRefereeAssignment
                ? this.props.selectedRefereeAssignment.athleteName
                : "",
            competitionPlanName: this.props.selectedRefereeAssignment
                ? this.props.selectedRefereeAssignment.competitionPlanName
                : "",
            dateTime: this.props.selectedRefereeAssignment
                ? this.props.selectedRefereeAssignment.dateTime
                : null,
            refereeAssignments: [],
            referees: [],
            isValid: true,
            brigadeNumber: 1,
            refereeBrigades: [],
            competition: null,
            isLoading: true,
        };

        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.onShow = this.onShow.bind(this);
        this.brigadeChanged = this.brigadeChanged.bind(this);
        this.getAssignedRefereeId = this.getAssignedRefereeId.bind(this);
    }

    async componentDidMount() {
        const { data: competition } = await competitionApiService.getOne(this.props.competitionId);

        const refereeAssignments = await RequestManager.fetch(
            `refereesByPerformanceId/${this.props.selectedRefereeAssignment.performanceId}`,
            "get"
        ).then((response) => {
            let normalizedRefereeAssignments = [];
            for (let i = 0; i < response.body.RefereeAssignments.length; i++) {
                normalizedRefereeAssignments[response.body.RefereeAssignments[i].refereeNumber] =
                    response.body.RefereeAssignments[i];
            }
            return normalizedRefereeAssignments;
        });
        const referees = await RequestManager.fetch("referees", "get").then((response) => {
            return response.body.Referees;
        });

        const refereeBrigades = await RequestManager.fetch(
            `getCompetitionBrigades/${this.props.competitionId}`,
            "get"
        ).then((result) => {
            return result.body.RefereeBrigades;
        });

        this.setState({ competition, refereeAssignments, referees, refereeBrigades });
        this.brigadeChanged();
        this.setState({ isLoading: false });
    }

    closeModal() {
        this.props.modalHide();
    }

    handleSubmit() {
        const { competition } = this.state;
        let data = {
            assignedReferees: [],
            performanceId: this.props.selectedRefereeAssignment.performanceId,
        };

        const brigadeVariant = competition?.brigadeVariant ?? BrigadeVariant.DEFAULT;
        const refereesCount = brigadeVariant === BrigadeVariant.DEFAULT ? 14 : 18;

        for (let i = 1; i < refereesCount; i++) {
            if (this.state.refereeAssignments[i]) {
                data.assignedReferees.push({
                    performanceId: this.props.selectedRefereeAssignment.performanceId,
                    refereeId: this.state.refereeAssignments[i].refereeId,
                    refereeRoleId: Utils.getRefereeRoleByRefereeNumber(i, brigadeVariant),
                    refereeNumber: i,
                });
            }
        }
        RequestManager.fetch("refereesByPerformanceId", "POST", data, false)
            .then((result) => {
                NotificationManager.success(this.props.t("dataSaved"));
                this.closeModal();
            })
            .catch((err) => NotificationManager.error(this.props.t("saveError")));
    }

    inputChanged(event) {
        let updatedRefereeAssignments = this.state.refereeAssignments;

        let assignedReferee = this.state.referees.find((item) => {
            return item.refereeId === +event.target.value;
        });

        let isRefereeAlreadyAssigned =
            assignedReferee &&
            this.state.refereeAssignments.some((item) => {
                return item !== undefined && item.refereeId === assignedReferee.refereeId;
            });

        if (isRefereeAlreadyAssigned) {
            NotificationManager.warning(this.props.t("twoPositionsOneReferee"));
        }

        if (assignedReferee && this.state.refereeAssignments[+event.target.name]) {
            assignedReferee.isScoresSet = this.state.refereeAssignments[
                +event.target.name
            ].isScoresSet;
        }

        updatedRefereeAssignments[+event.target.name] = assignedReferee;
        this.setState({
            refereeAssignments: updatedRefereeAssignments,
        });
    }

    getAssignedRefereeId(brigadeNumber, refereeNumber) {
        let refereeId = 0;
        this.state.refereeBrigades.map((item) => {
            if (item.brigadeNumber === +brigadeNumber && item.refereeNumber === +refereeNumber) {
                refereeId = item.refereeId ? item.refereeId : 0;
            }
            return null;
        });
        return refereeId ? refereeId : 0;
    }

    brigadeChanged(event) {
        const { competition } = this.state;
        let brigadeNumber = event ? event.target.value : 1;
        this.setState({
            brigadeNumber: brigadeNumber,
        });

        let refereeAssignments = this.state.refereeAssignments;

        const brigadeVariant = competition?.brigadeVariant ?? BrigadeVariant.DEFAULT;
        const refereesCount = brigadeVariant === BrigadeVariant.DEFAULT ? 14 : 18;

        for (let refereeNumber = 1; refereeNumber < refereesCount; refereeNumber++) {
            let refereeId = +this.getAssignedRefereeId(brigadeNumber, refereeNumber);
            if (
                refereeAssignments[refereeNumber] &&
                refereeAssignments[refereeNumber].isScoresSet &&
                refereeId === 0
            ) {
                NotificationManager.warning(
                    this.props.t("removeRefereeWhoRateError", {
                        refereeNumber: refereeNumber,
                    })
                );
            } else {
                refereeAssignments[refereeNumber] = { refereeId: refereeId };
            }
        }
        this.setState({
            refereeAssignments: refereeAssignments,
        });
    }

    onShow() {
        if (this.props.selectedRefereeAssignment) {
            this.setState({
                performanceId: this.props.selectedRefereeAssignment.performanceId,
                athleteName: this.props.selectedRefereeAssignment.athleteName,
                competitionPlanName: this.props.selectedRefereeAssignment.competitionPlanName,
                dateTime: this.props.selectedRefereeAssignment.dateTime,
            });
        }
    }

    getRefereeDDLbyRefereeNumber(refereeNumber) {
        const { t } = this.props;
        let allowToDeleteReferee =
            !this.state.refereeAssignments[refereeNumber] ||
            !this.state.refereeAssignments[refereeNumber].isScoresSet;
        const tooltip = (
            <Tooltip arrow id="tooltip">
                <strong>{t("refereeHasRated")}</strong>
                {t("cantRemoveRefereeCuzLoseMark")}
            </Tooltip>
        );

        const exclamation = (
            <OverlayTrigger placement="left" overlay={tooltip}>
                <span
                    // overlay={<Tooltip id="tooltip">{t("checkThisInfo")}.</Tooltip>}
                    className="exclamation-mark glyphicon glyphicon-exclamation-sign"
                ></span>
            </OverlayTrigger>
        );

        const exclamationPlaceholder = <span className="exclamation-mark"></span>;

        return (
            <FormControl>
                <Select
                    displayEmpty
                    name={`${refereeNumber}`}
                    onChange={this.inputChanged}
                    value={
                        this.state.refereeAssignments[refereeNumber]
                            ? this.state.refereeAssignments[refereeNumber].refereeId
                            : 0
                    }
                >
                    {allowToDeleteReferee && (
                        <MenuItem value="0" key={0}>
                            <small>{t("noRefereeAssigned")}</small>
                        </MenuItem>
                    )}
                    {this.state.referees.map((r) => (
                        <MenuItem value={`${r.refereeId}`} key={r.refereeId}>
                            {Helpers.getFullNameForUser(r)}
                        </MenuItem>
                    ))}
                </Select>
                {!allowToDeleteReferee ? exclamation : exclamationPlaceholder}
            </FormControl>
        );
    }

    render() {
        const { t } = this.props;
        const { isLoading, competition } = this.state;

        const brigadeVariant = competition?.brigadeVariant ?? BrigadeVariant.DEFAULT;
        const categoryRefereesCount = brigadeVariant === BrigadeVariant.DEFAULT ? 4 : 6;

        return (
            <Dialog
                onEnter={this.onShow}
                open={this.props.show}
                onClose={this.closeModal}
                aria-labelledby="ModalHeader"
                fullWidth
            >
                <DialogTitle id="ModalHeader">{this.props.header}</DialogTitle>

                <DialogContent>
                    <form onSubmit={this.handleSubmit}>
                        <Grid container direction="column">
                            <Typography>
                                <b>{t("category")}: </b>
                                {this.state.competitionPlanName}
                            </Typography>
                            <Typography>
                                <b>{t("performs")}: </b>
                                {this.state.athleteName}
                            </Typography>
                            <Typography>
                                <b>{t("perfomanceDateAndTime")}: </b>
                                {this.state.dateTime}
                            </Typography>
                        </Grid>

                        <Grid container alignItems="center">
                            <Grid item xs={12}>
                                <FormGroup>
                                    <InputLabel shrink>{t("brigade")}</InputLabel>
                                    <Select
                                        name={`${this.state.brigadeNumber}`}
                                        onChange={this.brigadeChanged}
                                        value={this.state.brigadeNumber | ""}
                                    >
                                        {
                                            <MenuItem value={1}>
                                                {t("brigade")} {i18n.language === "ru" ? 1 : "A"}
                                            </MenuItem>
                                        }
                                        {
                                            <MenuItem value={2}>
                                                {t("brigade")} {i18n.language === "ru" ? 2 : "B"}
                                            </MenuItem>
                                        }
                                    </Select>
                                </FormGroup>
                            </Grid>
                            <Divider />
                        </Grid>

                        {isLoading ? (
                            <Box display="flex" justifyContent="center">
                                <CircularProgress />
                            </Box>
                        ) : (
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <FormGroup>
                                        <InputLabel shrink>{t("perfomanceQuality")}:</InputLabel>
                                        {[...new Array(categoryRefereesCount)].map((_, i) =>
                                            this.getRefereeDDLbyRefereeNumber(i + 1)
                                        )}
                                    </FormGroup>
                                    <br />
                                    <FormGroup>
                                        <InputLabel shrink>{t("artisty")}:</InputLabel>
                                        {[...new Array(categoryRefereesCount)].map((_, i) =>
                                            this.getRefereeDDLbyRefereeNumber(
                                                i + categoryRefereesCount + 1
                                            )
                                        )}
                                    </FormGroup>
                                    <br />
                                </Grid>

                                <Grid item xs={6}>
                                    <FormGroup
                                        validationstate={this.state.isValid ? null : "error"}
                                    >
                                        <InputLabel shrink>{t("complexity")}:</InputLabel>
                                        {[...new Array(2)].map((_, i) =>
                                            this.getRefereeDDLbyRefereeNumber(
                                                i + (categoryRefereesCount * 2 + 1)
                                            )
                                        )}
                                    </FormGroup>
                                    <br />
                                    <FormGroup
                                        validationstate={this.state.isValid ? null : "error"}
                                    >
                                        <InputLabel shrink>{t("lineReferees")}:</InputLabel>
                                        {this.getRefereeDDLbyRefereeNumber(
                                            brigadeVariant === BrigadeVariant.DEFAULT ? 11 : 15
                                        )}
                                    </FormGroup>
                                    <br />
                                    <FormGroup
                                        validationstate={this.state.isValid ? null : "error"}
                                    >
                                        <InputLabel shrink>
                                            {t("arbiterOfRefereeGroup")}:
                                        </InputLabel>
                                        {this.getRefereeDDLbyRefereeNumber(
                                            brigadeVariant === BrigadeVariant.DEFAULT ? 12 : 16
                                        )}
                                    </FormGroup>
                                    <br />
                                    <FormGroup
                                        validationstate={this.state.isValid ? null : "error"}
                                    >
                                        <InputLabel shrink>{t("mainReferee")}:</InputLabel>
                                        {this.getRefereeDDLbyRefereeNumber(
                                            brigadeVariant === BrigadeVariant.DEFAULT ? 13 : 17
                                        )}
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        )}
                    </form>
                </DialogContent>

                <DialogActions>
                    <Button onClick={this.closeModal} color="default">
                        {t("cancel")}
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary">
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

RefereeAssignmentPopup.propTypes = {
    header: PropTypes.string,
    show: PropTypes.bool,
    modalHide: PropTypes.func,
    selectedRefereeAssignment: PropTypes.object,
    competitionId: PropTypes.number,
};

export default withTranslation("adminPage")(RefereeAssignmentPopup);
