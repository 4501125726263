import React from "react";
import {
    Container,
    Typography,
    Grid,
    Button,
    Divider,
    FormHelperText,
    Link,
} from "@material-ui/core";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";
import RequestManager from "../Modules/RequestManager";
import Auth from "../Modules/Auth";
import { withTranslation } from "react-i18next";
import "./RegisterPage.scss";

interface User {
    login: string;
    email: string;
    password: string;
    passwordRepeat: string;
    firstName: string;
    lastName: string;
    middleName: string;
    roleIds: Array<number>;
}

class RegisterPage extends React.Component<any, { user: User; errorMessage: string }> {
    constructor(props: any) {
        super(props);

        this.state = {
            user: {
                login: "",
                email: "",
                password: "",
                passwordRepeat: "",
                firstName: "",
                lastName: "",
                middleName: "",
                roleIds: [Auth.ROLES.trainer],
            },
            errorMessage: "",
        };
    }

    componentDidMount() {
        const user = Auth.getAuthUser();
        if (user) {
            this.props.history.push("/");
        }
    }

    handleSubmit = async () => {
        const { user } = this.state;

        const passwordsIsValid: boolean = this.comparePasswords(user.password, user.passwordRepeat);

        if (!passwordsIsValid) {
            this.setState({ errorMessage: this.props.t("passwordMismatch") });
            return;
        }

        this.setState({ errorMessage: "" });

        const res = await RequestManager.fetch("users", "post", user, true);
        if (res.status !== 201) {
            this.setState({ errorMessage: res.body.message });
            return;
        }

        this.props.history.push("/register/successful");
    };

    comparePasswords = (password: string, passwordRepeat: string): boolean => {
        return password === passwordRepeat;
    };

    handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { user } = this.state;
        const name = e.currentTarget.name;
        const value = e.currentTarget.value;

        user[name] = value;
        this.setState({ user });
    };

    render() {
        const { t } = this.props;
        const { errorMessage } = this.state;
        return (
            <Container className="register-page">
                <Grid xs={12} lg={5}>
                    <Typography variant="h4">{t("registration")}</Typography>
                    <Divider />
                    <ValidatorForm onSubmit={this.handleSubmit}>
                        <Grid item xs={10}>
                            <TextValidator
                                required
                                type="text"
                                name="login"
                                label={t("login")}
                                fullWidth
                                margin="dense"
                                validators={["required"]}
                                errorMessages={[t("fieldCantBeEmpty")]}
                                value={this.state.user.login}
                                onChange={this.handleInputChange}
                            />

                            <TextValidator
                                required
                                fullWidth
                                margin="dense"
                                type="email"
                                name="email"
                                validators={["required", "isEmail"]}
                                errorMessages={[t("fieldCantBeEmpty"), t("incorrectEmail")]}
                                value={this.state.user.email}
                                onChange={this.handleInputChange}
                                label="Email"
                            />

                            <TextValidator
                                required
                                type="text"
                                name="lastName"
                                fullWidth
                                margin="dense"
                                value={this.state.user.lastName}
                                onChange={this.handleInputChange}
                                label={t("lastName")}
                            />

                            <TextValidator
                                required
                                type="text"
                                name="firstName"
                                fullWidth
                                margin="dense"
                                value={this.state.user.firstName}
                                onChange={this.handleInputChange}
                                label={t("firstName")}
                            />

                            <TextValidator
                                type="text"
                                name="middleName"
                                fullWidth
                                margin="dense"
                                value={this.state.user.middleName}
                                onChange={this.handleInputChange}
                                label={t("middleName")}
                            />

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <TextValidator
                                        required
                                        type="password"
                                        name="password"
                                        label={t("password")}
                                        fullWidth
                                        margin="dense"
                                        validators={["required"]}
                                        errorMessages={[t("fieldCantBeEmpty")]}
                                        value={this.state.user.password}
                                        onChange={this.handleInputChange}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextValidator
                                        required
                                        type="password"
                                        name="passwordRepeat"
                                        label={t("passwordRepeat")}
                                        validators={["required"]}
                                        fullWidth
                                        margin="dense"
                                        errorMessages={[t("fieldCantBeEmpty")]}
                                        value={this.state.user.passwordRepeat}
                                        onChange={this.handleInputChange}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <FormHelperText error className="register-page__error-text">
                            {errorMessage}
                        </FormHelperText>

                        <div className="register-page__actions">
                            <Button color="primary" type="submit" variant="contained">
                                {t("signUp")}
                            </Button>
                        </div>
                    </ValidatorForm>

                    <Typography>
                        {t("registeredQuestion")}
                        <Link href="/login">{t("logIn")}</Link>
                    </Typography>
                </Grid>
            </Container>
        );
    }
}

export default withTranslation("pages")(RegisterPage);
