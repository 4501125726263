import { Box, Checkbox, Divider, Grid, Typography } from "@material-ui/core";
import React, { FunctionComponent, useMemo } from "react";
import shortid from "shortid";
import Members, {
    MemberDeleteHandler,
    MemberSelectHandler,
    MemberAssignHandler,
    MemberAddHandler,
} from "./Members";
import ContentLayout from "../../../Views/ContentLayout";
import { useStoreon } from "storeon/react";
import {
    RefereeLotteryModuleEvents,
    RefereeLotteryModuleState,
    RefereeMemberPosition,
} from "../../../store/StoreModules/RefereeLotteryModule";

interface BrigadeProps {
    number?: number;
}

const Brigade: FunctionComponent<BrigadeProps> = ({ number = 1 }) => {
    const { members: allMembers, dispatch } = useStoreon<
        RefereeLotteryModuleState,
        RefereeLotteryModuleEvents
    >("members");

    const members = useMemo(() => allMembers.filter((m) => m.brigadeNumber === number), [
        allMembers,
        number,
    ]);

    const handleAddMember = (position: RefereeMemberPosition): MemberAddHandler => (): void => {
        dispatch("members/add", {
            id: shortid(),
            brigadeNumber: number,
            position,
            referee: null,
            number: null,
            isFixed: false,
            selected: false,
        });
    };

    const handleAssignMember: MemberAssignHandler = (id: string, number: number | null): void => {
        dispatch("members/assign", { id, number });
    };

    const handleDeleteMember: MemberDeleteHandler = (id: string): void => {
        dispatch("members/delete", { id });
    };

    const handleSelectMember: MemberSelectHandler = (id: string, selected: boolean): void => {
        dispatch("members/select", { id, selected });
    };

    const handleSelectAllMembers = (_, selected: boolean) => {
        dispatch("members/select_all", { brigadeNumber: number, selected });
    };

    const allowedShuffleMembers = useMemo(() => members.filter((el) => !el.isFixed), [members]);

    return (
        <ContentLayout padding={[2, 0]}>
            <Box display="flex" justifyContent="center">
                <Typography component="h5" variant="h5">
                    Бригада №{number}
                </Typography>
            </Box>

            <Box padding={1}>
                <Grid container alignItems="center">
                    <Grid item xs={1} />
                    <Grid item xs={3}>
                        <Typography>
                            <b>ФИО</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>
                            <b>Регион</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography>
                            <b>Город</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>
                            <b>РФ</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>
                            <b>FIG</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Typography>
                            <b>№</b>
                        </Typography>
                    </Grid>
                    <Grid item xs={1}>
                        <Checkbox
                            color="primary"
                            checked={
                                !!allowedShuffleMembers.length &&
                                allowedShuffleMembers.every((e) => e.selected)
                            }
                            onChange={handleSelectAllMembers}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Divider />

            <Members
                title="Главный судья"
                items={members.filter((el) => el.position === RefereeMemberPosition.MAIN_REFEREE)}
                onAssign={handleAssignMember}
            />

            <Members
                title="Заместитель главного судьи"
                items={members.filter(
                    (el) => el.position === RefereeMemberPosition.DEPUTY_MAIN_REFEREE
                )}
                onAssign={handleAssignMember}
                onAdd={handleAddMember(RefereeMemberPosition.DEPUTY_MAIN_REFEREE)}
                onDelete={handleDeleteMember}
                onSelect={handleSelectMember}
            />

            <Members
                title="Главный секретарь"
                items={members.filter((el) => el.position === RefereeMemberPosition.MAIN_SECRETARY)}
                onAssign={handleAssignMember}
            />

            <Members
                title="Заместитель главного секретаря"
                items={members.filter(
                    (el) => el.position === RefereeMemberPosition.DEPUTY_MAIN_SECRETARY
                )}
                onAssign={handleAssignMember}
                onAdd={handleAddMember(RefereeMemberPosition.DEPUTY_MAIN_SECRETARY)}
                onDelete={handleDeleteMember}
                onSelect={handleSelectMember}
            />

            <Members
                title="Арбитр бригады судей"
                items={members.filter(
                    (el) => el.position === RefereeMemberPosition.BRIGADE_REFEREE
                )}
                onAssign={handleAssignMember}
                onSelect={handleSelectMember}
            />

            <Members
                title="Сложность"
                items={members.filter((el) => el.position === RefereeMemberPosition.COMPLEXITY)}
                onAssign={handleAssignMember}
                onAdd={handleAddMember(RefereeMemberPosition.COMPLEXITY)}
                onDelete={handleDeleteMember}
                onSelect={handleSelectMember}
            />

            <Members
                title="Эксперт сложности"
                items={members.filter(
                    (el) => el.position === RefereeMemberPosition.EXPERT_COMPLEXITY
                )}
                onAssign={handleAssignMember}
                onAdd={handleAddMember(RefereeMemberPosition.EXPERT_COMPLEXITY)}
                onDelete={handleDeleteMember}
                onSelect={handleSelectMember}
            />

            <Members
                title="Исполнение"
                items={members.filter((el) => el.position === RefereeMemberPosition.EXECUTION)}
                onAssign={handleAssignMember}
                onAdd={handleAddMember(RefereeMemberPosition.EXECUTION)}
                onDelete={handleDeleteMember}
                onSelect={handleSelectMember}
            />

            <Members
                title="Эксперт исполнения"
                items={members.filter(
                    (el) => el.position === RefereeMemberPosition.EXPERT_EXECUTION
                )}
                onAssign={handleAssignMember}
                onAdd={handleAddMember(RefereeMemberPosition.EXPERT_EXECUTION)}
                onDelete={handleDeleteMember}
                onSelect={handleSelectMember}
            />

            <Members
                title="Артистичность"
                items={members.filter((el) => el.position === RefereeMemberPosition.ARTISTRY)}
                onAssign={handleAssignMember}
                onAdd={handleAddMember(RefereeMemberPosition.ARTISTRY)}
                onDelete={handleDeleteMember}
                onSelect={handleSelectMember}
            />

            <Members
                title="Эксперт артистичности"
                items={members.filter(
                    (el) => el.position === RefereeMemberPosition.EXPERT_ARTISTRY
                )}
                onAssign={handleAssignMember}
                onAdd={handleAddMember(RefereeMemberPosition.EXPERT_ARTISTRY)}
                onDelete={handleDeleteMember}
                onSelect={handleSelectMember}
            />

            <Members
                title="Хронометрист"
                items={members.filter((el) => el.position === RefereeMemberPosition.TIMEKEEPER)}
                onAssign={handleAssignMember}
                onAdd={handleAddMember(RefereeMemberPosition.TIMEKEEPER)}
                onDelete={handleDeleteMember}
                onSelect={handleSelectMember}
            />

            <Members
                title="Линия"
                items={members.filter((el) => el.position === RefereeMemberPosition.LINE_REFEREE)}
                onAssign={handleAssignMember}
                onAdd={handleAddMember(RefereeMemberPosition.LINE_REFEREE)}
                onDelete={handleDeleteMember}
                onSelect={handleSelectMember}
            />

            <Members
                title="При участниках"
                items={members.filter(
                    (el) => el.position === RefereeMemberPosition.WITH_PARTICIPANTS
                )}
                onAssign={handleAssignMember}
                onAdd={handleAddMember(RefereeMemberPosition.WITH_PARTICIPANTS)}
                onDelete={handleDeleteMember}
                onSelect={handleSelectMember}
            />
        </ContentLayout>
    );
};

export default Brigade;
