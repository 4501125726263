import React, { useState } from "react";
import { Grid, Tabs, Tab } from "@material-ui/core";
import PeopleIcon from "@material-ui/icons/People";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PersonIcon from "@material-ui/icons/Person";
import EmojiPeopleIcon from "@material-ui/icons/EmojiPeople";
import SportsIcon from "@material-ui/icons/Sports";
import GavelIcon from "@material-ui/icons/Gavel";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const MainMenu = (props): JSX.Element => {
    const { t } = useTranslation("adminPage");

    const [selectedItem, setSelectedItem] = useState(props.selectedItem);

    const handleChange = (event, value) => {
        setSelectedItem(value);
        props.onChange(value);
    };

    return (
        <Grid container justifyContent="center">
            <Tabs
                value={selectedItem}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                variant="scrollable"
            >
                <Tab value={"competitions"} label={t("competitions")} icon={<SportsIcon />} />
                <Tab value={"athletes"} label={t("athletes")} icon={<PersonIcon />} />
                <Tab value={"referees"} label={t("referees")} icon={<GavelIcon />} />
                <Tab
                    value={"ageCategories"}
                    label={t("ageCategories")}
                    icon={<EmojiPeopleIcon />}
                />
                <Tab value={"categories"} label={t("categories")} icon={<PeopleIcon />} />
                <Tab value={"users"} label={t("users")} icon={<AccountCircleIcon />} />
            </Tabs>
        </Grid>
    );
};

MainMenu.propTypes = {
    selectedItem: PropTypes.string,
    onChange: PropTypes.func,
};

export default MainMenu;
