import React from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    DialogTitle,
    MenuItem,
    Card,
    Grid,
    CardActions,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    IconButton,
    Tabs,
    Tab,
    Select,
    CircularProgress,
    Box,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { withSnackbar } from "notistack";
import RequestManager from "../../../Modules/RequestManager";
import moment from "moment";
import SelectAthleteDialog from "./SelectAthleteDialog";
import Helpers from "../../../Modules/Helpers";
import Utils from "../../../Modules/Utils";
import TabPanel from "../../Common/TabPanel";
import { withTranslation } from "react-i18next";
import downloadReport from "./Reports/CompetitionRequestDetails";
import { trainerRefereeApiService, competitionTrainerRefereeApiService } from "@/api/Services";
import shortid from "shortid";
import ReportsManager from "@/modules/ReportsManager";

class RequestDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            title:
                this.props.data.requests.length > 0
                    ? this.props.t("requestDialog.editApplication")
                    : this.props.t("requestDialog.apply"),
            selectAthleteDialogIsOpen: false,
            requestsForSelectedCategory: [],
            athletes: [],
            ageCategoriesToSelect: [],
            categories: [],
            selectedCategory: null,
            countEntryFee: 0,
            tabKey: 1,
            music: [],
            competitionReferees: [],
            trainerReferees: [],
            isLoading: true,
        };
    }

    componentDidMount = async () => {
        this.sortCompetitionArray();
        this.setCountGlobalEntryFee();
        await this.getAthletes();
        await this.getMusic();
        await this.getCompetitionReferees();
        await this.getReferees();
        this.setState({ isLoading: false });
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data.requests !== this.props.data.requests) {
            this.setCountGlobalEntryFee();
        }
    }

    getMusic = async () => {
        const result = await RequestManager.fetch("trainer/music", "get", {
            trainerId: this.props.data.trainerId,
        });
        this.setState({ music: result.body.data.music });
    };

    getAthletes = async () => {
        const result = await RequestManager.fetch("trainer/athletes", "get", {
            trainerId: this.props.data.trainerId,
        });

        if (result) {
            result.body.data.trainerAthletes.map((item) => {
                item.age = moment().diff(moment(item.dateOfBirth).format("YYYY-MM-DD"), "years");
            });
            this.setState({
                athletes: result.body.data.trainerAthletes,
            });
        }
    };

    getCompetitionReferees = async () => {
        const { data } = this.props;
        const response = await competitionTrainerRefereeApiService.getAll({
            competitionId: data.competition.id,
            $trainer_id$: data.trainerId,
        });
        this.setState({ competitionReferees: response.result.rows });
    };

    getReferees = async () => {
        const { data } = this.props;
        const response = await trainerRefereeApiService.getAll({ trainerId: data.trainerId });
        this.setState({ trainerReferees: response.result.rows });
    };

    sortCompetitionArray = () => {
        const { competition } = this.props.data;
        const { ageCategoriesToSelect } = this.state;

        const uniqueAgeCategories = [
            ...new Set(competition.plans.map((obj) => obj.age_category_id)),
        ].map((ageCategory) => {
            return competition.plans.find((obj) => obj.age_category_id === ageCategory);
        });

        const uniqueCategories = [...new Set(competition.plans.map((obj) => obj.category_id))].map(
            (category) => {
                return competition.plans.find((obj) => obj.category_id === category);
            }
        );

        uniqueCategories.sort((a, b) => a.category_id - b.category_id);

        uniqueAgeCategories.map((item) => {
            let a = competition.plans.filter((obj) => obj.age_category_id === item.age_category_id);
            item.categories = a;
            ageCategoriesToSelect.push(item);
        });

        ageCategoriesToSelect.sort((a, b) => {
            return a.age_category_id - b.age_category_id;
        });

        ageCategoriesToSelect.forEach((item) => {
            item.categories.sort((a, b) => {
                return a.category_id - b.category_id;
            });
        });

        this.setState({
            ageCategoriesToSelect,
            categories: uniqueCategories,
        });
    };

    closeDialog = () => {
        this.props.onClose();
    };

    renderCategoryCard = (item) => {
        const { requests } = this.props.data;
        let requestsForCategory = [];
        let requestsDetails = [];

        requests.forEach((request) => {
            if (request.competitionPlanId === item.id) {
                let obj = request.trainerRequestDetail.map((details) => {
                    return (
                        <Typography key={details.id}>
                            {Helpers.getShortNameForAthlete(details.athlete)}
                        </Typography>
                    );
                });
                requestsForCategory.push(request);
                requestsDetails.push([...obj]);
            }
        });

        let color = Utils.getCategoryColor(item.category.world_category_code);

        return (
            <Grid item xs={12} sm={6} md={4} lg={2} key={item.id}>
                <Card className="request-dialog__expansion-panel__card">
                    <Button
                        onClick={() => this.openSelectAthleteDialog(item, requestsForCategory)}
                        className="request-dialog__expansion-panel__card-header"
                        style={{ backgroundColor: color }}
                    >
                        <Typography variant="h6">{item.category.world_category_code}</Typography>
                    </Button>
                    <Grid container className="request-dialog__expansion-panel__card-content">
                        {requestsDetails.map((detail, index) => {
                            return (
                                <Grid
                                    container
                                    key={index}
                                    alignItems="center"
                                    className="request-dialog__expansion-panel__card-details"
                                >
                                    <Grid item xs={2}>
                                        <Typography>
                                            <strong>{index + 1}</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={10}>
                                        {detail}
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <CardActions className="request-dialog__expansion-panel__card-actions">
                        <IconButton
                            onClick={() => this.openSelectAthleteDialog(item, requestsForCategory)}
                        >
                            {requestsDetails.length > 0 ? <EditIcon /> : <AddIcon />}
                        </IconButton>
                    </CardActions>
                </Card>
            </Grid>
        );
    };

    renderCategoryWithRequests = (item) => {
        const { requests } = this.props.data;

        let requestsCount = [];

        item.categories.forEach((category) => {
            let count = 0;
            requests.forEach((request) => {
                if (request.competitionPlanId === category.id) {
                    count++;
                }
            });
            requestsCount.push({
                id: category.id,
                categoryCode: category.category.world_category_code,
                count: count,
            });
        });

        return requestsCount.map((item) => {
            let color = Utils.getCategoryColor(item.categoryCode);
            if (item.count > 0)
                return (
                    <Card
                        className="request-dialog__expansion-panel__request-count-badge"
                        style={{ backgroundColor: color }}
                        key={item.id}
                    >
                        <Typography>
                            <strong>{item.count}</strong>
                        </Typography>
                    </Card>
                );
        });
    };

    openSelectAthleteDialog = (item, requests) => {
        this.setState({
            selectAthleteDialogIsOpen: true,
            selectedCategory: item,
            requestsForSelectedCategory: requests,
        });
    };

    handleCloseSelectAthleteDialog = () => {
        this.setState({
            selectAthleteDialogIsOpen: false,
            selectedCategory: null,
            requestsForSelectedCategory: [],
        });
    };

    handleSubmitSelectAthleteDialog = async () => {
        await this.props.onUpdate();
    };

    setCountGlobalEntryFee = () => {
        const { requests } = this.props.data;
        let countEntryFee = 0;

        if (requests.length === 0) this.setState({ countEntryFee });

        requests.forEach((item) => {
            countEntryFee += item.plan.entryFee;
        });

        this.setState({ countEntryFee });
    };

    handleSelectTab = (event, key) => {
        this.setState({ tabKey: key });
    };

    renderRequestItem = (request) => {
        const { music } = this.state;

        return (
            <Grid container className="request-dialog__music-request-item" key={request.id}>
                <Grid item xs={2}>
                    <Typography>
                        <b>{request.plan.category.worldCode}</b>
                    </Typography>
                    <Typography>{request.plan.ageCategory.name}</Typography>
                </Grid>
                <Grid item xs={7}>
                    {request.trainerRequestDetail.map((detail) => {
                        return (
                            <Typography key={detail.id}>
                                {Helpers.getFullNameForAthlete(detail.athlete)}
                            </Typography>
                        );
                    })}
                </Grid>
                <Grid item xs={3}>
                    <Select
                        fullWidth
                        name={`music-${request.id}`}
                        value={request.request.musicId || 0}
                        onChange={(e) => this.handleChangeRequestMusic(e, request)}
                    >
                        <MenuItem key={0} value={0}>
                            {this.props.t("requestDialog.notChosen")}
                        </MenuItem>
                        {music.map((music) => {
                            return (
                                <MenuItem key={music.id} value={music.id}>
                                    {music.musicDisplayName}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
            </Grid>
        );
    };

    pushCompetitionRefeee = () => {
        const { data } = this.props;
        this.setState((prevState) => ({
            competitionReferees: [
                ...prevState.competitionReferees,
                { key: shortid(), competitionId: data.competition.id, trainerRefereeId: null },
            ],
        }));
    };

    deleteCompetitionRefereeAssign = async (competitionReferee) => {
        try {
            if (competitionReferee.id) {
                await competitionTrainerRefereeApiService.delete(competitionReferee.id);
                await this.getCompetitionReferees();

                return;
            }

            this.setState((prevState) => ({
                competitionReferees: [...prevState.competitionReferees].filter(
                    (el) => el.key !== competitionReferee.key
                ),
            }));
        } catch (err) {
            const message = err.response?.data?.message;
            this.props.enqueueSnackbar(message, { variant: "error" });
        }
    };

    handleChangeCompetitionReferee = async (e, competitionReferee) => {
        const { value } = e.target;

        try {
            const dto = {
                competitionId: competitionReferee.competitionId,
                trainerRefereeId: value,
            };

            if (!competitionReferee.id) {
                await competitionTrainerRefereeApiService.create(dto);
            } else {
                await competitionTrainerRefereeApiService.update(competitionReferee.id, dto);
            }

            await this.getCompetitionReferees();
        } catch (err) {
            const message = err.response?.data?.message;
            this.props.enqueueSnackbar(message, { variant: "error" });
        }
    };

    renderRefereeItem = (competitionReferee, i) => {
        const { trainerReferees, competitionReferees } = this.state;
        const { t } = this.props;

        return (
            <Grid
                container
                className="request-dialog__music-request-item"
                key={competitionReferee.id || competitionReferee.key}
                alignItems="center"
            >
                <Grid item xs={1}>
                    <Typography>
                        <b>{i}.</b>
                    </Typography>
                </Grid>
                <Grid item xs={9} md={10}>
                    <Select
                        fullWidth
                        name={`referee-${competitionReferee.id}`}
                        value={competitionReferee.trainerRefereeId || 0}
                        margin="normal"
                        onChange={(e) => this.handleChangeCompetitionReferee(e, competitionReferee)}
                    >
                        <MenuItem key={0} value={0} disabled>
                            {this.props.t("requestDialog.notChosen")}
                        </MenuItem>
                        {trainerReferees.map((referee) => {
                            const value = competitionReferees.find(
                                (el) => el.trainerRefereeId === referee.id
                            );
                            return (
                                <MenuItem
                                    key={referee.id}
                                    value={referee.id}
                                    disabled={Boolean(value)}
                                >
                                    {Helpers.getFullNameForAthlete(referee)}
                                </MenuItem>
                            );
                        })}
                    </Select>
                </Grid>
                <Grid item xs={2} md={1}>
                    <Button
                        fullWidth
                        color="secondary"
                        onClick={() => this.deleteCompetitionRefereeAssign(competitionReferee)}
                    >
                        {t("requestDialog.delete")}
                    </Button>
                </Grid>
            </Grid>
        );
    };

    handleChangeRequestMusic = async (event, request) => {
        let value = event.target.value;

        if (value === 0) value = null;

        await this.setMusicToRequest({ request: request, musicId: value });
    };

    setMusicToRequest = async ({ request: req, musicId: id }) => {
        const result = await RequestManager.fetch(
            `trainer/requests/${req.competitionRequestId}`,
            "put",
            { musicId: id }
        );

        if (result.body.status === "Success") {
            this.props.enqueueSnackbar(result.body.message, {
                variant: "success",
            });
            await this.props.onUpdate();
        } else {
            this.props.enqueueSnackbar(result.body.message, {
                variant: "error",
            });
        }
    };

    render() {
        const { countEntryFee, tabKey, competitionReferees, isLoading } = this.state;
        const { data, t, i18n } = this.props;

        return (
            <React.Fragment>
                <Dialog fullWidth maxWidth="lg" open>
                    <DialogTitle>{this.state.title}</DialogTitle>

                    <Grid className="request-dialog__header">
                        <Typography variant="body1">
                            <strong>{t("requestDialog.name")}:</strong> {data.competition.name}
                        </Typography>
                        <Typography variant="body1">
                            <strong>{t("requestDialog.dateOf")}: </strong>{" "}
                            {moment(data.competition.dateFrom).isSame(data.competition.dateTo) ? (
                                <span>
                                    {moment(data.competition.dateFrom).format("Do MMMM YYYY")}
                                </span>
                            ) : (
                                <span>
                                    {moment(data.competition.dateFrom).format("D MMM")} -{" "}
                                    {moment(data.competition.dateTo).format("D MMM YYYY")}
                                </span>
                            )}
                        </Typography>

                        <Grid
                            container
                            className="request-dialog__expansion-panel__request-categories-container"
                        >
                            {this.state.categories.map((item) => {
                                let color = Utils.getCategoryColor(
                                    item.category.world_category_code
                                );
                                return (
                                    <Card
                                        className="request-dialog__expansion-panel__request-category-badge"
                                        style={{ backgroundColor: color }}
                                        key={item.id}
                                    >
                                        <Typography>
                                            <strong>{item.category.world_category_code}</strong>
                                        </Typography>
                                    </Card>
                                );
                            })}
                        </Grid>

                        <Tabs
                            value={tabKey}
                            onChange={this.handleSelectTab}
                            indicatorColor="primary"
                            className="tabs-margin request-dialog__header__tabs"
                        >
                            <Tab
                                value={1}
                                label={`1. ${t("requestDialog.formApp")}`}
                                wrapped
                                disabled={isLoading}
                            />
                            <Tab
                                value={2}
                                label={`2. ${t("requestDialog.attachMusic")}`}
                                disabled={isLoading || data.requests.length === 0}
                            />
                            <Tab
                                value={3}
                                label={`3. ${t("requestDialog.formReferee")}`}
                                disabled={isLoading}
                            />
                        </Tabs>
                    </Grid>

                    <DialogContent className="request-dialog__content">
                        {isLoading && (
                            <Box display="flex" justifyContent="center">
                                <CircularProgress />
                            </Box>
                        )}

                        <TabPanel value={tabKey} index={isLoading ? -1 : 1}>
                            <Grid item>
                                {this.state.ageCategoriesToSelect.map((item) => {
                                    return (
                                        <ExpansionPanel
                                            key={item.id}
                                            className="request-dialog__expansion-panel"
                                        >
                                            <ExpansionPanelSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                className="request-dialog__expansion-panel-summary"
                                            >
                                                <Typography
                                                    variant="h6"
                                                    component="p"
                                                    className="request-dialog__expansion-panel-summary-title"
                                                >
                                                    {item.ageCategory.age_category_name}
                                                </Typography>

                                                {this.renderCategoryWithRequests(item)}
                                            </ExpansionPanelSummary>
                                            <ExpansionPanelDetails>
                                                <Grid container>
                                                    {item.categories.map((category) => {
                                                        return this.renderCategoryCard(category);
                                                    })}
                                                </Grid>
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    );
                                })}
                            </Grid>

                            {this.state.selectAthleteDialogIsOpen && (
                                <SelectAthleteDialog
                                    open={this.state.selectAthleteDialogIsOpen}
                                    athletes={this.state.athletes}
                                    trainerId={this.props.data.trainerId}
                                    selectedCategory={this.state.selectedCategory}
                                    requestsForSelectedCategory={
                                        this.state.requestsForSelectedCategory
                                    }
                                    onClose={this.handleCloseSelectAthleteDialog}
                                    onUpdate={this.handleSubmitSelectAthleteDialog}
                                />
                            )}
                        </TabPanel>

                        <TabPanel value={tabKey} index={2}>
                            <Grid item>
                                {data.requests
                                    .sort((a, b) => a.plan.ageCategoryId - b.plan.ageCategoryId)
                                    .map((request) => {
                                        return this.renderRequestItem(request);
                                    })}
                            </Grid>
                        </TabPanel>
                        <TabPanel value={tabKey} index={3}>
                            <Grid item>
                                <Button
                                    fullWidth
                                    variant="outlined"
                                    color="primary"
                                    onClick={this.pushCompetitionRefeee}
                                >
                                    {t("requestDialog.add")}
                                </Button>
                                {competitionReferees.map((referee, i) =>
                                    this.renderRefereeItem(referee, i + 1)
                                )}
                            </Grid>
                        </TabPanel>
                    </DialogContent>
                    <DialogActions className="request-dialog__actions">
                        <Typography className="request-dialog__actions-entry-fee">
                            {t("requestDialog.entryFee")}: <strong>{countEntryFee} &#8381;</strong>
                            <Button
                                startIcon={<CloudDownloadIcon />}
                                onClick={() => downloadReport(data)}
                                disabled={countEntryFee === 0}
                                size="small"
                            >
                                {t("requestDialog.downloadReport")}
                            </Button>
                            {i18n.language === "ru" && (
                                <Button
                                    startIcon={<CloudDownloadIcon />}
                                    onClick={() =>
                                        ReportsManager.getNominalRegistrationReport(
                                            data.trainerId,
                                            data.competition.id
                                        )
                                    }
                                    disabled={data.requests.length === 0}
                                    size="small"
                                >
                                    Именная заявка
                                </Button>
                            )}
                        </Typography>
                        <Button onClick={this.closeDialog} color="primary" variant="contained">
                            {t("requestDialog.save")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

RequestDialog.propTypes = {
    data: PropTypes.shape({
        competition: PropTypes.object,
        trainerId: PropTypes.number,
        requests: PropTypes.array,
    }),
    onSubmit: PropTypes.func,
    onClose: PropTypes.func,
    onUpdate: PropTypes.func,
};

export default withTranslation("trainerPage")(withSnackbar(RequestDialog));
