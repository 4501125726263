import React from "react";
import { useStoreon } from "storeon/react";
import { BeamerModuleEvents, BeamerModuleState } from "../../store/StoreModules/BeamerModule";
import PerformanceView from "../../Views/Beamer/performance-view";

const PerformanceSlide = (): JSX.Element => {
    const { perfomanceObject } = useStoreon<BeamerModuleState, BeamerModuleEvents>(
        "perfomanceObject"
    );

    const performanceProps = {
        perfomanceObject: perfomanceObject,
    };
    return <PerformanceView {...performanceProps} />;
};

export default PerformanceSlide;
