import React, { createRef } from "react";
import PropTypes from "prop-types";
import Auth from "../../../Modules/Auth";
import {
    Grid,
    Fab,
    Typography,
    ListItem,
    List,
    IconButton,
    Tooltip,
    TextField,
    Divider,
} from "@material-ui/core";
import LoadingIndicator from "../../Common/LoadingIndicator";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import SaveIcon from "@material-ui/icons/Save";
import PauseIcon from "@material-ui/icons/Pause";
import CancelIcon from "@material-ui/icons/Cancel";
import DownloadIcon from "@material-ui/icons/CloudDownload";
import MusicUploaderDialog from "./MusicUploaderDialog";
import RequestManager from "../../../Modules/RequestManager";
import { withSnackbar } from "notistack";
import ConfirmDialog from "../../Common/ConfirmDialog";
import { withTranslation } from "react-i18next";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import "./MusicLibrary.scss";
import { publicApiService } from "@/api/Services";

const MAX_FILES_IN_STORAGE = 70;

class MusicLibrary extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            trainerId: Auth.getAuthUser().trainerId,
            dialogIsOpen: false,
            confirmDialogIsOpen: false,
            music: [],
            selectedMusicIndex: -1,
            selectedMusicItem: null,
            musicNewName: null,
            errorValidation: false,
            validationMessage: "",
            currentMusicPlaying: "",
            currentMusicPlayingDisplay: "",
            musicOnPause: false,
        };
    }

    componentDidMount = async () => {
        this.player = createRef();
        await this.getMusic();
    };

    getMusic = async () => {
        let result = await RequestManager.fetch("trainer/music", "get", {
            trainerId: this.state.trainerId,
        });
        this.setState({
            music: result.body.data.music,
            isLoading: false,
        });
    };

    handleDialogOpen = () => {
        this.setState({
            dialogIsOpen: true,
        });
    };

    deleteMusic = async (musicId) => {
        let result = await RequestManager.fetch(`trainer/music/${musicId}`, "delete");
        return result;
    };

    updateMusic = async (musicId, musicToUpdate) => {
        let result = await RequestManager.fetch(`trainer/music/${musicId}`, "put", {
            music: musicToUpdate,
        });
        return result;
    };

    handleDeleteMusic = async (musicItem) => {
        this.setState({
            selectedMusicItem: musicItem,
            confirmDialogIsOpen: true,
        });
    };

    handleEditMusic = (index) => {
        const { music } = this.state;
        this.setState({
            selectedMusicIndex: index,
            musicNewName: music[index].musicDisplayName,
        });
    };

    handleDownloadMusic = async (musicItem) => {
        try {
            const response = await publicApiService.http.get(`${musicItem.musicOriginalName}`, {
                responseType: "arraybuffer",
            });
            publicApiService.download(response, musicItem.musicOriginalName);
        } catch {
            this.props.enqueueSnackbar(this.props.t("musicLibrary.downloadError"), {
                variant: "error",
            });
        }
    };

    validate = (musicName) => {
        if (musicName.length === 0) {
            this.setState({
                errorValidation: true,
                validationMessage: this.props.t("musicLibrary.nameMinChar"),
            });
            return false;
        } else {
            this.setState({
                errorValidation: false,
                validationMessage: "",
            });
            return true;
        }
    };

    handleSaveMusic = async (musicItem, index) => {
        const { musicNewName, music } = this.state;
        let updatedMusic = music;

        let validate = this.validate(musicNewName);
        if (!validate) return;

        if (musicNewName !== music[index].musicDisplayName) {
            let newMusicItem = music[index];
            newMusicItem.musicDisplayName = musicNewName;

            let result = await this.updateMusic(musicItem.id, newMusicItem);

            if (result.body.status === "Success") {
                this.props.enqueueSnackbar(result.body.message, {
                    variant: "success",
                });
                this.getMusic();
            } else {
                this.props.enqueueSnackbar(result.body.message, {
                    variant: "error",
                });
            }
        }

        updatedMusic[index].musicDisplayName = musicNewName;

        this.setState({
            selectedMusicIndex: -1,
            music: updatedMusic,
        });
    };

    handleCancelRenameMusic = () => {
        this.setState({ selectedMusicIndex: -1 });
    };

    handleChangaNameMusic = (event) => {
        let value = event.target.value;
        this.setState({
            musicNewName: value,
        });
    };

    handleDialogClose = () => {
        this.setState({
            dialogIsOpen: false,
        });
    };

    playMusic = (musicOriginalName, musicDisplayName) => {
        const { musicOnPause } = this.state;

        if (musicOnPause) this.player.current.audio.current.play();

        this.setState({
            currentMusicPlaying: musicOriginalName,
            currentMusicPlayingDisplay: musicDisplayName,
            musicOnPause: false,
        });
    };

    pauseMusic = () => {
        this.setState({
            musicOnPause: true,
        });
        this.player.current.audio.current.pause();
    };

    renderMusicList = () => {
        const {
            music,
            selectedMusicIndex,
            musicNewName,
            errorValidation,
            validationMessage,
            currentMusicPlaying,
            musicOnPause,
        } = this.state;
        const { t } = this.props;
        const playButton = (item) => (
            <Tooltip title={t("musicLibrary.play")} arrow>
                <IconButton
                    onClick={() => this.playMusic(item.musicOriginalName, item.musicDisplayName)}
                >
                    <PlayCircleFilledIcon />
                </IconButton>
            </Tooltip>
        );

        const pauseButton = () => (
            <Tooltip title={t("musicLibrary.pause")} arrow>
                <IconButton onClick={() => this.pauseMusic()}>
                    <PauseIcon />
                </IconButton>
            </Tooltip>
        );
        return (
            <List className="music-library__files-list">
                {music.length > 0
                    ? music.map((item, index) => {
                          return (
                              <ListItem key={item.id}>
                                  {selectedMusicIndex !== index &&
                                      currentMusicPlaying !== item.musicOriginalName &&
                                      playButton(item)}

                                  {selectedMusicIndex !== index &&
                                      currentMusicPlaying === item.musicOriginalName &&
                                      musicOnPause &&
                                      playButton(item)}

                                  {selectedMusicIndex !== index &&
                                      currentMusicPlaying === item.musicOriginalName &&
                                      !musicOnPause &&
                                      pauseButton()}

                                  {selectedMusicIndex !== index ? (
                                      <Typography variant="body1">
                                          <b>{index + 1}.</b> {item.musicDisplayName}
                                      </Typography>
                                  ) : (
                                      <TextField
                                          className="music-library__file-item__textfield"
                                          type="text"
                                          value={musicNewName}
                                          onChange={this.handleChangaNameMusic}
                                          error={errorValidation}
                                          helperText={validationMessage}
                                          fullWidth
                                      />
                                  )}
                                  <div className="music-library__file-item__actions">
                                      {selectedMusicIndex !== index && (
                                          <Tooltip title={t("musicLibrary.rename")} arrow>
                                              <IconButton
                                                  onClick={() => this.handleEditMusic(index)}
                                              >
                                                  <EditIcon />
                                              </IconButton>
                                          </Tooltip>
                                      )}
                                      {selectedMusicIndex !== index && (
                                          <Tooltip title={t("musicLibrary.delete")} arrow>
                                              <IconButton
                                                  onClick={() => this.handleDeleteMusic(item)}
                                              >
                                                  <DeleteIcon />
                                              </IconButton>
                                          </Tooltip>
                                      )}

                                      {selectedMusicIndex === index && (
                                          <Tooltip title={t("musicLibrary.save")} arrow>
                                              <IconButton
                                                  onClick={() => this.handleSaveMusic(item, index)}
                                              >
                                                  <SaveIcon />
                                              </IconButton>
                                          </Tooltip>
                                      )}
                                      {selectedMusicIndex === index && (
                                          <Tooltip title={t("musicLibrary.cancel")} arrow>
                                              <IconButton
                                                  onClick={() => this.handleCancelRenameMusic()}
                                              >
                                                  <CancelIcon />
                                              </IconButton>
                                          </Tooltip>
                                      )}

                                      <Tooltip title={t("musicLibrary.download")} arrow>
                                          <IconButton
                                              onClick={() => this.handleDownloadMusic(item)}
                                          >
                                              <DownloadIcon />
                                          </IconButton>
                                      </Tooltip>
                                  </div>
                              </ListItem>
                          );
                      })
                    : t("musicLibrary.noAudio")}
            </List>
        );
    };

    renderConfirmDialog = () => {
        const { t } = this.props;
        return (
            <ConfirmDialog
                open={this.state.confirmDialogIsOpen}
                title={t("musicLibrary.attention")}
                content={t("musicLibrary.deleteVerify")}
                onConfirm={async () => {
                    return await this.deleteMusic(this.state.selectedMusicItem.id).then(
                        (result) => {
                            if (result.body.status == "Success") {
                                this.props.enqueueSnackbar(result.body.message, {
                                    variant: "success",
                                });
                                this.setState({
                                    selectedMusicItem: null,
                                    isLoading: true,
                                });
                                this.getMusic();
                            } else
                                this.props.enqueueSnackbar(result.body.message, {
                                    variant: "error",
                                });
                        }
                    );
                }}
                onClose={this.confirmCloseHandle}
            />
        );
    };

    confirmCloseHandle = () => {
        this.setState({ confirmDialogIsOpen: false, selectedMusicItem: null });
    };

    render() {
        const { isLoading, currentMusicPlaying, currentMusicPlayingDisplay } = this.state;
        const { t } = this.props;
        return (
            <React.Fragment>
                <Grid container justify="space-between" className="main-view-head">
                    <Grid item>
                        <Typography variant="h4">{t("musicLibrary.musicLibrary")}</Typography>
                    </Grid>
                    <Grid item>
                        <Fab variant="extended" size="small" onClick={this.handleDialogOpen}>
                            <AddIcon className="extended-icon-fab-margin" />
                            {t("musicLibrary.uploadAudio")}
                        </Fab>
                    </Grid>
                </Grid>
                <Grid container className="main-views" justify="space-between">
                    <Typography variant="subtitle1">
                        {t("musicLibrary.maxStore", {
                            count: MAX_FILES_IN_STORAGE,
                        })}
                    </Typography>
                    {isLoading ? (
                        <LoadingIndicator show={isLoading} />
                    ) : (
                        <Grid item xs={12}>
                            <Divider />
                            {this.renderMusicList()}
                        </Grid>
                    )}
                </Grid>

                <MusicUploaderDialog
                    open={this.state.dialogIsOpen}
                    data={{ trainerId: this.state.trainerId }}
                    updateMusicList={this.getMusic}
                    onClose={this.handleDialogClose}
                />

                {this.state.confirmDialogIsOpen && this.renderConfirmDialog()}

                <AudioPlayer
                    ref={this.player}
                    className="music-player"
                    autoPlay
                    autoPlayAfterSrcChange
                    header={currentMusicPlaying ? currentMusicPlayingDisplay : ""}
                    src={`/public/${currentMusicPlaying}`}
                    volume={0.3}
                    onPause={() => this.setState({ musicOnPause: true })}
                    onPlay={() => this.setState({ musicOnPause: false })}
                />
            </React.Fragment>
        );
    }
}

MusicLibrary.propTypes = {
    history: PropTypes.object,
};

export default withSnackbar(withTranslation("trainerPage")(MusicLibrary));
