import { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useStoreon } from "storeon/react";
import Auth from "../Modules/Auth";
import { AuthModuleEvents, AuthModuleState } from "../store/StoreModules/AuthModule";
import useQueryParams from "./useQueryParams";

const useAuth = () => {
    const { user, dispatch } = useStoreon<AuthModuleState, AuthModuleEvents>("user");
    const history = useHistory();
    const params = useQueryParams<{ redirect_to?: string }>();

    const getUrlForHomePage = useCallback(() => {
        if (Auth.isUserAdmin()) return "/admin";

        if (Auth.isUserCompetitionManager()) return "/manager";

        if (Auth.isUserReferee()) return "/referee";

        if (Auth.isUserTrainer()) return "/trainer";

        if (Auth.isUserBeamer()) return "/beamer";

        return "/home";
    }, []);

    const authenticate = useCallback(
        (token: string, user) => {
            Auth.authenticateUser(token, user);
            dispatch("auth/set/user", user);
            if (params.redirect_to) {
                history.push(params.redirect_to);
            } else {
                history.push(getUrlForHomePage());
            }
        },
        [dispatch, history, getUrlForHomePage, params]
    );

    const deauthenticate = useCallback(() => {
        Auth.deauthenticateUser();
        dispatch("auth/set/user", null);
        history.push(getUrlForHomePage());
    }, [dispatch, history, getUrlForHomePage]);

    return { user, authenticate, deauthenticate, getUrlForHomePage };
};

export default useAuth;
