import React from "react";
import PropTypes from "prop-types";
import "./PeformanceDisplay.css";

class ScheduleDisplay extends React.Component {
    constructor(props) {
        super(props);

        const schedulesPerPage = 10;

        let pages = this.createPages(this.props.schedules, schedulesPerPage);
        this.state = {
            numberOfPages: pages.length,
            currentPage: 0,
            pages: pages,
        };

        this.togglePage = this.togglePage.bind(this);
    }

    componentDidMount() {
        this.setState({
            intervalForToggle: setInterval(() => this.togglePage(), 10000),
        });
    }

    createPages(schedules, schedulesPerPage) {
        let pages = [];
        schedules.map((item, index) => {
            if (item.isFirstOfDay || pages[pages.length - 1].length === schedulesPerPage) {
                pages.push([]);
            }
            pages[pages.length - 1].push(item);
        });
        return pages;
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalForToggle);
    }

    togglePage() {
        let nextPage = this.state.currentPage + 1;
        if (nextPage >= this.state.numberOfPages) {
            nextPage = 0;
        }
        this.setState({
            currentPage: nextPage,
        });
        this.props.onPageIsShown();
    }

    render() {
        return (
            <div>
                <div className="header-row">{this.props.competitionName}</div>
                {this.props.schedules && (
                    <div>
                        <div className="performance-row">
                            {this.state.pages[this.state.currentPage][0].date}
                        </div>
                        {this.state.pages[this.state.currentPage].map((item, index) => {
                            return (
                                <div className="performance-row" key={index}>
                                    <div className="schedule-time">{item.time}</div>
                                    <div
                                        className={
                                            "schedule-name-category-container score-" + (index % 2)
                                        }
                                    >
                                        <div className="schedule-category">
                                            {item.competitionPlanWorldName}
                                        </div>
                                        <div className="schedule-stage">
                                            {item.competitionStageShortName}
                                        </div>
                                        <div className="schedule-athlete-name">
                                            {item.competitorName}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}

ScheduleDisplay.propTypes = {
    competitionName: PropTypes.string,
    schedules: PropTypes.array,
    onPageIsShown: PropTypes.array,
};

export default ScheduleDisplay;
