import { Container } from "@material-ui/core";
import React from "react";
import CompetitionDetail from "../Controls/Events/CompetitionDetail";

const EventDetailPage: React.FunctionComponent = () => {
    return (
        <Container>
            <CompetitionDetail />
        </Container>
    );
};

export default EventDetailPage;
