import { Box, Divider, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Pagination as MuiPagination } from "@material-ui/lab";
import React from "react";

const useStyles = makeStyles((theme) => ({
    pagination: {
        "& .MuiPagination-ul": {
            display: "flex",
            justifyContent: "flex-end",
        },
    },
    pageSizeSelect: {
        marginLeft: 10,
        width: 70,
    },
    container: {
        padding: theme.spacing(2, 0),
    },
}));

type PaginationProps = {
    page: number;
    totalPages: number;
    pageSize: number;
    onChangePage(value): void;
    onChangePageSize?(value): void;
};

const Pagination: React.FunctionComponent<PaginationProps> = ({
    page,
    totalPages,
    pageSize,
    onChangePage,
    onChangePageSize,
}) => {
    const classes = useStyles();

    const handleChangePage = (_, page: number): void => {
        onChangePage(page);
    };

    const handleChangePageSize = (e) => {
        if (!onChangePageSize) return;
        const { value } = e.target;

        onChangePageSize(+value);
    };

    return (
        <Box mt={2}>
            <Divider />
            <Grid
                container
                justifyContent={onChangePageSize ? "space-between" : "flex-end"}
                alignItems="center"
                className={classes.container}
            >
                {onChangePageSize && (
                    <Box display="flex" alignItems="center">
                        <Typography variant="overline">Кол-во элементов</Typography>
                        <TextField
                            className={classes.pageSizeSelect}
                            variant="outlined"
                            size="small"
                            select
                            value={pageSize}
                            onChange={handleChangePageSize}
                        >
                            {[20, 50, 100].map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Box>
                )}

                <MuiPagination
                    className={classes.pagination}
                    page={page}
                    count={totalPages}
                    defaultPage={1}
                    siblingCount={2}
                    onChange={handleChangePage}
                    color="primary"
                    showFirstButton
                    showLastButton
                />
            </Grid>
        </Box>
    );
};

export default Pagination;
