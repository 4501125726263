import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Helpers from "../../../../Modules/Helpers";
import moment from "moment";
import RequestManager from "../../../../Modules/RequestManager";
import i18n from "i18next";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function generateTableBody(athletes) {
    let ranks = [...new Set(athletes.map((athlete) => athlete.athlete.rank))];

    let information = [];

    ranks.forEach((rank) => {
        let rankCount = 0;

        athletes.forEach((athlete) => {
            if (rank !== athlete.athlete.rank) return;

            rankCount++;
        });

        information.push({
            text: `${rank ? rank : i18n.t("adminPage:noDischarge")} - ${rankCount}\n`,
            style: "subheader1",
        });
    });

    return information;
}

function setRegionCount(athletes) {
    let cities = [...new Set(athletes.map((athlete) => athlete.athlete.city))];

    let information = [];

    cities.forEach((city) => {
        let cityCount = 0;

        athletes.forEach((athlete) => {
            if (city !== athlete.athlete.city) return;

            cityCount++;
        });

        information.push({
            text: `${city ? city : i18n.t("adminPage:noCity")} - ${cityCount}\n`,
            style: "subheader1",
        });
    });

    return information;
}

function generatePdf({ competition: competition, athletes: athletes }) {
    let docDefinition = {
        content: [
            {
                text: i18n.t("adminPage:competitionReport"),
                style: "header",
            },
            {
                text: i18n.t("adminPage:competition"),
                style: "subtitle",
            },
            {
                text: competition.name,
                style: "subheader",
            },
            {
                text: i18n.t("adminPage:dateOf"),
                style: "subtitle",
            },
            {
                text: moment(competition.dateFrom).isSame(competition.dateTo)
                    ? `${moment(competition.dateFrom).format("Do MMMM YYYY")}`
                    : `${moment(competition.dateFrom).format("D MMM")} - ${moment(
                          competition.dateTo
                      ).format("D MMM YYYY")}`,
                style: "subheader",
            },
            {
                text: i18n.t("adminPage:numbAthletesByCategory"),
                style: "subtitle",
            },
            {
                text: generateTableBody(athletes),
                style: "marginTopBottom",
            },
            {
                text: i18n.t("adminPage:numbParticipatingCities"),
                style: "subtitle",
            },
            {
                text: setRegionCount(athletes),
                style: "marginTopBottom",
            },
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
            },
            subtitle: {
                fontSize: 10,
                color: "#505050",
            },
            subheader: {
                fontSize: 14,
                bold: true,
                margin: [0, 2, 0, 10],
            },
            subheader1: {
                fontSize: 12,
                bold: true,
                margin: [0, 2, 0, 5],
            },
            total: {
                alignment: "right",
                bold: true,
            },
            marginTopBottom: {
                margin: [0, 2, 0, 10],
            },
        },
    };

    return docDefinition;
}

async function getRequests({ competitionId: id }) {
    const result = await RequestManager.fetch(`v2/requests/${id}`, "get");
    return result.body.data;
}

async function downloadPdf({ competitionId: id }) {
    const data = await getRequests({ competitionId: id });

    const requests = [].concat(
        ...data.competition.plans.map((plan) => {
            return plan.requests;
        })
    );

    let athletes = [];

    requests.forEach((request) => {
        request.details.forEach((detail) => {
            athletes.push(detail);
        });
    });

    let uniqueAthletes = [...new Set(athletes.map((obj) => obj.athleteId))].map((athleteId) => {
        return athletes.find((obj) => obj.athleteId === athleteId);
    });

    data.competition.plans = undefined;

    const docDefinition = generatePdf({
        competition: data.competition,
        athletes: uniqueAthletes,
    });
    pdfMake.createPdf(docDefinition).download(i18n.t("adminPage:competitionReport"));
}

export default downloadPdf;
