import qs from "query-string";
import { useHistory } from "react-router-dom";
import { useQueryParams } from ".";

type UsePaginationOptions = Partial<{
    initialPage: number;
    initialPageSize: number;
    scrollOnChange: boolean;
    pageQueryParam?: string;
    pageSizeQueryParam?: string;
}>;

const usePagination = (options: UsePaginationOptions = {}) => {
    const {
        initialPage = 1,
        initialPageSize = 20,
        scrollOnChange = true,
        pageQueryParam = "page",
        pageSizeQueryParam = "size",
    } = options;

    const history = useHistory();
    const params = useQueryParams();

    const page = params[pageQueryParam] || initialPage;
    const pageSize = params[pageSizeQueryParam] || initialPageSize;

    const handleChangePageSize = (value: number) => {
        history.replace({ search: qs.stringify({ ...params, [pageSizeQueryParam]: value }) });

        if (scrollOnChange) window.scrollTo({ top: 0, behavior: "smooth" });
    };

    const handleChangePage = (value: number) => {
        history.replace({ search: qs.stringify({ ...params, [pageQueryParam]: value }) });

        if (scrollOnChange) window.scrollTo({ top: 0, behavior: "smooth" });
    };

    return {
        handleChangePage,
        handleChangePageSize,
        page,
        pageSize,
    };
};

export default usePagination;
