import React from "react";
import PropTypes from "prop-types";
import {
    ExpansionPanel,
    ExpansionPanelSummary,
    Button,
    Typography,
    Grid,
    Paper,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DataTable from "../Common/DataTable";
import { withRouter } from "react-router";
import { withTranslation } from "react-i18next";

class PerformancesAgeCategoryGroup extends React.Component {
    constructor(props) {
        super(props);

        this.onItemClick = this.onItemClick.bind(this);
        this.openDetails = this.openDetails.bind(this);
    }

    onItemClick(e) {
        if (e.target.innerHTML.length > 0) {
            this.props.history.push(`/PerformanceDetails/${e.target.getAttribute("itemID")}`);
        }
    }

    openDetails(e) {
        const firstItem = this.props.ageCategory[e.currentTarget.value][0];
        const showDetails = e.target.name === "full-report" ? 1 : 0;
        this.props.history.push(
            `/CategoryResultsReport/${firstItem.competitionPlanId}/${firstItem.competitionStageId}/${showDetails}/0`
        );
    }

    render() {
        const { t } = this.props;
        const columns = [
            { title: t("participants"), fieldName: "competitorName" },
            { title: t("evaluation"), fieldName: "totalPoints" },
            { title: t("place"), fieldName: "place" },
        ];

        let panelHeader = this.props.ageCategory[Object.keys(this.props.ageCategory)[0]][0]
            .ageCategoryName;

        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    {panelHeader}
                </ExpansionPanelSummary>
                {Object.keys(this.props.ageCategory).map((competitionStageId, i) => {
                    return (
                        <Grid key={i}>
                            <Paper style={{ padding: "1rem" }}>
                                <Typography variant="h6">
                                    {
                                        this.props.ageCategory[competitionStageId][0]
                                            .competitionStageName
                                    }
                                </Typography>
                            </Paper>
                            <DataTable
                                columns={columns}
                                data={this.props.ageCategory[competitionStageId]}
                                uniqueFieldOfItem="performanceId"
                                onItemClick={this.onItemClick}
                                clickable
                            />
                            <Button
                                onClick={this.openDetails}
                                value={competitionStageId}
                                name="full-report"
                            >
                                {t("detailedReport")}
                            </Button>
                            &nbsp;
                            <Button
                                onClick={this.openDetails}
                                value={competitionStageId}
                                name="short-report"
                            >
                                {t("reportWithAgvGradesOnly")}
                            </Button>
                        </Grid>
                    );
                })}
            </ExpansionPanel>
        );
    }
}

PerformancesAgeCategoryGroup.propTypes = {
    ageCategory: PropTypes.object,
    history: PropTypes.object,
};

export default withRouter(withTranslation()(PerformancesAgeCategoryGroup));
