import React, { useState } from "react";
import { Grid, Fab, Typography } from "@material-ui/core";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import AgeCategoriesPopup from "./AgeCategoriesPopup";
import { useTranslation } from "react-i18next";
import MuiDataTable, { DataTableColumn, DataTableProps } from "../../Common/MuiDataTable";
import { ageCategoryApiService } from "@/api/Services";
import { useSnackbar } from "notistack";
import { AgeCategory } from "@/interfaces";
import { useTable } from "../../../Hooks";

const AgeCategoriesList: React.FunctionComponent = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("adminPage");
    const { updateData } = useTable();

    const [selectedAgeCategory, setSelectedAgeCategory] = useState<AgeCategory | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const modalHide = () => {
        setOpen(false);
        setSelectedAgeCategory(null);
    };

    const handleEditClick: DataTableProps<AgeCategory>["onEditClick"] = (rowData) => {
        if (rowData instanceof Array) return;
        setSelectedAgeCategory(rowData);
        handleOpen();
    };

    const handleDeleteClick: DataTableProps<AgeCategory>["onDeleteClick"] = async (rowData) => {
        try {
            const { message } = await ageCategoryApiService.delete(rowData.id);

            enqueueSnackbar(message, {
                variant: "success",
            });
        } catch (err: any) {
            const message = err.response?.data?.message;

            enqueueSnackbar(message || err.message, {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<AgeCategory>[] = [
        { title: "#", field: "id", defaultSort: "asc" },
        {
            title: t("ageCategoryName"),
            field: "name",
        },
        {
            title: t("ageFrom"),
            field: "ageFrom",
        },
        { title: t("to"), field: "ageTo" },
        {
            title: t("ageCategoryCode"),
            field: "code",
        },
        {
            title: t("appealMale"),
            field: "appealMale",
        },
        {
            title: t("appealFemale"),
            field: "appealFemale",
        },
        {
            title: t("hasCustomComplexityDivider"),
            field: "hasCustomComplexityDivider",
            type: "boolean",
        },
    ];

    return (
        <React.Fragment>
            <Grid container justifyContent="space-between" className="main-view-head">
                <Grid item>
                    <Typography variant="h4">{t("ageCategories")}</Typography>
                </Grid>
                <Grid item>
                    <Fab variant="extended" size="small" onClick={handleOpen}>
                        <GroupAddIcon className="extended-icon-fab-margin" />
                        {t("newAgeCategory")}
                    </Fab>
                </Grid>
            </Grid>
            <AgeCategoriesPopup
                show={open}
                modalHide={modalHide}
                selectedAgeCategory={selectedAgeCategory}
                onUpdate={updateData}
            />
            <MuiDataTable
                columns={columns}
                fetchFn={ageCategoryApiService.getAll}
                canEdit
                canDelete
                onEditClick={handleEditClick}
                onDeleteClick={handleDeleteClick}
                showToolbar
                showSearch
            />
        </React.Fragment>
    );
};

export default AgeCategoriesList;
