import React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    FormGroup,
    TextField,
    FormHelperText,
    FormControlLabel,
    Checkbox,
} from "@material-ui/core";
import { NotificationManager } from "react-notifications";
import { WithTranslation, withTranslation } from "react-i18next";
import { AgeCategory } from "@/interfaces";
import { ageCategoryApiService } from "@/api/Services";

type Props = WithTranslation<"adminPage"> & {
    selectedAgeCategory?: AgeCategory | null;
    show: boolean;
    modalHide: () => void;
    onUpdate(): void;
};

type State = {
    name: string;
    ageCategoryId: number;
    ageFrom: number;
    ageTo: number;
    code: string;
    appealMale: string;
    appealFemale: string;
    ageValid: boolean;
    hasCustomComplexityDivider: boolean;
};

class AgeCategoriesPopup extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            name: this.props.selectedAgeCategory ? this.props.selectedAgeCategory.name : "",
            ageCategoryId: this.props.selectedAgeCategory ? this.props.selectedAgeCategory.id : 0,
            ageFrom: this.props.selectedAgeCategory ? this.props.selectedAgeCategory.ageFrom : 0,
            ageTo: this.props.selectedAgeCategory ? this.props.selectedAgeCategory.ageTo : 0,
            code: this.props.selectedAgeCategory ? this.props.selectedAgeCategory.code : "",
            appealMale: this.props.selectedAgeCategory
                ? this.props.selectedAgeCategory.appealMale
                : "",
            appealFemale: this.props.selectedAgeCategory
                ? this.props.selectedAgeCategory.appealFemale
                : "",
            ageValid: true,
            hasCustomComplexityDivider: this.props.selectedAgeCategory
                ? this.props.selectedAgeCategory.hasCustomComplexityDivider
                : false,
        };

        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.fromChanged = this.fromChanged.bind(this);
        this.toChanged = this.toChanged.bind(this);
        this.codeChanged = this.codeChanged.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.onShow = this.onShow.bind(this);
    }

    closeModal() {
        this.props.modalHide();
        this.setState({
            ageValid: true,
        });
    }

    async handleSubmit() {
        const { t, onUpdate } = this.props;
        const { ageCategoryId } = this.state;

        if (!this.state.ageValid) {
            NotificationManager.error(t("incorrectAge"));
            return;
        }
        try {
            const data = {
                name: this.state.name.trim(),
                ageFrom: Number(this.state.ageFrom),
                ageTo: Number(this.state.ageTo),
                code: this.state.code.trim(),
                appealMale: this.state.appealMale.trim(),
                appealFemale: this.state.appealFemale.trim(),
                hasCustomComplexityDivider: this.state.hasCustomComplexityDivider,
            };
            if (ageCategoryId) {
                const { message } = await ageCategoryApiService.update(ageCategoryId, data);
                NotificationManager.success(message);
            } else {
                const { message } = await ageCategoryApiService.create(data);
                NotificationManager.success(message);
            }

            this.closeModal();
            onUpdate();
        } catch (err: any) {
            const message = err.response?.data?.message;
            NotificationManager.error(message);
        }
    }

    fromChanged(e) {
        const newAgeFrom = e.target.value;
        this.setState({
            ageFrom: newAgeFrom,
            ageValid: +newAgeFrom <= +this.state.ageTo,
        });
    }

    toChanged(e) {
        const ageTo = e.target.value;
        this.setState({
            ageTo: ageTo,
            ageValid: +this.state.ageFrom <= +ageTo,
        });
    }

    codeChanged(e) {
        const ageCategoryCode = e.target.value;
        this.setState({
            code: ageCategoryCode,
        });
    }

    inputChanged(event) {
        const stateChange = {};
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        stateChange[event.target.name] = value;
        this.setState(stateChange);
    }

    isAgeValid() {
        return +this.state.ageFrom <= +this.state.ageTo;
    }

    onShow() {
        if (this.props.selectedAgeCategory) {
            this.setState({
                name: this.props.selectedAgeCategory.name,
                ageCategoryId: this.props.selectedAgeCategory.id,
                ageFrom: this.props.selectedAgeCategory.ageFrom,
                ageTo: this.props.selectedAgeCategory.ageTo,
                code: this.props.selectedAgeCategory.code,
                appealFemale: this.props.selectedAgeCategory.appealFemale,
                appealMale: this.props.selectedAgeCategory.appealMale,
                hasCustomComplexityDivider: this.props.selectedAgeCategory
                    .hasCustomComplexityDivider,
            });
        } else {
            this.setState({
                name: "",
                ageCategoryId: 0,
                ageFrom: 0,
                ageTo: 0,
                code: "",
                appealFemale: "",
                appealMale: "",
                hasCustomComplexityDivider: false,
            });
        }
    }

    render() {
        const { t, selectedAgeCategory } = this.props;

        const validationText = this.state.ageValid ? "" : t("wrongAge");

        return (
            <Dialog
                onEnter={this.onShow}
                open={this.props.show}
                onClose={this.closeModal}
                aria-labelledby="ModalHeader"
                fullWidth
            >
                <DialogTitle id="ModalHeader">
                    {selectedAgeCategory ? t("changeCategory") : t("newAgeCategory")}
                </DialogTitle>
                <DialogContent>
                    <form onSubmit={this.handleSubmit}>
                        <FormGroup>
                            <FormGroup>
                                <TextField
                                    value={this.state.name}
                                    name="name"
                                    label={t("name")}
                                    onChange={this.inputChanged}
                                    margin="dense"
                                />
                            </FormGroup>

                            <TextField
                                value={this.state.ageFrom || ""}
                                type="number"
                                name="ageFrom"
                                label={t("ageFrom")}
                                onChange={this.fromChanged}
                                margin="dense"
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextField
                                value={this.state.ageTo || ""}
                                type="number"
                                name="ageTo"
                                label={t("ageTo")}
                                onChange={this.toChanged}
                                margin="dense"
                            />
                        </FormGroup>

                        <FormGroup>
                            <TextField
                                value={this.state.code || ""}
                                name="code"
                                label={t("ageCategoryCode")}
                                onChange={this.codeChanged}
                                margin="dense"
                            />
                        </FormGroup>

                        <FormGroup>
                            <TextField
                                value={this.state.appealMale || ""}
                                name="appealMale"
                                label={t("appealMale")}
                                onChange={this.inputChanged}
                                margin="dense"
                            />
                        </FormGroup>

                        <FormGroup>
                            <TextField
                                value={this.state.appealFemale || ""}
                                name="appealFemale"
                                label={t("appealFemale")}
                                onChange={this.inputChanged}
                                margin="dense"
                            />
                        </FormGroup>

                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        name="hasCustomComplexityDivider"
                                        checked={this.state.hasCustomComplexityDivider}
                                        onChange={this.inputChanged}
                                        color="primary"
                                    />
                                }
                                label={t("hasCustomComplexityDivider")}
                            />
                        </FormGroup>

                        <FormHelperText error>{validationText}</FormHelperText>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeModal} color="default">
                        {t("cancel")}
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary" variant="contained">
                        {t("save")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withTranslation("adminPage")(AgeCategoriesPopup);
