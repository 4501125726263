import BaseApiService from "@/api/Base";
import { TokenInterceptor } from "@/api/Http/Interceptors";
import { Settings } from "@/modules/Constants";
import { File } from "@/interfaces";
import { QueryResult } from "@/api/types";

class FileApiService extends BaseApiService<File> {
    constructor(config) {
        super(config);
        this.url = "/files";
        this.http.interceptors.request.use(TokenInterceptor);
    }

    upload: (
        payload: Partial<Pick<File, "displayName">> & { file: Blob }
    ) => Promise<QueryResult<File>> = async (payload) => {
        const formData = new FormData();

        formData.set("file", payload.file);
        if (payload.displayName) {
            formData.set("displayName", payload.displayName);
        }

        const { data } = await this.http.post(this.url, formData);
        return data;
    };

    reUpload: (
        id: number,
        payload: Partial<Pick<File, "displayName">> & { file: Blob }
    ) => Promise<QueryResult<File>> = async (id, payload) => {
        const formData = new FormData();

        formData.set("file", payload.file);
        if (payload.displayName) {
            formData.set("displayName", payload.displayName);
        }

        const { data } = await this.http.patch(`${this.url}/${id}`, formData);
        return data;
    };
}

export default new FileApiService({
    baseURL: Settings.MAIN_API_URL_V2,
});
