import React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    Box,
    Typography,
} from "@material-ui/core";
import "./RequestsList.css";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { competitionRequestApiService } from "@/api/Services";

type ImportRequestsDialogProps = {
    open: boolean;
    onClose(): void;
    competitionId: number;
};

interface ImportRequestsState {
    file: File | null;
}

const ImportRequestsDialog: React.FunctionComponent<ImportRequestsDialogProps> = ({
    open,
    onClose,
    competitionId,
}: ImportRequestsDialogProps) => {
    const { t } = useTranslation("adminPage");

    const importRequests = async (values, { setStatus }) => {
        try {
            const formData = new FormData();
            formData.append("file", values.file);
            const { message } = await competitionRequestApiService.importRequests(
                competitionId,
                formData
            );
            setStatus({ error: false, message });
        } catch (err: any) {
            const { message } = err.response.data;
            setStatus({ error: true, message });
        }
    };

    const {
        handleSubmit,
        isSubmitting,
        setFieldValue,
        values,
        errors,
        touched,
        isValid,
        status,
        resetForm,
    } = useFormik<ImportRequestsState>({
        initialValues: { file: null },
        onSubmit: importRequests,
        validationSchema: Yup.object({
            file: Yup.mixed().required().nullable(),
        }),
    });

    const handleChangeFile = (e) => {
        setFieldValue(e.target.name, e.target.files?.[0] || null);
    };

    return (
        <Dialog fullWidth open={open} TransitionProps={{ onExit: () => resetForm() }}>
            <DialogTitle>{t("importRequests")}</DialogTitle>
            <DialogContent>
                <form noValidate onSubmit={handleSubmit}>
                    <TextField
                        name="file"
                        fullWidth
                        type="file"
                        onChange={handleChangeFile}
                        error={touched.file && !!errors.file}
                        helperText={t("chooseOneXlsxFile")}
                    />
                    {status ? (
                        <Box my={2}>
                            <Typography
                                className={clsx({
                                    ["icon-color--success"]: !status.error,
                                    ["icon-color--failure"]: status.error,
                                })}
                            >
                                {status.message}
                            </Typography>
                        </Box>
                    ) : null}
                    <Box mt={2}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={!isValid || isSubmitting}
                        >
                            {t("import")}
                        </Button>
                    </Box>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="default">
                    {t("close")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ImportRequestsDialog;
