import RequestManager from "./RequestManager";

class CategoriesManager {
    static findAll() {
        return RequestManager.fetch("categories", "get").then((response) => {
            return response.body.Categories;
        });
    }
}

export default CategoriesManager;
