import React, { useState } from "react";
import { Grid, Fab, Typography } from "@material-ui/core";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import CategoryPopup from "./CategoryPopup";
import { useTranslation } from "react-i18next";
import { Category } from "@/interfaces";
import { useSnackbar } from "notistack";
import MuiDataTable, { DataTableColumn, DataTableProps } from "../../Common/MuiDataTable";
import { categoryApiService } from "@/api/Services";
import { useTable } from "../../../Hooks";

const CategoriesList: React.FunctionComponent = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("adminPage");
    const { updateData } = useTable();

    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const modalHide = () => {
        setOpen(false);
        setSelectedCategory(null);
    };

    const handleEditClick: DataTableProps<Category>["onEditClick"] = (rowData) => {
        if (rowData instanceof Array) return;
        setSelectedCategory(rowData);
        handleOpen();
    };

    const handleDeleteClick: DataTableProps<Category>["onDeleteClick"] = async (rowData) => {
        try {
            const { message } = await categoryApiService.delete(rowData.id);

            enqueueSnackbar(message, {
                variant: "success",
            });
        } catch (err: any) {
            const message = err.response?.data?.message;

            enqueueSnackbar(message || err.message, {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<Category>[] = [
        { title: "#", field: "id", defaultSort: "asc" },
        { title: t("categoryName"), field: "name" },
        { title: t("gender"), field: "gender", render: (rowData) => rowData.gender?.name },
        { title: t("minCount"), field: "countFrom" },
        { title: t("maxCount"), field: "countTo" },
        // {
        //     title: t("complexityDividerShort"),
        //     field: "complexityDivider",
        // },
        { title: t("categoryCode"), field: "code" },
        {
            title: t("worldCode"),
            field: "worldCode",
        },
        {
            title: t("isGroup"),
            field: "isGroup",
            type: "boolean",
        },
        {
            title: t("hasCustomComplexityDivider"),
            field: "hasCustomComplexityDivider",
            type: "boolean",
        },
    ];

    return (
        <React.Fragment>
            <Grid container justifyContent="space-between" className="main-view-head">
                <Grid item>
                    <Typography variant="h4">{t("categories")}</Typography>
                </Grid>
                <Grid item>
                    <Fab variant="extended" size="small" onClick={handleOpen}>
                        <GroupAddIcon className="extended-icon-fab-margin" />
                        {t("newCategory")}
                    </Fab>
                </Grid>
            </Grid>

            <CategoryPopup
                show={open}
                modalHide={modalHide}
                selectedCategory={selectedCategory}
                onUpdate={updateData}
            />

            <MuiDataTable
                columns={columns}
                fetchFn={categoryApiService.getAll}
                canEdit
                canDelete
                onEditClick={handleEditClick}
                onDeleteClick={handleDeleteClick}
                showToolbar
                showSearch
            />
        </React.Fragment>
    );
};

export default CategoriesList;
