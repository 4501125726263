import React, { useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import MuiDataTable, {
    DataTableColumn,
    OnEditClick,
    OnDeleteClick,
} from "../../Common/MuiDataTable";
import Helpers from "../../../Modules/Helpers";
import Auth from "../../../Modules/Auth";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { TrainerReferee } from "@/interfaces";
import { trainerRefereeApiService } from "@/api/Services";
import RefereeForm from "./RefereeForm";
import { useTable, useAuth } from "../../../Hooks";

const RefereesList = () => {
    const { user } = useAuth();
    const { t, i18n } = useTranslation("trainerPage");
    const { enqueueSnackbar } = useSnackbar();
    const { updateData } = useTable();

    const [selectedReferee, setSelectedReferee] = useState<TrainerReferee | null>(null);

    const clearSelectedReferee = () => {
        setSelectedReferee(null);
    };

    const handleSubmitForm = async () => {
        setSelectedReferee(null);
        await updateData();
    };

    const handleEditClick: OnEditClick<TrainerReferee> = (rowData) => {
        if (rowData instanceof Array) return;
        setSelectedReferee(rowData);
    };

    const handleDeleteClick: OnDeleteClick<TrainerReferee> = async (rowData) => {
        try {
            const { message } = await trainerRefereeApiService.delete(rowData.id);

            enqueueSnackbar(message, {
                variant: "success",
            });
        } catch (err: any) {
            const message = err.response?.data?.message;

            enqueueSnackbar(message || err.message, {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<TrainerReferee>[] = [
        {
            title: t("refereesList.fullName"),
            field: "lastName",
            render: (rowData) => (
                <Typography variant="body2">{Helpers.getFullNameForAthlete(rowData)}</Typography>
            ),
        },
        { title: t("refereesList.city"), field: "city" },
        { title: t("refereesList.region"), field: "region" },
        { title: t("athletesList.country"), field: "country", hidden: i18n.language === "ru" },
        {
            title: t("refereesList.categoryReferee"),
            field: "categoryReferee",
            hidden: i18n.language !== "ru",
        },
        { title: t("refereesList.categoryFig"), field: "categoryFig" },
        { title: t("refereesList.categoryInternational"), field: "categoryInternational" },
    ];

    return (
        <>
            <Grid container justifyContent="space-between" className="main-view-head">
                <Grid item>
                    <Typography variant="h4">{t("refereesList.myReferees")}</Typography>
                </Grid>
            </Grid>
            <Grid container className="main-views" justifyContent="space-between">
                <Grid item xs={12} lg={3}>
                    <RefereeForm
                        onSubmit={handleSubmitForm}
                        onCancelEdit={clearSelectedReferee}
                        data={selectedReferee}
                    />
                </Grid>
                <Grid item xs={12} lg={8}>
                    <MuiDataTable
                        columns={columns}
                        fetchFn={(params) =>
                            trainerRefereeApiService.getAll({
                                trainerId: user.trainerId,
                                ...params,
                            })
                        }
                        canEdit={Auth.getAuthUser().isTrainer}
                        canDelete={Auth.getAuthUser().isTrainer}
                        onEditClick={handleEditClick}
                        onDeleteClick={handleDeleteClick}
                        showToolbar
                        showSearch
                        actionsIndex={0}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default RefereesList;
