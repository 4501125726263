import React from "react";
import {
    Grid,
    Card,
    CardActions,
    CardContent,
    Button,
    CardMedia,
    Typography,
    Divider,
    CardActionArea,
    List,
    ListItem,
    Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Competition } from "../../interfaces/competition";
import { Event, InsertDriveFile, Place } from "@material-ui/icons";
import Utils from "@/modules/Utils";
import { useTranslation } from "react-i18next";
import moment from "moment";

const useStyles = ({ hasRequest }) =>
    makeStyles((theme) => ({
        card: {
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        },
        bold: {
            fontWeight: "bold",
        },
        media: {
            height: 240,
        },
        iconLabel: {
            display: "flex",
            alignContent: "center",
            alignItems: "center",
        },
        icon: {
            marginRight: 5,
        },
        meta: {
            "& .MuiGrid-item": {
                paddingTop: 5,
                paddingBottom: 5,
            },
        },
        divider: {
            marginTop: 3,
            marginBottom: 3,
        },
        applyBtn: {
            color: hasRequest ? "#33994d" : theme.palette.primary.main,
            borderColor: hasRequest ? "#33994d" : theme.palette.primary.main,
        },
    }));

type CompetitionCardItem = Pick<
    Competition,
    | "id"
    | "name"
    | "description"
    | "dateFrom"
    | "dateTo"
    | "place"
    | "isAllowedRequests"
    | "cover"
    | "attachments"
>;

export type CompetitionCardProps = CompetitionCardItem &
    Partial<{
        hideActions: boolean;
        hideAttachments: boolean;
        onClick(competition: CompetitionCardItem): void;
        onApplyClick?(competition: CompetitionCardItem): void;
        hasRequest?: boolean;
        buttonText?: "login" | "apply";
    }>;

const CompetitionCard: React.FunctionComponent<CompetitionCardProps> = (props) => {
    const {
        hideActions = false,
        hideAttachments = false,
        onClick,
        onApplyClick,
        hasRequest = false,
        buttonText = "apply",
        ...other
    } = props;
    const competition = other;

    const { t } = useTranslation("common");
    const { t: trainerPageT } = useTranslation("trainerPage");
    const classes = useStyles({ hasRequest })();

    const handleClick = () => {
        onClick?.(competition);
    };

    const handleAppy = () => {
        onApplyClick?.(competition);
    };

    const isAvailable = moment().isBefore(competition.dateTo);

    return (
        <Card className={classes.card}>
            <CardActionArea disabled={!onClick} onClick={handleClick}>
                <CardMedia
                    className={classes.media}
                    image={competition.cover?.path ?? "/assets/competition.png"}
                    title={competition.name}
                />
                <CardContent>
                    <Grid container justifyContent="space-between" className={classes.meta}>
                        <Grid item>
                            <Typography
                                className={classes.iconLabel}
                                color="textSecondary"
                                variant="body2"
                            >
                                <Place className={classes.icon} />
                                {competition.place ||
                                    trainerPageT("competitionCard.venueNotSpecified")}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography
                                className={classes.iconLabel}
                                color="textSecondary"
                                variant="body2"
                            >
                                <Event className={classes.icon} />
                                {Utils.getFormattedDates(
                                    competition.dateFrom,
                                    competition.dateTo
                                ) || "Даты не указаны"}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider className={classes.divider} />
                    <Typography variant="h6" gutterBottom>
                        {competition.name}
                    </Typography>
                    <Typography>
                        {competition.description || trainerPageT("competitionCard.noDescription")}
                    </Typography>
                </CardContent>
            </CardActionArea>
            {!hideAttachments && competition.attachments.length > 0 && (
                <CardContent>
                    <Typography variant="subtitle2">{t("attachments")}</Typography>
                    <List disablePadding>
                        {competition.attachments.map((attachment) => (
                            <ListItem disableGutters key={attachment.id}>
                                <InsertDriveFile />
                                <Link
                                    variant="body2"
                                    href={attachment.file.path}
                                    target="_blank"
                                    download={attachment.file.displayName}
                                    noWrap
                                >
                                    {attachment.file.displayName ?? attachment.file.originalName}
                                </Link>
                            </ListItem>
                        ))}
                    </List>
                </CardContent>
            )}
            {!hideActions && (
                <CardActions>
                    {hasRequest && isAvailable && (
                        <Button
                            onClick={handleAppy}
                            href={!onApplyClick ? "/trainer?redirect_to=/trainer/competitions" : ""}
                            className={classes.applyBtn}
                            variant="outlined"
                        >
                            {trainerPageT("competitionCard.editApplication")}
                        </Button>
                    )}

                    {!hasRequest && competition.isAllowedRequests && (
                        <Button
                            onClick={handleAppy}
                            href={!onApplyClick ? "/trainer?redirect_to=/trainer/competitions" : ""}
                            className={classes.applyBtn}
                            variant="outlined"
                        >
                            {trainerPageT(
                                buttonText === "login"
                                    ? "competitionCard.login"
                                    : "competitionCard.apply"
                            )}
                        </Button>
                    )}
                </CardActions>
            )}
        </Card>
    );
};

export default CompetitionCard;
