import { Container } from "@material-ui/core";
import React from "react";
import Home from "../Views/Home";

const HomePage: React.FC = () => {
    return (
        <Container>
            <Home />
        </Container>
    );
};

export default HomePage;
