import { StoreonModule } from "storeon";
import { CompetitionPlan } from "../../interfaces/competition-plan";
import { Performance } from "../../interfaces/performance";

export interface OnlineResultsModuleState {
    socket: SocketIOClient.Socket | null;
    onlinePlans: CompetitionPlan[];
    completedPlans: CompetitionPlan[];
    performances: Performance[];
    onlinePerformances: Performance[][];
}

export interface OnlineResultsModuleEvents {
    "online/set/socket": SocketIOClient.Socket | null;
    "online/set/online_plans": CompetitionPlan[];
    "online/set/completed_plans": CompetitionPlan[];
    "online/set/performances": Performance[];
    "online/set/online_performances": Performance[][];
    "online/update/online_performance": Performance;
    "online/reset/state";
}

const initialState: OnlineResultsModuleState = {
    socket: null,
    onlinePlans: [],
    completedPlans: [],
    performances: [],
    onlinePerformances: [],
};

export const onlineResultsModule: StoreonModule<
    OnlineResultsModuleState,
    OnlineResultsModuleEvents
> = (store) => {
    store.on("@init", () => initialState);

    store.on("online/set/socket", (state, socket) => ({ socket }));

    store.on("online/set/online_plans", (state, onlinePlans) => ({ onlinePlans }));

    store.on("online/set/completed_plans", (state, completedPlans) => ({ completedPlans }));

    store.on("online/set/performances", (state, performances) => ({ performances }));

    store.on("online/set/online_performances", (state, onlinePerformances) => ({
        onlinePerformances,
    }));

    store.on("online/update/online_performance", (state, performance) => {
        const { onlinePerformances } = state;

        const updatedPerformances: Array<Performance[]> = [...onlinePerformances];

        updatedPerformances.forEach((item: Performance[]) => {
            item.forEach((perf: Performance) => {
                if (perf.id === performance.id) {
                    perf.otherScores = performance.otherScores;
                    perf.totalPoints = performance.totalPoints;
                    perf.place = performance.place;
                }
            });
        });

        return {
            onlinePerformances: updatedPerformances,
        };
    });

    store.on("online/reset/state", () => initialState);
};
