import React from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    Divider,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    Checkbox,
    ListItemText,
    TextField,
    Tooltip,
    DialogTitle,
} from "@material-ui/core";
import PlaceIcon from "@material-ui/icons/Place";
import Enums from "../../../Modules/Enums";
import RequestManager from "../../../Modules/RequestManager";
import { withSnackbar } from "notistack";
import { withTranslation } from "react-i18next";
import moment from "moment";

class SelectAthleteDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: this.props.open,
            athletesToSelect: [],
            athletesChecked: [],
            athletesCheckedToBack: [],
            request: {
                competitionPlanId: this.props.selectedCategory.id,
                categoryId: this.props.selectedCategory.category_id,
                ageCategoryId: this.props.selectedCategory.age_category_id,
                trainerId: this.props.trainerId,
                requests: [],
            },
            selectedRequest: null,
            errorMessage: {
                index: null,
                message: "",
            },
            type: this.props.requestsForSelectedCategory.length > 0 ? "put" : "post",
        };
    }

    componentDidMount = async () => {
        this.sortAthletesByAgeAndGender();
        this.applyRequestsProps();
    };

    applyRequestsProps = () => {
        const { athletesToSelect, request } = this.state;
        const { requestsForSelectedCategory } = this.props;

        let newRequest = request;

        if (requestsForSelectedCategory.length > 0) {
            requestsForSelectedCategory.forEach((request, index) => {
                request.trainerRequestDetail.forEach((athlete) => {
                    athletesToSelect.forEach((toSelect, toSelectIndex) => {
                        if (athlete.athleteId === toSelect.id) {
                            if (!newRequest.requests[index])
                                newRequest.requests.push({
                                    city: "",
                                    athletes: [],
                                });
                            newRequest.requests[index].athletes.push(toSelect);
                            athletesToSelect.splice(toSelectIndex, 1);
                        }
                    });
                });
            });

            requestsForSelectedCategory.forEach((request, index) => {
                newRequest.requests[index].city = request.request.city;
            });
        }

        this.setState({
            request: newRequest,
            athletesToSelect: athletesToSelect,
        });
    };

    sortAthletesByAgeAndGender = () => {
        const { athletesToSelect } = this.state;
        const { selectedCategory } = this.props;

        this.props.athletes.forEach((item) => {
            if (item.ageCategories.includes(selectedCategory.ageCategory.age_category_code)) {
                if (selectedCategory.category.gender_id === Enums.GENDERS[3].id)
                    athletesToSelect.push(item);
                else if (selectedCategory.category.gender_id === Enums.GENDERS[item.genderId].id) {
                    athletesToSelect.push(item);
                }
            }
        });

        athletesToSelect.sort((a, b) => {
            return a.genderId - b.genderId;
        });

        this.setState({
            athletesToSelect: athletesToSelect,
        });
    };

    checkAllRequestsAreFull = () => {
        const { request } = this.state;
        const { selectedCategory, t } = this.props;
        let validated = true;

        if (request) {
            request.requests.forEach((item, index) => {
                if (
                    item.athletes.length < selectedCategory.category.count_from &&
                    item.athletes.length < selectedCategory.category.count_to
                ) {
                    let message = {
                        index: index,
                        message: `${t("selectAthleteDialog.minPartsInGr")} - ${
                            selectedCategory.category.count_from
                        }. ${t("selectAthleteDialog.youChoosed")} - ${item.athletes.length}`,
                    };
                    this.setState({
                        selectedRequest: index,
                        errorMessage: message,
                    });
                    validated = false;
                    return validated;
                }
                if (this.props.selectedCategory.category.world_category_code === "MP") {
                    if (item.athletes[0].genderId === item.athletes[1].genderId) {
                        let message = {
                            index: index,
                            message: `${t("selectAthleteDialog.1boy1girlInGr")}`,
                        };
                        this.setState({
                            selectedRequest: index,
                            errorMessage: message,
                        });
                        validated = false;
                        return validated;
                    }
                }
            });

            return validated;
        }
        return false;
    };

    handleClose = () => {
        this.props.onClose();
    };

    isAS_or_AD_category = (category) => {
        return !!category.is_group;
    };

    handleSubmit = async () => {
        const { type, request } = this.state;
        const { selectedCategory } = this.props;

        let validated = !this.isAS_or_AD_category(selectedCategory.category)
            ? this.checkAllRequestsAreFull()
            : true;
        if (validated) {
            await RequestManager.fetch(`trainer/requests`, `${type}`, {
                request,
            }).then(async (result) => {
                if (result.body.status === "Success") {
                    this.props.enqueueSnackbar(result.body.message, {
                        variant: "success",
                    });
                    this.props.onClose();
                    await this.props.onUpdate();
                } else {
                    this.props.enqueueSnackbar(result.body.message, {
                        variant: "error",
                    });
                }
            });
        }
    };

    handleToggle = (item) => {
        const currentItem = this.state.athletesChecked.findIndex((i) => i.id === item.id);
        const newChecked = [...this.state.athletesChecked];

        if (currentItem === -1) {
            newChecked.push(item);
        } else {
            newChecked.splice(currentItem, 1);
        }

        this.setState({
            athletesChecked: newChecked,
        });
    };

    handleToggleAll = () => {
        const newChecked =
            this.state.athletesChecked.length === this.state.athletesToSelect.length
                ? []
                : this.state.athletesToSelect;
        this.setState({ athletesChecked: newChecked });
    };

    handleToggleToBack = (item) => {
        const currentItem = this.state.athletesCheckedToBack.find((i) => i.id === item.id);
        const newChecked = [...this.state.athletesCheckedToBack];

        if (currentItem === undefined) {
            newChecked.push(item);
        } else {
            newChecked.splice(currentItem, 1);
        }

        this.setState({
            athletesCheckedToBack: newChecked,
        });
    };

    handleRemoveAllRequests = () => {
        const { request, athletesToSelect } = this.state;

        let newAthletesToSelect = [];
        request.requests.forEach((item) => {
            newAthletesToSelect = [...newAthletesToSelect, ...item.athletes];
        });

        let newRequest = request;
        newRequest.requests = [];

        let message = { index: null, message: "" };

        this.setState({
            request: newRequest,
            athletesToSelect: [...athletesToSelect, ...newAthletesToSelect],
            selectedRequest: null,
            errorMessage: message,
        });
    };

    handleEditRequest = (item, index) => {
        this.setState({
            athletesCheckedToBack: [],
            selectedRequest: index,
        });
    };

    handleRemoveRequest = (item, index) => {
        const { request, athletesToSelect, errorMessage } = this.state;

        request.requests.splice(index, 1);

        let message = errorMessage;
        if (index === this.state.errorMessage.index) {
            message = { index: null, message: "" };
        }

        this.setState({
            athletesToSelect: [...athletesToSelect, ...item.athletes],
            request: request,
            selectedRequest: null,
            errorMessage: message,
        });
    };

    handleCancelEditRequest = () => {
        this.setState({
            selectedRequest: null,
            athletesCheckedToBack: [],
        });
    };

    baseHandleCheckedBottom = (request, athletesChecked, athletesToSelect, selectedRequest) => {
        const minAthletesCount = this.props.selectedCategory.category.count_from;
        const maxAthletesCount = this.props.selectedCategory.category.count_to;

        let index = selectedRequest;

        if (index !== null) {
            request.requests[index].athletes = [
                ...request.requests[index].athletes,
                ...athletesChecked,
            ];

            const lastAthleteCity =
                request.requests[index].athletes[request.requests[index].athletes.length - 1].city;
            request.requests[index].city = lastAthleteCity;
        } else {
            index = request.requests.length === 0 ? 0 : request.requests.length - 1;
            if (index !== 0 && request.requests[index].athletes.length >= minAthletesCount) {
                index++;
            }
            if (!request.requests[index]) {
                request.requests.push({
                    city: "",
                    athletes: [],
                });
            }
            athletesChecked.forEach((athlete) => {
                if (request.requests[index].athletes.length < minAthletesCount) {
                    request.requests[index].athletes.push(athlete);
                } else {
                    request.requests.push({
                        city: "",
                        athletes: [],
                    });
                    index++;
                    request.requests[index].athletes.push(athlete);
                }
                const lastAthleteCity =
                    request.requests[index].athletes[request.requests[index].athletes.length - 1]
                        .city;
                request.requests[index].city = lastAthleteCity;
            });
        }

        let newAthletesToSelect = athletesToSelect.filter((el) => !athletesChecked.includes(el));

        let message = { index: null, message: "" };
        if (request.requests[index].athletes.length > maxAthletesCount) {
            message = {
                index: index,
                message: this.props.t("selectAthleteDialog.maxNumbOfParts"),
            };
        }

        this.setState({
            request: request,
            athletesChecked: [],
            athletesToSelect: newAthletesToSelect,
            errorMessage: message,
        });
    };

    AS_or_AD_handleCheckedBottom = (
        request,
        athletesChecked,
        athletesToSelect,
        selectedRequest
    ) => {
        if (selectedRequest !== null) {
            request.requests[selectedRequest].athletes = [
                ...request.requests[selectedRequest].athletes,
                ...athletesChecked,
            ];
            const lastAthleteCity =
                request.requests[selectedRequest].athletes[
                    request.requests[selectedRequest].athletes.length - 1
                ].city;
            request.requests[selectedRequest].city = lastAthleteCity;
        } else {
            const index = request.requests.length === 0 ? 0 : request.requests.length;

            if (!request.requests[index]) {
                request.requests.push({
                    city: "",
                    athletes: [],
                });
            }
            athletesChecked.forEach((athlete) => request.requests[index].athletes.push(athlete));

            const lastAthleteCity =
                request.requests[index].athletes[request.requests[index].athletes.length - 1].city;
            request.requests[index].city = lastAthleteCity;
        }

        const newAthletesToSelect = athletesToSelect.filter((el) => !athletesChecked.includes(el));

        let message = { index: null, message: "" };

        this.setState({
            request: request,
            athletesChecked: [],
            athletesToSelect: newAthletesToSelect,
            errorMessage: message,
        });
    };

    handleCheckedBottomRouter = () => {
        const { request, athletesChecked, athletesToSelect, selectedRequest } = this.state;
        const { selectedCategory } = this.props;

        if (this.isAS_or_AD_category(selectedCategory.category)) {
            console.log(1);
            this.AS_or_AD_handleCheckedBottom(
                request,
                athletesChecked,
                athletesToSelect,
                selectedRequest
            );
        } else {
            this.baseHandleCheckedBottom(
                request,
                athletesChecked,
                athletesToSelect,
                selectedRequest
            );
        }
    };

    handleCheckedTop = () => {
        const { request, selectedRequest, athletesCheckedToBack, athletesToSelect } = this.state;
        const { selectedCategory } = this.props;

        const maxAthletesCount = this.props.selectedCategory.category.count_to;

        let newAthletes = request.requests[selectedRequest].athletes.filter(
            (el) => !athletesCheckedToBack.includes(el)
        );
        request.requests[selectedRequest].athletes = newAthletes;

        const lastAthleteCity =
            request.requests[selectedRequest].athletes.length !== 0
                ? request.requests[selectedRequest].athletes[
                      request.requests[selectedRequest].athletes.length - 1
                  ].city
                : "";
        request.requests[selectedRequest].city = lastAthleteCity;

        let message = { index: null, message: "" };
        if (
            request.requests[selectedRequest].athletes.length > maxAthletesCount &&
            !this.isAS_or_AD_category(selectedCategory.category)
        ) {
            message = {
                index: selectedRequest,
                message: this.props.t("selectAthleteDialog.maxNumbOfParts"),
            };
        }

        this.setState({
            athletesToSelect: [...athletesToSelect, ...athletesCheckedToBack],
            athletesCheckedToBack: [],
            request: request,
            errorMessage: message,
        });
    };

    handleChangeRequestData = (event, index) => {
        const value = event.target.value;
        const name = event.target.name;

        const { request } = this.state;
        let updatedRequest = request;

        updatedRequest.requests[index][name] = value;
        let message = { index: null, message: "" };

        this.setState({
            request: updatedRequest,
            errorMessage: message,
        });
    };

    render() {
        const { selectedCategory, t } = this.props;

        let countTo =
            selectedCategory.category.count_from !== selectedCategory.category.count_to
                ? selectedCategory.category.count_to
                : null;

        return (
            <Dialog open={this.state.open} fullWidth>
                <DialogTitle className="select-athletes-dialog_aside ">
                    <Grid item xs>
                        <Typography variant="h6">
                            {this.props.selectedCategory.category.world_category_code}
                        </Typography>
                        <Typography variant="body1">
                            <span>{this.props.selectedCategory.ageCategory.age_category_name}</span>
                        </Typography>

                        <Grid container direction="column" item>
                            <Button
                                variant="outlined"
                                disabled={this.state.athletesCheckedToBack.length === 0}
                                onClick={this.handleCheckedTop}
                                color="secondary"
                            >
                                &#11165; {t("selectAthleteDialog.return")}
                            </Button>
                            <Button
                                variant="contained"
                                disabled={this.state.athletesChecked.length === 0}
                                onClick={this.handleCheckedBottomRouter}
                                color="primary"
                                style={{ marginTop: 10 }}
                            >
                                {t("selectAthleteDialog.declare")} &#11167;
                            </Button>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent className="select-athletes-dialog">
                    <Grid item xs className="select-athletes-dialog__athletes-list">
                        <Typography variant="subtitle1">
                            <b>{t("selectAthleteDialog.selectAthletes")}</b>
                        </Typography>
                        {this.state.athletesToSelect.length > 0 ? (
                            <React.Fragment>
                                <ListItem
                                    role="listitem"
                                    button
                                    onClick={() => this.handleToggleAll()}
                                >
                                    <ListItemText
                                        primary={`${t("selectAthleteDialog.selectAll")} (${
                                            this.state.athletesToSelect.length
                                        })`}
                                    />
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={
                                                this.state.athletesToSelect.length ===
                                                this.state.athletesChecked.length
                                            }
                                            tabIndex={-1}
                                            disableRipple
                                            color="primary"
                                        />
                                    </ListItemIcon>
                                </ListItem>
                                <Divider />
                            </React.Fragment>
                        ) : null}
                        <List role="list">
                            {this.state.athletesToSelect.length > 0 ? (
                                this.state.athletesToSelect.map((item) => {
                                    const yarnOfBirth = moment(
                                        item.dateOfBirth,
                                        "YYYY-MM-dd"
                                    ).year();

                                    return (
                                        <ListItem
                                            key={item.id}
                                            role="listitem"
                                            button
                                            onClick={() => this.handleToggle(item)}
                                        >
                                            <ListItemText
                                                primary={`${item.lastName} ${item.firstName} (${yarnOfBirth})`}
                                            />
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={
                                                        this.state.athletesChecked.indexOf(item) !==
                                                        -1
                                                    }
                                                    tabIndex={-1}
                                                    disableRipple
                                                    color="primary"
                                                />
                                            </ListItemIcon>
                                        </ListItem>
                                    );
                                })
                            ) : (
                                <Typography variant="body1">
                                    {t("selectAthleteDialog.noRightAthletes")}
                                </Typography>
                            )}
                        </List>
                    </Grid>

                    <Divider />

                    <Grid item xs className="select-athletes-dialog__requests">
                        {this.state.request.requests.map((item, index) => {
                            return (
                                <Grid
                                    container
                                    key={index}
                                    className={`select-athletes-dialog__requests__item ${
                                        this.state.selectedRequest === index
                                            ? "select-athletes-dialog__requests__item-active"
                                            : ""
                                    } `}
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        className="select-athletes-dialog__requests__item-actions"
                                    >
                                        {this.state.selectedRequest !== index && (
                                            <Button
                                                onClick={() => this.handleEditRequest(item, index)}
                                                disabled={this.state.errorMessage.index !== null}
                                                size="small"
                                            >
                                                {t("selectAthleteDialog.edit")}
                                            </Button>
                                        )}
                                        {this.state.selectedRequest === index && (
                                            <Button
                                                onClick={() => this.handleCancelEditRequest()}
                                                disabled={this.state.errorMessage.index !== null}
                                                size="small"
                                                color="primary"
                                            >
                                                {t("selectAthleteDialog.save")}
                                            </Button>
                                        )}

                                        <Button
                                            onClick={() => this.handleRemoveRequest(item, index)}
                                            disabled={
                                                this.state.errorMessage.index !== null &&
                                                this.state.errorMessage.index !== index
                                            }
                                            size="small"
                                            color="secondary"
                                        >
                                            {t("selectAthleteDialog.delete")}
                                        </Button>
                                    </Grid>
                                    <Grid
                                        container
                                        item
                                        xs
                                        className="select-athletes-dialog__requests__item-content"
                                    >
                                        <Grid
                                            item
                                            xs={2}
                                            className="select-athletes-dialog__requests__item-content__left"
                                        >
                                            <Typography variant="body1">
                                                <strong>
                                                    {selectedCategory.category.world_category_code}{" "}
                                                    {index + 1}
                                                </strong>
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                {item.athletes.length}/
                                                {selectedCategory.category.count_from}
                                                {countTo ? `(${countTo})` : null}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs>
                                            {item.athletes.map((athlete) => {
                                                const yarnOfBirth = moment(
                                                    athlete.dateOfBirth,
                                                    "YYYY-MM-dd"
                                                ).year();

                                                return athlete ? (
                                                    <ListItem key={athlete.id}>
                                                        <ListItemText
                                                            primary={`${athlete.lastName} ${athlete.firstName} (${yarnOfBirth})`}
                                                        />
                                                        {this.state.selectedRequest === index && (
                                                            <ListItemIcon
                                                                onClick={() =>
                                                                    this.handleToggleToBack(athlete)
                                                                }
                                                            >
                                                                <Checkbox
                                                                    checked={
                                                                        this.state.athletesCheckedToBack.indexOf(
                                                                            athlete
                                                                        ) !== -1
                                                                    }
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    color="primary"
                                                                />
                                                            </ListItemIcon>
                                                        )}
                                                    </ListItem>
                                                ) : null;
                                            })}
                                            {this.state.selectedRequest !== index && (
                                                <Grid
                                                    container
                                                    className="select-athletes-dialog__requests__item-content__bottom"
                                                >
                                                    <Grid item xs={6}>
                                                        <PlaceIcon />
                                                        <Typography variant="body2">
                                                            {this.state.request.requests[index]
                                                                .city !== ""
                                                                ? this.state.request.requests[index]
                                                                      .city
                                                                : t(
                                                                      "selectAthleteDialog.notIndicated"
                                                                  )}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>

                                    {/* <Divider orientation="vertical" flexItem /> */}

                                    {this.state.selectedRequest === index && (
                                        <Grid
                                            container
                                            className="select-athletes-dialog__requests__item-additions"
                                        >
                                            <Grid container item xs={7}>
                                                <Grid
                                                    item
                                                    xs={2}
                                                    className="select-athletes-dialog__requests__item-additions-icon"
                                                >
                                                    <Tooltip
                                                        arrow
                                                        title={t("selectAthleteDialog.autoCity")}
                                                    >
                                                        <PlaceIcon />
                                                    </Tooltip>
                                                </Grid>
                                                <Grid item xs={10}>
                                                    <TextField
                                                        type="text"
                                                        name="city"
                                                        value={
                                                            this.state.request.requests[index]
                                                                .city || ""
                                                        }
                                                        onChange={(e) =>
                                                            this.handleChangeRequestData(e, index)
                                                        }
                                                        label={t("selectAthleteDialog.city")}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {this.state.errorMessage.index === index && (
                                        <Grid
                                            container
                                            item
                                            xs={12}
                                            className="select-athletes-dialog__requests__item-error"
                                        >
                                            <Typography variant="body1">
                                                {this.state.errorMessage.message}
                                            </Typography>
                                        </Grid>
                                    )}
                                </Grid>
                            );
                        })}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose}>{t("selectAthleteDialog.cancel")}</Button>
                    <Button
                        color="primary"
                        disabled={this.state.errorMessage.index !== null}
                        onClick={this.handleSubmit}
                        variant="default"
                    >
                        {t("selectAthleteDialog.save")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

SelectAthleteDialog.propTypes = {
    open: PropTypes.bool,
    athletes: PropTypes.array,
    trainerId: PropTypes.number,
    selectedCategory: PropTypes.object,
    requestsForSelectedCategory: PropTypes.array,
    onClose: PropTypes.func,
    onUpdate: PropTypes.func,
};

export default withSnackbar(withTranslation("trainerPage")(SelectAthleteDialog));
