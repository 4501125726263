import { OtherScores } from "../../interfaces/other-scores";

export interface Beamer {
    userId: number;
    ready: boolean;
    options: BeamerOptions;
}

export interface BeamerOptions {
    mode: BeamerMode;
    slideShowTime: number;
    slide: SlideMode;
}

export enum BeamerMode {
    AUTO = "auto",
    MANUAL = "manual",
}

export enum SlideType {
    PERFORMANCE = "performance",
    RATING = "rating",
}

export enum SlideMode {
    PERFORMANCE = "performance",
    RATING = "rating",
    ALL = "all",
}

export interface RatingSlideObject {
    categoryName: string;
    ageCategoryName: string;
    appealName: string;
    performances: BeamerSlideObject[];
}

export interface BeamerSlideObject {
    performanceId: number;
    place: number;
    scores: OtherScores;
    athletes: Athlete[];
    ageCategoryCode: string;
    worldCode: string;
    appealName: string;
    region: string;
}

export interface Athlete {
    id: number;
    firstName: string;
    lastName: string;
    middleName: string;
    genderId: number;
    city: string;
    country: string;
}
