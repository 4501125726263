import React, { FunctionComponent } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@material-ui/core";
import { TextField, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { trainerRefereeApiService } from "@/api/Services";
import { TrainerReferee } from "@/interfaces";
import { FormikConfig, useFormik } from "formik";
import { Autocomplete } from "@material-ui/lab";
import { subjects } from "@/modules/russia-subjects";
import { countries } from "@/modules/Countries";
import { UnivesalTrainerRefereeSchema } from "../../../Schema/TrainerRefereeSchema";
import { useSnackbar } from "notistack";
import { AxiosError } from "axios";
import { useQuery } from "react-query";
import CompetitionsManager from "@/modules/CompetitionsManager";
import Helpers from "@/modules/Helpers";

interface RefereesPopupProps {
    open: boolean;
    selectedReferee: TrainerReferee | null;
    onUpdate(): void;
    modalHide(): void;
}

type TrainerRefereeValues = Omit<TrainerReferee, "id" | "trainerId"> & {
    trainerId: number | null;
};

const RefereesPopup: FunctionComponent<RefereesPopupProps> = ({
    open,
    selectedReferee,
    onUpdate,
    modalHide,
}: RefereesPopupProps) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("adminPage");
    const { t: tp } = useTranslation("trainerPage");

    const { data: trainers } = useQuery(["trainers"], CompetitionsManager.getAllTrainers, {
        select: (data) => data,
    });

    const onHide: VoidFunction = () => {
        modalHide();
    };

    const submit: FormikConfig<TrainerRefereeValues>["onSubmit"] = async (values) => {
        try {
            if (selectedReferee) {
                const { message } = await trainerRefereeApiService.update(
                    selectedReferee.id,
                    values
                );
                enqueueSnackbar(message, {
                    variant: "success",
                });
            } else {
                const { message } = await trainerRefereeApiService.create(values);
                enqueueSnackbar(message, {
                    variant: "success",
                });
            }

            onHide();
            onUpdate();
        } catch (err) {
            const message = (err as AxiosError).response?.data?.message;
            enqueueSnackbar(message ?? (err as Error).message, {
                variant: "error",
            });
        }
    };

    const { handleSubmit, values, errors, touched, handleChange, setFieldValue } = useFormik({
        initialValues: {
            lastName: selectedReferee?.lastName ?? "",
            firstName: selectedReferee?.firstName ?? "",
            middleName: selectedReferee?.middleName ?? "",
            city: selectedReferee?.city ?? "",
            region: selectedReferee?.region ?? "",
            country: selectedReferee?.country ?? "",
            categoryReferee: selectedReferee?.categoryReferee ?? "",
            categoryFig: selectedReferee?.categoryFig ?? "",
            categoryInternational: selectedReferee?.categoryInternational ?? "",
            trainerId: selectedReferee?.trainerId ?? null,
        },
        onSubmit: submit,
        validationSchema: UnivesalTrainerRefereeSchema,
        enableReinitialize: true,
    });

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>{selectedReferee ? t("editReferee") : t("newReferee")}</DialogTitle>
            <form noValidate onSubmit={handleSubmit}>
                <DialogContent>
                    <TextField
                        required
                        type="text"
                        name="lastName"
                        label={tp("athleteForm.surname")}
                        fullWidth
                        margin="dense"
                        onChange={(e) => {
                            const { value } = e.target;
                            const val = value.charAt(0).toUpperCase() + value.slice(1);

                            setFieldValue("lastName", val.trimLeft().trimRight());
                        }}
                        value={values.lastName || ""}
                        error={touched.lastName && !!errors.lastName}
                        helperText={touched.lastName ? errors.lastName : ""}
                    />

                    <TextField
                        required
                        type="text"
                        name="firstName"
                        label={tp("athleteForm.firstName")}
                        fullWidth
                        margin="dense"
                        onChange={(e) => {
                            const { value } = e.target;
                            const val = value.charAt(0).toUpperCase() + value.slice(1);

                            setFieldValue("firstName", val.trimLeft().trimRight());
                        }}
                        value={values.firstName || ""}
                        error={touched.firstName && !!errors.firstName}
                        helperText={touched.firstName ? errors.firstName : ""}
                    />

                    <TextField
                        type="text"
                        name="middleName"
                        label={tp("athleteForm.middleName")}
                        fullWidth
                        margin="dense"
                        onChange={(e) => {
                            const { value } = e.target;
                            const val = value.charAt(0).toUpperCase() + value.slice(1);

                            setFieldValue("middleName", val.trimLeft().trimRight());
                        }}
                        value={values.middleName || ""}
                        error={touched.middleName && !!errors.middleName}
                        helperText={touched.middleName ? errors.middleName : ""}
                    />

                    <Autocomplete
                        fullWidth
                        options={countries}
                        getOptionLabel={(option) => option}
                        value={countries.find((el) => el === values.country) || null}
                        onChange={(e, value) => {
                            setFieldValue("country", value || "");
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin="dense"
                                label={tp("athleteForm.country")}
                                name="country"
                                error={touched.country && !!errors.country}
                                helperText={touched.country ? errors.country : ""}
                            />
                        )}
                    />

                    <Autocomplete
                        fullWidth
                        options={subjects}
                        getOptionLabel={(option) => option.title}
                        value={subjects.find((el) => el.title === values.region) || null}
                        onChange={(_, value) => {
                            setFieldValue("region", value?.title || "");
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin="dense"
                                label={tp("athleteForm.region")}
                                name="region"
                                error={touched.region && !!errors.region}
                                helperText={touched.region ? errors.region : ""}
                            />
                        )}
                    />

                    <TextField
                        required
                        type="text"
                        name="city"
                        label={tp("athleteForm.city")}
                        fullWidth
                        margin="dense"
                        onChange={(e) => {
                            const { value } = e.target;

                            setFieldValue("city", value.trimLeft().trimRight());
                        }}
                        value={values.city || ""}
                        error={touched.city && !!errors.city}
                        helperText={touched.city ? errors.city : ""}
                    />

                    <TextField
                        select
                        name="categoryReferee"
                        label={tp("refereesList.categoryReferee")}
                        fullWidth
                        margin="dense"
                        onChange={handleChange}
                        value={values.categoryReferee || ""}
                    >
                        <MenuItem key={0} value="">
                            {tp("athleteForm.notChosen")}
                        </MenuItem>
                        {["Б/К", "1", "2", "3", "4", "СВК"].map((category) => {
                            return (
                                <MenuItem key={category} value={category}>
                                    {category}
                                </MenuItem>
                            );
                        })}
                    </TextField>

                    <TextField
                        select
                        name="categoryFig"
                        label={tp("refereesList.categoryFig")}
                        fullWidth
                        margin="dense"
                        onChange={handleChange}
                        value={values.categoryFig || ""}
                    >
                        <MenuItem key={0} value="">
                            {tp("athleteForm.notChosen")}
                        </MenuItem>
                        {["Б/К", "1", "2", "3", "4"].map((category) => {
                            return (
                                <MenuItem key={category} value={category}>
                                    {category}
                                </MenuItem>
                            );
                        })}
                    </TextField>

                    <TextField
                        select
                        name="categoryInternational"
                        label={tp("refereesList.categoryInternational")}
                        fullWidth
                        margin="dense"
                        onChange={handleChange}
                        value={values.categoryInternational || ""}
                    >
                        <MenuItem key={0} value="">
                            {tp("athleteForm.notChosen")}
                        </MenuItem>
                        {["Б/К", "1", "2", "3", "4"].map((category) => {
                            return (
                                <MenuItem key={category} value={category}>
                                    {category}
                                </MenuItem>
                            );
                        })}
                    </TextField>

                    <Autocomplete
                        fullWidth
                        options={trainers}
                        getOptionLabel={(option) => Helpers.getFullNameForUser(option) || ""}
                        value={trainers?.find((el) => el.id === values.trainerId) || null}
                        onChange={(_, value) => {
                            setFieldValue("trainerId", value?.id || "");
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin="dense"
                                label={t("coachName")}
                                name="trainerId"
                                error={touched.trainerId && !!errors.trainerId}
                                helperText={touched.trainerId ? errors.trainerId : ""}
                            />
                        )}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onHide} color="default">
                        {t("cancel")}
                    </Button>
                    <Button type="submit" color="primary" variant="contained">
                        {t("save")}
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default RefereesPopup;
