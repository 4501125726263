import React, { useCallback, useMemo, useRef, forwardRef, useEffect } from "react";
import { PropTypes } from "@material-ui/core";
import MaterialTable, {
    MaterialTableProps,
    Icons,
    Action,
    Column,
    Query,
    QueryResult,
    Options,
} from "@material-table/core";
import {
    AddBox,
    ArrowDownward,
    Check,
    ChevronLeft,
    ChevronRight,
    Clear,
    Delete,
    Edit,
    FilterList,
    FirstPage,
    LastPage,
    Remove,
    SaveAlt,
    Search,
    ViewColumn,
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { PaginatedQueryResult, QueryParams } from "@/api/types";
import { useTable } from "../../Hooks";

export type DataTableColumn<DataType extends {}> = Column<DataType>;

export interface RowAction<DataType extends {}> extends Action<DataType> {
    name: string;
    color?: PropTypes.Color;
}

type FetchResult<DataType> = PaginatedQueryResult<DataType>;

export type DataTableOptions<DataType extends Record<string, unknown>> = Options<DataType>;

export type OnEditClick<DataType> = (rowData: DataType | DataType[]) => void;

export type OnDeleteClick<DataType> = (rowData: DataType) => Promise<void>;

export type OnMassDeleteClick<DataType> = (rowData: DataType | DataType[]) => Promise<void>;

export type DataTableProps<DataType extends {}> = Omit<
    MaterialTableProps<DataType>,
    "data" | "columns"
> &
    Partial<{
        columns: DataTableColumn<DataType>[];
        pageSize: number;
        enableGrouping: boolean;
        showToolbar: boolean;
        showSearch: boolean;
        actionsIndex: number;
        canDelete: boolean;
        canEdit: boolean;
        onEditClick: OnEditClick<DataType>;
        onDeleteClick: OnDeleteClick<DataType>;
        onMassDeleteClick: OnMassDeleteClick<DataType>;
        customActions: RowAction<DataType>[];
        data: DataType[];
        fetchFn(params?: QueryParams<DataType>): Promise<FetchResult<DataType>>;
        options?: DataTableOptions<DataType>;
    }>;

const tableIcons: Icons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const DataTable = <T extends {}>(props: DataTableProps<T>) => {
    const {
        columns = [],
        data,
        fetchFn,
        onEditClick,
        onDeleteClick,
        onMassDeleteClick,
        customActions = [],
        canDelete = false,
        canEdit = false,
        showSearch = false,
        showToolbar = false,
        enableGrouping = false,
        pageSize = 10,
        options,
    } = props;
    const { t } = useTranslation("common");
    const { setRef } = useTable();

    const tableRef = useRef<any>();

    useEffect(() => {
        if (tableRef.current) {
            setRef(tableRef);
        }

        return () => setRef(null);
    }, [setRef]);

    const fetch = useCallback(
        async (q: Query<T>): Promise<QueryResult<T>> => {
            if (!fetchFn) return Promise.reject();

            const { page, pageSize, orderDirection, orderBy, search } = q;

            const nextPage = page + 1;

            const params = {
                page: nextPage,
                size: pageSize,
                sort: orderBy?.field as keyof T,
                order: orderDirection ? orderDirection : undefined,
                q: search ? search : undefined,
            };

            const response = await fetchFn(params as any);

            return {
                data: response.result.rows,
                page: nextPage - 1,
                totalCount: response.result.count,
            };
        },
        [fetchFn]
    );

    const getActions = useMemo((): RowAction<T>[] => {
        const actions = [] as RowAction<T>[];

        actions.push(...customActions);

        if (canEdit) {
            actions.push({
                name: "edit",
                icon: "edit",
                tooltip: "Редактировать",
                position: "row",
                iconProps: {
                    fontSize: "small",
                },
                onClick: (event, rowData) => {
                    onEditClick?.(rowData);
                },
                disabled: false,
            });
        }
        if (onMassDeleteClick) {
            actions.push({
                name: "massdelete",
                tooltip: "Удалить выбранные элементы",
                icon: "delete",
                position: "toolbarOnSelect",
                onClick: async (evt, data) => await onMassDeleteClick?.(data),
            });
        }

        return actions;
    }, [canEdit, onEditClick, onMassDeleteClick, customActions]);

    return (
        <MaterialTable<T>
            tableRef={tableRef}
            title={props.title || ""}
            data={data ? data : fetch}
            columns={columns}
            icons={tableIcons}
            onRowClick={props.onRowClick}
            isLoading={props.isLoading}
            localization={{
                toolbar: {
                    nRowsSelected: `{0} ${t("rowsSelected")}`,
                    searchTooltip: t("search"),
                    searchPlaceholder: t("search"),
                },
                header: {
                    actions: "",
                },
                body: {
                    emptyDataSourceMessage: t("nothingHere"),
                    filterRow: {
                        filterTooltip: t("filter"),
                    },
                    editRow: {
                        deleteText: t("deleteQuestin"),
                        saveTooltip: t("save"),
                        cancelTooltip: t("cancel"),
                    },
                    deleteTooltip: t("delete"),
                    editTooltip: t("edit"),
                },
                pagination: {
                    labelRowsSelect: "",
                    labelRowsPerPage: t("linesPerPage"),
                    labelDisplayedRows: ` {from}-{to} ${t("of")} {count}`,
                    firstTooltip: t("firstPage"),
                    previousTooltip: t("prevPage"),
                    nextTooltip: t("nextPage"),
                    lastTooltip: t("lastPage"),
                },
                grouping: {
                    placeholder: t("dragColumnNamesForGroop"),
                    groupedBy: `${t("groupedBy")}:`,
                },
            }}
            editable={{
                isDeleteHidden: () => !canDelete ?? false,
                onRowDelete: (data) =>
                    new Promise((resolve) => {
                        onDeleteClick?.(data).then(() => resolve(data));
                    }),
            }}
            actions={getActions}
            options={{
                rowStyle: {
                    fontSize: 12,
                },
                debounceInterval: 500,
                pageSizeOptions: [10, 20, 50],
                padding: "dense",
                actionsColumnIndex: props.actionsIndex ?? -1,
                tableLayout: "auto",
                search: showSearch,
                toolbar: showToolbar,
                grouping: enableGrouping,
                pageSize,
                ...options,
            }}
        />
    );
};

export default DataTable;
