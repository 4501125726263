import React from "react";
import CompetitionsList from "../Controls/Beamers/competitions-list";
import { withAuth } from "../Hoc";
import Auth from "../Modules/Auth";

const BeamerPage = () => {
    return (
        <div>
            <CompetitionsList />
        </div>
    );
};
export default withAuth(BeamerPage, [Auth.ROLES.beamer]);
