import BaseApiService from "@/api/Base";
import { TokenInterceptor } from "@/api/Http/Interceptors";
import { Settings } from "@/modules/Constants";
import { CompetitionBrigade } from "../../../interfaces/competition-brigade";

class CompetitionBrigadeApiService extends BaseApiService<CompetitionBrigade> {
    constructor(config) {
        super(config);
        this.url = "/competition-brigades";
        this.http.interceptors.request.use(TokenInterceptor);
    }
}

export default new CompetitionBrigadeApiService({
    baseURL: Settings.MAIN_API_URL_V2,
});
