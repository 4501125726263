import React from "react";
import RequestManager from "../../../Modules/RequestManager";
import PropTypes from "prop-types";
import { Grid, Button, Paper, Typography } from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ScheduleCreatePopup from "./ScheduleCreatePopup";
import ScheduleItem from "./ScheduleItem";
import { withRouter } from "react-router";
import Utils from "../../../Modules/Utils.js";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { withTranslation } from "react-i18next";
import { BrigadeVariant } from "@/interfaces";
import { competitionBrigadeApiService, performanceApiService } from "@/api/Services";
import "./SchedulesList.css";

let itemTechnicalTimeout = {
    date: "",
    time: "",
    competitionPlanWorldName: "",
    competitionStageName: "",
    duration: "",
};

class SchedulesList extends React.Component {
    static DEFAULT_DURATION = 3;
    constructor(props) {
        super(props);

        this.state = {
            schedules: [],
            isLoading: true,
            isDeleteLoading: false,
            isAssigmentLoading: false,
            isPlanesFixed: false,

            selectedSchedule: {
                competitionPlanId: -1,
                duration: SchedulesList.DEFAULT_DURATION,
                competitionStageId: 1,
                competitionPlanWorldName: "...",
            },
            modalIsOpen: false,
            competition: {},
            competitionPlans: [],
            competitionStages: [],
            competitionDates: [],
            requests: [],
        };

        this.scroll = false;

        this.processForm = this.processForm.bind(this);
        this.handleCategoryBtnClick = this.handleCategoryBtnClick.bind(this);
        this.refreshCompetitionLists = this.refreshCompetitionLists.bind(this);
        this.refreshSchedules = this.refreshSchedules.bind(this);
        this.getDates = this.getDates.bind(this);
        this.getCompetitionDatesList = this.getCompetitionDatesList.bind(this);
        this.getDateIdByValue = this.getDateIdByValue.bind(this);
        this.isTechnicalTimeout = this.isTechnicalTimeout.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.deleteSchedules = this.deleteAllSchedules.bind(this);
    }

    componentDidMount = async () => {
        this.refreshCompetitionLists();
        await this.getRequsts();
        this.setState({
            planesContainer: document.getElementById("planes-container"),
        });
        document.addEventListener("scroll", this.handleScroll);
    };

    componentWillUnmount() {
        document.removeEventListener("scroll", this.handleScroll);
    }

    getRequsts = async () => {
        const res = await RequestManager.fetch(
            `requestsByCompetitionId/${this.props.competitionId}`,
            "get"
        );

        this.setState({ requests: res.body.Requests });
    };

    handleScroll() {
        let planesShouldBeFixed =
            document.getElementById("planes-container").getBoundingClientRect().top < 0;
        if (this.state.isPlanesFixed !== planesShouldBeFixed) {
            this.setState({
                isPlanesFixed: planesShouldBeFixed,
            });
        }
    }

    getDates(startDate, stopDate) {
        let dateArray = [];
        let currentDate = moment(startDate);
        let index = 0;
        while (currentDate <= moment(stopDate)) {
            index++;
            dateArray.push({ id: index, dat: new Date(currentDate) });
            currentDate = currentDate.add(1, "days");
        }
        return dateArray;
    }

    getCompetitionDatesList(dates) {
        // gen dates from range
        const dateFrom = moment(dates.dateFrom);
        const dateTo = moment(dates.dateTo);
        if (dateFrom <= dateTo) {
            let datesList = this.getDates(dateFrom, dateTo);
            this.setState({
                competitionDates: datesList,
            });
        }
    }

    refreshCompetitionLists() {
        RequestManager.fetch(`competitions/${this.props.competitionId}`, "get")
            .then((response) => {
                this.setState({
                    competition: response.body.Competition[0],
                });
                this.getCompetitionDatesList(response.body.Competition[0]);
            })
            .catch((err) => {
                throw new Error(err);
            });

        RequestManager.fetch("competitionStages", "get")
            .then((response) => {
                this.setState({
                    competitionStages: response.body.CompetitionStages,
                });
            })
            .catch((err) => {
                throw new Error(err);
            });

        RequestManager.fetch(`competitionPlans/${this.props.competitionId}`, "get")
            .then((response) => {
                this.setState({
                    competitionPlans: response.body.CompetitionPlans,
                });
            })
            .catch((err) => {
                throw new Error(err);
            });

        this.refreshSchedules();
    }

    refreshSchedules() {
        RequestManager.fetch(`/schedules/${this.props.competitionId}`, "get")
            .then((response) => {
                this.setState({
                    schedules: response.body.Schedules,
                });
            })
            .catch((err) => {
                throw new Error(err);
            });
    }

    processForm(competitionPlanWorldName = null) {
        this.assignReferees(competitionPlanWorldName);
        this.refreshCompetitionLists();
        this.closeModal();
    }

    closeModal() {
        this.setState({
            modalIsOpen: false,
        });
    }

    getDateIdByValue(arr, value) {
        let selectedItemId = 0;
        arr.map((item) => {
            let cmpDate = moment(item.dat).format("DD.MM.YYYY");
            if (cmpDate === value) {
                selectedItemId = item.id;
            }
            return item;
        });
        return selectedItemId;
    }

    handleCategoryBtnClick(event) {
        let competitionPlanId = +event.currentTarget.id;
        this.state.competitionPlans.map((item) => {
            if (item.competitionPlanId === competitionPlanId) {
                this.setState({
                    selectedSchedule: {
                        competitionPlanWorldName: item.competitionPlanWorldName,
                        competitionPlanId: competitionPlanId,
                    },
                    modalIsOpen: true,
                });
                return true;
            }
            return false;
        });
    }

    isTechnicalTimeout(timeFrom, timeTo) {
        const { t } = this.props;
        let momentFrom = moment(timeFrom, "HH:mm:ss");
        let momentTo = moment(timeTo, "HH:mm:ss");

        if (!momentFrom.isValid || !momentTo.isValid) {
            return false;
        }

        const MIN_DURATION_SECONDS = 10 * 60; // 10 minutes.
        const MAX_DURATION_SECONDS = 24 * 60 * 60; // 24 hours - limitation of the algorithm below.

        let deltaSeconds = momentTo.diff(momentFrom, "seconds");
        if (deltaSeconds < MIN_DURATION_SECONDS || deltaSeconds >= MAX_DURATION_SECONDS) {
            return false;
        }

        let durationMoment = moment.unix(deltaSeconds).utc();
        let durationString =
            durationMoment.hours() === 0
                ? moment.unix(deltaSeconds).utc().format("mm:ss")
                : moment.unix(deltaSeconds).format("HH:mm:ss");

        itemTechnicalTimeout = {
            date: t("duration"),
            time: durationString,
            competitionPlanWorldName: t("break"),
            competitionStageName: <div>&nbsp;</div>,
            duration: deltaSeconds,
        };
        return true;
    }

    deleteAllSchedules(event) {
        const { t } = this.props;
        let confirm = window.confirm(t("deleteAllEntriesQuestion"));
        const promises = [];
        if (confirm) {
            this.setState({ isDeleteLoading: true });
            this.state.schedules.forEach((schedule) => {
                promises.push(
                    RequestManager.fetch(`schedules/${schedule.scheduleId}`, "delete").catch(
                        (err) => {
                            NotificationManager.error(t("dataRemoveError"));
                        }
                    )
                );
            });
            Promise.all(promises).then(() => {
                NotificationManager.success(t("entriesRemovedFromSchedule"));
                this.setState({ isDeleteLoading: false });
                this.refreshSchedules();
            });
        }
    }

    fillPerformanceTable = () => {
        const { competitionId } = this.props;

        RequestManager.fetch(`createEmptyListofPerformances/${competitionId}`, "get");
    };

    updateRefereeAssignmentList = async (competitionPlanWorldName = null) => {
        const { competitionId } = this.props;

        const { result: brigadesList } = await competitionBrigadeApiService.getAll({
            competitionId,
        });

        const refereeAssigments = await RequestManager.fetch(
            `refereeAssignments/${competitionId}`,
            "get"
        ).then(({ body }) => {
            if (competitionPlanWorldName) {
                return body.RefereeAssignments.filter(
                    (ra) => ra.competitionPlanName === competitionPlanWorldName
                );
            } else {
                return body.RefereeAssignments;
            }
        });

        const promises = [];

        refereeAssigments.forEach((item) => {
            const brigadeVariant = this.state.competition.brigadeVariant ?? BrigadeVariant.DEFAULT;

            const data = Utils.getEmptyRefereesList(brigadeVariant);

            for (let i = 0; i < data.length; i++) {
                const row = data[i];

                const referee = brigadesList.rows.find(
                    (referee) =>
                        referee.number === item.brigadeNumber &&
                        referee.refereeRoleId === row.refereeRoleId &&
                        referee.refereeNumber === row.refereeNumber
                );

                data[i].refereeId = referee?.refereeId ?? null;
            }

            promises.push(performanceApiService.assignReferees(item.performanceId, data));
        });

        Promise.all(promises);
    };

    assignReferees = async (_, competitionPlanWorldName) => {
        this.setState({ isAssigmentLoading: true });
        await this.fillPerformanceTable();
        await this.updateRefereeAssignmentList(competitionPlanWorldName);
        this.setState({ isAssigmentLoading: false });
    };

    render() {
        const { t, competitionId } = this.props;
        let currentScheduleDate = "";
        let currentScheduleTime = "";
        const isControlBtnDisabled =
            !this.state.schedules.length ||
            this.state.isDeleteLoading ||
            this.state.isAssigmentLoading;
        return (
            <Grid container justifyContent="space-between">
                <Grid item xs={12} md={8} xl={9} id="schedules-container" spacing={2}>
                    <Grid container justifyContent="space-between">
                        <Button
                            onClick={this.deleteSchedules}
                            variant="contained"
                            className="delete-btn"
                            startIcon={<DeleteForeverIcon />}
                            disabled={isControlBtnDisabled}
                        >
                            {t("deleteAllEntries")}
                        </Button>
                        <Button
                            competitionId={competitionId}
                            variant="outlined"
                            color="primary"
                            onClick={this.assignReferees}
                            disabled={isControlBtnDisabled}
                        >
                            Назначить судей
                        </Button>
                    </Grid>

                    <Grid item xs>
                        {this.state.schedules.map((item, index) => {
                            item.date = moment(item.dateTime).format("DD.MM.YYYY");
                            item.time = moment(item.dateTime).format("HH:mm:ss");

                            if (currentScheduleDate !== item.date) {
                                currentScheduleTime = "";
                                currentScheduleDate = item.date;
                                return (
                                    <div key={index}>
                                        <div className="schedule-item-head">
                                            <Typography>
                                                {t("dateOf")}:{" "}
                                                <strong>{currentScheduleDate}</strong>
                                            </Typography>
                                        </div>
                                        <ScheduleItem
                                            tileData={item}
                                            key={index}
                                            callback={this.refreshSchedules}
                                        />
                                    </div>
                                );
                            }

                            if (
                                currentScheduleTime !== "" &&
                                currentScheduleDate === item.date &&
                                this.isTechnicalTimeout(currentScheduleTime, item.time)
                            ) {
                                item.time = moment(item.dateTime).format("HH:mm:ss");
                                currentScheduleTime = item.time;

                                return (
                                    <div key={index}>
                                        <ScheduleItem
                                            tileData={itemTechnicalTimeout}
                                            key={index + 10000000}
                                        />
                                        <ScheduleItem
                                            tileData={item}
                                            key={index}
                                            callback={this.refreshSchedules}
                                        />
                                    </div>
                                );
                            }

                            currentScheduleTime = item.time;
                            return (
                                <div key={index}>
                                    <ScheduleItem
                                        tileData={item}
                                        key={index}
                                        callback={this.refreshSchedules}
                                    />
                                </div>
                            );
                        })}
                    </Grid>
                    {!this.state.modalIsOpen ? null : (
                        <ScheduleCreatePopup
                            data={this.state.selectedSchedule}
                            modalIsOpen={this.state.modalIsOpen}
                            competitionStages={this.state.competitionStages}
                            competitionDates={this.state.competitionDates}
                            onSubmit={this.processForm}
                            onClose={this.closeModal}
                        ></ScheduleCreatePopup>
                    )}
                </Grid>

                <Grid item xs={12} md={4} xl={3} id="planes-container">
                    <Grid container item xs className="planes" align="center" direction="column">
                        <Typography variant="h6"> {t("competitionPlan")}:</Typography>
                        <Paper elevation={3} className="plans-btn-list">
                            {this.state.competitionPlans.map((planItem, index) => {
                                const planSchedules = this.state.schedules.filter(
                                    (el) => el.competitionPlanId === planItem.competitionPlanId
                                );

                                const requests = this.state.requests.filter(
                                    (el) => el.competitionPlanId === planItem.competitionPlanId
                                );

                                return (
                                    <div key={index}>
                                        <Button
                                            className="schedule-create-button"
                                            onClick={this.handleCategoryBtnClick}
                                            id={planItem.competitionPlanId}
                                            key={index}
                                            style={{
                                                backgroundColor: Utils.getCategoryColor(
                                                    planItem.competitionPlanWorldName
                                                ),
                                            }}
                                            disabled={planSchedules.length === requests.length}
                                        >
                                            {planItem.competitionPlanWorldName}
                                        </Button>
                                    </div>
                                );
                            })}
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

SchedulesList.propTypes = {
    history: PropTypes.object,
    competitionId: PropTypes.number,
};

export default withRouter(withTranslation("adminPage")(SchedulesList));
