import React from "react";
import PropTypes from "prop-types";
import {
    Typography,
    Grid,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Popover,
    MenuItem,
    Button,
    Paper,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DataTable from "../Common/DataTable";
import { withRouter } from "react-router-dom";
import "./PerformanceSearchResultsGrid.css";
import moment from "moment";
import { withTranslation } from "react-i18next";

class PerformanceSearchResultsGrid extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            popoverOpen: false,
            anchorEl: null,
        };

        this.renderCategoryResults = this.renderCategoryResults.bind(this);
        this.renderAthletesResults = this.renderAthletesResults.bind(this);
    }

    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget,
            popoverOpen: true,
        });
    };

    onClosePopover = () => {
        this.setState({
            popoverOpen: false,
            anchorEl: null,
        });
    };

    renderCategoryResults(categories) {
        const { t } = this.props;
        return (
            <Grid item xs={12}>
                {categories.map((item, index) => {
                    return (
                        <Grid item value="1" key={item.planName} className="category-panel">
                            <Paper elevation={0} className="category-panel-header">
                                <Typography variant="h6">{item.planName}</Typography>
                                <Button
                                    onClick={this.handleClick}
                                    key={1}
                                    id={`1`}
                                    className="category-protocol-button"
                                >
                                    {t("openProtocol")}
                                </Button>
                                <Popover
                                    open={this.state.popoverOpen}
                                    onClose={this.onClosePopover}
                                    anchorEl={this.state.anchorEl}
                                    elevation={1}
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "center",
                                    }}
                                    transformOrigin={{
                                        vertical: "top",
                                        horizontal: "center",
                                    }}
                                >
                                    {item.qCompetitionResultDetailId && (
                                        <MenuItem
                                            onClick={(e) => {
                                                this.props.history.push(
                                                    "/CategoryResultsReport/0/0/0/" +
                                                        item.qCompetitionResultDetailId
                                                );
                                            }}
                                            value="1"
                                            key={item.qCompetitionResultDetailId}
                                        >
                                            {t("qualification")}
                                        </MenuItem>
                                    )}
                                    {item.fCompetitionResultDetailId && (
                                        <MenuItem
                                            onClick={(e) => {
                                                this.props.history.push(
                                                    "/CategoryResultsReport/0/0/0/" +
                                                        item.fCompetitionResultDetailId
                                                );
                                            }}
                                            value="2"
                                            key={item.fCompetitionResultDetailId}
                                        >
                                            {t("final")}
                                        </MenuItem>
                                    )}
                                </Popover>
                            </Paper>
                            {this.renderAthletesResults(item.athletes)}
                        </Grid>
                    );
                })}
            </Grid>
        );
    }

    renderAthletesResults(athletes) {
        const { t } = this.props;
        athletes.sort(function (a, b) {
            return (
                a.qCompetitionResultDataId - b.qCompetitionResultDataId ||
                a.fCompetitionResultDataId - b.fCompetitionResultDataId
            );
        });
        athletes.map(function (item) {
            item.qPointsDiv = item.qPoints && <div>{Number(item.qPoints).toFixed(3)}</div>;
            item.fPointsDiv = item.fPoints && <div>{Number(item.fPoints).toFixed(3)}</div>;
            item.qPlaceDiv = item.qPlace && <div>{item.qPlace}</div>;
            item.fPlaceDiv = item.fPlace && <div>{item.fPlace}</div>;
            item.athleteNameWithCity =
                item.athleteName + (item.athleteCity ? " (" + item.athleteCity + ")" : "");
            item.uniqueFieldOfItem =
                item.athleteName + item.qCompetitionResultDataId + item.fCompetitionResultDataId;
            return item;
        });
        const columns = [
            {
                title: t("participant"),
                fieldName: "athleteNameWithCity",
                width: "60%",
            },
            { title: t("qualificationPoints"), fieldName: "qPointsDiv" },
            { title: t("qualificationPlace"), fieldName: "qPlaceDiv" },
            { title: t("finalPoints"), fieldName: "fPointsDiv" },
            { title: t("finalPlace"), fieldName: "fPlaceDiv" },
        ];
        return (
            <DataTable columns={columns} data={athletes} uniqueFieldOfItem="uniqueFieldOfItem" />
        );
    }

    render() {
        const { t } = this.props;
        return (
            <Grid container>
                {this.props.showSearchResultsHeader && (
                    <Grid container>
                        {this.props.searchResults && this.props.searchResults.length > 0 ? (
                            <Typography variant="h6">
                                {t("searchResults")} &quot;
                                {this.props.searchName}
                                &quot;
                            </Typography>
                        ) : (
                            <Typography variant="h6">
                                {t("byRequest")} &quot;{this.props.searchName}
                                &quot; {t("nothingFound")}
                            </Typography>
                        )}
                    </Grid>
                )}
                <Grid item xs={12} className="perf-search-results">
                    {this.props.searchResults &&
                        this.props.searchResults.map((item, index) => {
                            return (
                                <ExpansionPanel defaultExpanded={false} key={item.competition}>
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        {item.competition +
                                            " " +
                                            item.competitionCity +
                                            ", " +
                                            moment(item.competitionDateFrom).format("DD.MM.YYYY")}
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        {this.renderCategoryResults(item.categories)}
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            );
                        })}
                </Grid>
            </Grid>
        );
    }
}

PerformanceSearchResultsGrid.propTypes = {
    searchName: PropTypes.string,
    searchResults: PropTypes.array,
    showSearchResultsHeader: PropTypes.bool,
    history: PropTypes.object,
};

export default withRouter(withTranslation()(PerformanceSearchResultsGrid));
