import React from "react";
import "./ProtocolExecution.css";
import "../Protocol.css";
import CriteriaManager from "../../../../../Modules/CriteriaManager";
import { REFEREE_ROLES } from "../../../../../Modules/Enums";
import Utils from "../../../../../Modules/Utils";
import NumberInput from "../../../../Common/InputControls/NumberInput";
import CriterionTypesManager from "../../../../../Modules/CriterionTypesManager";
import SaveCancelPanel from "../../../Protocols/SaveCancelPanel";
import { PerformanceReferee } from "../../../../../interfaces/performance-referee";

interface Props {
    performanceReferee: PerformanceReferee;
    onSave: (scores) => Promise<void>;
    onClose: () => void;
}

interface Score {
    performanceRefereeId: number;
    criterionId: number;
    score: {};
    penalty: {};
}

class ProtocolExecution extends React.Component<Props, any> {
    constructor(props) {
        super(props);

        this.state = {
            criterionGroups: null,
        };
    }

    componentDidMount() {
        this.loadCriteria();
    }

    loadCriteria() {
        CriteriaManager.getCriteriaAndGroups(REFEREE_ROLES.executionJudge.id)
            .then((response) => {
                const criterionGroups = response.body.criterionGroups;
                const criterionGroupsWithScores = criterionGroups.map((group) => {
                    group.score = 0;
                    group.criteria = group.criteria.map((criterion) => {
                        criterion.score = 0;
                        return criterion;
                    });
                    return group;
                });
                this.setState({
                    criterionGroups: criterionGroupsWithScores,
                });
            })
            .then(() => {
                this.loadCriteriaScores();
            });
    }

    loadCriteriaScores() {
        CriteriaManager.loadScores(this.props.performanceReferee.id).then((response) => {
            const scores = response.body;
            const criterionGroups = this.state.criterionGroups;
            criterionGroups.forEach((group) => {
                group.criteria.forEach((criterion) => {
                    if (scores[criterion.criterionId] !== undefined) {
                        criterion.score = scores[criterion.criterionId].score;
                        criterion.penalty = scores[criterion.criterionId].penalty;
                    } else {
                        criterion.score = null;
                        criterion.penalty = null;
                    }

                    return criterion;
                });
                return group;
            });
            this.setState({
                criterionGroups: criterionGroups,
                scoresLoaded: true,
            });
        });
    }

    onChange(event, groupIdx, criterionIdx) {
        const criterionGroups = this.state.criterionGroups;
        const criterion = criterionGroups[groupIdx].criteria[criterionIdx];
        const newValue = +event.target.value;
        if (Utils.isValueInRange(newValue, criterion.minValue, criterion.maxValue)) {
            if (criterion.criterionTypeId === CriterionTypesManager.CRITERION_TYPES.penalty.id) {
                criterion.penalty = newValue;
            } else {
                criterion.score = newValue;
            }

            this.setState({
                criterionGroups: criterionGroups,
            });
        }
    }

    getCriteria(criteriaArray, groupIdx) {
        return criteriaArray.map((criterion, idx) => {
            const defValue =
                criterion.criterionTypeId === CriterionTypesManager.CRITERION_TYPES.penalty.id
                    ? criterion.penalty
                    : criterion.score;

            return (
                <div key={idx} className="protocol-execution-body">
                    <h6 className="title">{`${criterion.criterionName} / ${criterion.criterionNameEn}`}</h6>
                    <NumberInput
                        onChange={(value) => this.onChange(value, groupIdx, idx)}
                        accuracy={1}
                        defaultValue={defValue}
                        minValue={criterion.minValue}
                        maxValue={criterion.maxValue}
                    />
                </div>
            );
        });
    }

    getTotal() {
        if (!this.state.criterionGroups) {
            return 0;
        }

        let totalScore = 0;
        for (const group of this.state.criterionGroups) {
            let criteriaScore = 0;
            for (const criterion of group.criteria) {
                criteriaScore += criterion.score;
            }
            group.score = criteriaScore;
            totalScore += group.score;
        }
        return totalScore;
    }

    getScoresReqObject() {
        const scoresReqObject: Score[] = [];

        this.state.criterionGroups.forEach((group) => {
            group.criteria.forEach((criterion) => {
                scoresReqObject.push({
                    performanceRefereeId: this.props.performanceReferee.id,
                    criterionId: criterion.criterionId,
                    score: criterion.score,
                    penalty: criterion.penalty,
                });
            });
        });
        return scoresReqObject;
    }

    onSave = async () => {
        const scores = this.getScoresReqObject();
        await this.props.onSave(scores);
    };

    getGroups() {
        if (!this.state.criterionGroups || !this.state.scoresLoaded) {
            return null;
        }
        return this.state.criterionGroups.map((group, idx, array) => {
            if (array.length === 1) {
                return this.getCriteria(group.criteria, idx);
            }
            return (
                <div key={group.criterionGroupId} className="protocol-execution-body">
                    <h6 className="title">{`${group.criterionGroupName} / ${group.criterionGroupNameEn}`}</h6>
                    {this.getCriteria(group.criteria, idx)}
                </div>
            );
        });
    }

    render() {
        return (
            <div id="penalties-panel">
                <div id="protocol-execution-container">
                    {this.getGroups()}
                    <SaveCancelPanel onSave={this.onSave} onCancel={this.props.onClose} />
                </div>
            </div>
        );
    }
}

export default ProtocolExecution;
