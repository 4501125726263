/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import AdminPage from "./Pages/AdminPage";
import HomePage from "./Pages/HomePage";
import LoginPage from "./Pages/LoginPage";
import CompetitionsListPage from "./Pages/CompetitionsListPage";
import RefereePage from "./Pages/RefereePage";
import ArchivePage from "./Pages/ArchivePage";
import CompetitionManagerPage from "./Pages/CompetitionManagerPage";
import PublicDisplayPage from "./Pages/PublicDisplayPage";
import TrainerPage from "./Pages/TrainerPage";
import OnlineResultsPage from "./Pages/OnlineResultsPage";
import RegisterPage from "./Pages/RegisterPage";
import BeamerPage from "./Pages/BeamerPage";
import SuccessfulPage from "./Pages/SuccessfulPage";
import { create } from "jss";
import { StylesProvider, jssPreset, ThemeProvider } from "@material-ui/core/styles";
import { SnackbarProvider } from "notistack";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { getTheme } from "./theme";
import BeamerActivityPage from "./Pages/BeamerActivityPage";
import Layout from "./Controls/Common/Layout";
import useAuth from "./Hooks/useAuth";
import BeamerSettings from "./Controls/Beamers/beamer-settings";
import RefereeActivityPage from "./Pages/RefereeActivityPage";
import { SelectLanguageModal } from "./Controls/Common/modals/SelectLanguageModal";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { LocalesEnum } from "./Modules/enums";
import EventsPage from "./Pages/EventsPage";
import { Paths } from "./Modules/Constants";
import EventDetailPage from "./Pages/EventDetailPage";
import RefereeLotteryPage from "./Pages/RefereeLotteryPage";

import "moment/locale/ru";

const jss = create({
    ...jssPreset(),
    // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
    insertionPoint: document.getElementById("jss-insertion-point")!,
});

const App = (): JSX.Element => {
    const { i18n } = useTranslation();

    const { user, getUrlForHomePage } = useAuth();

    const [isLanguageSelected, setIsLanguageSelected] = useState<boolean>(false);

    const handleLanguageSelecting = () => {
        setIsLanguageSelected(true);
    };

    moment.locale(i18n.language);

    return (
        <MuiPickersUtilsProvider utils={MomentUtils} locale={i18n.language}>
            <ThemeProvider theme={getTheme(i18n.language as LocalesEnum)}>
                <StylesProvider jss={jss}>
                    <SnackbarProvider
                        maxSnack={5}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <SelectLanguageModal handleLanguageSelecting={handleLanguageSelecting} />

                        <Layout>
                            {isLanguageSelected && (
                                <Switch>
                                    <Route
                                        path="/display/:competitionId"
                                        component={PublicDisplayPage}
                                    />
                                    <Route path="/login/:token?" component={LoginPage} />
                                    <Route exact path="/register" component={RegisterPage} />
                                    <Route path="/register/successful" component={SuccessfulPage} />
                                    <Route path="/admin" component={AdminPage} />
                                    <Route path="/online-results" component={OnlineResultsPage} />
                                    <Route
                                        path="/competitionsList"
                                        component={CompetitionsListPage}
                                    />
                                    <Route
                                        path="/manager"
                                        render={() => <CompetitionManagerPage authUser={user} />}
                                    />
                                    <Route path="/Archive" component={ArchivePage} />
                                    <Route path="/referee" exact component={RefereePage} />
                                    <Route
                                        path="/referee/:competitionId"
                                        exact
                                        component={RefereeActivityPage}
                                    />
                                    <Route path="/trainer" component={TrainerPage} />
                                    {/** *@todo rewrite routes for beamer */}
                                    <Route path="/beamer" exact component={BeamerPage} />
                                    <Route
                                        path="/beamer/:competitionId"
                                        exact
                                        component={BeamerActivityPage}
                                    />
                                    <Route
                                        path="/beamer/settings/:competitionId"
                                        exact
                                        component={BeamerSettings}
                                    />
                                    <Route path="/home" exact component={HomePage} />
                                    <Route path={Paths.EVENTS} exact component={EventsPage} />
                                    <Route
                                        path={Paths.EVENT_DETAIL}
                                        exact
                                        component={EventDetailPage}
                                    />
                                    <Route
                                        path={Paths.REFEREE_LOTTERY}
                                        exact
                                        component={RefereeLotteryPage}
                                    />
                                    <Route path="*">
                                        <Redirect to={getUrlForHomePage()} />
                                    </Route>
                                </Switch>
                            )}
                        </Layout>
                        <NotificationContainer />
                    </SnackbarProvider>
                </StylesProvider>
            </ThemeProvider>
        </MuiPickersUtilsProvider>
    );
};

export default App;
