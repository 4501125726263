import React from "react";
import PropTypes from "prop-types";
import PerformancesAgeCategoryGroup from "./PerformancesAgeCategoryGroup";
import { ExpansionPanel, ExpansionPanelSummary } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

class PerformancesCategoryGroup extends React.Component {
    render() {
        return (
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    {this.props.header}
                </ExpansionPanelSummary>
                {Object.keys(this.props.category).map((ageCategory) => {
                    return (
                        <PerformancesAgeCategoryGroup
                            key={ageCategory}
                            ageCategory={this.props.category[ageCategory]}
                        />
                    );
                })}
            </ExpansionPanel>
        );
    }
}

PerformancesCategoryGroup.propTypes = {
    category: PropTypes.object,
    header: PropTypes.string,
};

export default PerformancesCategoryGroup;
