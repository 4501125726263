import moment from "moment";
import { BrigadeVariant } from "../interfaces/competition";
import Enums, { REFEREE_ROLES } from "./Enums";

class Utils {
    static isValueInRange(value, min, max) {
        let res = true;
        if (min !== undefined) {
            res &= value >= min;
        }
        if (max !== undefined) {
            res &= value <= max;
        }
        return res;
    }

    static getStageColor(stageName) {
        if (!stageName.indexOf) {
            return "";
        }
        if (stageName.indexOf("инал") >= 0) {
            return "darkgoldenrod";
        }
        if (stageName.indexOf("алиф") >= 0) {
            return "lightsteelblue";
        }
        return "";
    }

    static getCategoryColor(category) {
        if (category.indexOf("IW") >= 0) {
            return "deepskyblue";
        }
        if (category.indexOf("IM") >= 0) {
            return "darkviolet";
        }
        if (category.indexOf("TR") >= 0) {
            return "darkslateblue";
        }
        if (category.indexOf("GR") >= 0) {
            return "forestgreen";
        }
        if (category.indexOf("MP") >= 0) {
            return "lightcoral";
        }
        if (category.indexOf("AS") >= 0) {
            return "maroon";
        }
        if (category.indexOf("AD") >= 0) {
            return "goldenrod";
        }
        return "red";
    }

    static convertSecToMin(duration) {
        let result = "";
        let minutes = Math.floor(duration / 60);
        if (minutes > 0) {
            result = result + minutes + " мин.";
        }

        if (duration % 60 > 0) {
            result = result + " " + (duration % 60) + "cек.";
        }
        return result;
    }

    /** @deprecated */
    static getRefereeRoleByRefereeNumber(refereeNumber, brigadeVariant = BrigadeVariant.DEFAULT) {
        if (brigadeVariant === BrigadeVariant.DEFAULT) {
            if ([1, 2, 3, 4].includes(refereeNumber)) return Enums.REFEREE_ROLES.executionJudge.id;
            if ([5, 6, 7, 8].includes(refereeNumber)) return Enums.REFEREE_ROLES.artistryJudge.id;
            if ([9, 10].includes(refereeNumber)) return Enums.REFEREE_ROLES.complexityJudge.id;
            if ([11].includes(refereeNumber)) return Enums.REFEREE_ROLES.lineReferee.id;
            if ([12].includes(refereeNumber)) return Enums.REFEREE_ROLES.referee.id;
            if ([13].includes(refereeNumber)) return Enums.REFEREE_ROLES.general.id;
            return -1;
        } else {
            if ([1, 2, 3, 4, 5, 6].includes(refereeNumber))
                return Enums.REFEREE_ROLES.executionJudge.id;
            if ([7, 8, 9, 10, 11, 12].includes(refereeNumber))
                return Enums.REFEREE_ROLES.artistryJudge.id;
            if ([13, 14].includes(refereeNumber)) return Enums.REFEREE_ROLES.complexityJudge.id;
            if ([15].includes(refereeNumber)) return Enums.REFEREE_ROLES.lineReferee.id;
            if ([16].includes(refereeNumber)) return Enums.REFEREE_ROLES.referee.id;
            if ([17].includes(refereeNumber)) return Enums.REFEREE_ROLES.general.id;
            return -1;
        }
    }

    static getEmptyRefereesList(brigadeVariant = BrigadeVariant.DEFAULT) {
        const exeReferees = [...Array(Number(brigadeVariant)).keys()].map((_, i) => ({
            refereeId: null,
            refereeRoleId: REFEREE_ROLES.executionJudge.id,
            refereeNumber: i + 1,
        }));

        const artReferees = [...Array(Number(brigadeVariant)).keys()].map((_, i) => ({
            refereeId: null,
            refereeRoleId: REFEREE_ROLES.artistryJudge.id,
            refereeNumber: i + 1,
        }));

        const comReferees = [...Array(2).keys()].map((_, i) => ({
            refereeId: null,
            refereeRoleId: REFEREE_ROLES.complexityJudge.id,
            refereeNumber: i + 1,
        }));

        return [
            ...exeReferees,
            ...artReferees,
            ...comReferees,
            {
                refereeId: null,
                refereeRoleId: REFEREE_ROLES.lineReferee.id,
                refereeNumber: 1,
            },
            {
                refereeId: null,
                refereeRoleId: REFEREE_ROLES.referee.id,
                refereeNumber: 1,
            },
            {
                refereeId: null,
                refereeRoleId: REFEREE_ROLES.general.id,
                refereeNumber: 1,
            },
        ];
    }

    static getLicenseStatus(license) {
        if (!license.assigmentDate) return "not-assigment";

        if (license.assigmentDate && license.expirationDate) {
            if (moment().isAfter(license.expirationDate)) return "expired";

            if (moment().isBefore(license.expirationDate)) {
                if (moment(moment()).isAfter(moment(license.expirationDate).subtract(2, "month")))
                    return "expires";
                return "active";
            }
        }
    }

    static compose = (...rest) => (x) => rest.reduceRight((y, f) => f(y), x);

    static getFormattedDates = (dateFrom, dateTo) => {
        if (!dateFrom && !dateTo) return "";

        return moment(dateFrom).isSame(dateTo)
            ? moment(dateFrom).format("Do MMMM YYYY")
            : `${moment(dateFrom).format("D MMM")} - ${moment(dateTo).format("D MMM YYYY")}`;
    };

    static swapArray(arr) {
        arr.forEach((_, i) => {
            const a = arr[i];
            const b = arr[i + 1];

            if ((i + 1) % 2 == 0 && arr[i + 1]) {
                arr[i + 1] = a;
                arr[i] = b;
            }
        });

        return arr;
    }

    static sliceToChunks(arr, size) {
        const myArray = [];
        for (let i = 0; i < arr.length; i += size) {
            myArray.push(arr.slice(i, i + size));
        }
        return myArray;
    }
}
export default Utils;
