class CriterionTypesManager {
    static CRITERION_TYPES = {
        score: { id: 1, name: "score" },
        penalty: { id: 2, name: "penalty" },
        disqualification: { id: 3, name: "disqualification" },
        warning: { id: 4, name: "warning" },
        scoreIsZero: { id: 5, name: "scoreIsZero" },
    };
}

export default CriterionTypesManager;
