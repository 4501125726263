import React from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ConfirmDialog = (props) => {
    const { t } = useTranslation("common");

    const handleCancel = async () => {
        await props.onClose();
    };

    const confirmHandle = async () => {
        await props.onConfirm();
        handleCancel();
    };

    return (
        <React.Fragment>
            <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="md"
                open={props.open || true}
            >
                <DialogTitle disableTypography>{props.title}</DialogTitle>
                <DialogContent>{props.content}</DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleCancel}>
                        {t("cancel")}
                    </Button>
                    <Button color="primary" onClick={confirmHandle}>
                        {t("confirm")}
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

ConfirmDialog.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool,
};

export default ConfirmDialog;
