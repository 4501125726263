import Http, { HttpOptions, HttpResponse } from "@/api/Http";
import { AxiosRequestConfig } from "axios";
import { PaginatedQueryResult, QueryParams, QueryResult } from "../types";

interface IApiService<TModel> {
    url: string;
    http: Http;
    getAll(params: QueryParams<TModel>): Promise<PaginatedQueryResult<TModel>>;
    getOne(id: number): Promise<QueryResult<TModel>>;
    create(payload: Partial<TModel>): Promise<QueryResult<TModel>>;
    createBulk(payload: Partial<TModel[]>): Promise<QueryResult<TModel[]>>;
    update(id: number, payload: Partial<TModel>): Promise<QueryResult<TModel>>;
    delete(id: number): Promise<QueryResult<TModel>>;
}

interface IApiServiceHelper {
    download(response: HttpResponse, fileName: string): void;
}

abstract class BaseApiService<TModel> implements IApiService<TModel>, IApiServiceHelper {
    url: string;
    http: Http;

    constructor(config: HttpOptions) {
        this.url = "";
        this.http = Http(config);
    }

    getAll = async (params: QueryParams<TModel> = {}): Promise<PaginatedQueryResult<TModel>> => {
        const { data } = await this.http.get(this.url, { params });
        return data;
    };

    getOne = async (id: number): Promise<QueryResult<TModel>> => {
        const { data } = await this.http.get(`${this.url}/${id}`);
        return data;
    };

    create = async <TType = Partial<TModel>>(payload: TType): Promise<QueryResult<TModel>> => {
        const { data } = await this.http.post(this.url, payload);
        return data;
    };

    createBulk = async <TType = Partial<TModel>>(
        payload: TType[]
    ): Promise<QueryResult<TModel[]>> => {
        const { data } = await this.http.post(this.url, payload);
        return data;
    };

    update = async <TType = Partial<TModel>>(
        id: number,
        payload: TType | FormData,
        config?: AxiosRequestConfig
    ): Promise<QueryResult<TModel>> => {
        const { data } = await this.http.patch(`${this.url}/${id}`, payload, config);
        return data;
    };

    delete = async (id: number): Promise<QueryResult<TModel>> => {
        const { data } = await this.http.delete(`${this.url}/${id}`);
        return data;
    };

    download = (response: HttpResponse, fileName: string) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
    };

    public getFileNameFromHeaders = (response: HttpResponse): string => {
        const contentDisposition = response.headers["content-disposition"];
        const match = contentDisposition.match(/filename\s*=\s*"(.+)"/i);
        return match[1];
    };
}

export default BaseApiService;
