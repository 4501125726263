import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Helpers from "../../../../Modules/Helpers";
import moment from "moment";
import RequestManager from "../../../../Modules/RequestManager";
import i18n from "i18next";
import i18next from "i18next";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

function getTableColumns() {
    const columns = ["#", "category", "coachName", "fullName", "fee"];

    const tableColumns = columns.map((item) => {
        return [
            {
                text: item === "#" ? item : i18n.t(`adminPage:${item}`),
                bold: true,
            },
        ];
    });

    return tableColumns;
}

function generateTableBody(requests) {
    const authors = [...new Set(requests.map((req) => req.author))];

    const tableColumns = getTableColumns();
    let tableBody = [];
    let countEntryFee = 0;

    tableBody.push(tableColumns);

    authors.forEach((author) => {
        let totalTrainerEntryFee = 0;

        requests.forEach((request) => {
            if (request.author !== author) return;

            let dataRow = [];

            dataRow.push(tableBody.length);
            dataRow.push(`${request.plan.category.worldCode} (${request.plan.ageCategory.name})`);

            dataRow.push(request.author);

            let details = [];
            request.details.forEach((detail) => {
                details.push(Helpers.getFullNameForAthlete(detail.athlete));
            });
            dataRow.push(details);

            dataRow.push(request.plan.entryFee);
            countEntryFee += request.plan.entryFee;
            totalTrainerEntryFee += request.plan.entryFee;

            tableBody.push(dataRow);
        });

        tableBody.push([
            {},
            {},
            {},
            {
                text: `${author} - ${i18n.t("adminPage:total")} (${i18n.t("adminPage:rub")}.):`,
                style: "total",
            },
            { text: `${totalTrainerEntryFee}`, bold: true },
        ]);
    });

    tableBody.push([
        {},
        {},
        {},
        {
            text: `${i18n.t("adminPage:totalAmount")} (${i18n.t("adminPage:rub")}.):`,
            style: "total",
        },
        { text: `${countEntryFee}`, bold: true },
    ]);

    return tableBody;
}

function generatePdf({ competition: competition, requests: requests }) {
    moment.locale(i18next.language);
    let docDefinition = {
        content: [
            {
                text: i18n.t("adminPage:detailsOfEntyFees"),
                style: "header",
            },
            {
                text: i18n.t("adminPage:competition"),
                style: "subtitle",
            },
            {
                text: competition.name,
                style: "subheader",
            },
            {
                text: i18n.t("adminPage:dateOf"),
                style: "subtitle",
            },
            {
                text: moment(competition.dateFrom).isSame(competition.dateTo)
                    ? `${moment(competition.dateFrom).format("Do MMMM YYYY")}`
                    : `${moment(competition.dateFrom).format("D MMM")} - ${moment(
                          competition.dateTo
                      ).format("D MMM YYYY")}`,
                style: "subheader",
            },
            {
                layout: "lightHorizontalLines",
                table: {
                    headerRows: 1,
                    widths: ["auto", "auto", "auto", "*", "auto"],

                    body: generateTableBody(requests),
                },
            },
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
            },
            subtitle: {
                fontSize: 10,
                color: "#505050",
            },
            subheader: {
                fontSize: 14,
                bold: true,
                margin: [0, 2, 0, 10],
            },
            total: {
                alignment: "right",
                bold: true,
            },
        },
    };

    return docDefinition;
}

async function getRequests({ competitionId: id }) {
    const result = await RequestManager.fetch(`v2/requests/${id}`, "get");
    return result.body.data;
}

async function downloadPdf({ competitionId: id }) {
    const data = await getRequests({ competitionId: id });

    const requests = [].concat(
        ...data.competition.plans.map((plan) => {
            return plan.requests;
        })
    );
    data.competition.plans = undefined;

    const docDefinition = generatePdf({
        competition: data.competition,
        requests: requests,
    });
    pdfMake.createPdf(docDefinition).download(i18n.t("adminPage:detailsOfEntyFees"));
}

export default downloadPdf;
