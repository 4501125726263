import { useState } from "react";

type LoadingStatus = {
    idle: "idle";
    loading: "loading";
    loaded: "loaded";
    failed: "failed";
};

const useLoadingStatus = (initialStatus: keyof LoadingStatus = "loading") => {
    const [loadingStatus, setLoadingStatus] = useState<keyof LoadingStatus>(initialStatus);
    const [errors, setErrors] = useState<string[]>([]);

    return { loadingStatus, setLoadingStatus, errors, setErrors };
};

export default useLoadingStatus;
