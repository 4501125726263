import React, { useState, useEffect, FunctionComponent } from "react";
import MuiDataTable from "../../Common/MuiDataTable";
import { CompetitionRequestDetails } from "../../../interfaces/competition-request-details";
import Helpers from "../../../Modules/Helpers";
import { GENDERS } from "../../../Modules/Enums";
import { EVENTS } from "../../../Modules/OnlineResults/events";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { useStoreon } from "storeon/react";
import {
    OnlineResultsModuleEvents,
    OnlineResultsModuleState,
} from "../../../store/StoreModules/OnlineResults";

const Online: FunctionComponent = () => {
    const { t } = useTranslation("common");
    const [isLoading, setLoadingStatus] = useState(true);

    const { socket, onlinePlans, onlinePerformances, dispatch } = useStoreon<
        OnlineResultsModuleState,
        OnlineResultsModuleEvents
    >("socket", "onlinePlans", "onlinePerformances");

    useEffect(() => {
        return () => dispatch("online/set/online_performances", []);
    }, []);

    useEffect((): void => {
        if (!onlinePlans.length) return;

        socket?.emit(
            EVENTS.USER_REQUEST_ONLINE_PLAN_PERFORMANCES,
            onlinePlans[0] ? onlinePlans[0].id : null,
            onlinePlans[1] ? onlinePlans[1].id : null
        );
    }, [onlinePlans]);

    useEffect((): void => {
        if (onlinePerformances.length) setLoadingStatus(false);
    }, [onlinePerformances]);

    const columns = [
        {
            title: t("fullName"),
            field: "athleteFullName",
            sorting: false,
            render: (rowData) =>
                rowData.competitionRequest.details.map((detail: CompetitionRequestDetails) => {
                    return <div key={detail.id}>{Helpers.getNameForAthlete(detail.athlete)}</div>;
                }),
        },
        {
            title: t("execution"),
            field: "averageExecution",
            sorting: false,
            render: (rowData) => rowData.otherScores.averageExecution ?? "-",
        },
        {
            title: t("artistry"),
            field: "averageArtistry",
            sorting: false,
            render: (rowData) => rowData.otherScores.averageArtistry ?? "-",
        },
        {
            title: t("complexity"),
            field: "averageComplexity",
            sorting: false,
            render: (rowData) => rowData.otherScores.averageComplexity ?? "-",
        },
        {
            title: t("penalties"),
            field: "totalPenalty",
            sorting: false,
            render: (rowData) => rowData.otherScores.totalPenalty,
        },
        {
            title: t("totalPoints"),
            field: "totalScore",
            render: (rowData) => rowData.otherScores.totalScore,
            sorting: false,
        },
        {
            title: t("place"),
            field: "place",
            sorting: false,
            render: (rowData) => rowData.place,
        },
    ];

    if (!onlinePlans.length) return <p>{t("noPerfomancesAtMoment")}</p>;

    return (
        <>
            {onlinePlans.length !== 0 && (
                <Typography variant="h5">{onlinePlans[0].competition.name}</Typography>
            )}

            <Grid container spacing={2}>
                {onlinePlans.map((plan, index) => {
                    return (
                        <Grid item key={plan.id} md={onlinePlans.length === 1 ? 12 : 6}>
                            <Typography variant="h6">
                                {plan.category?.name} (
                                {t(`modules:${GENDERS[plan.category?.genderId].name}`)},{" "}
                                {plan.ageCategory?.name})
                            </Typography>
                            <Typography variant="body1">
                                {t("brigade")}{" "}
                                {i18n.language === "ru"
                                    ? plan.brigadeNumber
                                    : plan.brigadeNumber === 1
                                    ? "A"
                                    : "B"}
                            </Typography>

                            <MuiDataTable
                                columns={columns}
                                data={onlinePerformances[index] ?? []}
                                showSearch={false}
                                showToolbar={false}
                                enableGrouping={false}
                                isLoading={isLoading}
                            />
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

export default Online;
