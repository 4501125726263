import React, { FunctionComponent, useEffect, useState } from "react";
import RequestManager from "../../../Modules/RequestManager";
import {
    Grid,
    Select,
    InputLabel,
    FormGroup,
    FormControl,
    Typography,
    MenuItem,
    Divider,
} from "@material-ui/core";
import Helpers from "../../../Modules/Helpers";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import "./RefereeBrigadeList.css";
import { competitionApiService, competitionBrigadeApiService } from "@/api/Services";
import { BrigadeVariant, Competition } from "../../../interfaces/competition";
import { REFEREE_ROLES } from "@/modules/Enums";
import { CompetitionBrigade } from "../../../interfaces/competition-brigade";
import { useSnackbar } from "notistack";
import LoadingIndicator from "../../../Controls/Common/LoadingIndicator";

interface RefereeBrigadesListProps {
    competitionId: number;
}

interface RefereeBrigadesListState {
    refereeBrigades: CompetitionBrigade[];
    referees: any[];
    competition: Competition | null;
    isLoading: boolean;
}

const RefereeBrigadesList: FunctionComponent<RefereeBrigadesListProps> = ({
    competitionId,
}: RefereeBrigadesListProps) => {
    const { t } = useTranslation("adminPage");
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState<RefereeBrigadesListState>({
        refereeBrigades: [],
        referees: [],
        isLoading: true,
        competition: null,
    });

    useEffect(() => {
        const fetch = async () => {
            await fetchCompetition();
            await fetchBrigades();
            setState((prev) => ({ ...prev, isLoading: false }));
        };

        fetch();
    }, []);

    const fetchCompetition: VoidFunction = async () => {
        const res = await competitionApiService.getOne(competitionId);
        setState((prev) => ({ ...prev, competition: res.result }));
    };

    const fetchBrigades: VoidFunction = async () => {
        const requests = [
            competitionBrigadeApiService.getAll({ competitionId }),
            RequestManager.fetch("referees", "get"),
        ];

        return Promise.all(requests)
            .then((results) => {
                const [resultRefereeBrigades, resultReferees] = results;

                setState((prev) => ({
                    ...prev,
                    refereeBrigades: resultRefereeBrigades.result.rows,
                    referees: resultReferees.body.Referees,
                }));
            })
            .catch((err) => {
                throw err;
            });
    };

    const getRefereeDDLbyRefereeNumber = (
        brigadeNumber: number,
        refereeNumber: number,
        refereeRoleId: number
    ) => {
        const { refereeBrigades, referees } = state;

        const item: CompetitionBrigade | undefined = refereeBrigades.find(
            (el) =>
                el.number === brigadeNumber &&
                el.refereeNumber === refereeNumber &&
                el.refereeRoleId === refereeRoleId
        );

        return (
            <FormControl margin="normal">
                <Select
                    fullWidth
                    displayEmpty
                    name={`${brigadeNumber}-${refereeRoleId}-${refereeNumber}`}
                    onChange={(e) =>
                        changeReferee(
                            Number(e.target.value),
                            brigadeNumber,
                            refereeNumber,
                            refereeRoleId,
                            item?.id
                        )
                    }
                    value={item?.refereeId ?? ""}
                >
                    {
                        <MenuItem value="" key={0}>
                            <small>{t("noRefereeAssigned")}</small>
                        </MenuItem>
                    }
                    {referees.map((r) => (
                        <MenuItem value={r.refereeId} key={r.refereeId}>
                            {Helpers.getFullNameForUser(r)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        );
    };

    const changeReferee = async (
        value: number,
        brigadeNumber: number,
        refereeNumber: number,
        refereeRoleId: number,
        id?: number
    ) => {
        const dto = {
            competitionId,
            number: brigadeNumber,
            refereeNumber,
            refereeRoleId,
            refereeId: Number(value) || null,
        };

        if (id) {
            await competitionBrigadeApiService.update(id, dto);
            enqueueSnackbar(t("dataSaved"), { variant: "success" });
        } else {
            await competitionBrigadeApiService.create(dto);
            enqueueSnackbar(t("dataSaved"), { variant: "success" });
        }

        await fetchBrigades();
    };

    const brigadeLocale: (brigadeNumber: number) => string = (brigadeNumber) => {
        const brigades = {
            1: "A",
            2: "B",
        };

        return brigades[brigadeNumber];
    };

    const { competition, isLoading } = state;

    if (isLoading) return <LoadingIndicator show />;

    const brigadeVariant = competition?.brigadeVariant ?? BrigadeVariant.DEFAULT;
    const categoryRefereesCount = brigadeVariant === BrigadeVariant.DEFAULT ? 4 : 6;

    return (
        <Grid container className="brigade-list" spacing={6}>
            {[1, 2].map((brigade) => {
                const brigadeName = i18n.language === "ru" ? brigade : brigadeLocale(brigade);

                return (
                    <Grid item xs={12} md={6} key={brigade}>
                        <Typography variant="h5">
                            {t("brigade")} {brigadeName}
                        </Typography>
                        <Divider />

                        <FormGroup>
                            <InputLabel>{t("perfomanceQuality")}:</InputLabel>
                            {[...new Array(categoryRefereesCount)].map((_, i) =>
                                getRefereeDDLbyRefereeNumber(
                                    brigade,
                                    i + 1,
                                    REFEREE_ROLES.executionJudge.id
                                )
                            )}
                        </FormGroup>

                        <FormGroup>
                            <InputLabel>{t("artisty")}:</InputLabel>
                            {[...new Array(categoryRefereesCount)].map((_, i) =>
                                getRefereeDDLbyRefereeNumber(
                                    brigade,
                                    i + 1,
                                    REFEREE_ROLES.artistryJudge.id
                                )
                            )}
                        </FormGroup>

                        <FormGroup>
                            <InputLabel>{t("complexity")}:</InputLabel>
                            {[...new Array(2)].map((_, i) =>
                                getRefereeDDLbyRefereeNumber(
                                    brigade,
                                    i + 1,
                                    REFEREE_ROLES.complexityJudge.id
                                )
                            )}
                        </FormGroup>

                        <FormGroup>
                            <InputLabel>{t("lineReferees")}:</InputLabel>
                            {getRefereeDDLbyRefereeNumber(brigade, 1, REFEREE_ROLES.lineReferee.id)}
                        </FormGroup>

                        <FormGroup>
                            <InputLabel>{t("arbiterOfRefereeGroup")}:</InputLabel>
                            {getRefereeDDLbyRefereeNumber(brigade, 1, REFEREE_ROLES.referee.id)}
                        </FormGroup>

                        <FormGroup>
                            <InputLabel>{t("mainReferee")}:</InputLabel>
                            {getRefereeDDLbyRefereeNumber(brigade, 1, REFEREE_ROLES.general.id)}
                        </FormGroup>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default RefereeBrigadesList;
