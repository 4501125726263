import React from "react";
import { Container, Typography, Link } from "@material-ui/core";
import Auth from "../Modules/Auth";
import { useTranslation } from "react-i18next";
import "./SuccessfulPage.scss";

const SuccessfulPage = (props: any) => {
    const user = Auth.getAuthUser();
    if (user) {
        props.history.push("/");
    }
    const { t } = useTranslation("pages");

    return (
        <Container className="successful-register-page">
            <Typography variant="h4">{t("registrationSuccess")}</Typography>
            <Typography variant="body1">
                {t("nowYouCanEnter")}
                <Link href="/login">{t("personalAccount")}</Link>
            </Typography>
        </Container>
    );
};

export default SuccessfulPage;
