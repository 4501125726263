import RequestManager from "./RequestManager";
import Auth from "../Modules/Auth";

class CompetitionsManager {
    static replaceDatesStrToObject(competitions) {
        if (competitions) {
            competitions.map((com) => {
                com.dateFrom = new Date(com.dateFrom);
                com.dateTo = new Date(com.dateTo);
                return com;
            });
        }
        return competitions;
    }
    static deleteCompetition(id, type = "hard") {
        return RequestManager.fetch(
            `competitions/${id}?type=${type}`,
            "delete",
            undefined,
            false,
            2
        ).then((response) => {
            return response;
        });
    }

    /**
     * @deprecated need to be rewritten for using RequestManager.getAllCompetitions method
     */
    static findAllCompetitions() {
        return RequestManager.fetch("competitions", "get").then((response) => {
            return response.body.Competitions;
        });
    }

    static competitionsForManager(user) {
        return RequestManager.fetch("competitionsForManager", "get").then((response) => {
            return response.body.Competitions;
        });
    }

    static editCompetition(competitionData) {
        return RequestManager.fetch("competitionEdit", "POST", competitionData, false);
    }

    static getReferees() {
        return RequestManager.fetch("referees", "get").then((response) => {
            return response.body;
        });
    }

    static getCompetitionStatuses() {
        return RequestManager.fetch("competitionStatuses", "get").then((response) => {
            return response.body;
        });
    }

    static getAllCompetitionsFromArchive() {
        return RequestManager.fetch("competitionsFromArchive", "get").then((response) => {
            return response.body;
        });
    }

    static getAllBeamers() {
        return RequestManager.fetch("users", "get", {
            roleId: Auth.ROLES.beamer,
        })
            .then((response) => {
                return response.body.data;
            })
            .catch((e) => console.log(e));
    }

    static getAllTrainers() {
        return RequestManager.fetch("users", "get", {
            roleId: Auth.ROLES.trainer,
        })
            .then((response) => {
                return response.body.data;
            })
            .catch((e) => console.log(e));
    }

    static getAllCompetitionManagers() {
        return RequestManager.fetch("users", "get", {
            roleId: Auth.ROLES.competitionManager,
        }).then((response) => {
            return response.body.data;
        });
    }
}

export default CompetitionsManager;
