import React from "react";
import {
    Grid,
    Typography,
    Button,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelActions,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import DownloadEntryFeeReport from "./EntryFeeReport";
import DownloadMembersReport from "./MembersReport";
import ReportsManager from "../../../../Modules/ReportsManager";
import BunchPerformancesReport from "./BunchPerformancesReport";
import ScheduleReport from "./ScheduleReports";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const Reports = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("adminPage");

    const onSuccess = () => {
        enqueueSnackbar(t("reportGenerateSuccess"), {
            variant: "success",
        });
    };

    const onFailure = () => {
        enqueueSnackbar(t("reportGenerateError"), {
            variant: "error",
        });
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <ScheduleReport
                    competitionId={props.competitionId}
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                />
            </Grid>
            <Grid item xs={12} align="center">
                <BunchPerformancesReport
                    competitionId={props.competitionId}
                    onSuccess={onSuccess}
                    onFailure={onFailure}
                />
            </Grid>
            {/* <Grid item xs={12}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{t("refereeCertificate")}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelActions>
                        <Button
                            onClick={async () =>
                                await ReportsManager.getCompetitionRefereesReport(
                                    props.competitionId
                                )
                            }
                            color="primary"
                            variant="contained"
                        >
                            {t("download")}
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
            </Grid> */}
            <Grid item xs={12}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{t("regionsList")}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelActions>
                        <Button
                            onClick={async () =>
                                await ReportsManager.getCompetitionSubjectsReport(
                                    props.competitionId
                                )
                            }
                            color="primary"
                            variant="contained"
                        >
                            {t("download")}
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{t("entryFeesDetails")}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelActions>
                        <Button
                            onClick={() =>
                                DownloadEntryFeeReport({
                                    competitionId: props.competitionId,
                                })
                            }
                            color="primary"
                            variant="contained"
                        >
                            {t("download")}
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{t("competitionReport")}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelActions>
                        <Button
                            onClick={() =>
                                DownloadMembersReport({
                                    competitionId: props.competitionId,
                                })
                            }
                            color="primary"
                            variant="contained"
                        >
                            {t("download")}
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{t("athletesWithoutLicenses")}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelActions>
                        <Button
                            onClick={() =>
                                ReportsManager.getAthletesWithoutLicensesReport(props.competitionId)
                            }
                            color="primary"
                            variant="contained"
                        >
                            {t("download")}
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{t("trainerRefereesOnCompetition")}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelActions>
                        <Button
                            onClick={() =>
                                ReportsManager.getTrainerRefereesReport(props.competitionId)
                            }
                            color="primary"
                            variant="contained"
                        >
                            {t("download")}
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{t("athleteRanks")}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelActions>
                        <Button
                            onClick={() =>
                                ReportsManager.getAthleteRanksReport(props.competitionId)
                            }
                            color="primary"
                            variant="contained"
                        >
                            {t("download")}
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
            </Grid>
            <Grid item xs={12}>
                <ExpansionPanel>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{t("subjectAthletes")}</Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelActions>
                        <Button
                            onClick={() =>
                                ReportsManager.getSubjectAthletesReport(props.competitionId)
                            }
                            color="primary"
                            variant="contained"
                        >
                            {t("download")}
                        </Button>
                    </ExpansionPanelActions>
                </ExpansionPanel>
            </Grid>
        </Grid>
    );
};

Reports.propTypes = {
    competitionId: PropTypes.number,
    history: PropTypes.object,
};

export default Reports;
