import {
    Button,
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useStoreon } from "storeon/react";
import { withAuth } from "../../Hoc";
import Auth from "../../Modules/Auth";
import { BeamerMode, BeamerOptions, SlideMode } from "../../Modules/Beamer/types";
import { BeamerModuleEvents, BeamerModuleState } from "../../store/StoreModules/BeamerModule";
import styles from "./beamer-settings.module.scss";

const BeamerSettings = (): JSX.Element => {
    const { competitionId } = useParams<{ competitionId?: string }>();
    const { options, dispatch } = useStoreon<BeamerModuleState, BeamerModuleEvents>("options");
    const { t } = useTranslation("beamers");
    const [mode, setMode] = useState(options.mode);
    const [slide, setSlide] = useState(options.slide);
    const [slideShowTime, setSlideShowTime] = useState<number>(options.slideShowTime / 1000);
    const history = useHistory();

    const setSettings = () => {
        const options: BeamerOptions = {
            mode: mode,
            slideShowTime: slideShowTime * 1000,
            slide: slide,
        };
        dispatch("setOptions", options);
        sessionStorage.setItem("beamerOptions", JSON.stringify(options));
        history.push(`/beamer/${competitionId}`);
    };

    const handleChangeMode = (event: React.ChangeEvent<{ value: unknown }>) => {
        setMode(event.target.value as BeamerMode);
    };

    const handleChangeSlide = (event: React.ChangeEvent<{ value: unknown }>) => {
        setSlide(event.target.value as SlideMode);
    };

    return (
        <div className={styles["settings-container"]}>
            {/** @todo add mode usage */}
            {/* <FormControl>
                <InputLabel id="demo-simple-select-helper-label">{t("mode")}</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={_mode}
                    onChange={handleChangeMode}
                >
                    <MenuItem value={BeamerMode.AUTO}>auto</MenuItem>
                    <MenuItem value={BeamerMode.MANUAL}>manual</MenuItem>
                </Select>
                <FormHelperText>{t("selectBeamerMode")}</FormHelperText>
            </FormControl> */}

            <FormControl>
                <InputLabel id="slides">{t("slides")}</InputLabel>
                <Select labelId="slides" id="slide" value={slide} onChange={handleChangeSlide}>
                    <MenuItem value={SlideMode.PERFORMANCE}>{t("onlyPerformance")}</MenuItem>
                    <MenuItem value={SlideMode.RATING}>{t("onlyRatingTable")}</MenuItem>
                    <MenuItem value={SlideMode.ALL}>{t("all")}</MenuItem>
                </Select>
                <FormHelperText>{t("selectShownSlides")}</FormHelperText>
            </FormControl>

            <TextField
                className={styles["slideShowTime-input"]}
                id="slide-show-time"
                label={t("slideShowTime")}
                type="number"
                InputLabelProps={{
                    shrink: true,
                }}
                helperText={`${t("enterTime")} (${t("inSeconds")}). ${t(
                    "slideShowTimeDescription"
                )}`}
                value={slideShowTime.toString()}
                onChange={(e) => {
                    setSlideShowTime(Number(e.target.value));
                }}
            />

            <Button variant="contained" color="primary" onClick={setSettings}>
                {t("start")}
            </Button>
        </div>
    );
};

export default withAuth(BeamerSettings, [Auth.ROLES.beamer]);
