import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
    athletes: Array<string | null>;
    nomination: string;
}

const RefereeHeader = (props: Props) => {
    const { t } = useTranslation("refereePage");
    return (
        <div id="refereeHeader">
            <div className="description_line">
                <div className="description_name">
                    <strong>{t("nomination")}: </strong> {props.nomination}
                </div>
            </div>
            <div className="description_line">
                <div className="description_name">
                    <strong>{t("performs")}: </strong> {props.athletes}
                </div>
            </div>
        </div>
    );
};

export default RefereeHeader;
