import RequestManager from "./RequestManager";
/**
 * Module for operations with users
 */
class CriteriaManager {
    /**
     * Returns object with criteria groups and criteria inside them
     * @param {number} refereeRoleId   referee role id
     * @return {Promise}               returns response as Promise
     */
    static getCriteriaAndGroups(refereeRoleId) {
        return RequestManager.fetch("criteria", "GET", { refereeRoleId: refereeRoleId }, false);
    }
    static saveScores(protocolScores) {
        return RequestManager.fetch("scores", "POST", protocolScores, false);
    }
    static loadScores(performanceRefereeId) {
        return RequestManager.fetch(
            "scores",
            "GET",
            { performanceRefereeId: performanceRefereeId },
            false
        );
    }
}

export default CriteriaManager;
