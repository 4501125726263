import RequestManager from "./RequestManager";
/**
 * Module for operations with users
 */
class UsersManager {
    /**
     * creates
     * @param {object} user    new user data
     * @return {Promise}  returns response as Promise
     */
    static async saveUser(user, type) {
        let params = type === "put" ? `/${user.id}` : "";
        return await RequestManager.fetch(`users${params}`, type, user, false);
    }
}

export default UsersManager;
