import * as Yup from "yup";
import i18n from "i18next";

export const getAthleteSchema = () => {
    if (i18n.language === "ru")
        return Yup.object().shape({
            firstName: Yup.string().required("Пожалуйста, укажите имя"),
            middleName: Yup.string().required("Пожалуйста, укажите отчество"),
            lastName: Yup.string().required("Пожалуйста, укажите фамилию"),
            genderId: Yup.number().required(),
            dateOfBirth: Yup.string().nullable().required("Пожалуйста, укажите дату рождения"),
            city: Yup.string().required("Пожалуйста, укажите город"),
            region: Yup.string().required("Пожалуйста, укажите регион"),
        });
    else
        return Yup.object().shape({
            firstName: Yup.string().required("Please state the name"),
            lastName: Yup.string().required("Please state the last name"),
            genderId: Yup.number().required(),
            dateOfBirth: Yup.string().nullable().required("Please enter the date of birth"),
            country: Yup.string().required("Please specify the country"),
            city: Yup.string().required("Please specify the city"),
            club: Yup.string().nullable(),
        });
};
