import { Grid } from "@material-ui/core";
import React from "react";
import CompetitionPlansList from "./CompetitionPlansList";
import SelectCompetition from "./SelectCompetiton";

const AthletesTransferContainer = ({ competitionId }) => {
    return (
        <Grid container>
            <Grid item xs={6}>
                <SelectCompetition />
            </Grid>
            <CompetitionPlansList competitionId={competitionId} />
        </Grid>
    );
};

export default AthletesTransferContainer;
