import React from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    Typography,
    Grid,
    FormGroup,
    InputLabel,
    MenuItem,
    Button,
    FormHelperText,
} from "@material-ui/core";
import RequestManager from "../../../Modules/RequestManager";
import { NotificationManager } from "react-notifications";
import { withTranslation } from "react-i18next";

class RefereeClonePopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            performanceId: this.props.selectedRefereeAssignment
                ? this.props.selectedRefereeAssignment.performanceId
                : -1,
            clonePeriod: 0,
            cloneCategory: 0,
        };

        this.closeModal = this.closeModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.onShow = this.onShow.bind(this);
    }

    closeModal() {
        this.props.modalHide();
    }

    handleSubmit() {
        let data = {
            PerformanceId: +this.state.performanceId,
            ClonePeriod: +this.state.clonePeriod,
            CloneCategory: +this.state.cloneCategory,
        };

        RequestManager.fetch("refereesCloneAssignments", "POST", data, false)
            .then((result) => {
                NotificationManager.success(this.props.t("dataSaved"));
                this.closeModal();
            })
            .catch((err) => NotificationManager.error(this.props.t("saveError")));
    }

    inputChanged(event) {
        const eventTargetName = event.target.name;
        const eventTargetValue = event.target.value;
        let stateChange = {};
        stateChange[eventTargetName] = eventTargetValue;
        this.setState(stateChange);
    }

    onShow() {
        if (this.props.selectedRefereeAssignment) {
            this.setState({
                performanceId: this.props.selectedRefereeAssignment.performanceId,
                athleteName: this.props.selectedRefereeAssignment.athleteName,
                competitionPlanName: this.props.selectedRefereeAssignment.competitionPlanName,
                dateTime: this.props.selectedRefereeAssignment.dateTime,
            });
        }
    }

    render() {
        const { t } = this.props;
        return (
            <Dialog
                onEnter={this.onShow}
                open={this.props.show}
                onClose={this.closeModal}
                aria-labelledby="ModalHeader"
                fullWidth
            >
                <DialogTitle id="ModalHeader" disableTypography>
                    {this.props.header}
                </DialogTitle>

                <DialogContent>
                    <form onSubmit={this.handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">
                                    {t("category")}: {this.state.competitionPlanName}
                                    <br />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormHelperText filled className="warning">
                                    {t("selectPeriodAndCategory")}
                                </FormHelperText>
                            </Grid>
                            <Grid item xs={6}>
                                <FormGroup>
                                    <InputLabel shrink>{t("period")}:</InputLabel>
                                    <Select
                                        displayEmpty
                                        name="clonePeriod"
                                        onChange={this.inputChanged}
                                        value={this.state.clonePeriod || ""}
                                    >
                                        <MenuItem value="" key={0} disabled>
                                            {t("selectPeriod")}
                                        </MenuItem>
                                        <MenuItem value={1}>{t("byEndOfDat")}</MenuItem>
                                        <MenuItem value={2}>{t("forEntireCompetition")}</MenuItem>
                                    </Select>
                                </FormGroup>
                            </Grid>

                            <Grid item xs={6}>
                                <FormGroup>
                                    <InputLabel shrink>{t("categories")}:</InputLabel>
                                    <Select
                                        displayEmpty
                                        name="cloneCategory"
                                        onChange={this.inputChanged}
                                        value={this.state.cloneCategory || ""}
                                    >
                                        <MenuItem value="" disabled>
                                            {t("selectCategory")}
                                        </MenuItem>
                                        <MenuItem value={1}>{t("allCategories")}</MenuItem>
                                        <MenuItem value={2}>{t("currentCategory")}</MenuItem>
                                        <MenuItem value={3}>{t("currentCategoryAndAge")}</MenuItem>
                                    </Select>
                                </FormGroup>
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.closeModal} color="default">
                        {t("cancel")}
                    </Button>
                    <Button onClick={this.handleSubmit} color="primary">
                        {t("copy")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

RefereeClonePopup.propTypes = {
    header: PropTypes.string,
    show: PropTypes.bool,
    modalHide: PropTypes.func,
    selectedRefereeAssignment: PropTypes.object,
};

export default withTranslation("adminPage")(RefereeClonePopup);
