import BaseApiService from "@/api/Base";
import { TokenInterceptor } from "@/api/Http/Interceptors";
import { PaginatedQueryResult, QueryParams } from "@/api/types";
import { Competition } from "@/interfaces";
import { Settings } from "@/modules/Constants";

class CompatitionApiService extends BaseApiService<Competition> {
    constructor(config) {
        super(config);
        this.url = "/competitions";
        this.http.interceptors.request.use(TokenInterceptor);
    }

    getAllActive = async (
        params: QueryParams<Competition> = {}
    ): Promise<PaginatedQueryResult<Competition>> => {
        const { data } = await this.http.get(this.url, {
            params: { type: "active", ...params },
        });
        return data;
    };

    getAllActiveForBeamer = async (
        beamerId: number
    ): Promise<PaginatedQueryResult<Competition>> => {
        const { data } = await this.http.get(this.url, {
            params: { type: "active", beamer_user_id: beamerId },
        });
        return data;
    };
}

export default new CompatitionApiService({
    baseURL: Settings.MAIN_API_URL_V2,
});
