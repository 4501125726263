import Utils from "@/modules/Utils";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./license-status.module.scss";
import clsx from "clsx";

const LicenseStatus: React.FunctionComponent<{ license: any }> = ({ license }) => {
    const { t } = useTranslation("trainerPage");

    if (!license) {
        return <Typography variant="body2">{t("athletesList.absent")}</Typography>;
    }

    const licenseStatus = Utils.getLicenseStatus(license);

    return (
        <div>
            <Grid container>
                <div
                    className={clsx([
                        styles["license-status"],
                        styles[`license-status__${licenseStatus}`],
                    ])}
                />

                <Typography variant="body2">
                    <b>№</b>
                    {license.id}
                </Typography>
            </Grid>

            {license.assigmentDate && licenseStatus !== "expired" ? (
                <Typography variant="body2">
                    <b>{t("athletesList.validUntil")}: </b>
                    {moment(new Date(license.expirationDate)).format("DD.MM.YYYY")}
                </Typography>
            ) : null}
        </div>
    );
};

export default LicenseStatus;
