import React from "react";
import PropTypes from "prop-types";
import { Container, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import MainContent from "./MainContent";
import MainMenu from "./MainMenu";
import "../../Css/MainGrid.css";

class MainGrid extends React.Component {
    constructor(props) {
        super(props);
        this.handleSelect = this.handleSelect.bind(this);
        this.state = { selectedItem: MainGrid.DefaultSelectedMenu };
    }
    static get MenuItems() {
        return {
            athletes: "athletes",
            competitions: "competitions",
            requests: "requests",
            ageCategories: "ageCategories",
            categories: "categories",
            users: "users",
        };
    }

    static get DefaultSelectedMenu() {
        return MainGrid.MenuItems.competitions;
    }

    componentDidMount() {
        if (this.props.history.location.pathname === "/admin") {
            this.props.history.push("/admin/competitions");
        }
    }

    componentDidUpdate() {
        if (this.props.history.location.pathname === "/admin") {
            this.props.history.push("/admin/competitions");
        }
    }

    handleSelect(selectedKey) {
        this.props.history.push(`/admin/${selectedKey ? selectedKey : "competitions"}`);
        this.setState({
            selectedItem: selectedKey,
        });
    }

    render() {
        return (
            <Grid container direction="column">
                <Grid item className="main-menu" xs={12}>
                    <Container>
                        <MainMenu
                            onChange={this.handleSelect}
                            selectedItem={this.state.selectedItem}
                        />
                    </Container>
                </Grid>
                <Grid item className="main-content" xs={12}>
                    <Container>
                        <MainContent selectedItem={this.state.selectedItem} />
                    </Container>
                </Grid>
            </Grid>
        );
    }
}

MainGrid.propTypes = {
    history: PropTypes.object,
};

export default withRouter(MainGrid);
