import React from "react";
import "./ProtocolArtistry.css";
import "../Protocol.css";
import CriteriaManager from "../../../../../Modules/CriteriaManager";
import { REFEREE_ROLES } from "../../../../../Modules/Enums";
import CriterionGroup from "./CriterionGroup";
import SaveCancelPanel from "../../../Protocols/SaveCancelPanel";
import RefereeHeaderScore from "../../../Protocols/RefereeHeaderScore";
import { PerformanceReferee } from "../../../../../interfaces/performance-referee";

interface Props {
    performanceReferee: PerformanceReferee;
    onSave: (scores) => Promise<void>;
    onClose: () => void;
}

interface Score {
    performanceRefereeId: number;
    criterionId: number;
    score: {};
    penalty: {};
}

class ProtocolArtistry extends React.Component<Props, any> {
    constructor(props) {
        super(props);

        this.state = {
            criterionGroups: null,
            scoresLoaded: false,
            performanceInfo: null,
        };
    }

    componentDidMount() {
        this.loadCriteria();
    }

    loadCriteria() {
        CriteriaManager.getCriteriaAndGroups(REFEREE_ROLES.artistryJudge.id)
            .then((response) => {
                const criterionGroups = response.body.criterionGroups;
                criterionGroups.forEach((group) => {
                    group.score = 0;
                    group.penalty = 0;
                    group.criteria.forEach((criterion) => {
                        criterion.score = 0;
                        criterion.penalty = 0;
                        return criterion;
                    });
                    return group;
                });
                this.setState({
                    criterionGroups: criterionGroups,
                });
            })
            .then(() => {
                this.loadCriteriaScores();
            });
    }

    loadCriteriaScores() {
        CriteriaManager.loadScores(this.props.performanceReferee.id).then((response) => {
            const scores = response.body;
            const criterionGroups = this.state.criterionGroups;
            criterionGroups.forEach((group) => {
                group.criteria.forEach((criterion) => {
                    if (scores[`${criterion.criterionId}`] !== undefined) {
                        criterion.score = scores[`${criterion.criterionId}`].score;
                        criterion.penalty = scores[`${criterion.criterionId}`].penalty;
                    } else {
                        criterion.score = null;
                        criterion.penalty = null;
                    }

                    return criterion;
                });
                return group;
            });
            this.setState({
                criterionGroups: criterionGroups,
                scoresLoaded: true,
            });
        });
    }

    roundGrade(g) {
        return +g.toFixed(1);
    }

    onChange(value, groupIdx, criterionIdx) {
        const criterionGroups = this.state.criterionGroups;
        criterionGroups[groupIdx].criteria[criterionIdx].score = +value;
        this.setState({
            criterionGroups: criterionGroups,
        });
    }

    getRatingScaleValues(min, max, step) {
        const ratingValues: any = [];

        for (let i = min; i <= max; i += step) {
            const fixedValue = i.toFixed(1);
            ratingValues.push(fixedValue);
        }
        return ratingValues;
    }

    getGroups() {
        if (!this.state.criterionGroups || !this.state.scoresLoaded) {
            return null;
        }
        return this.state.criterionGroups.map((group, idx) => (
            <CriterionGroup
                criterionGroup={group}
                key={group.criterionGroupId}
                onChange={(value, criterionIdx) => this.onChange(value, idx, criterionIdx)}
                ratingValues={this.getRatingScaleValues(1, 2.1, 0.1)}
            />
        ));
    }

    calcGroupScores() {
        const scores: any = [];
        if (!this.state.criterionGroups) {
            return [];
        }

        for (const group of this.state.criterionGroups) {
            let criteriaScore = 0;
            for (const criterion of group.criteria) {
                criteriaScore += criterion.score;
            }
            group.score = criteriaScore;
            scores.push(this.roundGrade(group.score));
        }

        return scores;
    }

    getTotalScore() {
        let totalScore = 0;
        if (!this.state.criterionGroups) {
            return 0;
        }
        for (const group of this.state.criterionGroups) {
            totalScore += group.score;
        }
        return totalScore;
    }

    getScoresReqObject() {
        const scoresReqObject: Score[] = [];

        this.state.criterionGroups.forEach((group) => {
            group.criteria.forEach((criterion) => {
                scoresReqObject.push({
                    performanceRefereeId: this.props.performanceReferee.id,
                    criterionId: criterion.criterionId,
                    score: criterion.score,
                    penalty: criterion.penalty,
                });
            });
        });

        return scoresReqObject;
    }

    onSave = async () => {
        const scores = this.getScoresReqObject();
        await this.props.onSave(scores);
    };

    render() {
        this.calcGroupScores();
        const totalScore = this.roundGrade(this.getTotalScore());
        return (
            <div>
                <div id="protocol-artistry-container">
                    {this.getGroups()}
                    <RefereeHeaderScore isVisible={true} totalScore={totalScore} />
                    <SaveCancelPanel onSave={this.onSave} onCancel={this.props.onClose} />
                </div>
            </div>
        );
    }
}

export default ProtocolArtistry;
