import React from "react";
import Auth from "../Modules/Auth";
import RefereeHeader from "../Controls/RefereePage/components/referee-header";
import { withAuth } from "../Hoc";
import MainView from "../Controls/RefereePage/views/main";
import "../Css/Common.css";
import { useParams } from "react-router-dom";

const RefereeActivityPage: React.FunctionComponent<unknown> = () => {
    const { competitionId } = useParams<{ competitionId: string }>();
    const referee = Auth.getAuthReferee();

    return (
        <section className="referee_page">
            <RefereeHeader />
            <MainView competitionId={+competitionId} referee={referee} />
        </section>
    );
};

export default withAuth(RefereeActivityPage, [Auth.ROLES.referee]);
