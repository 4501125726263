import Helpers from "@/modules/Helpers";
import {
    Box,
    Button,
    Checkbox,
    Grid,
    IconButton,
    Input,
    List,
    ListItem,
    Paper,
    Tooltip,
    Typography,
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import React, { FunctionComponent } from "react";
import { RefereeMember } from "../../../../store/StoreModules/RefereeLotteryModule";

export type MemberAddHandler = () => void;
export type MemberAssignHandler = (id: string, number: number | null) => void;
export type MemberDeleteHandler = (id: string) => void;
export type MemberSelectHandler = (id: string, selected: boolean) => void;

interface MembersProps {
    title: string;
    items: RefereeMember[];
    onAdd?: MemberAddHandler;
    onAssign: MemberAssignHandler;
    onDelete?: MemberDeleteHandler;
    onSelect?: MemberSelectHandler;
}

const Members: FunctionComponent<MembersProps> = ({
    title,
    items,
    onAssign,
    onAdd,
    onDelete,
    onSelect,
}) => {
    return (
        <Box component={Paper} borderRadius={10} marginTop={1} marginBottom={1} padding={1}>
            <List subheader={<Typography variant="h6">{title}</Typography>}>
                {items.map((item) => (
                    <ListItem key={item.id} dense disableGutters>
                        <Grid container alignItems="center" className="grid-item-clear">
                            <Grid item xs={1}>
                                <Tooltip title="Удалить" arrow>
                                    <span>
                                        <IconButton
                                            size="small"
                                            color="secondary"
                                            onClick={() => onDelete?.(item.id)}
                                            disabled={!onDelete}
                                        >
                                            <Delete />
                                        </IconButton>
                                    </span>
                                </Tooltip>
                            </Grid>

                            {item.referee ? (
                                <>
                                    <Grid item xs={3}>
                                        <Typography>
                                            {Helpers.getFullNameForUser(item.referee)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>{item.referee?.region}</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography>{item.referee?.city}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography>{item.referee?.categoryReferee}</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography>{item.referee?.categoryFig}</Typography>
                                    </Grid>
                                </>
                            ) : (
                                <Grid item xs={9}>
                                    <Typography variant="subtitle1" color="textSecondary">
                                        Новый {title}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid item xs={1}>
                                <Input
                                    type="number"
                                    value={item.number || ""}
                                    onChange={(e) => {
                                        const { value } = e.target;

                                        onAssign(item.id, +value || null);
                                    }}
                                />
                            </Grid>
                            <Grid item xs={1}>
                                {onSelect ? (
                                    <Checkbox
                                        color="primary"
                                        checked={item.selected}
                                        onChange={(e) => onSelect(item.id, e.target.checked)}
                                    />
                                ) : null}
                            </Grid>
                        </Grid>
                    </ListItem>
                ))}
                {onAdd ? (
                    <ListItem disableGutters>
                        <Button size="small" onClick={onAdd} startIcon={<Add />}>
                            {`Добавить «${title}»`}
                        </Button>
                    </ListItem>
                ) : null}
            </List>
        </Box>
    );
};

export default Members;
