export enum OUTGOING_EVENT_TYPES {
    BEAMER_UPDATE_OPTIONS = "BEAMER_UPDATE_OPTIONS",
    BEAMER_SET_READY_STATUS = "BEAMER_SET_READY_STATUS",
}

export enum INCOMING_EVENT_TYPES {
    BEAMER_CONNECTED = "BEAMER_CONNECTED",
    BEAMER_JOINED_TO_THE_ROOM = "BEAMER_JOINED_TO_THE_ROOM",
    BEAMER_OPTIONS_UPDATED = "BEAMER_OPTIONS_UPDATED",
    BEAMER_READY_STATUS_UPDATED = "BEAMER_READY_STATUS_UPDATED",
    BEAMER_SHOW_SLIDE = "BEAMER_SHOW_SLIDE",
    BEAMER_LEFT_THE_ROOM = "BEAMER_LEFT_THE_ROOM",
    BEAMER_DISCONNECTED = "BEAMER_DISCONNECTED",
}
