import React from "react";
import { useHistory } from "react-router-dom";
import { Grid, Container, Typography } from "@material-ui/core";
import { Competition } from "../../interfaces/competition";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../Hooks";
import styles from "./competitions-list.module.scss";
import CompetitionCard from "../../Views/CompetitionCard";
import ContentLayout from "../../Views/ContentLayout";
import { useQuery } from "react-query";
import { competitionApiService } from "@/api/Services";
import { Skeleton } from "@material-ui/lab";

const CompetitionsList = (): JSX.Element => {
    const history = useHistory();
    const { user } = useAuth();

    const { data, isFetching, isFetched } = useQuery(["beamer-competitions"], () =>
        competitionApiService.getAllActiveForBeamer(user.userId)
    );

    const { t } = useTranslation("beamers");

    const handleCardClick = (competition) => {
        history.push(`/beamer/settings/${competition.id}`);
    };

    return (
        <div className={styles["referee__competitions"]}>
            <Container>
                <Typography variant="h3">{t("selectCompetition")}</Typography>
                <ContentLayout>
                    {!isFetching && !data?.result.count && (
                        <Typography>{t("noCompetitionsAvailable")}</Typography>
                    )}

                    <Grid container spacing={2}>
                        {isFetching &&
                            [...new Array(3)].map((el) => (
                                <Grid key={el} item xs={12} md={4}>
                                    <Skeleton width="100%" height={300} />
                                </Grid>
                            ))}

                        {isFetched &&
                            data?.result.rows.map((competition: Competition) => {
                                return (
                                    <Grid item xs={12} md={4} key={competition.id}>
                                        <CompetitionCard
                                            {...competition}
                                            onClick={handleCardClick}
                                            hideAttachments
                                            hideActions
                                        />
                                    </Grid>
                                );
                            })}
                    </Grid>
                </ContentLayout>
            </Container>
        </div>
    );
};

export default CompetitionsList;
