import React from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    Tabs,
    Tab,
    FormGroup,
    Checkbox,
    FormControlLabel,
    MenuItem,
    Grid,
    FormHelperText,
} from "@material-ui/core";
import TabPanel from "../../Common/TabPanel";
import { NotificationManager } from "react-notifications";
import UsersManager from "../../../Modules/UsersManager";
import { ValidatorForm } from "react-material-ui-form-validator";
import { withTranslation } from "react-i18next";
import "./UserPopup.css";

class UserPopup extends React.Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getPasswordValidationState = this.getPasswordValidationState.bind(this);
        this.getConfirmValidationState = this.getConfirmValidationState.bind(this);
        this.handleCancelClick = this.handleCancelClick.bind(this);
        this.inputChanged = this.inputChanged.bind(this);
        this.getValidationStateForRequiredField = this.getValidationStateForRequiredField.bind(
            this
        );
        this.checkAllRequiredFieldsAreFilled = this.checkAllRequiredFieldsAreFilled.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }

    componentDidMount() {
        if (this.props.data.id > 0) {
            this.setState({
                header: this.props.t("userChange"),
            });
        }
        this.setState({
            tabKey: 1,
            user: {
                id: this.props.data.id,
                userName: this.props.data.userName || "",
                login: this.props.data.login || "",
                password: "",
                passwordRepeat: "",
                isPasswordCorrect: true,
                firstName: this.props.data.firstName || "",
                middleName: this.props.data.middleName || "",
                lastName: this.props.data.lastName || "",
                city: this.props.data.city || "",
                email: this.props.data.email || "",
                phoneNumber: this.props.data.phoneNumber || "",
                roleIds: this.props.data.userRoles || [],
                isAdmin: this.props.data.userRoles.includes(1),
                isCompetitionManager: this.props.data.userRoles.includes(3),
                isReferee: this.props.data.userRoles.includes(2),
                isTrainer: this.props.data.userRoles.includes(4),
                isBeamer: this.props.data.userRoles.includes(5),
                refereeRank: this.props.data.refereeRank || "1 категория",
            },
            isPasswordCorrect: true,
            isAllRequiredFieldsAreFilled: true,
            showValidation: false,
            open: true,
        });
    }

    getPasswordValidationState() {
        const length = this.state.user.password.length;
        if (length === 0) return null;
        if (length < 4) return "error";
        if (length < 8) return "warning";
        return "success";
    }

    getConfirmValidationState() {
        const length = this.state.user.passwordRepeat.length;
        if (!this.state.isPasswordCorrect) return "error";
        if (length === 0) return null;
        return "success";
    }

    comparePasswords() {
        return this.state.user.password === this.state.user.passwordRepeat;
    }

    inputChanged(event) {
        const field = event.target.name;
        const user = this.state.user;
        const value = event.target.type === "checkbox" ? event.target.checked : event.target.value;
        user[field] = value;

        user.roleIds = [];
        if (user.isAdmin) {
            user.roleIds.push(1);
        }

        if (user.isReferee) {
            user.roleIds.push(2);
        }

        if (user.isCompetitionManager) {
            user.roleIds.push(3);
        }

        if (user.isTrainer) {
            user.roleIds.push(4);
        }
        if (user.isBeamer) {
            user.roleIds.push(5);
        }

        this.setState({
            user,
        });

        if (field === "password") {
            this.setState({
                isPasswordCorrect:
                    event.target.value === this.state.user.passwordRepeat ? true : false,
            });
        }

        if (field === "passwordRepeat") {
            this.setState({
                isPasswordCorrect: event.target.value === this.state.user.password ? true : false,
            });
        }
    }

    getValidationStateForRequiredField(value) {
        if (this.state.showValidation && !(value && value.trim().length > 0)) {
            return "error";
        }
        return null;
    }

    checkAllRequiredFieldsAreFilled() {
        let arrayOfRequiredFields = [
            this.state.user.login,
            this.state.user.firstName,
            this.state.user.lastName,
        ];

        let isAllRequiredFieldsAreFilled = arrayOfRequiredFields.every(function (item) {
            return item && item.trim().length > 0;
        });

        return isAllRequiredFieldsAreFilled;
    }

    async handleSubmit(event) {
        if (this.comparePasswords() && this.checkAllRequiredFieldsAreFilled()) {
            let type = this.state.user.id > 0 ? "put" : "post";
            await UsersManager.saveUser(this.state.user, type)
                .then((result) => {
                    if (result.status === 201 && !result.error) {
                        NotificationManager.success(result.body.message);
                        this.handleCancelClick();
                        this.props.onSubmit();
                    } else {
                        NotificationManager.warning(result.body.message);
                    }
                })
                .catch((err) => {
                    NotificationManager.error(err.message);
                });
        } else {
            event.preventDefault();
            this.setState({
                showValidation: true,
                tabKey: 1,
            });
            // select 1st tab
        }
    }
    handleSelect(event, key) {
        this.setState({ tabKey: key });
    }

    handleCancelClick(event) {
        this.props.modalCancel(event);
    }

    render() {
        const { t } = this.props;
        if (!this.state) {
            return null;
        }
        const validationText = this.state.isPasswordCorrect ? "" : t("passwordMismatch");
        return (
            <Dialog
                open={this.props.modalIsOpen}
                onClose={this.handleCancelClick}
                aria-labelledby="ModalHeader"
                fullWidth
            >
                <DialogTitle id="ModalHeader" disableTypography>
                    {this.state.header || this.props.header}
                </DialogTitle>

                <ValidatorForm onSubmit={this.handleSubmit}>
                    <DialogContent className="user-popup">
                        <Tabs
                            value={this.state.tabKey}
                            onChange={this.handleSelect}
                            indicatorColor="primary"
                            className="tabs-margin"
                        >
                            <Tab value={1} label={t("main")} wrapped />
                            <Tab value={2} label={t("roles")} />
                        </Tabs>

                        <TabPanel value={this.state.tabKey} index={1}>
                            <TextField
                                required
                                type="text"
                                name="login"
                                label={t("login")}
                                value={this.state.user.login || ""}
                                onChange={this.inputChanged}
                                fullWidth
                                margin="dense"
                            />

                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        required={this.state.user.id < 0 ? true : false}
                                        type="password"
                                        name="password"
                                        value={this.state.user.password || ""}
                                        onChange={this.inputChanged}
                                        label={t("password")}
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required={this.state.user.id < 0 ? true : false}
                                        type="password"
                                        name="passwordRepeat"
                                        value={this.state.user.passwordRepeat || ""}
                                        onChange={this.inputChanged}
                                        label={t("passwordRepeat")}
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        type="text"
                                        name="lastName"
                                        value={this.state.user.lastName || ""}
                                        onChange={this.inputChanged}
                                        label={t("lastName")}
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        required
                                        type="text"
                                        name="firstName"
                                        value={this.state.user.firstName || ""}
                                        onChange={this.inputChanged}
                                        label={t("firstName")}
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                            </Grid>

                            <TextField
                                type="text"
                                name="middleName"
                                value={this.state.user.middleName || ""}
                                onChange={this.inputChanged}
                                label={t("middleName")}
                                fullWidth
                                margin="dense"
                            />

                            <TextField
                                type="text"
                                name="city"
                                value={this.state.user.city || ""}
                                onChange={this.inputChanged}
                                label={t("city")}
                                fullWidth
                                margin="dense"
                            />

                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <TextField
                                        type="text"
                                        name="email"
                                        value={this.state.user.email || ""}
                                        onChange={this.inputChanged}
                                        label={t("email")}
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        type="text"
                                        name="phoneNumber"
                                        value={this.state.user.phoneNumber || ""}
                                        onChange={this.inputChanged}
                                        label={t("phoneNumber")}
                                        fullWidth
                                        margin="dense"
                                    />
                                </Grid>
                            </Grid>
                            <FormHelperText error>{validationText}</FormHelperText>
                        </TabPanel>

                        <TabPanel value={this.state.tabKey} index={2}>
                            <FormGroup>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="isAdmin"
                                            checked={this.state.user.isAdmin}
                                            onChange={this.inputChanged}
                                            value="primary"
                                        />
                                    }
                                    label={t("admin")}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="isCompetitionManager"
                                            checked={this.state.user.isCompetitionManager}
                                            onChange={this.inputChanged}
                                            value="primary"
                                        />
                                    }
                                    label={t("competitionsManager")}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="isReferee"
                                            checked={this.state.user.isReferee}
                                            onChange={this.inputChanged}
                                            value="primary"
                                        />
                                    }
                                    label={t("referee")}
                                />

                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="isTrainer"
                                            checked={this.state.user.isTrainer}
                                            onChange={this.inputChanged}
                                            value="primary"
                                        />
                                    }
                                    label={t("coachName")}
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            name="isBeamer"
                                            checked={this.state.user.isBeamer}
                                            onChange={this.inputChanged}
                                            value="primary"
                                        />
                                    }
                                    label={t("beamer")}
                                />

                                {this.state.user.isReferee && (
                                    <TextField
                                        select
                                        name="refereeRank"
                                        label={`${t("refereeRank")}:`}
                                        value={this.state.user.refereeRank || "1 категория"}
                                        onChange={this.inputChanged}
                                    >
                                        <MenuItem value="1 категория">1 {t("category")}</MenuItem>
                                        <MenuItem value="2 категория">2 {t("category")}</MenuItem>
                                        <MenuItem value="3 категория">3 {t("category")}</MenuItem>
                                        <MenuItem value="МК">МК</MenuItem>
                                        <MenuItem value="ССВК">ССВК</MenuItem>
                                    </TextField>
                                )}
                            </FormGroup>
                        </TabPanel>
                    </DialogContent>

                    <DialogActions>
                        <Button onClick={this.handleCancelClick}>{t("cancel")}</Button>
                        <Button color="primary" type="submit" variant="contained">
                            {t("save")}
                        </Button>
                    </DialogActions>
                </ValidatorForm>
            </Dialog>
        );
    }
}

UserPopup.propTypes = {
    header: PropTypes.string,
    onSubmit: PropTypes.func,
    modalCancel: PropTypes.func,
    error: PropTypes.string,
    data: PropTypes.object,
    modalIsOpen: PropTypes.bool,
};

export default withTranslation("adminPage")(UserPopup);
