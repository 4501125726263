import React from "react";
import PropTypes from "prop-types";
import { IconButton, TextField, MenuItem, Grid } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { withRouter } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { withTranslation } from "react-i18next";

class SearchAthleteCoach extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchName: "",
            searchBy: 1,
        };

        this.inputChanged = this.inputChanged.bind(this);
        this.clickSearch = this.clickSearch.bind(this);
    }

    inputChanged(event) {
        let stateChange = {};
        stateChange[event.target.name] = event.target.value;
        this.setState(stateChange);
    }

    clickSearch(e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.searchName.match(/^ *$/) !== null) {
            NotificationManager.warning(this.props.t("enterAthOrTrenNameToSearch"));
            return;
        }
        this.props.history.push(
            `/competitionsList/PerformanceScoresSearchResults/${this.state.searchName.trim()}/${
                this.state.searchBy
            }`
        );
    }

    render() {
        const { t } = this.props;
        return (
            <form onSubmit={this.clickSearch}>
                <Grid container spacing={2}>
                    <Grid item>
                        <TextField
                            name="searchName"
                            type="text"
                            placeholder={t("search")}
                            value={this.state.searchName}
                            onChange={this.inputChanged}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            select
                            name="searchBy"
                            onChange={this.inputChanged}
                            value={this.state.searchBy}
                            style={{ width: "11rem" }}
                        >
                            <MenuItem value={1}>{t("byAthlete")}</MenuItem>
                            <MenuItem value={2}>{t("byCoach")}</MenuItem>
                        </TextField>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={this.clickSearch}>
                            <SearchIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </form>
        );
    }
}

SearchAthleteCoach.propTypes = {
    history: PropTypes.object,
};

export default withRouter(withTranslation()(SearchAthleteCoach));
