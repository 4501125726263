import React from "react";
import { Button, Typography, MenuItem, Grid, Box, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useSnackbar } from "notistack";
import { subjects } from "../../../Modules/russia-subjects";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import "../../../Css/TrainerPage.css";
import { useAuth } from "../../../Hooks";
import { trainerRefereeApiService } from "@/api/Services";
import { getTrainerRefereeSchema } from "../../../Schema/TrainerRefereeSchema";
import { TrainerReferee } from "@/interfaces";
import { countries } from "@/modules/Countries";

interface RefereeFormProps {
    data: TrainerReferee | null;
    onSubmit(): Promise<void>;
    onCancelEdit(): void;
}

const RefereeForm: React.FunctionComponent<RefereeFormProps> = ({
    data,
    onSubmit,
    onCancelEdit,
}) => {
    const { user } = useAuth();
    const { enqueueSnackbar } = useSnackbar();
    const { t, i18n } = useTranslation("trainerPage");

    const submit = async (values, { resetForm }) => {
        try {
            let response: any = null;
            if (!data) {
                response = await trainerRefereeApiService.create(values);
            } else {
                response = await trainerRefereeApiService.update(data.id, values);
            }

            enqueueSnackbar(response.message, {
                variant: "success",
            });
            resetForm();
            onSubmit();
        } catch (err: any) {
            const message = err.response?.data?.message;
            enqueueSnackbar(message, { variant: "error" });
        }
    };

    const {
        handleSubmit,
        values,
        handleChange,
        errors,
        setFieldValue,
        touched,
        resetForm,
    } = useFormik({
        validationSchema: getTrainerRefereeSchema,
        initialValues: {
            trainerId: user.trainerId,
            firstName: data?.firstName ?? "",
            middleName: data?.middleName ?? "",
            lastName: data?.lastName ?? "",
            city: data?.city ?? "",
            region: data?.region ?? "",
            categoryReferee: data?.categoryReferee ?? null,
            categoryFig: data?.categoryFig ?? null,
            categoryInternational: data?.categoryInternational ?? null,
            country: data?.country ?? "",
        },
        enableReinitialize: true,
        onSubmit: submit,
    });

    return (
        <form noValidate onSubmit={handleSubmit} className="athlete-form">
            <Typography variant="h6">
                {data ? t("refereeForm.refereeChange") : t("refereeForm.refereeAdd")}
            </Typography>

            <Grid item xs={12}>
                <TextField
                    required
                    type="text"
                    name="lastName"
                    label={t("athleteForm.surname")}
                    fullWidth
                    margin="dense"
                    onChange={(e) => {
                        const { value } = e.target;
                        const val = value.charAt(0).toUpperCase() + value.slice(1);

                        setFieldValue("lastName", val.trimLeft().trimRight());
                    }}
                    value={values.lastName || ""}
                    error={touched.lastName && !!errors.lastName}
                    helperText={touched.lastName ? errors.lastName : ""}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    required
                    type="text"
                    name="firstName"
                    label={t("athleteForm.firstName")}
                    fullWidth
                    margin="dense"
                    onChange={(e) => {
                        const { value } = e.target;
                        const val = value.charAt(0).toUpperCase() + value.slice(1);

                        setFieldValue("firstName", val.trimLeft().trimRight());
                    }}
                    value={values.firstName || ""}
                    error={touched.firstName && !!errors.firstName}
                    helperText={touched.firstName ? errors.firstName : ""}
                />
            </Grid>

            {i18n.language === "ru" && (
                <Grid item xs={12}>
                    <TextField
                        required
                        type="text"
                        name="middleName"
                        label={t("athleteForm.middleName")}
                        fullWidth
                        margin="dense"
                        onChange={(e) => {
                            const { value } = e.target;
                            const val = value.charAt(0).toUpperCase() + value.slice(1);

                            setFieldValue("middleName", val.trimLeft().trimRight());
                        }}
                        value={values.middleName || ""}
                        error={touched.middleName && !!errors.middleName}
                        helperText={touched.middleName ? errors.middleName : ""}
                    />
                </Grid>
            )}

            {i18n.language !== "ru" && (
                <Autocomplete
                    fullWidth
                    options={countries}
                    getOptionLabel={(option) => option}
                    value={countries.find((el) => el === values.country) || ""}
                    onChange={(e, value) => {
                        setFieldValue("country", value || "");
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            margin="dense"
                            required
                            label={t("athleteForm.country")}
                            name="country"
                            error={touched.country && !!errors.country}
                            helperText={touched.country ? errors.country : ""}
                        />
                    )}
                />
            )}

            {i18n.language === "ru" && (
                <Grid item xs={12}>
                    <Autocomplete
                        fullWidth
                        options={subjects}
                        getOptionLabel={(option) => option.title}
                        value={subjects.find((el) => el.title === values.region) || null}
                        onChange={(e, value) => {
                            setFieldValue("region", value?.title || "");
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin="dense"
                                required
                                label={t("athleteForm.region")}
                                name="region"
                                error={touched.region && !!errors.region}
                                helperText={touched.region ? errors.region : ""}
                            />
                        )}
                    />
                </Grid>
            )}

            <Grid item xs={12}>
                <TextField
                    required
                    type="text"
                    name="city"
                    label={t("athleteForm.city")}
                    fullWidth
                    margin="dense"
                    onChange={(e) => {
                        const { value } = e.target;

                        setFieldValue("city", value.trimLeft().trimRight());
                    }}
                    value={values.city || ""}
                    error={touched.city && !!errors.city}
                    helperText={touched.city ? errors.city : ""}
                />
            </Grid>

            {i18n.language === "ru" && (
                <Grid item xs={12}>
                    <TextField
                        select
                        name="categoryReferee"
                        label={t("refereesList.categoryReferee")}
                        fullWidth
                        margin="dense"
                        onChange={handleChange}
                        value={values.categoryReferee || ""}
                    >
                        <MenuItem key={0} value="">
                            {t("athleteForm.notChosen")}
                        </MenuItem>
                        {["Б/К", "1", "2", "3", "4", "СВК"].map((category) => {
                            return (
                                <MenuItem key={category} value={category}>
                                    {category}
                                </MenuItem>
                            );
                        })}
                    </TextField>
                </Grid>
            )}

            <Grid item xs={12}>
                <TextField
                    select
                    name="categoryFig"
                    label={t("refereesList.categoryFig")}
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={values.categoryFig || ""}
                >
                    <MenuItem key={0} value="">
                        {t("athleteForm.notChosen")}
                    </MenuItem>
                    {["Б/К", "1", "2", "3", "4"].map((category) => {
                        return (
                            <MenuItem key={category} value={category}>
                                {category}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </Grid>

            <Grid item xs={12}>
                <TextField
                    select
                    name="categoryInternational"
                    label={t("refereesList.categoryInternational")}
                    fullWidth
                    margin="dense"
                    onChange={handleChange}
                    value={values.categoryInternational || ""}
                >
                    <MenuItem key={0} value="">
                        {t("athleteForm.notChosen")}
                    </MenuItem>
                    {["Б/К", "1", "2", "3", "4"].map((category) => {
                        return (
                            <MenuItem key={category} value={category}>
                                {category}
                            </MenuItem>
                        );
                    })}
                </TextField>
            </Grid>

            <Grid container justify="flex-end" component={Box} mt={3}>
                {data ? (
                    <Button
                        onClick={() => {
                            onCancelEdit();
                            resetForm();
                        }}
                    >
                        {t("athleteForm.cancel")}
                    </Button>
                ) : null}
                <Button color="primary" type="submit" variant="contained">
                    {!data ? t("athleteForm.add") : t("athleteForm.save")}
                </Button>
            </Grid>
        </form>
    );
};

export default RefereeForm;
