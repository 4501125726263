import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import Helpers from "../../../../Modules/Helpers";
import moment from "moment";
import i18n from "i18next";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

function getTableColumns() {
    const columns = ["#", "fullName", "category", "fee", "total"];

    const tableColumns = columns.map((item) => {
        return {
            text: item === "#" ? item : i18n.t(`trainerPage:CompetitionRequestDetails.${item}`),
            bold: true,
            style: "tableHeader",
        };
    });

    return tableColumns;
}

function generateTableBody(data) {
    const tableColumns = getTableColumns();
    let tableBody = [];
    let countEntryFee = 0;
    tableBody.push(tableColumns);

    let athletes = [];

    data.requests.forEach((request) => {
        request.trainerRequestDetail.forEach((detail) => {
            athletes.push(detail);
        });
    });

    let uniqueAthletes = [...new Set(athletes.map((obj) => obj.athleteId))].map((athleteId) => {
        return athletes.find((obj) => obj.athleteId === athleteId);
    });

    uniqueAthletes.forEach((athlete) => {
        let countEntryFeeForPerson = 0;
        let dataRow = [];
        let plans = [];

        data.requests.forEach((request) => {
            request.trainerRequestDetail.forEach((detail) => {
                if (athlete.athleteId === detail.athleteId) {
                    request.entryFeeForPerson = +(
                        request.plan.entryFee / request.trainerRequestDetail.length
                    ).toFixed(2);
                    countEntryFeeForPerson += +request.entryFeeForPerson;
                    plans.push(request);
                }
            });
        });

        dataRow.push({ text: tableBody.length });
        dataRow.push({ text: Helpers.getFullNameForAthlete(athlete.athlete) });
        dataRow.push({
            text: plans.map((item) => `${item.plan.category.worldCode}\n`),
            border: [false, false, false, true],
        });
        dataRow.push({
            text: plans.map((item) => `${item.entryFeeForPerson}\n`),
        });
        dataRow.push({ text: countEntryFeeForPerson });

        countEntryFee += countEntryFeeForPerson;

        tableBody.push(dataRow);
    });

    tableBody.push([
        {},
        {},
        {},
        {
            text: `${i18n.t("trainerPage:CompetitionRequestDetails.total")} (${i18n.t(
                "trainerPage:CompetitionRequestDetails.rubleShort"
            )}.):`,
            style: "total",
        },
        {
            text: `${
                countEntryFee.toFixed(2) == Math.round(countEntryFee)
                    ? Math.round(countEntryFee)
                    : countEntryFee.toFixed(2)
            }`,
            bold: true,
        },
    ]);

    return tableBody;
}

function generatePdf(data) {
    let docDefinition = {
        content: [
            {
                text: i18n.t("trainerPage:CompetitionRequestDetails.detailsOfEntyFees"),
                style: "header",
            },
            {
                text: i18n.t("trainerPage:CompetitionRequestDetails.competition"),
                style: "subtitle",
            },
            {
                text: data.competition.name,
                style: "subheader",
            },
            {
                text: i18n.t("trainerPage:CompetitionRequestDetails.dateOf"),
                style: "subtitle",
            },
            {
                text: moment(data.competition.dateFrom).isSame(data.competition.dateTo)
                    ? `${moment(data.competition.dateFrom).format("Do MMMM YYYY")}`
                    : `${moment(data.competition.dateFrom).format("D MMM")} - ${moment(
                          data.competition.dateTo
                      ).format("D MMM YYYY")}`,
                style: "subheader",
            },
            {
                layout: "lightHorizontalLines",
                table: {
                    headerRows: 1,
                    widths: ["auto", "*", "auto", "auto", "auto"],

                    body: generateTableBody(data),
                },
            },
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 10],
            },
            subtitle: {
                fontSize: 10,
                color: "#505050",
            },
            subheader: {
                fontSize: 14,
                bold: true,
                margin: [0, 2, 0, 10],
            },
            total: {
                alignment: "right",
                bold: true,
            },
            tableHeader: {
                fillColor: "#eeeeee",
            },
        },
    };

    return docDefinition;
}

function downloadPdf(data) {
    const docDefinition = generatePdf(data);
    pdfMake
        .createPdf(docDefinition)
        .download(i18n.t("trainerPage:CompetitionRequestDetails.detailsOfEntyFees"));
}

export default downloadPdf;
