import { StoreonModule } from "storeon";
import {
    BeamerMode,
    BeamerOptions,
    BeamerSlideObject,
    RatingSlideObject,
    SlideMode,
    SlideType,
} from "../../Modules/Beamer/types";

export interface BeamerModuleState {
    socket: SocketIOClient.Socket;
    slideType: SlideType | null;
    perfomanceObject: BeamerSlideObject;
    options: BeamerOptions;
    updateTimeInterval: number;
    ratingSlide: RatingSlideObject;
}

export interface BeamerModuleEvents {
    initSocketInstance: SocketIOClient.Socket;
    setSlideType: SlideType | null;
    setPerfomanceObject: BeamerSlideObject;
    setOptions: BeamerOptions;
    setSlideShowing: boolean;
    setUpdateTimeInterval: number;
    setRatingSlide: RatingSlideObject;
}

export const beamerModule: StoreonModule<BeamerModuleState, BeamerModuleEvents> = (store) => {
    store.on("@init", () => ({
        perfomanceObject: defaultBeamerSlideObject,
        slideType: defaultSlideType,
        options: JSON.parse(
            sessionStorage.getItem("beamerOptions") ?? JSON.stringify(defaultOptions)
        ) as BeamerOptions,
        updateTimeInterval: defaultUpdateTimeInterval,
        ratingSlide: defaultRatingSlideObject,
    }));
    store.on("initSocketInstance", (state, socket: SocketIOClient.Socket) => ({
        ...state,
        socket,
    }));
    store.on("setSlideType", (state, newSlideType) => ({
        slideType: newSlideType,
    }));
    store.on("setPerfomanceObject", (state, newPerfomanceObject) => ({
        perfomanceObject: newPerfomanceObject,
    }));
    store.on("setOptions", (state, newOptions) => ({
        options: newOptions,
    }));
    store.on("setUpdateTimeInterval", (state, newUpdateTimeInterval) => ({
        updateTimeInterval: newUpdateTimeInterval,
    }));
    store.on("setRatingSlide", (state, newRatingSlide) => ({
        ratingSlide: newRatingSlide,
    }));
};

const defaultUpdateTimeInterval = 10000;

const defaultSlideType = null;

const defaultOptions: BeamerOptions = {
    mode: BeamerMode.AUTO,
    slideShowTime: 60000,
    slide: SlideMode.ALL,
};

export const defaultBeamerSlideObject: BeamerSlideObject = {
    performanceId: 1,
    place: 1,
    scores: {
        averageExecutionCalcMethod: 0,
        averageExecution: 0,
        averageArtistryCalcMethod: 0,
        averageArtistry: 0,
        averageComplexity: 0,
        complexityPenalty: 0,
        brigadePenalty: 0,
        linePenalty: 0,
        totalPenalty: 0,
        totalScore: 0,
    },
    athletes: [
        {
            id: 1,
            firstName: "firstName",
            lastName: "lastName",
            middleName: "middleName",
            genderId: 1,
            city: "city",
            country: "country",
        },
    ],
    ageCategoryCode: "ageCategoryCode",
    worldCode: "worldCode",
    appealName: "appealName",
    region: "region",
};

const defaultRatingSlideObject: RatingSlideObject = {
    categoryName: "123",
    ageCategoryName: "312",
    appealName: "string",
    performances: [defaultBeamerSlideObject],
};
