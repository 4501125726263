export const subjects = [
    {
        title: "Республика Адыгея",
        code: "01",
        gibdd: "01",
        okato: "79",
        code_iso_31662: "RU-AD",
        type: "республика",
        utc_offset: "+03:00",
    },
    {
        title: "Республика Алтай",
        code: "04",
        gibdd: "04",
        okato: "84",
        code_iso_31662: "RU-AL",
        type: "республика",
        utc_offset: "+07:00",
    },
    {
        title: "Республика Башкортостан",
        code: "02",
        gibdd: "02, 102",
        okato: "80",
        code_iso_31662: "RU-BA",
        type: "республика",
        utc_offset: "+05:00",
    },
    {
        title: "Республика Бурятия",
        code: "03",
        gibdd: "03",
        okato: "81",
        code_iso_31662: "RU-BU",
        type: "республика",
        utc_offset: "+07:00",
    },
    {
        title: "Республика Дагестан",
        code: "05",
        gibdd: "05",
        okato: "82",
        code_iso_31662: "RU-DA",
        type: "республика",
        utc_offset: "+03:00",
    },
    {
        title: "Республика Ингушетия",
        code: "06",
        gibdd: "06",
        okato: "26",
        code_iso_31662: "RU-IN",
        type: "республика",
        utc_offset: "+03:00",
    },
    {
        title: "Кабардино-Балкарская республика",
        code: "07",
        gibdd: "07",
        okato: "83",
        code_iso_31662: "RU-KB",
        type: "республика",
        utc_offset: "+03:00",
    },
    {
        title: "Республика Калмыкия",
        code: "08",
        gibdd: "08",
        okato: "85",
        code_iso_31662: "RU-KL",
        type: "республика",
        utc_offset: "+03:00",
    },
    {
        title: "Карачаево-Черкесская республика",
        code: "09",
        gibdd: "09",
        okato: "91",
        code_iso_31662: "RU-KC",
        type: "республика",
        utc_offset: "+03:00",
    },
    {
        title: "Республика Карелия",
        code: "10",
        gibdd: "10",
        okato: "86",
        code_iso_31662: "RU-KR",
        type: "республика",
        utc_offset: "+03:00",
    },
    {
        title: "Республика Коми",
        code: "11",
        gibdd: "11, 111",
        okato: "87",
        code_iso_31662: "RU-KO",
        type: "республика",
        utc_offset: "+03:00",
    },
    {
        title: "Республика Крым",
        code: "91",
        gibdd: "82",
        okato: "35",
        code_iso_31662: "RU-CR",
        type: "республика",
        utc_offset: "+03:00",
    },
    {
        title: "Республика Марий Эл",
        code: "12",
        gibdd: "12",
        okato: "88",
        code_iso_31662: "RU-ME",
        type: "республика",
        utc_offset: "+03:00",
    },
    {
        title: "Республика Мордовия",
        code: "13",
        gibdd: "13, 113",
        okato: "89",
        code_iso_31662: "RU-MO",
        type: "республика",
        utc_offset: "+03:00",
    },
    {
        title: "Республика Саха (Якутия)",
        code: "14",
        gibdd: "14",
        okato: "98",
        code_iso_31662: "RU-SA",
        type: "республика",
        utc_offset: "+09:00",
    },
    {
        title: "Республика Северная Осетия — Алания",
        code: "15",
        gibdd: "15",
        okato: "90",
        code_iso_31662: "RU-SE",
        type: "республика",
        utc_offset: "+03:00",
    },
    {
        title: "Республика Татарстан",
        code: "16",
        gibdd: "16, 116",
        okato: "92",
        code_iso_31662: "RU-TA",
        type: "республика",
        utc_offset: "+03:00",
    },
    {
        title: "Республика Тыва",
        code: "17",
        gibdd: "17",
        okato: "93",
        code_iso_31662: "RU-TY",
        type: "республика",
        utc_offset: "+07:00",
    },
    {
        title: "Удмуртская республика",
        code: "18",
        gibdd: "18",
        okato: "94",
        code_iso_31662: "RU-UD",
        type: "республика",
        utc_offset: "+04:00",
    },
    {
        title: "Республика Хакасия",
        code: "19",
        gibdd: "19",
        okato: "95",
        code_iso_31662: "RU-KK",
        type: "республика",
        utc_offset: "+07:00",
    },
    {
        title: "Чеченская республика",
        code: "20",
        gibdd: "95",
        okato: "96",
        code_iso_31662: "RU-CE",
        type: "республика",
        utc_offset: "+03:00",
    },
    {
        title: "Чувашская республика",
        code: "21",
        gibdd: "21, 121",
        okato: "97",
        code_iso_31662: "RU-CU",
        type: "республика",
        utc_offset: "+03:00",
    },
    {
        title: "Донецкая Народная Республика",
        code: "86",
        type: "республика",
    },
    {
        title: "Луганская Народная Республика",
        code: "87",
        type: "республика",
    },
    {
        title: "Алтайский край",
        code: "22",
        gibdd: "22",
        okato: "01",
        code_iso_31662: "RU-ALT",
        type: "край",
        utc_offset: "+07:00",
    },
    {
        title: "Забайкальский край",
        code: "75",
        gibdd: "75, 80",
        okato: "76",
        code_iso_31662: "RU-ZAB",
        type: "край",
        utc_offset: "+09:00",
    },
    {
        title: "Камчатский край",
        code: "41",
        gibdd: "41",
        okato: "30",
        code_iso_31662: "RU-KAM",
        type: "край",
        utc_offset: "+12:00",
    },
    {
        title: "Краснодарский край",
        code: "23",
        gibdd: "23, 93, 123",
        okato: "03",
        code_iso_31662: "RU-KDA",
        type: "край",
        utc_offset: "+03:00",
    },
    {
        title: "Красноярский край",
        code: "24",
        gibdd: "24, 124, 84, 88",
        okato: "04",
        code_iso_31662: "RU-KYA",
        type: "край",
        utc_offset: "+07:00",
    },
    {
        title: "Пермский край",
        code: "59",
        gibdd: "59, 81, 159",
        okato: "57",
        code_iso_31662: "RU-PER",
        type: "край",
        utc_offset: "+05:00",
    },
    {
        title: "Приморский край",
        code: "25",
        gibdd: "25, 125",
        okato: "05",
        code_iso_31662: "RU-PRI",
        type: "край",
        utc_offset: "+10:00",
    },
    {
        title: "Ставропольский край",
        code: "26",
        gibdd: "26, 126",
        okato: "07",
        code_iso_31662: "RU-STA",
        type: "край",
        utc_offset: "+03:00",
    },
    {
        title: "Хабаровский край",
        code: "27",
        gibdd: "27",
        okato: "08",
        code_iso_31662: "RU-KHA",
        type: "край",
        utc_offset: "+10:00",
    },
    {
        title: "Амурская область",
        code: "28",
        gibdd: "28",
        okato: "10",
        code_iso_31662: "RU-AMU",
        type: "область",
        utc_offset: "+09:00",
    },
    {
        title: "Архангельская область",
        code: "29",
        gibdd: "29",
        okato: "11",
        code_iso_31662: "RU-ARK",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Астраханская область",
        code: "30",
        gibdd: "30",
        okato: "12",
        code_iso_31662: "RU-AST",
        type: "область",
        utc_offset: "+04:00",
    },
    {
        title: "Белгородская область",
        code: "31",
        gibdd: "31",
        okato: "14",
        code_iso_31662: "RU-BEL",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Брянская область",
        code: "32",
        gibdd: "32",
        okato: "15",
        code_iso_31662: "RU-BRY",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Владимирская область",
        code: "33",
        gibdd: "33",
        okato: "17",
        code_iso_31662: "RU-VLA",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Волгоградская область",
        code: "34",
        gibdd: "34, 134",
        okato: "18",
        code_iso_31662: "RU-VGG",
        type: "область",
        utc_offset: "+04:00",
    },
    {
        title: "Вологодская область",
        code: "35",
        gibdd: "35",
        okato: "19",
        code_iso_31662: "RU-VLG",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Воронежская область",
        code: "36",
        gibdd: "36, 136",
        okato: "20",
        code_iso_31662: "RU-VOR",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Запорожская область",
        code: "88",
        type: "область",
    },
    {
        title: "Ивановская область",
        code: "37",
        gibdd: "37",
        okato: "24",
        code_iso_31662: "RU-IVA",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Иркутская область",
        code: "38",
        gibdd: "38, 138, 85",
        okato: "25",
        code_iso_31662: "RU-IRK",
        type: "область",
        utc_offset: "+08:00",
    },
    {
        title: "Калининградская область",
        code: "39",
        gibdd: "39, 91",
        okato: "27",
        code_iso_31662: "RU-KGD",
        type: "область",
        utc_offset: "+02:00",
    },
    {
        title: "Калужская область",
        code: "40",
        gibdd: "40",
        okato: "29",
        code_iso_31662: "RU-KLU",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Кемеровская область",
        code: "42",
        gibdd: "42, 142",
        okato: "32",
        code_iso_31662: "RU-KEM",
        type: "область",
        utc_offset: "+07:00",
    },
    {
        title: "Кировская область",
        code: "43",
        gibdd: "43",
        okato: "33",
        code_iso_31662: "RU-KIR",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Костромская область",
        code: "44",
        gibdd: "44",
        okato: "34",
        code_iso_31662: "RU-KOS",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Курганская область",
        code: "45",
        gibdd: "45",
        okato: "37",
        code_iso_31662: "RU-KGN",
        type: "область",
        utc_offset: "+05:00",
    },
    {
        title: "Курская область",
        code: "46",
        gibdd: "46",
        okato: "38",
        code_iso_31662: "RU-KRS",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Ленинградская область",
        code: "47",
        gibdd: "47",
        okato: "41",
        code_iso_31662: "RU-LEN",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Липецкая область",
        code: "48",
        gibdd: "48",
        okato: "42",
        code_iso_31662: "RU-LIP",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Магаданская область",
        code: "49",
        gibdd: "49",
        okato: "44",
        code_iso_31662: "RU-MAG",
        type: "область",
        utc_offset: "+11:00",
    },
    {
        title: "Московская область",
        code: "50",
        gibdd: "50, 90, 150, 190, 750",
        okato: "46",
        code_iso_31662: "RU-MOS",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Мурманская область",
        code: "51",
        gibdd: "51",
        okato: "47",
        code_iso_31662: "RU-MUR",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Нижегородская область",
        code: "52",
        gibdd: "52, 152",
        okato: "22",
        code_iso_31662: "RU-NIZ",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Новгородская область",
        code: "53",
        gibdd: "53",
        okato: "49",
        code_iso_31662: "RU-NGR",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Новосибирская область",
        code: "54",
        gibdd: "54, 154",
        okato: "50",
        code_iso_31662: "RU-NVS",
        type: "область",
        utc_offset: "+07:00",
    },
    {
        title: "Омская область",
        code: "55",
        gibdd: "55",
        okato: "52",
        code_iso_31662: "RU-OMS",
        type: "область",
        utc_offset: "+06:00",
    },
    {
        title: "Оренбургская область",
        code: "56",
        gibdd: "56",
        okato: "53",
        code_iso_31662: "RU-ORE",
        type: "область",
        utc_offset: "+05:00",
    },
    {
        title: "Орловская область",
        code: "57",
        gibdd: "57",
        okato: "54",
        code_iso_31662: "RU-ORL",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Пензенская область",
        code: "58",
        gibdd: "58",
        okato: "56",
        code_iso_31662: "RU-PNZ",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Псковская область",
        code: "60",
        gibdd: "60",
        okato: "58",
        code_iso_31662: "RU-PSK",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Ростовская область",
        code: "61",
        gibdd: "61, 161",
        okato: "60",
        code_iso_31662: "RU-ROS",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Рязанская область",
        code: "62",
        gibdd: "62",
        okato: "61",
        code_iso_31662: "RU-RYA",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Самарская область",
        code: "63",
        gibdd: "63, 163",
        okato: "36",
        code_iso_31662: "RU-SAM",
        type: "область",
        utc_offset: "+04:00",
    },
    {
        title: "Саратовская область",
        code: "64",
        gibdd: "64, 164",
        okato: "63",
        code_iso_31662: "RU-SAR",
        type: "область",
        utc_offset: "+04:00",
    },
    {
        title: "Сахалинская область",
        code: "65",
        gibdd: "65",
        okato: "64",
        code_iso_31662: "RU-SAK",
        type: "область",
        utc_offset: "+11:00",
    },
    {
        title: "Свердловская область",
        code: "66",
        gibdd: "66, 96, 196",
        okato: "65",
        code_iso_31662: "RU-SVE",
        type: "область",
        utc_offset: "+05:00",
    },
    {
        title: "Смоленская область",
        code: "67",
        gibdd: "67",
        okato: "66",
        code_iso_31662: "RU-SMO",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Тамбовская область",
        code: "68",
        gibdd: "68",
        okato: "68",
        code_iso_31662: "RU-TAM",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Тверская область",
        code: "69",
        gibdd: "69",
        okato: "28",
        code_iso_31662: "RU-TVE",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Томская область",
        code: "70",
        gibdd: "70",
        okato: "69",
        code_iso_31662: "RU-TOM",
        type: "область",
        utc_offset: "+07:00",
    },
    {
        title: "Тульская область",
        code: "71",
        gibdd: "71",
        okato: "70",
        code_iso_31662: "RU-TUL",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Тюменская область",
        code: "72",
        gibdd: "72",
        okato: "71",
        code_iso_31662: "RU-TYU",
        type: "область",
        utc_offset: "+05:00",
    },
    {
        title: "Ульяновская область",
        code: "73",
        gibdd: "73, 173",
        okato: "73",
        code_iso_31662: "RU-ULY",
        type: "область",
        utc_offset: "+04:00",
    },
    {
        title: "Херсонская область",
        code: "89",
        type: "область",
    },
    {
        title: "Челябинская область",
        code: "74",
        gibdd: "74, 174",
        okato: "75",
        code_iso_31662: "RU-CHE",
        type: "область",
        utc_offset: "+05:00",
    },
    {
        title: "Ярославская область",
        code: "76",
        gibdd: "76",
        okato: "78",
        code_iso_31662: "RU-YAR",
        type: "область",
        utc_offset: "+03:00",
    },
    {
        title: "Москва",
        code: "77",
        gibdd: "77, 97, 99, 177, 197, 199, 777",
        okato: "45",
        code_iso_31662: "RU-MOW",
        type: "город федерального значения",
        utc_offset: "+03:00",
    },
    {
        title: "Санкт-Петербург",
        code: "78",
        gibdd: "78, 98, 178",
        okato: "40",
        code_iso_31662: "RU-SPE",
        type: "город федерального значения",
        utc_offset: "+03:00",
    },
    {
        title: "Севастополь",
        code: "92",
        gibdd: "92",
        okato: "67",
        code_iso_31662: "RU-SEV",
        type: "город федерального значения",
        utc_offset: "+03:00",
    },
    {
        title: "Еврейская автономная область",
        code: "79",
        gibdd: "79",
        okato: "99",
        code_iso_31662: "RU-YEV",
        type: "автономная область",
        utc_offset: "+10:00",
    },
    {
        title: "Ненецкий автономный округ",
        code: "83",
        gibdd: "83",
        okato: "11-8",
        code_iso_31662: "RU-NEN",
        type: "автономный округ",
        utc_offset: "+03:00",
    },
    {
        title: "Ханты-Мансийский автономный округ - Югра",
        code: "86",
        gibdd: "86, 186",
        okato: "71-8",
        code_iso_31662: "RU-KHM",
        type: "автономный округ",
        utc_offset: "+05:00",
    },
    {
        title: "Чукотский автономный округ",
        code: "87",
        gibdd: "87",
        okato: "77",
        code_iso_31662: "RU-CHU",
        type: "автономный округ",
        utc_offset: "+12:00",
    },
    {
        title: "Ямало-Ненецкий автономный округ",
        code: "89",
        gibdd: "89",
        okato: "71-9",
        code_iso_31662: "RU-YAN",
        type: "автономный округ",
        utc_offset: "+05:00",
    },
];
