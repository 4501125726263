import React, { useState, useEffect, useMemo } from "react";
import JudgeItem from "./judge/judge-item";
import { PerformanceReferee } from "../../../interfaces/performance-referee";
import ProtocolModal from "../components/protocols/protocol-modal";
import { Performance } from "../../../interfaces/performance";
import { useSnackbar } from "notistack";
import { EVENTS } from "../../../Modules/Referee/Events";
import { Button, Container, Typography, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStoreon } from "storeon/react";
import { RefereeModuleEvents, RefereeModuleState } from "../../../store/StoreModules/RefereeModule";

export default function JudgeView() {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { t } = useTranslation("refereePage");

    const { performanceReferees, performanceDetails, socket, referee } = useStoreon<
        RefereeModuleState,
        RefereeModuleEvents
    >("performanceReferees", "performanceDetails", "socket", "referee");

    const [modalShow, setModalShow] = useState<boolean>(false);

    const performance = performanceDetails || ({} as Performance);

    const performanceReferee = useMemo(() => {
        let res = {} as PerformanceReferee;
        performanceReferees?.forEach((item: PerformanceReferee) => {
            if (item.refereeId === referee?.id) res = item;
        });

        return res;
    }, [performanceReferees, referee]);

    useEffect(() => {
        if (!performanceDetails && modalShow) {
            setModalShow(false);
        }
    }, [performanceDetails]);

    useEffect((): any => {
        socket?.on(EVENTS.REFEREE_GOT_SIGNAL, (value: "up" | "down") => {
            const msg =
                value === "up" ? (
                    <h5>
                        {t("arbiterDemains")} <b>{t("up")}</b> {t("grade")}
                    </h5>
                ) : (
                    <h5>
                        {t("arbiterDemains")} <b>{t("down")}</b> {t("grade")}
                    </h5>
                );
            enqueueSnackbar(msg, {
                variant: value === "up" ? "success" : "error",
                persist: true,
                action: (key) => (
                    <Button
                        onClick={() => {
                            socket?.emit(EVENTS.REFEREE_RESPOND_SIGNAL);
                            closeSnackbar(key);
                        }}
                        color="inherit"
                    >
                        {t("close")}
                    </Button>
                ),
            });
        });

        return () => socket?.removeListener(EVENTS.REFEREE_GOT_SIGNAL);
    }, [socket]);

    if (!performanceDetails) {
        return (
            <Grid container justifyContent="center">
                <Typography variant="h6">{t("youHaveNoAppointments")}</Typography>
            </Grid>
        );
    }

    return (
        <>
            <Container>
                <JudgeItem
                    performanceDetails={performanceDetails}
                    performanceReferee={performanceReferee}
                    onClick={() => setModalShow(true)}
                />
            </Container>
            {modalShow && referee && (
                <ProtocolModal
                    show={modalShow}
                    onClose={() => setModalShow(false)}
                    referee={referee}
                    performanceDetails={performance}
                    performanceReferee={performanceReferee}
                />
            )}
        </>
    );
}
