import React, { FunctionComponent, useState } from "react";
import { Grid, Typography } from "@material-ui/core";
import LoadingIndicator from "../../Common/LoadingIndicator";
import RequestDialog from "./RequestDialog";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Competition } from "@/interfaces";
import CompetitionCard from "../../../Views/CompetitionCard";
import Auth from "@/modules/Auth";
import RequestManager from "@/modules/RequestManager";

import "../../../Css/TrainerPage.css";

const CompetitionsList: FunctionComponent = () => {
    const { t } = useTranslation("trainerPage");
    const trainerId = Auth.getAuthUser().trainerId;

    const [selectedCompetition, setSelectedCompetition] = useState<Competition | null>(null);
    const [requestsForSelectedCompetition, setRequestsForSelectedCompetition] = useState<any[]>([]);
    const [open, setOpen] = useState<boolean>(false);

    const { data: competitions, isFetching, isFetched } = useQuery(
        ["competitions"],
        () => RequestManager.fetch("trainer/competitions", "get"),
        { select: (result) => result.body.data.competitions }
    );

    const { data: requests, isFetching: isRequestsFetching, refetch: refetchRequests } = useQuery(
        ["requests"],
        () =>
            RequestManager.fetch("trainer/requests", "get", {
                trainerId,
            }),
        { select: (result) => result.body.data.trainerRequests }
    );

    const sortRequestsByCompetition = (selectedItem, requests: any = []) => {
        if (!selectedItem) return [];

        const requestsByCompetition: any[] = [];
        requests?.forEach((item) => {
            if (item.plan.competition.id === selectedItem.id) {
                requestsByCompetition.push(item);
            }
        });
        return requestsByCompetition;
    };

    const handleRequest = (competition): void => {
        const sortedRequests = sortRequestsByCompetition(competition, requests);
        setSelectedCompetition(competition);
        setRequestsForSelectedCompetition(sortedRequests);
        setOpen(true);
    };

    const handleUpdateRequests = async () => {
        const { data } = await refetchRequests();
        const sortedRequests = sortRequestsByCompetition(selectedCompetition, data);
        setRequestsForSelectedCompetition(sortedRequests);
    };

    const handleClose: VoidFunction = () => {
        setSelectedCompetition(null);
        setRequestsForSelectedCompetition([]);
        setOpen(false);
    };

    if (isFetching || isRequestsFetching) {
        <LoadingIndicator show={isFetching} />;
    }

    return (
        <Grid container justify="space-between" className="main-view-head">
            <Grid item xs={12}>
                <Typography variant="h4">{t("competitionsList.competitions")}</Typography>
            </Grid>

            <Grid container spacing={2}>
                {isFetched && !competitions?.current.length && (
                    <Typography variant="body1">{t("competitionsList.noCompetitions")}</Typography>
                )}

                {competitions?.current.map((competition) => (
                    <Grid item xs={12} md={4} key={competition.id}>
                        <CompetitionCard
                            {...competition}
                            onApplyClick={handleRequest}
                            hasRequest={Boolean(
                                sortRequestsByCompetition(competition, requests).length
                            )}
                        />
                    </Grid>
                ))}
            </Grid>
            {open && (
                <RequestDialog
                    data={{
                        competition: selectedCompetition,
                        trainerId,
                        requests: requestsForSelectedCompetition,
                    }}
                    onUpdate={handleUpdateRequests}
                    onClose={handleClose}
                />
            )}
        </Grid>
    );
};

export default CompetitionsList;
