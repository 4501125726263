import React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    Typography,
} from "@material-ui/core";
import RequestManager from "../../../../Modules/RequestManager";
import PropTypes from "prop-types";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import "./RequestsList.css";
import { withTranslation } from "react-i18next";

class EntryFeeDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: this.props.open,
            requests: [],
            plans: [],
        };
    }

    getData = async () => {
        await this.getCompetitionPlans();
        await this.getRequests();
    };

    getCompetitionPlans = async () => {
        await RequestManager.fetch(
            `v2/competitionPlans/${this.props.data.competitionId}`,
            "get"
        ).then((result) => {
            this.setState({
                plans: result.body.CompetitionPlans,
            });
        });
    };

    getRequests = async () => {
        await RequestManager.fetch(
            `requestsByCompetitionId/${this.props.data.competitionId}`,
            "get"
        ).then((result) => {
            this.setState({
                requests: result.body.Requests,
            });
        });
    };

    handleClose = () => {
        this.props.onClose();
    };

    renderTrainerRequests = (trainer) => {
        const { requests, plans } = this.state;

        if (requests.length === 0) return;

        return plans.map((plan) => {
            let countEntryFee = 0;
            let count = 0;
            let requestDetails = [];

            requests.forEach((request) => {
                if (trainer === request.author && plan.id === request.competitionPlanId) {
                    countEntryFee += plan.entryFee;
                    count++;
                    requestDetails.push(
                        <Typography key={request.competitionRequestId}>
                            <b>{count}.</b> {request.competitor}
                        </Typography>
                    );
                }
            });
            return (
                <ExpansionPanel key={plan.id}>
                    <ExpansionPanelSummary>
                        <Grid item xs={9}>
                            <Typography>
                                {plan.category.name}, {plan.ageCategory.name} (
                                {plan.category.worldCode})
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography>{count !== 0 ? `(${count})` : ""}</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <Typography>
                                {countEntryFee !== 0 ? <b>{countEntryFee} &#8381;</b> : "-"}
                            </Typography>
                        </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                        <Grid item xs={12}>
                            {requestDetails}
                        </Grid>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            );
        });
    };

    getCountEntryFeeByTrainer = (trainer) => {
        const { requests, plans } = this.state;

        let countEntryFee = 0;

        plans.forEach((plan) => {
            requests.forEach((request) => {
                if (trainer === request.author && plan.id === request.competitionPlanId)
                    countEntryFee += plan.entryFee;
            });
        });

        return countEntryFee;
    };

    render() {
        const { t } = this.props;
        const { requests } = this.state;
        let uniqueTrainers = [...new Set(requests.map((item) => item.author))];
        uniqueTrainers.forEach((item, index) => {
            if (item === "") uniqueTrainers.splice(index, 1);
        });
        return (
            <Dialog
                fullWidth
                open={this.props.open}
                onEnter={this.getData}
                className="entry-fee-dialog"
            >
                <DialogTitle disableTypography>{t("fees")}</DialogTitle>
                <DialogContent>
                    {uniqueTrainers.length > 0
                        ? uniqueTrainers.map((trainer, index) => {
                              return trainer !== "" ? (
                                  <ExpansionPanel key={index}>
                                      <ExpansionPanelSummary
                                          expandIcon={<ExpandMoreIcon />}
                                          className="entry-fee-dialog__trainer-summary"
                                      >
                                          <Typography>
                                              <b>{trainer}</b>
                                          </Typography>
                                          <Typography>
                                              <b>
                                                  {this.getCountEntryFeeByTrainer(trainer)} &#8381;
                                              </b>
                                          </Typography>
                                      </ExpansionPanelSummary>
                                      <ExpansionPanelDetails className="entry-fee-dialog__trainer-details">
                                          <Grid item xs={12}>
                                              {this.renderTrainerRequests(trainer)}
                                          </Grid>
                                      </ExpansionPanelDetails>
                                  </ExpansionPanel>
                              ) : null;
                          })
                        : t("nothingHere")}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary" variant="contained">
                        {t("close")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

EntryFeeDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default withTranslation("adminPage")(EntryFeeDialog);
