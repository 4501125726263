import React from "react";
import FormControl from "react-bootstrap/es/FormControl";
import PropTypes from "prop-types";
import Utils from "../../../Modules/Utils";

class NumberInput extends React.Component {
    constructor(props) {
        super(props);
        this.accuracy = props.accuracy || 0;
        this.defaultValue = props.defaultValue || 0;

        this.state = {
            value: this.defaultValue.toFixed(this.accuracy),
        };

        this.onInputFocused = this.onInputFocused.bind(this);
        this.onInputUnfocused = this.onInputUnfocused.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    onChange(event) {
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    onInputFocused(event) {
        this.setState({ tempValue: event.target.value });
        this.setState({ value: "" });
    }

    onInputUnfocused(event) {
        if (this.state.value === "") {
            this.setState({ value: this.state.tempValue });
        } else {
            this.setState({
                value: (+this.state.value).toFixed(this.accuracy),
            });
            this.onChange(event);
        }
    }

    changeHandler(event) {
        const v = event.target.value;
        if (Utils.isValueInRange(v, this.props.minValue, this.props.maxValue)) {
            let fixedV = (+v).toFixed(this.accuracy);
            this.setState({
                value: v.length > fixedV.length ? fixedV : v,
            });
        }
    }

    render() {
        return (
            <FormControl
                className={this.props.className}
                onFocus={this.onInputFocused}
                onBlur={this.onInputUnfocused}
                type="number"
                onChange={this.changeHandler}
                value={this.state.value}
            />
        );
    }
}

NumberInput.propTypes = {
    accuracy: PropTypes.number,
    defaultValue: PropTypes.number,
    onChange: PropTypes.func,
    className: PropTypes.string,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
};

export default NumberInput;
