import React from "react";
import PropTypes from "prop-types";
import RequestManager from "../../Modules/RequestManager";
import PerformanceSearchResultsGrid from "./PerformanceSearchResultsGrid";

class PerformanceScoresForCompetitionFromArchive extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            competitionResultId: this.props.competitionResultId || this.props.match.params.id,
        };
        this.getPerformanceScoresForCompetitionFromArchive = this.getPerformanceScoresForCompetitionFromArchive.bind(
            this
        );
    }

    componentDidMount() {
        this.getPerformanceScoresForCompetitionFromArchive(
            this.state.competitionResultId
        ).then(() => this.setState({ isLoading: false }));
    }

    getPerformanceScoresForCompetitionFromArchive(competitionResultId) {
        return RequestManager.getPerformanceScoresForCompetitionFromArchive(
            competitionResultId
        ).then((response) => {
            this.setState({
                performanceScores: response.body.PerformanceScores,
            });
        });
    }

    render() {
        return (
            <div>
                {this.state.isLoading && (
                    <div className="spinner-background">
                        <div className="spinner"></div>
                    </div>
                )}
                {!this.state.isLoading && (
                    <PerformanceSearchResultsGrid
                        searchResults={this.state.performanceScores}
                        showSearchResultsHeader={false}
                    />
                )}
            </div>
        );
    }
}

PerformanceScoresForCompetitionFromArchive.propTypes = {
    competitionResultId: PropTypes.string,
    // Props from router
    match: PropTypes.object,
};

export default PerformanceScoresForCompetitionFromArchive;
