import RequestManager from "./RequestManager";
import i18n from "i18next";

export type BunchReportParams = {
    plans: { cp: number; cs: number }[];
    regionView: "region" | "city";
    competitionName: string;
    place: string;
    dateFrom: string;
    dateTo: string;
    docType: "pdf" | "xlsx";
};
class ReportsManager {
    static getBunchPerformancesReport = async ({
        plans,
        regionView = "region",
        competitionName = "",
        place = "",
        dateFrom = "",
        dateTo = "",
        docType = "pdf",
    }: BunchReportParams) => {
        await RequestManager.fetchFile(`bunchPerformanceReport`, `report.${docType}`, false, {
            method: "POST",
            data: {
                plans,
                regionView,
                competitionName,
                place,
                dateFrom,
                dateTo,
                docType,
            },
        });
    };

    /** @deprecated */
    static async getCompetitionRefereesReport(competitionId: number) {
        await RequestManager.fetchFile(
            `reports/competition-referees`,
            `${i18n.t("modules:refereesList")}.pdf`,
            false,
            { data: { competitionId }, apiVersion: 2 }
        );
    }

    static async getCompetitionSubjectsReport(competitionId: number) {
        await RequestManager.fetchFile(
            `reports/competition-subjects`,
            `${i18n.t("modules:regionsList")}.xlsx`,
            false,
            { data: { competitionId }, apiVersion: 2 }
        );
    }

    static async getAthletesWithoutLicensesReport(competitionId: number) {
        await RequestManager.fetchFile(
            `reports/athletes-without-licenses`,
            `no-licenses.xlsx`,
            false,
            { data: { competitionId }, apiVersion: 2 }
        );
    }

    static async getRequestsReport(competitionId: number) {
        await RequestManager.fetchFile(`reports/requests`, `requests.xlsx`, false, {
            data: { competitionId },
            apiVersion: 2,
        });
    }

    static async getTrainerRefereesReport(competitionId: number) {
        await RequestManager.fetchFile(`reports/trainer-referees`, `trainer-referees.xlsx`, false, {
            data: { competitionId },
            apiVersion: 2,
        });
    }

    static async getAthleteRanksReport(competitionId: number) {
        await RequestManager.fetchFile(`reports/athlete-ranks`, `athlete-ranks.xlsx`, false, {
            data: { competitionId },
            apiVersion: 2,
        });
    }

    static async getNominalRegistrationReport(trainerId: number, competitionId: number) {
        await RequestManager.fetchFile(
            `reports/nominal-registration`,
            `nominal-registration.xlsx`,
            false,
            {
                data: { trainerId, competitionId },
                apiVersion: 2,
            }
        );
    }

    static getSubjectAthletesReport(competitionId: number) {
        RequestManager.fetchFile(`reports/subject-athletes`, `subject-athletes.xlsx`, false, {
            data: { competitionId },
            apiVersion: 2,
        });
    }

    static getRefereeLotteryReport(data) {
        RequestManager.fetchFile(`reports/referee-lottery`, `referee-lottery.xlsx`, false, {
            data,
            apiVersion: 2,
            method: "POST",
        });
    }
}

export default ReportsManager;
