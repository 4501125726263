import React from "react";
import SaveCancelPanel from "../../../Protocols/SaveCancelPanel";
import CriteriaManager from "../../../../../Modules/CriteriaManager";
import RefereeRoleManager from "../../../../../Modules/RefereeRoleManager";
import CriterionGroup from "../../../Protocols/Criteria/CriterionGroup";
import CriterionTypesManager from "../../../../../Modules/CriterionTypesManager";
import RefereeHeaderScore from "../../../Protocols/RefereeHeaderScore";
import Helpers from "../../../../../Modules/Helpers";
import { PerformanceReferee } from "../../../../../interfaces/performance-referee";

const onlyCriterionGroups = [8]; // LINE_CRITERION_GROUP_NUMBER
const numberOfDigitsAfterDotInTotalScore = 2;

interface Props {
    performanceReferee: PerformanceReferee;
    onSave: (scores) => Promise<void>;
    onClose: () => void;
}

interface Score {
    performanceRefereeId: number;
    criterionId: number;
    score: {} | null;
    penalty: {} | null;
}

class ProtocolLineReferee extends React.Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.state = {
            criterionGroups: null,
            isLoading: true,
            totalScore: null,
        };
    }

    componentDidMount() {
        this.loadCriteria().then(() => {
            this.setState({
                isLoading: false,
            });
        });
    }

    loadCriteria() {
        return CriteriaManager.getCriteriaAndGroups(RefereeRoleManager.ROLES.lineReferee.id)
            .then((response) => {
                const criterionGroups = response.body.criterionGroups;

                this.setState({
                    criterionGroups,
                });
            })
            .then(() => {
                return this.loadCriteriaScores();
            });
    }

    loadCriteriaScores() {
        return CriteriaManager.loadScores(this.props.performanceReferee.id).then((result) => {
            const scores = result.body;
            const criterionGroups = this.state.criterionGroups;
            criterionGroups.forEach((group) => {
                group.criteria.forEach((criterion) => {
                    if (scores[criterion.criterionId]) {
                        criterion.score = scores[criterion.criterionId].score;
                        criterion.penalty = scores[criterion.criterionId].penalty;
                    } else {
                        criterion.score = null;
                        criterion.penalty = null;
                    }
                    return criterion;
                });
                return group;
            });
            this.setState({
                criterionGroups: criterionGroups,
                scoresLoaded: true,
            });
            this.recalculateTotalScores();
        });
    }

    roundGrade(g) {
        return Math.round(g);
    }

    recalculateTotalScores() {
        let totalScore = 0;
        for (const group of this.state.criterionGroups) {
            for (const criterion of group.criteria) {
                if (
                    criterion.criterionTypeId === CriterionTypesManager.CRITERION_TYPES.penalty.id
                ) {
                    totalScore = +(totalScore - criterion.penalty).toFixed(
                        numberOfDigitsAfterDotInTotalScore
                    );
                } else if (
                    criterion.criterionTypeId === CriterionTypesManager.CRITERION_TYPES.score.id
                ) {
                    totalScore = +(totalScore + criterion.score).toFixed(
                        numberOfDigitsAfterDotInTotalScore
                    );
                }
            }
        }

        this.setState({
            totalScore,
        });
    }

    onChange = (value, groupId, criterionId) => {
        this.recalculateTotalScores();
    };

    getGroups() {
        return this.state.criterionGroups.map((group, idx) => (
            <CriterionGroup
                criterionGroup={group}
                key={group.criterionGroupId}
                onChange={this.onChange}
            />
        ));
    }

    getScoresReqObject() {
        const scoresReqObject: Score[] = [];

        this.state.criterionGroups.forEach((group) => {
            group.criteria.forEach((criterion) => {
                scoresReqObject.push({
                    performanceRefereeId: this.props.performanceReferee.id,
                    criterionId: criterion.criterionId,
                    score: Helpers.getNullOrFixedNumber(criterion.score, 2),
                    penalty: Helpers.getNullOrFixedNumber(criterion.penalty, 2),
                });
            });
        });
        return scoresReqObject;
    }

    onSave = async () => {
        const scores = this.getScoresReqObject();
        await this.props.onSave(scores);
    };

    render() {
        if (this.state.isLoading === true) {
            return null;
        }

        return (
            <div>
                <RefereeHeaderScore isVisible={true} totalScore={this.state.totalScore} />
                <div id="protocol-artistry-container">
                    {this.getGroups()}
                    <SaveCancelPanel onSave={this.onSave} onCancel={this.props.onClose} />
                </div>
            </div>
        );
    }
}

export default ProtocolLineReferee;
