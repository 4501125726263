import qs from "query-string";
import { useLocation } from "react-router-dom";

const useQueryParams = <T = {}>(): T => {
    const { search } = useLocation();
    const params = qs.parse(search, { parseNumbers: true });

    return (params as unknown) as T;
};

export default useQueryParams;
