import React from "react";
import PropTypes from "prop-types";
import RequestManager from "../Modules/RequestManager";
import PerformanceSearchResultsGrid from "../Controls/Performances/PerformanceSearchResultsGrid";

class PerformanceScoresSearchResults extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            searchName: this.props.searchName || this.props.match.params.searchName,
            searchBy: this.props.searchBy || this.props.match.params.searchBy,
        };
        this.getPerformanceScoresSearchResults = this.getPerformanceScoresSearchResults.bind(this);
    }

    componentDidMount() {
        this.getPerformanceScoresSearchResults(
            this.state.searchName,
            this.state.searchBy
        ).then(() => this.setState({ isLoading: false }));
    }

    componentDidUpdate(nextProps, nextState) {
        if (
            this.props.match.params.searchBy !== nextProps.match.params.searchBy ||
            this.props.match.params.searchName !== nextProps.match.params.searchName
        ) {
            let searchName = this.props.searchName || this.props.match.params.searchName;
            let searchBy = this.props.searchBy || this.props.match.params.searchBy;
            this.setState({
                searchName: searchName,
                searchBy: searchBy,
            });
            this.getPerformanceScoresSearchResults(searchName, searchBy);
        }
    }

    getPerformanceScoresSearchResults(searchName, searchBy) {
        this.setState({
            isLoading: true,
        });
        return RequestManager.getPerformanceScoresSearchResults(searchName, searchBy).then(
            (response) => {
                this.setState({
                    searchResults: response.body.searchResults,
                    isLoading: false,
                });
            }
        );
    }

    render() {
        return (
            <div>
                {this.state.isLoading && (
                    <div className="spinner-background">
                        <div className="spinner"></div>
                    </div>
                )}
                {!this.state.isLoading && (
                    <PerformanceSearchResultsGrid
                        searchResults={this.state.searchResults}
                        searchName={this.state.searchName}
                        showSearchResultsHeader={true}
                    />
                )}
            </div>
        );
    }
}

PerformanceScoresSearchResults.propTypes = {
    searchName: PropTypes.string,
    searchBy: PropTypes.string,
    // Props from router
    match: PropTypes.object,
};

export default PerformanceScoresSearchResults;
