import React from "react";
import { Tabs, Tab, Grid, FormControlLabel, Switch } from "@material-ui/core";
import TabPanel from "../../../Common/TabPanel";
import RequestManager from "../../../../Modules/RequestManager";
import Helpers from "../../../../Modules/Helpers";
import PropTypes from "prop-types";
import PlanRequestsList from "./PlanRequestsList";
import "./RequestsList.css";
import { NotificationManager } from "react-notifications";
import { withTranslation } from "react-i18next";

class RequestsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            selectedCategory: null,
            selectedAge: null,
            allowRequests: false,
        };

        this.updateRequests = this.updateRequests.bind(this);
        this.handleAgeSelect = this.handleAgeSelect.bind(this);
        this.handleCategorySelect = this.handleCategorySelect.bind(this);
        this.checkSelectedTabs = this.checkSelectedTabs.bind(this);
    }

    componentDidMount() {
        this.updateRequests();
        this.getAllowRequestsStatus();
    }

    getAllowRequestsStatus = async () => {
        return await RequestManager.fetch(`competitions/${this.props.competitionId}`, "get").then(
            (result) => {
                let allowRequests =
                    result.body.Competition[0].isAllowedRequests == 0 ? false : true;
                this.setState({
                    allowRequests: allowRequests,
                });
            }
        );
    };

    chooseFirst(objForChoose) {
        for (let key in objForChoose) {
            if (Object.prototype.hasOwnProperty.call(objForChoose, key)) {
                return key;
            }
        }
    }

    sortAgesInCategory(objAges) {
        let ageNames = [];
        for (let ageName in objAges) {
            if (Object.prototype.hasOwnProperty.call(objAges, ageName)) {
                ageNames.push(ageName);
            }
        }
        ageNames = ageNames.sort((a, b) => {
            let re = /\d+/;
            if (+re.exec(a)[0] > +re.exec(b)[0]) {
                return 1;
            }
            return -1;
        });
        let newObjAges = {};
        for (let i = 0; i < ageNames.length; i++) {
            newObjAges[ageNames[i]] = objAges[ageNames[i]];
        }
        return newObjAges;
    }

    updateRequests() {
        this.setState({ isLoading: true });
        let competitionId = this.props.competitionId;
        RequestManager.fetch(`competitionPlans/${competitionId}`, "get")
            .then((response) => {
                let newTabs = {};
                response.body.CompetitionPlans.forEach((item) => {
                    if (newTabs[item.worldCategoryCode] === undefined) {
                        newTabs[item.worldCategoryCode] = {};
                    }
                    let ageCategory = Helpers.getAgeCategoryString(item.ageFrom, item.ageTo);
                    newTabs[item.worldCategoryCode][ageCategory] = item.competitionPlanId;
                });
                for (let category in newTabs) {
                    if (Object.prototype.hasOwnProperty.call(newTabs, category)) {
                        newTabs[category] = this.sortAgesInCategory(newTabs[category]);
                    }
                }
                this.setState({
                    tabs: newTabs,
                    isLoading: false,
                });
                this.checkSelectedTabs();
            })
            .catch((err) => {
                throw err;
            });
    }

    handleAgeSelect(event, selectedTabKey) {
        this.setState({
            selectedAge: selectedTabKey,
        });
    }

    handleCategorySelect(event, selectedTabKey) {
        this.setState({
            selectedCategory: selectedTabKey,
            selectedAge: this.chooseFirst(this.state.tabs[selectedTabKey]),
        });
    }

    checkSelectedTabs() {
        if (!this.state.selectedCategory) {
            this.setState({
                selectedCategory: this.chooseFirst(this.state.tabs),
            });
        }
        if (!this.state.selectedAge) {
            this.setState({
                selectedAge: this.chooseFirst(this.state.tabs[this.state.selectedCategory]),
            });
        }
    }

    planRequestsRender(key) {
        let planId = this.state.tabs[this.state.selectedCategory][this.state.selectedAge];
        return this.state.selectedAge === key ? (
            <PlanRequestsList
                competitionId={this.props.competitionId}
                selectedPlanId={planId}
                selectedCategories={
                    this.state.selectedCategory + "( " + this.state.selectedAge + " )"
                }
            />
        ) : (
            ""
        );
    }

    contentTabPanelRender() {
        let ageArr = this.state.tabs[this.state.selectedCategory];
        for (let i in ageArr) {
            if (this.state.selectedAge === i) {
                return (
                    <TabPanel value={i} key={i} index={i}>
                        {this.planRequestsRender(i)}
                    </TabPanel>
                );
            }
        }
    }

    ageTabPanelRender() {
        let categories = this.state.tabs;
        for (let i in categories) {
            if (this.state.selectedCategory === i) {
                return (
                    <TabPanel value={i} key={i} index={i}>
                        {this.ageRender()}
                    </TabPanel>
                );
            }
        }
    }

    ageRender() {
        if (!this.state.selectedAge) {
            return null;
        }

        let ageArr = [];
        if (this.state.tabs && this.state.selectedCategory) {
            let objCategory = this.state.tabs[this.state.selectedCategory];
            for (let key in objCategory) {
                if (Object.prototype.hasOwnProperty.call(objCategory, key)) {
                    ageArr.push(<Tab value={key} label={key} key={key}></Tab>);
                }
            }
        }

        return (
            <div>
                <Tabs
                    variant="scrollable"
                    id="requests-age-tabs"
                    onChange={this.handleAgeSelect}
                    value={this.state.selectedAge}
                    className="age-tabs"
                >
                    {ageArr}
                </Tabs>

                {this.contentTabPanelRender()}
            </div>
        );
    }

    handleSwitchChange = (event) => {
        this.setState({
            allowRequests: event.target.checked,
        });
        let allowRequests = event.target.checked ? 1 : 0;
        RequestManager.fetch(`competitions/${this.props.competitionId}/allowRequests`, "put", {
            allowRequests: allowRequests,
        }).then((result) => {
            if (!result.body.Error) {
                NotificationManager.success(this.props.t("dataUpdated"));
            } else {
                NotificationManager.error(this.props.t("updatingDataError"));
            }
        });
    };

    render() {
        if (!this.state.selectedCategory) {
            return null;
        }

        let categoryRender = [];
        if (this.state.tabs) {
            for (let key in this.state.tabs) {
                if (Object.prototype.hasOwnProperty.call(this.state.tabs, key)) {
                    categoryRender.push(<Tab value={key} index={key} label={key} key={key}></Tab>);
                }
            }
        }

        return (
            <Grid container justify="space-between">
                <Tabs
                    variant="scrollable"
                    id="requests-category-tabs"
                    onChange={this.handleCategorySelect}
                    value={this.state.selectedCategory}
                    className="category-tabs"
                    indicatorColor="primary"
                >
                    {categoryRender}
                </Tabs>

                <FormControlLabel
                    checked={this.state.allowRequests}
                    value={this.state.allowRequests}
                    onChange={this.handleSwitchChange}
                    control={<Switch color="primary" />}
                    label={this.props.t("allowAcceptRequests")}
                    labelPlacement="start"
                />

                <Grid item xs={12}>
                    {this.ageTabPanelRender()}
                </Grid>
            </Grid>
        );
    }
}

RequestsList.propTypes = {
    competitionId: PropTypes.number,
};

export default withTranslation("adminPage")(RequestsList);
