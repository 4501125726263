import React from "react";
import { List, ListItem, ListSubheader } from "@material-ui/core";
import PropTypes from "prop-types";
import RatingScale from "../../../../Common/InputControls/RatingScale";
import CriterionTypesManager from "../../../../../Modules/CriterionTypesManager";
import "./ProtocolArtistry.css";

class CriterionGroup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            group: props.criterionGroup,
            ratingValues: props.ratingValues,
        };
    }

    getCriteria(criteriaArray) {
        return criteriaArray.map((criterion, idx) => {
            const defValue =
                criterion.criterionTypeId === CriterionTypesManager.CRITERION_TYPES.penalty.id
                    ? criterion.penalty
                    : criterion.score;

            const label = `${criterion.criterionNumber}) ${criterion.criterionName} / ${criterion.criterionNameEn}`;
            return (
                <ListItem key={criterion.criterionId}>
                    <RatingScale
                        label={label}
                        values={this.state.ratingValues}
                        defaultValue={defValue}
                        onChange={(value) => this.props.onChange(value, idx)}
                    />
                </ListItem>
            );
        });
    }

    render() {
        const { group } = this.state;
        return (
            <div className="criterion-group" key={this.state.group.criterionGroupId}>
                <List
                    subheader={
                        <ListSubheader component="div">
                            {group.criterionGroupName} / {group.criterionGroupNameEn}
                            <b>({group.score.toFixed(1)})</b>
                        </ListSubheader>
                    }
                >
                    {this.getCriteria(group.criteria)}
                </List>
            </div>
        );
    }
}

CriterionGroup.propTypes = {
    criterionGroup: PropTypes.object.isRequired,
    ratingValues: PropTypes.array.isRequired,
    onChange: PropTypes.func,
};

export default CriterionGroup;
