import Enums from "./Enums";

class RefereeRoleManager {
    static ROLES = Enums.REFEREE_ROLES;

    static getRoleById(id) {
        for (let key of Object.keys(RefereeRoleManager.ROLES)) {
            const role = RefereeRoleManager.ROLES[key];

            if (role.id === id) {
                return role;
            }
        }
        return null;
    }
}

export default RefereeRoleManager;
