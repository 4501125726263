import React from "react";
import { FormControl } from "react-bootstrap";
import PropTypes from "prop-types";
import "./RatingCheckbox.css";

class RatingCheckbox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: props.value || 0,
        };

        this.checkboxHandler = this.checkboxHandler.bind(this);
    }

    checkboxHandler(event) {
        const value = event.target.checked ? this.props.worth : 0;
        this.props.onChange(value);
        this.setState({ value });
    }

    render() {
        return (
            <FormControl
                className="rating-checkbox"
                checked={this.state.value}
                type="checkbox"
                onChange={this.checkboxHandler}
            />
        );
    }
}

RatingCheckbox.propTypes = {
    value: PropTypes.number,
    worth: PropTypes.number.isRequired,
    onChange: PropTypes.func,
};

export default RatingCheckbox;
