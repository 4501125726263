import React from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import {
    Grid,
    Divider,
    Typography,
    ListItem,
    List,
    IconButton,
    Tooltip,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    LinearProgress,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import BackupIcon from "@material-ui/icons/Backup";
import RequestManager from "../../../Modules/RequestManager";
import { withSnackbar } from "notistack";
import "./MusicUploaderDialog.scss";
import { withTranslation } from "react-i18next";

const MAX_FILE_SIZE = 5242880; // size in bytes
const MAX_FILES_PER_UPLOAD = 5;
const SUPPORTED_FORMATS = ["audio/*"];

const bytesToMbytes = (bytes) => {
    return bytes / 1048576;
};

class MusicUploader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            files: [],
            isUploading: false,
            resultMessage: "",
            resultStatus: "",
        };
    }

    onDrop = (newFiles) => {
        const { files } = this.state;

        if ([...files, ...newFiles].length > MAX_FILES_PER_UPLOAD) return;

        this.setState({
            files: [...files, ...newFiles],
            resultMessage: "",
            resultStatus: "",
        });
    };

    handlerDeleteFile = (index) => {
        const { files } = this.state;

        let newFiles = files;
        newFiles.splice(index, 1);

        this.setState({
            files: newFiles,
        });
    };

    handleSubmit = async () => {
        const { files } = this.state;
        const { t } = this.props;

        const data = new FormData();
        files.forEach((file) => {
            data.append("musicFile", file);
        });

        this.setState({ isUploading: true });

        const result = await RequestManager.sendFile("trainer/music", data, {
            trainerId: this.props.data.trainerId,
        }).catch((err) => {
            this.setState({
                isUploading: false,
                resultStatus: "error",
                resultMessage: t("musicUploaderDialog.loadingErr"),
            });
            this.props.enqueueSnackbar(err.message, {
                variant: "error",
            });
        });

        if (!result) return;

        if (result.body.status == "Success") {
            this.props.enqueueSnackbar(result.body.message, {
                variant: "success",
            });
            this.setState({
                isUploading: false,
                files: [],
                resultMessage: t("musicUploaderDialog.uploadSuccess"),
                resultStatus: "success",
            });
            this.props.updateMusicList();
        } else {
            this.setState({
                isUploading: false,
                resultStatus: "error",
                resultMessage: t("musicUploaderDialog.loadingErr"),
            });
            this.props.enqueueSnackbar(result.body.message, {
                variant: "error",
            });
        }
    };

    handleClose = () => {
        this.setState({ files: [] });
        this.props.onClose();
    };

    render() {
        const { t } = this.props;
        const files = this.state.files.map((file, index) => {
            let size = bytesToMbytes(file.size);
            return (
                <ListItem key={index}>
                    <Typography>
                        <b>{index + 1}.</b> {file.path} - {size.toFixed(2)} МБ
                    </Typography>
                    <Tooltip title={t("musicUploaderDialog.delete")} arrow>
                        <IconButton onClick={() => this.handlerDeleteFile(index)}>
                            <DeleteIcon disabled={this.state.isUploading} />
                        </IconButton>
                    </Tooltip>
                </ListItem>
            );
        });
        return (
            <Dialog open={this.props.open} fullWidth maxWidth="md">
                <DialogTitle disableTypography>
                    {t("musicUploaderDialog.uploadingAudio")}
                </DialogTitle>
                <DialogContent className="upload-music-dialog">
                    <Dropzone
                        accept={SUPPORTED_FORMATS}
                        onDrop={this.onDrop}
                        maxSize={MAX_FILE_SIZE}
                        className="upload-music-dialog__dropzone"
                    >
                        {({ getRootProps, getInputProps }) => (
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    {...getRootProps({
                                        className: "upload-music-dialog__dropzone",
                                    })}
                                >
                                    <input {...getInputProps()} disabled={this.state.isUploading} />
                                    <BackupIcon
                                        className="upload-music-dialog__dropzone-icon"
                                        color="primary"
                                    />
                                    <Typography>{t("musicUploaderDialog.selectFiles")}</Typography>
                                    <Typography>
                                        {t("musicUploaderDialog.maxFileSize", {
                                            size: bytesToMbytes(MAX_FILE_SIZE),
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justify="space-between">
                                        <Typography variant="h5">
                                            {t("musicUploaderDialog.selectedFiles")}
                                        </Typography>
                                        <Typography variant="subtitle1">
                                            {t("musicUploaderDialog.maxUploadPerTime", {
                                                count: MAX_FILES_PER_UPLOAD,
                                            })}
                                        </Typography>
                                    </Grid>
                                    <List className="upload-music-dialog__files-list">{files}</List>
                                    {this.state.isUploading && (
                                        <div>
                                            <Typography variant="subtitle1">
                                                {t("musicUploaderDialog.filesLoadingWait")}
                                            </Typography>
                                            <LinearProgress />
                                        </div>
                                    )}
                                    <Divider />
                                    <div className="upload-music-dialog__actions">
                                        <Button
                                            disabled={files.length === 0 || this.state.isUploading}
                                            onClick={this.handleSubmit}
                                            className="upload-music-dialog__upload-button"
                                        >
                                            {t("musicUploaderDialog.uploadFiles")}
                                        </Button>
                                        <Typography className="upload-music-dialog__actions__text-result">
                                            <span
                                                className={`status-icon status-icon-${this.state.resultStatus}`}
                                            />
                                            {this.state.resultMessage}
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        )}
                    </Dropzone>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={this.handleClose}
                        disabled={this.state.isUploading}
                        color="primary"
                    >
                        {t("musicUploaderDialog.close")}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

MusicUploader.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    updateMusicList: PropTypes.func,
    onClose: PropTypes.func,
};

export default withSnackbar(withTranslation("trainerPage")(MusicUploader));
