import React from "react";
import PropTypes from "prop-types";
import DataTable from "../../Common/DataTable";
import "./ScoresTables.css";
import RefereeRoleManager from "../../../Modules/RefereeRoleManager";
import Helpers from "../../../Modules/Helpers";

class TotalScoresTable extends React.Component {
    getDataForTotalTable() {
        const res = {};
        res.total = {
            score: 0,
            penalty: 0,
            title: "Итого",
        };
        res.execution = {
            score: null,
            penalty: null,
            title: "Исполнение",
        };
        res.artistry = {
            score: null,
            penalty: null,
            title: "Артистичность",
        };
        res.complexity = {
            score: null,
            penalty: null,
            title: "Сложность",
        };
        res.referee = {
            score: null,
            penalty: null,
            title: "Арбитр Судей",
        };
        res.place = this.props.performance.place;
        res.totalPoints = Helpers.getNullOrFixedNumber(this.props.performance.totalPoints, 3);

        res.total = {
            score: 0,
            penalty: 0,
            title: "Итого",
        };

        for (let item of this.props.totalScores) {
            res.total.score += +item.totalScore;
            res.total.penalty += +item.totalPenalty;

            let totalScore = Helpers.getNullOrFixedNumber(item.totalScore, 3);
            let totalPenalty = Helpers.getNullOrFixedNumber(item.totalPenalty, 2);

            switch (item.refereeRoleId) {
                case RefereeRoleManager.ROLES.artistryJudge.id:
                    res.artistry.score = Helpers.getNullOrFixedNumber(totalScore, 1);
                    res.artistry.penalty = Helpers.getNullOrFixedNumber(totalPenalty, 1);
                    break;
                case RefereeRoleManager.ROLES.complexityJudge.id:
                    res.complexity.score = Helpers.getNullOrFixedNumber(totalScore, 1);
                    res.complexity.penalty = Helpers.getNullOrFixedNumber(totalPenalty, 1);
                    break;
                case RefereeRoleManager.ROLES.executionJudge.id:
                    res.execution.score = Helpers.getNullOrFixedNumber(totalScore, 1);
                    res.execution.penalty = Helpers.getNullOrFixedNumber(totalPenalty, 1);
                    break;
                case RefereeRoleManager.ROLES.referee.id:
                    res.referee.score = Helpers.getNullOrFixedNumber(totalScore, 1);
                    res.referee.penalty = Helpers.getNullOrFixedNumber(totalPenalty, 1);
                    break;
                default:
                    break;
            }
        }

        res.total.score = Helpers.getNullOrFixedNumber(res.total.score, 3);
        res.total.penalty = Helpers.getNullOrFixedNumber(res.total.penalty, 2);

        return res;
    }

    render() {
        const totalObj = this.getDataForTotalTable();

        const columns = [
            { title: "", fieldName: "title" },
            { title: "Оценка", fieldName: "score" },
            { title: "Сбавка", fieldName: "penalty" },
        ];

        const data = [
            {
                title: "Исполнение",
                score: totalObj.execution.score,
                penalty: totalObj.execution.penalty,
            },
            {
                title: "Артистичность",
                score: totalObj.artistry.score,
                penalty: totalObj.artistry.penalty,
            },
            {
                title: "Сложность",
                score: totalObj.complexity.score,
                penalty: totalObj.complexity.penalty,
            },
            {
                title: "Арбитр судей",
                score: totalObj.referee && totalObj.referee.score,
                penalty: totalObj.referee && totalObj.referee.penalty,
            },
            {
                title: <b>Итог</b>,
                score: <b>{totalObj.total.score}</b>,
                penalty: <b>{totalObj.total.penalty}</b>,
            },
        ];

        return (
            <div className="scores-table">
                <DataTable
                    columns={columns}
                    data={data}
                    uniqueFieldOfItem="title"
                    useFieldNameAsClassForColumn={true}
                />
                <div className="scores-result">
                    <p>
                        Общий балл:{" "}
                        <mark>{totalObj.totalPoints === null ? "-" : totalObj.totalPoints}</mark>
                    </p>
                    <p>
                        Текущее место: <mark>{totalObj.place === null ? "-" : totalObj.place}</mark>
                    </p>
                </div>
            </div>
        );
    }
}

TotalScoresTable.propTypes = {
    performance: PropTypes.shape({
        place: PropTypes.number,
        totalPoints: PropTypes.number,
    }),
    totalScores: PropTypes.arrayOf(
        PropTypes.shape({
            totalScore: PropTypes.number,
            totalPenalty: PropTypes.number,
            refereeRoleId: PropTypes.number,
        })
    ),
};

export default TotalScoresTable;
