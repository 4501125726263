import { TrainerReferee } from "@/interfaces";
import {
    RefereeMember,
    RefereeMemberPosition,
    TrainerRefereeSelectable,
} from "../../store/StoreModules/RefereeLotteryModule";

export const RefereeListSerializer = (referees: TrainerReferee[]): TrainerRefereeSelectable[] => {
    if (!referees) return [];

    return referees.map((el) => ({
        ...el,
        categoryReferee: el.categoryReferee || "Б/К",
        categoryFig: el.categoryFig || "Б/К",
        selected: false,
    }));
};

export function sortMembers(members: RefereeMember[]): RefereeMember[] {
    return Object.values(RefereeMemberPosition)
        .flatMap((p) => {
            const filteredMembers = members.filter((m) => m.position === p);
            return filteredMembers;
        })
        .filter((m) => m.referee);
}
