class Auth {
    static ROLES = {
        admin: 1,
        referee: 2,
        competitionManager: 3,
        trainer: 4,
        beamer: 5,
    };

    /**
     * Authenticate a user. Save a token string in Local Storage
     *
     * @param {string} token
     * @param {object} user
     * @param {object} roles
     */
    static authenticateUser(token, user) {
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
    }

    /**
     * Check if a user is authenticated - check if a token is saved in Local Storage
     *
     * @return {boolean}
     */
    static isUserAuthenticated() {
        try {
            return localStorage.getItem("token") !== null;
        } catch (err) {
            Auth.deauthenticateUser();
            return false;
        }
    }

    /**
     * Deauthenticate a user. Remove a token from Local Storage.
     *
     */
    static deauthenticateUser() {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
    }

    /**
     * Get a token value.
     *
     * @return {string}
     */
    static getToken() {
        try {
            return localStorage.getItem("token");
        } catch (err) {
            Auth.deauthenticateUser();
            return null;
        }
    }

    static getAuthUser() {
        const authUser = JSON.parse(localStorage.getItem("user"));

        if (authUser && authUser.roleIds) {
            authUser.isAdmin = authUser.roleIds.includes(Auth.ROLES.admin);
            authUser.isReferee = authUser.roleIds.includes(Auth.ROLES.referee);
            authUser.isCompetitionManager = authUser.roleIds.includes(
                Auth.ROLES.competitionManager
            );
            authUser.isTrainer = authUser.roleIds.includes(Auth.ROLES.trainer);
            authUser.isBeamer = authUser.roleIds.includes(Auth.ROLES.beamer);
        }

        return authUser;
    }

    static isUserReferee() {
        try {
            return Auth.getAuthReferee() !== null;
        } catch (err) {
            Auth.deauthenticateUser();
            return false;
        }
    }

    static isUserAdmin() {
        try {
            const authUser = Auth.getAuthUser();
            return authUser && authUser.roleIds && authUser.roleIds.indexOf(Auth.ROLES.admin) >= 0;
        } catch (err) {
            Auth.deauthenticateUser();
            return false;
        }
    }

    static isUserCompetitionManager() {
        try {
            const authUser = Auth.getAuthUser();
            return (
                authUser &&
                authUser.roleIds &&
                authUser.roleIds.indexOf(Auth.ROLES.competitionManager) >= 0
            );
        } catch (err) {
            Auth.deauthenticateUser();
            return false;
        }
    }

    static isUserTrainer() {
        try {
            const authUser = Auth.getAuthUser();
            return (
                authUser && authUser.roleIds && authUser.roleIds.indexOf(Auth.ROLES.trainer) >= 0
            );
        } catch (err) {
            Auth.deauthenticateUser();
            return false;
        }
    }
    static isUserBeamer() {
        try {
            const authUser = Auth.getAuthUser();
            return authUser && authUser.roleIds && authUser.roleIds.indexOf(Auth.ROLES.beamer) >= 0;
        } catch (err) {
            Auth.deauthenticateUser();
            return false;
        }
    }
    static getAuthReferee() {
        try {
            if (!Auth.isUserAuthenticated()) {
                return null;
            }

            const roles = JSON.parse(localStorage.getItem("user")).roles;
            if (!roles || !roles.referees || !roles.referees[0]) {
                return null;
            }

            return roles.referees[0];
        } catch (err) {
            Auth.deauthenticateUser();
            return null;
        }
    }
}

export default Auth;
