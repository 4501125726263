import React, { useState } from "react";
import { Fab, Typography, Grid, Tabs, Tab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { generatePath, useHistory } from "react-router-dom";
import CompetitionPopup from "./CompetitionPopup";
import { useTranslation } from "react-i18next";
import { competitionApiService } from "@/api/Services";
import MuiDataTable, { DataTableColumn, DataTableProps } from "../../Common/MuiDataTable";
import Auth from "@/modules/Auth";
import { useAuth, useTable } from "../../../Hooks";
import { Competition, CompetitionSearchType } from "@/interfaces";
import { useSnackbar } from "notistack";
import ContentLayout from "../../../Views/ContentLayout";

const CompetitionsList: React.FunctionComponent = () => {
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("adminPage");
    const { updateData } = useTable();
    const { user } = useAuth();

    const [type, setType] = useState<CompetitionSearchType>(CompetitionSearchType.ACTIVE);

    const [selectedCompetition, setSelectedCompetition] = useState<Competition | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen: VoidFunction = () => {
        setOpen(true);
    };

    // const params = { type, page, size: pageSize };

    // const { data, isFetching, isFetched } = useQuery(["events-competitions", params], () =>
    //     competitionApiService.getAll(params)
    // );

    const handleHide: VoidFunction = () => {
        setOpen(false);
        setSelectedCompetition(null);
    };

    const onItemClick: DataTableProps<Competition>["onRowClick"] = (e, rowData) => {
        if (!rowData) return;
        const path = Auth.isUserCompetitionManager() ? "/manager/:id" : "/admin/competitions/:id";
        history.push(generatePath(path, { id: rowData.id }));
    };

    const handleEditClick: DataTableProps<Competition>["onEditClick"] = (rowData) => {
        if (rowData instanceof Array) return;
        setSelectedCompetition(rowData);
        handleOpen();
    };

    const handleDeleteClick: DataTableProps<Competition>["onDeleteClick"] = async (rowData) => {
        try {
            const { message } = await competitionApiService.delete(rowData.id);

            enqueueSnackbar(message, {
                variant: "success",
            });
        } catch (err: any) {
            const message = err.response?.data?.message;

            enqueueSnackbar(message ?? err.message, {
                variant: "error",
            });
        }
    };

    const handleNewClick: VoidFunction = () => {
        setSelectedCompetition(null);
        handleOpen();
    };

    const handleChangeTab = (_, value) => {
        setType(value);
        updateData();
    };

    const columns: DataTableColumn<Competition>[] = [
        { title: t("competitionName"), field: "name" },
        { title: t("description"), field: "description" },
        { title: t("dateFrom"), field: "dateFrom", type: "date" },
        { title: t("dateTo"), field: "dateTo", type: "date" },
        { title: t("place"), field: "place" },
        // {
        //     title: t("referees"),
        //     field: "referees",
        //     sorting: false,
        //     grouping: false,
        //     // render: (rowData) => (
        //     //     <div>
        //     //         <div className="text-nowrap">{rowData.mainReferee}</div>
        //     //         <div className="text-nowrap">{rowData.deputy}</div>
        //     //         <div className="text-nowrap">{rowData.secretary}</div>
        //     //         <div className="text-nowrap">{rowData.secretaryDeputy}</div>
        //     //     </div>
        //     // ),
        // },
        { title: t("owner"), field: "ownerUser" },
    ];

    const filterArgs = Auth.isUserCompetitionManager() ? { ownerUserId: user.userId } : {};

    return (
        <>
            <Grid container justify="space-between" className="main-view-head">
                <Grid item>
                    <Typography variant="h4">{t("competitions")}</Typography>
                </Grid>
                {Auth.getAuthUser().isAdmin && (
                    <Grid item>
                        <Fab variant="extended" size="small" onClick={handleNewClick}>
                            <AddIcon className="extended-icon-fab-margin" />
                            {t("newCompetition")}
                        </Fab>
                    </Grid>
                )}
            </Grid>

            <Tabs
                value={type}
                onChange={handleChangeTab}
                textColor="primary"
                indicatorColor="primary"
            >
                <Tab value={CompetitionSearchType.ACTIVE} label="Актуальные" />
                <Tab value={CompetitionSearchType.ARCHIVE} label="Завершенные" />
            </Tabs>

            <ContentLayout>
                <MuiDataTable
                    fetchFn={(params) =>
                        competitionApiService.getAll({ ...params, ...filterArgs, type })
                    }
                    columns={columns}
                    onRowClick={onItemClick}
                    canEdit={Auth.getAuthUser().isAdmin}
                    canDelete={Auth.getAuthUser().isAdmin}
                    onEditClick={handleEditClick}
                    onDeleteClick={handleDeleteClick}
                />
            </ContentLayout>

            <CompetitionPopup
                modalCancel={handleHide}
                selectedCompetition={selectedCompetition}
                open={open}
                onUpdate={updateData}
            />
        </>
    );
};

export default CompetitionsList;
