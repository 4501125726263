import React, { FunctionComponent, useMemo, useState } from "react";
import { competitionApiService, competitionTrainerRefereeApiService } from "@/api/Services";
import { useQuery } from "react-query";
import Referees, {
    RefereeAddHandler,
    RefereeSelectAllHandler,
    RefereeSelectHandler,
    RefereeAssignHandler,
    RefereeDeleteHandler,
    RefereeDeleteAllHandler,
} from "./Referees";
import { Box, Fab, Grid, MenuItem, Paper, Select, Toolbar, Typography } from "@material-ui/core";
import { Competition } from "@/interfaces";
import Brigade from "./Brigade";
import ContentLayout from "../../Views/ContentLayout";
import { RefereeListSerializer, sortMembers } from "./utils";
import { useStoreon } from "storeon/react";
import {
    RefereeLotteryModuleEvents,
    RefereeLotteryModuleState,
    TrainerRefereeSelectable,
} from "../../store/StoreModules/RefereeLotteryModule";
import ReportsManager from "@/modules/ReportsManager";
import { GetApp, SwapVert } from "@material-ui/icons";

import styles from "./styles.module.scss";

const RefereeLottery: FunctionComponent = () => {
    const { referees, members, dispatch } = useStoreon<
        RefereeLotteryModuleState,
        RefereeLotteryModuleEvents
    >("referees", "members");

    const [competition, setCompetition] = useState<Competition | null>(null);

    const { data: competitions = [] } = useQuery(
        ["competitions"],
        () => competitionApiService.getAllActive({ isPublic: true }),
        { select: (data) => data.result.rows }
    );

    useQuery(
        ["competition-referees", competition?.id],
        () => competitionTrainerRefereeApiService.getAll({ competitionId: competition?.id }),
        {
            enabled: !!competition,
            onSuccess: (data) =>
                dispatch(
                    "referees/set",
                    RefereeListSerializer(data.result.rows.map((e) => e.trainerReferee))
                ),
        }
    );

    const selectedReferees = useMemo(() => referees.filter((el) => el.selected), [referees]);

    const handleChange = (e) => {
        const { value } = e.target;

        const competition = competitions?.find((el) => el.id === +value) ?? null;
        setCompetition(competition);
    };

    const handleSelectReferee: RefereeSelectHandler = (id: number, selected: boolean): void => {
        dispatch("referees/select", { id, selected });
    };

    const handleSelectAllReferees: RefereeSelectAllHandler = (_, selected: boolean): void => {
        dispatch("referees/select_all", { selected });
    };

    const handleAddReferee: RefereeAddHandler = () => {
        dispatch("referees/add", {
            id: -referees.length,
            selected: false,
        } as TrainerRefereeSelectable);
    };

    const handleDeleteReferee: RefereeDeleteHandler = (id: number) => {
        dispatch("referees/delete", {
            id,
        });
    };

    const handleDeleteAllReferees: RefereeDeleteAllHandler = () => {
        dispatch("referees/delete_all");
    };

    const handleAssignReferee: RefereeAssignHandler = (
        id: number,
        referee: TrainerRefereeSelectable | null
    ) => {
        dispatch("referees/assign", { id, referee });
    };

    const handleDraw: VoidFunction = () => {
        dispatch("members/draw");
    };

    const handleSortByCategory: VoidFunction = () => {
        dispatch("referees/sort_by_category");
    };

    const handleExport: VoidFunction = () => {
        ReportsManager.getRefereeLotteryReport({
            competitionId: competition ? competition.id : null,
            members: sortMembers(members),
        });
    };

    return (
        <ContentLayout component="section" padding={[5, 5]}>
            <Typography variant="h3" gutterBottom>
                Жеребьевка судей
            </Typography>

            <Box component={Paper} borderRadius={10} marginTop={1} marginBottom={1} padding={1}>
                <Typography variant="h6">Соревнование</Typography>
                <Select
                    name="competition"
                    fullWidth
                    onChange={handleChange}
                    displayEmpty
                    value={competition?.id || ""}
                >
                    <MenuItem key={0} value="" disabled>
                        Не выбрано
                    </MenuItem>
                    {competitions.map((el) => {
                        return (
                            <MenuItem key={el.id} value={el.id}>
                                {el.name}
                            </MenuItem>
                        );
                    })}
                </Select>
            </Box>

            <Referees
                referees={referees}
                onSortByCategory={handleSortByCategory}
                onSelect={handleSelectReferee}
                onSelectAll={handleSelectAllReferees}
                onAdd={handleAddReferee}
                onDelete={handleDeleteReferee}
                onDeleteAll={handleDeleteAllReferees}
                onAssign={handleAssignReferee}
            />

            {selectedReferees.length ? (
                <Toolbar disableGutters className={styles["fabs"]}>
                    <Fab onClick={handleDraw} color="primary" variant="extended">
                        <SwapVert /> Назначить
                    </Fab>
                    <Fab onClick={handleExport} color="primary" variant="extended">
                        <GetApp /> Экспорт в Excel
                    </Fab>
                </Toolbar>
            ) : null}

            <Box margin="2rem 0">
                <Grid container spacing={1}>
                    <Grid item xs={12} lg={6}>
                        <Brigade number={1} />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <Brigade number={2} />
                    </Grid>
                </Grid>
            </Box>
        </ContentLayout>
    );
};

export default RefereeLottery;
