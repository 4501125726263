import React from "react";
import PropTypes from "prop-types";
import { Grid, Tooltip, Typography } from "@material-ui/core";
import MuiDataTable from "../../Common/MuiDataTable";
import Helpers from "../../../Modules/Helpers";
import Auth from "../../../Modules/Auth";
import AthleteForm from "./AthleteForm";
import { withSnackbar } from "notistack";
import RequestManager from "../../../Modules/RequestManager";
import AddingAthleteByLicenseId from "./AddingAthleteByLicenseId";
import i18n from "i18next";
import { withTranslation } from "react-i18next";
import LicenseStatus from "../../../Views/LicenseStatus";

class AthletesList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            athletes: [],
            trainerId: Auth.getAuthUser().trainerId,
            isLoading: true,
            selectedAthlete: null,
        };
    }

    componentDidMount() {
        this.refreshAthletesList();
    }

    refreshAthletesList = async () => {
        const res = await RequestManager.fetch(`trainer/athletes/`, "get", {
            trainerId: this.state.trainerId,
        });

        if (res.body.data.error) this.props.enqueueSnackbar(res.body.message, { variant: "error" });

        this.setState({
            athletes: res.body.data.trainerAthletes,
            isLoading: false,
        });
    };

    deleteAthlete = async (id) => {
        return await RequestManager.fetch(`/trainer/athletes/delete/${id}`, "put");
    };

    onEditClick = (data) => {
        this.setState({
            selectedAthlete: data,
        });
        // const { athletes } = this.state;

        // athletes.forEach((item) => {
        //     if (item.id === +data.id) {
        //         this.setState({
        //             selectedAthlete: item,
        //         });
        //     }
        //     return;
        // });
    };

    onDeleteClick = async (data) => {
        const result = await this.deleteAthlete(data.id);

        if (result.body.status == "Success") {
            this.props.enqueueSnackbar(result.body.message, {
                variant: "success",
            });
            await this.refreshAthletesList();
        } else {
            this.props.enqueueSnackbar(result.body.message, {
                variant: "error",
            });
        }
    };

    onSubmitAthleteForm = async () => {
        this.setState({
            selectedAthlete: null,
        });
        await this.refreshAthletesList();
    };

    onCloseAthleteForm = () => {
        this.setState({
            selectedAthlete: null,
        });
    };

    get columns() {
        const { t } = this.props;
        this.state.athletes.map((athlete) => {
            return (athlete.fullName = Helpers.getFullNameForAthlete(athlete));
        });
        const columns = [
            {
                title: this.props.t("athletesList.fullName"),
                field: "fullName",
                render: (rowData) => (
                    <Typography variant="body2">
                        {Helpers.getFullNameForAthlete(rowData)}
                    </Typography>
                ),
                grouping: false,
            },
            {
                title: t("athletesList.dateOfBirth"),
                field: "dateOfBirth",
                type: "date",
            },
            {
                title: t("athletesList.ageCategory"),
                field: "ageCategories",
                render: (rowData) => (
                    <div>
                        {rowData.ageCategories.length
                            ? rowData.ageCategories.map((el) => <p key={el}>{el}</p>)
                            : "-"}
                    </div>
                ),
            },
            {
                title: t("athletesList.genderId"),
                field: "genderId",
                lookup: {
                    1: t("athletesList.maleLetter"),
                    2: t("athletesList.femaleLetter"),
                },
            },
            { title: t("athletesList.city"), field: "city" },
        ];
        if (i18n.language === "ru") {
            columns.splice(
                4,
                0,
                {
                    title: t("athletesList.rank"),
                    field: "rank",
                },
                {
                    title: (
                        <Tooltip title={t("athletesList.licenseStatusTooltip")}>
                            <span>{t("athletesList.licenseStatus")}</span>
                        </Tooltip>
                    ),
                    field: "licenseStatus",
                    render: (rowData) => <LicenseStatus license={rowData.license} />,
                    grouping: false,
                }
            );
            columns.push({
                title: t("athletesList.region"),
                field: "region",
            });
        }
        if (i18n.language !== "ru") {
            columns.splice(4, 0, {
                title: t("athletesList.country"),
                field: "country",
            });
            columns.push({ title: t("athletesList.club"), field: "club" });
        }
        return columns;
    }

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <Grid container justifyContent="space-between" className="main-view-head">
                    <Grid item>
                        <Typography variant="h4">{t("athletesList.mySportsmens")}</Typography>
                    </Grid>
                </Grid>
                <Grid container className="main-views" justifyContent="space-between">
                    <Grid item xs={12} lg={3}>
                        {i18n.language === "ru" && (
                            <AddingAthleteByLicenseId onUpdate={this.refreshAthletesList} />
                        )}
                        <AthleteForm
                            onSubmit={this.onSubmitAthleteForm}
                            onCancelEdit={this.onCloseAthleteForm}
                            data={{
                                athlete: this.state.selectedAthlete,
                                trainerId: this.state.trainerId,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <MuiDataTable
                            columns={this.columns}
                            data={this.state.athletes}
                            canEdit={Auth.getAuthUser().isTrainer}
                            canDelete={Auth.getAuthUser().isTrainer}
                            onEditClick={this.onEditClick}
                            onDeleteClick={this.onDeleteClick}
                            isLoading={this.state.isLoading}
                            actionsIndex={0}
                            showToolbar
                            showSearch
                        />
                    </Grid>
                </Grid>

                {this.state.confirmIsOpen && this.renderConfirmDialog()}
            </React.Fragment>
        );
    }
}

AthletesList.propTypes = {
    history: PropTypes.object,
};

export default withSnackbar(withTranslation("trainerPage")(AthletesList));
