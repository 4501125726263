import React from "react";
import { Button, Typography } from "@material-ui/core";
import RefereeRoleManager from "../../../../Modules/RefereeRoleManager";
import Helpers from "../../../../Modules/Helpers";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { PerformanceReferee } from "../../../../interfaces/performance-referee";
import { Performance } from "../../../../interfaces/performance";
import { PerformanceScore } from "../../../../interfaces/performance-score";

import "./judge-item.scss";

interface Props {
    performanceDetails: Performance;
    performanceReferee: PerformanceReferee;
    onClick: () => void;
}

const JudgeItem: React.FC<Props> = (props: Props) => {
    const { performanceReferee, performanceDetails, onClick } = props;

    const performanceScore: PerformanceScore | undefined =
        performanceReferee.performanceScores && performanceReferee.performanceScores[0];
    const { t } = useTranslation("refereePage");
    const { t: tm } = useTranslation("modules");

    let showScores;
    let showPenalties;
    switch (performanceReferee.refereeRoleId) {
        case 1: // СУДЬЯ АРТИСТИЧНОСТИ
            showScores = true;
            showPenalties = false;
            break;
        case 2: // СУДЬЯ СЛОЖНОСТИ
            showScores = true;
            showPenalties = true;
            break;
        case 3: // СУДЬЯ ИСПОЛНЕНИЯ
            showScores = true;
            showPenalties = false;
            break;
        case 4: // АРБИТР БРИГАДЫ СУДЕЙ
            showScores = true;
            showPenalties = true;
            break;
        case 5: // ГЛАВНЫЙ СУДЬЯ
            showScores = false;
            showPenalties = false;
            break;
        case 6: // судья на линии
            showScores = false;
            showPenalties = true;
            break;
        default:
            showScores = false;
            showPenalties = false;
            break;
    }

    let performanceStatusColor = "";
    switch (performanceDetails.statusId) {
        case 1: // Запланировано
            performanceStatusColor = "blue";
            break;
        case 2: // Выступает
            performanceStatusColor = "green";
            break;
        case 3: // Выступление завершено
            performanceStatusColor = "orange";
            break;
        case 4: // Дисквалификация
            performanceStatusColor = "red";
            break;
        default:
            performanceStatusColor = "";
            break;
    }

    const city = performanceDetails.competitionRequest?.city
        ? performanceDetails.competitionRequest?.city
        : performanceDetails.competitionRequest?.details &&
          performanceDetails.competitionRequest?.details[0].athlete?.city;

    const refereeName = tm(
        RefereeRoleManager.getRoleById(performanceReferee?.refereeRole?.id).refereeName
    );

    return (
        <Button className="referee_page__judge-item" onClick={onClick}>
            <div className="judge-item__header">
                <Typography variant="h6">
                    {refereeName + " (№" + performanceReferee.refereeNumber + ")"}
                </Typography>

                <div className={"performance-status " + performanceStatusColor}></div>
            </div>

            <div className="judge-item__body">
                <Typography variant="h5">
                    {performanceDetails.schedule?.plan?.category?.worldCode}{" "}
                    {performanceDetails.schedule?.plan?.ageCategory?.name}
                </Typography>
                <div className="judge-item__body_athletes">
                    {performanceDetails.competitionRequest?.details?.map((detail) => {
                        return (
                            <Typography variant="body1" key={detail.id}>
                                {Helpers.getFullNameForAthlete(detail.athlete)}
                            </Typography>
                        );
                    })}
                </div>

                <div className="judge-item__body_scores">
                    {showScores && (
                        <div className="score">
                            <Typography variant="body1">
                                {t("score")}:
                                <b>
                                    {!performanceScore?.totalScore
                                        ? "..."
                                        : performanceScore?.totalScore}
                                </b>
                            </Typography>
                        </div>
                    )}
                    {showPenalties && (
                        <div className="penalty">
                            <Typography variant="body1">
                                {t("rebate")}:
                                <b>
                                    {!performanceScore?.totalPenalty
                                        ? "..."
                                        : performanceScore?.totalPenalty}
                                </b>
                            </Typography>
                        </div>
                    )}
                </div>
            </div>

            <div className="judge-item__footer">
                <Typography variant="body1">{city}</Typography>

                <Typography variant="body1">
                    {moment(performanceDetails.schedule?.dateTime)
                        .utc()
                        .format("Do MMMM, HH:mm:ss")}
                </Typography>
            </div>
        </Button>
    );
};

export default JudgeItem;
