import React from "react";
import PropTypes from "prop-types";
import { Label } from "react-bootstrap";
import InputMask from "react-input-mask";

class SheduleLabel extends React.Component {
    /**
     * @param {{
     *   mask:string,
     *   data:string,
     *   placeholder:string,
     *   updateData:function,
     *   isCompetition: boolean,
     * }} props
     */
    constructor(props) {
        super(props);

        this.state = {
            isInput: false,
            data: props.data,
        };
    }

    _updateData(event) {
        this.props.updateData(event.target.value);
        this.setState({
            isInput: false,
            data: this.props.data,
        });
    }

    _onChange(event) {
        if (event.keyCode === 13)
            // 13 == Press key Enter
            this._updateData(event);
    }

    componentDidUpdate(newProps) {
        const oldProps = this.props;
        if (oldProps.data !== newProps.data) this.setState({ data: newProps.data });
    }

    render() {
        const labelStyle = this.props.isCompetition ? { cursor: "pointer" } : null;

        if (this.props.isCompetition && this.state.isInput)
            return (
                <InputMask
                    autoFocus
                    mask={this.props.mask}
                    name="performanceTime"
                    value={this.state.data}
                    size={this.props.mask.length}
                    placeholder={this.props.placeholder}
                    onKeyDown={this._onChange.bind(this)}
                    onBlur={this._updateData.bind(this)}
                    onChange={(event) => this.setState({ data: event.target.value })}
                />
            );
        else {
            return (
                <Label
                    style={labelStyle}
                    onClick={() => {
                        this.setState({ isInput: true });
                    }}
                >
                    {this.props.data}
                </Label>
            );
        }
    }
}

export default SheduleLabel;
