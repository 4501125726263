import React from "react";
import PropTypes from "prop-types";
import { Container, AppBar, Typography, Link as MatLink } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
import Auth from "../Modules/Auth";
import "./MobileHeader.scss";
import { withTranslation } from "react-i18next";
import i18n from "i18next";
import Utils from "../Modules/Utils";
import { Config, Paths } from "../Modules/Constants";

class MobileHeader extends React.Component {
    constructor(props) {
        super(props);
    }

    onLogoutClick = () => {
        Auth.deauthenticateUser();
        this.props.onLogout();
    };

    getMenuContent() {
        const { t } = this.props;
        const logoutLink = (
            <MatLink onClick={this.onLogoutClick} key={1}>
                <img src="/assets/nav/logout.png" />
                <p>{t("exit")}</p>
            </MatLink>
        );
        const toTrainerLink = (
            <MatLink component={Link} to="/trainer" key={2}>
                <img src="/assets/nav/trainer-min.png" />
                <p>{t("personal_trainer_account")}</p>
            </MatLink>
        );
        const toAdminLink = (
            <MatLink component={Link} to="/admin" key={3}>
                <img src="/assets/nav/admin-min.png" />
                <p>{t("control")}</p>
            </MatLink>
        );
        const toUseAJSystemLink = (
            <MatLink component={Link} to="/login" key={4}>
                <img src="/assets/nav/ajsystem-min.png" />
                <p>+ {t("use_A_J_System")}</p>
            </MatLink>
        );
        const toRefereeListLink = (
            <MatLink component={Link} to="/referee" key={5}>
                <img src="/assets/nav/referee.png" />
                <p>{t("referee_list")}</p>
            </MatLink>
        );

        const toLicenses = (
            <MatLink href={Config.LICENSES_URL} target="_blank" key={6}>
                <img src="/assets/nav/licenses-min.png" />
                <p>{t("licenses")}</p>
            </MatLink>
        );
        const toEventsClick = (
            <MatLink component={Link} to="/events" key={7}>
                <img src="/assets/nav/competitions-min.png" />
                <p>{t("competitions")}</p>
            </MatLink>
        );
        const competitionManagerLink = (
            <MatLink component={Link} to="/manager" key={8}>
                <img src="/assets/nav/competitions-min.png" />
                <p> {t("my_competitions")}</p>
            </MatLink>
        );
        const toOnlineResults = (
            <MatLink component={Link} to="/online-results" key={9}>
                <img src="/assets/nav/online-min.png" />
                <p>
                    {t("online")}
                    <br /> {t("results")}
                </p>
            </MatLink>
        );
        const toPartner = (
            <MatLink href="http://venturelli-gymnastics.ru/" target="_blank" key={10}>
                <img src="/assets/nav/venturelli-cut-min.png" />
                <p>{t("partner_venturelli")}</p>
            </MatLink>
        );

        const toBeamer = (
            <MatLink component={Link} to="/beamer" key={11}>
                <img src="/assets/nav/beamer-min.png" />
                <p>{t("beamer")}</p>
            </MatLink>
        );

        const toLottery = (
            <MatLink component={Link} to={Paths.REFEREE_LOTTERY} key={12}>
                <img src="/assets/nav/toss.png" />
                <p>{t("lottery")}</p>
            </MatLink>
        );

        const displayedLinks = [];
        const isAuth = Auth.isUserAuthenticated();
        const isAdmin = Auth.isUserAdmin();
        const isReferee = Auth.isUserReferee();
        const isCompetitionManager = Auth.isUserCompetitionManager();
        const isTrainer = Auth.isUserTrainer();
        const isBeamer = Auth.isUserBeamer();

        if (isAuth && isReferee) {
            if (isBeamer) {
                displayedLinks.push(toBeamer);
            }
            displayedLinks.push(toRefereeListLink);
            displayedLinks.push(logoutLink);

            return displayedLinks;
        }
        if (i18n.language === "ru") displayedLinks.push(toLicenses);
        displayedLinks.push(toEventsClick);
        displayedLinks.push(toLottery);

        if (isAuth) {
            if (isAdmin) {
                displayedLinks.push(toAdminLink);
            }
            if (isTrainer) {
                displayedLinks.push(toTrainerLink);
            }

            if (isCompetitionManager) {
                displayedLinks.push(competitionManagerLink);
            }
            if (isBeamer) {
                displayedLinks.push(toBeamer);
            }
            displayedLinks.push(toUseAJSystemLink);
            displayedLinks.push(toOnlineResults);
            displayedLinks.push(toPartner);
            displayedLinks.push(logoutLink);
        } else {
            displayedLinks.push(toTrainerLink);
            displayedLinks.push(toUseAJSystemLink);
            displayedLinks.push(toOnlineResults);
            displayedLinks.push(toPartner);
        }
        return displayedLinks;
    }

    render() {
        return (
            <AppBar position="static" className="nav-bar">
                <Container className="nav-bar__container">
                    <div className="app-logo">
                        <Link to="/">
                            <img src="/icon.ico" height="30" width="30" alt="Aerobic" />
                            <Typography className="logo-text" variant="h5">
                                A`J System
                            </Typography>
                        </Link>
                    </div>
                    <div className="nav-links">{this.getMenuContent()}</div>
                </Container>
            </AppBar>
        );
    }
}

MobileHeader.propTypes = {
    authUser: PropTypes.object,
    onLogout: PropTypes.func,

    // Props from router
    location: PropTypes.object,
    history: PropTypes.object,
};

export default Utils.compose(withRouter, withTranslation("header"))(MobileHeader);
