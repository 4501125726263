import React from "react";
import { Grid, Typography } from "@material-ui/core";
import RefereeRoleManager from "../../../Modules/RefereeRoleManager";
import { REFEREE_ROLES } from "../../../Modules/Enums";
import { useTranslation } from "react-i18next";
import "./referee-header.scss";
import { useStoreon } from "storeon/react";
import { RefereeModuleEvents, RefereeModuleState } from "../../../store/StoreModules/RefereeModule";

export default function RefereeHeader() {
    const { referee } = useStoreon<RefereeModuleState, RefereeModuleEvents>("referee");
    const { t, i18n } = useTranslation("refereePage");
    const { t: tm } = useTranslation("modules");

    if (!referee) return <Grid container className="referee_page__header" />;

    const roleName = tm(RefereeRoleManager.getRoleById(referee.roleId).refereeName);
    const refereeNumber = [
        REFEREE_ROLES.artistryJudge.id,
        REFEREE_ROLES.executionJudge.id,
        REFEREE_ROLES.complexityJudge.id,
    ].includes(referee.roleId)
        ? `№${referee.refereeNumber}`
        : "";

    return (
        <Grid container className="referee_page__header">
            <Typography variant="h5">
                {t("brigade")} -{" "}
                <b>
                    {i18n.language === "ru"
                        ? referee.brigadeNumber
                        : referee.brigadeNumber === 1
                        ? "A"
                        : "B"}
                </b>
            </Typography>

            <Typography variant="h5">
                {roleName} {refereeNumber}
            </Typography>
        </Grid>
    );
}
