import { TextField, TextFieldProps } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect, useMemo, useState } from "react";
import CompetitionsManager from "../../../Modules/CompetitionsManager";
import { useTranslation } from "react-i18next";

interface Props {
    textFieldProps: TextFieldProps;
    onChange: (value) => void;
    value?: number;
    [key: string]: any;
}

const CompetitionAutocomplete = ({ textFieldProps, onChange, value, ...props }: Props) => {
    const [options, setOptions] = useState([] as any[]);
    const { t } = useTranslation("adminPage");

    useEffect(() => {
        const fetch = async () => {
            const res = await CompetitionsManager.findAllCompetitions();
            setOptions(res);
        };
        fetch();
    }, []);

    const optValue = options?.find((el) => el.competitionId === value);

    return (
        <Autocomplete
            {...props}
            options={options}
            value={optValue}
            onChange={(e, value) => onChange(value.competitionId)}
            getOptionLabel={(option) => option.competitionName}
            renderInput={(params) => (
                <TextField {...params} {...textFieldProps} label={t("selectCompetition")} />
            )}
        />
    );
};

export default CompetitionAutocomplete;
