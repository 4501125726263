import React from "react";
import DataTable from "../../Common/DataTable";
import RequestManager from "../../../Modules/RequestManager";
import { Button, ButtonToolbar } from "react-bootstrap";
import TeamPopup from "./TeamPopup";

class TeamsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            teams: [],
            shown: false,
            isLoading: true,
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.modalHide = this.modalHide.bind(this);
        this.onItemClick = this.onItemClick.bind(this);
    }

    componentDidMount() {
        this.updateTeamsList();
    }

    updateTeamsList() {
        this.setState({ isLoading: true });
        RequestManager.fetch("teams", "get")
            .then((response) => {
                this.setState({
                    teams: response.body.Teams,
                    isLoading: false,
                });
            })
            .catch((err) => {
                throw err;
            });
    }

    handleOpen(e) {
        this.onItemClick(e);
        this.setState({
            shown: true,
        });
    }

    modalHide() {
        this.setState({
            shown: false,
        });
        this.updateTeamsList();
    }

    onItemClick(e) {
        if (e && e.target.getAttribute("command") === "edit") {
            const teams = this.state.teams;
            const itemId = e.target.getAttribute("itemID");
            teams.forEach((a) => {
                if (a.teamId === +itemId) {
                    this.setState({
                        selectedTeam: a,
                    });
                    return;
                }
            });
            this.setState({ shown: true });
        } else {
            this.setState({
                selectedTeam: null,
            });
        }
    }

    render() {
        const columns = [
            { title: "#", fieldName: "teamId" },
            { title: "Имя", fieldName: "teamName" },
            { title: "Город", fieldName: "city" },
            { title: "Регион", fieldName: "region" },
            { title: "Страна", fieldName: "country" },
            { title: "Тренер", fieldName: "coachName" },
        ];

        return (
            <div>
                <h4 className="pull-left">Команды</h4>
                <ButtonToolbar>
                    <Button className="pull-right" onClick={this.handleOpen}>
                        <span className="glyphicon glyphicon-user">&nbsp;</span>
                        Новая команда
                    </Button>
                </ButtonToolbar>
                <TeamPopup
                    header="Новая команда"
                    show={this.state.shown}
                    modalHide={this.modalHide}
                    selectedTeam={this.state.selectedTeam}
                />
                <DataTable
                    columns={columns}
                    data={this.state.teams}
                    uniqueFieldOfItem="teamId"
                    editable={true}
                    onItemClick={this.onItemClick}
                    isLoading={this.state.isLoading}
                />
            </div>
        );
    }
}

export default TeamsList;
