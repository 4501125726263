import BaseApiService from "@/api/Base";
import { LanguageInterceptor, TokenInterceptor } from "@/api/Http/Interceptors";
import { Settings } from "@/modules/Constants";
import { Performance } from "@/interfaces";

class PerformanceApiService extends BaseApiService<Performance> {
    constructor(config) {
        super(config);
        this.url = "/performances";
        this.http.interceptors.request.use(TokenInterceptor);
        this.http.interceptors.request.use(LanguageInterceptor);
    }

    getAllStatuses = async () => {
        const { data } = await this.http.get("/performanceStatuses");

        return data;
    };

    assignReferees = async (performaceId: number, referees: any[]) => {
        const { data } = await this.http.post(
            `${this.url}/${performaceId}/assign-referees`,
            referees
        );

        return data;
    };
}

export default new PerformanceApiService({
    baseURL: Settings.MAIN_API_URL_V2,
});
