import { competitionApiService } from "@/api/Services";
import { CompetitionSearchType } from "@/interfaces";
import { Paths } from "@/modules/Constants";
import { Grid, Tab, Tabs, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { generatePath, useHistory } from "react-router-dom";
import { usePagination, useQueryParams } from "../../../Hooks";
import CompetitionCard, { CompetitionCardProps } from "../../../Views/CompetitionCard";
import ContentLayout from "../../../Views/ContentLayout";
import Pagination from "../../Common/Pagination";

const CompetitionsList: React.FunctionComponent = () => {
    const { t } = useTranslation("common");
    const history = useHistory();
    const { type = CompetitionSearchType.ACTIVE } = useQueryParams<{
        type: CompetitionSearchType;
        page: number;
        size: number;
    }>();

    const { page, pageSize, handleChangePage } = usePagination({
        initialPageSize: 6,
    });

    const defaultParams = { type, page, size: pageSize, isPublic: true };

    const { data, isFetching, isFetched } = useQuery(["events-competitions", defaultParams], () =>
        competitionApiService.getAll(defaultParams)
    );

    const handleClick: CompetitionCardProps["onClick"] = (competition) => {
        history.push(generatePath(Paths.EVENT_DETAIL, { id: competition.id }));
    };

    const handleChangeTab = (_, value: CompetitionSearchType): void => {
        history.replace({ search: qs.stringify({ type: value }) });
    };

    return (
        <ContentLayout component="section">
            <Typography variant="h3" gutterBottom>
                {t("events")}
            </Typography>
            <Tabs
                value={type}
                onChange={handleChangeTab}
                textColor="primary"
                indicatorColor="primary"
            >
                <Tab value={CompetitionSearchType.ACTIVE} label="Актуальные" />
                <Tab value={CompetitionSearchType.ARCHIVE} label="Завершенные" />
            </Tabs>
            <ContentLayout padding={[2, 0]}>
                <Grid container spacing={2}>
                    {isFetching &&
                        [...new Array(6)].map((_, i) => (
                            <Grid key={i} item xs={12} md={4}>
                                <Skeleton width="100%" height={300} />
                            </Grid>
                        ))}
                    {isFetched && !data?.result.rows.length && (
                        <Grid item xs={12} md={4}>
                            <Typography>{t("nothingHere")}</Typography>
                        </Grid>
                    )}
                    {isFetched &&
                        data?.result.rows.map((comp) => (
                            <Grid key={comp.id} item xs={12} md={4}>
                                <CompetitionCard
                                    {...comp}
                                    onClick={handleClick}
                                    buttonText="login"
                                />
                            </Grid>
                        ))}
                </Grid>
            </ContentLayout>

            {isFetched && data && data.result.count > pageSize && (
                <Grid container justifyContent="center">
                    <Pagination
                        page={page}
                        pageSize={pageSize}
                        totalPages={Math.ceil(data.result.count / pageSize)}
                        onChangePage={handleChangePage}
                    />
                </Grid>
            )}
        </ContentLayout>
    );
};

export default CompetitionsList;
