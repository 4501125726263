import { trainerRefereeApiService } from "@/api/Services";
import { Fab, Grid, Typography } from "@material-ui/core";
import { PersonAdd } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import React, { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTable } from "../../../Hooks";
import DataTable, { DataTableColumn, OnEditClick, OnDeleteClick } from "../../Common/MuiDataTable";
import RefereesPopup from "./RefereesPopup";
import { TrainerReferee } from "@/interfaces";

const RefereesList: FunctionComponent = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("adminPage");
    const { t: tp } = useTranslation("trainerPage");
    const { updateData } = useTable();

    const [selectedAthlete, setSelectedAthlete] = useState<TrainerReferee | null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleHide = () => {
        setOpen(false);
        setSelectedAthlete(null);
    };

    const handleEditClick: OnEditClick<TrainerReferee> = (rowData) => {
        if (rowData instanceof Array) return;
        setSelectedAthlete(rowData);
        handleOpen();
    };

    const handleDeleteClick: OnDeleteClick<TrainerReferee> = async (rowData: TrainerReferee) => {
        try {
            const { message } = await trainerRefereeApiService.delete(rowData.id);

            enqueueSnackbar(message, {
                variant: "success",
            });
        } catch (err: any) {
            const message = err.response?.data?.message;

            enqueueSnackbar(message || err.message, {
                variant: "error",
            });
        }
    };

    const columns: DataTableColumn<TrainerReferee>[] = [
        { title: "#", field: "id", defaultSort: "asc" },
        { title: t("lastName"), field: "lastName" },
        { title: t("firstName"), field: "firstName" },
        { title: t("middleName"), field: "middleName" },
        { title: t("country"), field: "country" },
        { title: t("city"), field: "city" },
        { title: t("region"), field: "region" },
        { title: tp("refereesList.categoryReferee"), field: "categoryReferee" },
        { title: tp("refereesList.categoryFig"), field: "categoryFig" },
        { title: tp("refereesList.categoryInternational"), field: "categoryInternational" },
    ];

    return (
        <>
            <Grid container justifyContent="space-between" className="main-view-head">
                <Grid item>
                    <Typography variant="h4">{t("referees")}</Typography>
                </Grid>
                <Grid item>
                    <Fab variant="extended" size="small" onClick={handleOpen}>
                        <PersonAdd className="extended-icon-fab-margin" />
                        {t("newReferee")}
                    </Fab>
                </Grid>
            </Grid>

            <RefereesPopup
                open={open}
                modalHide={handleHide}
                selectedReferee={selectedAthlete}
                onUpdate={updateData}
            />

            <DataTable<TrainerReferee>
                columns={columns}
                fetchFn={trainerRefereeApiService.getAll}
                canEdit
                canDelete
                onEditClick={handleEditClick}
                onDeleteClick={handleDeleteClick}
                showToolbar
                showSearch
            />
        </>
    );
};

export default RefereesList;
