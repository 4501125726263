import { IconButton, Tooltip, Typography } from "@material-ui/core";
import { Videocam } from "@material-ui/icons";
import React from "react";
import { PERFORMANCE_STATUSES } from "../../../../../Modules/Enums";
import { useTranslation } from "react-i18next";
import { ACTIONS_BY } from "../referee-table";

const RefereeTableIndexCell = ({ actionsBy, content, shownScores, onShowScores, performance }) => {
    const handleShowScores = (e) => {
        e.stopPropagation();
        onShowScores(performance.id);
    };
    const { t } = useTranslation("refereePage");
    return (
        <div>
            <Typography>{content}</Typography>
            {actionsBy === ACTIONS_BY.Referee &&
                (performance.statusId === PERFORMANCE_STATUSES.IN_PROGRESS.id ||
                    performance.statusId === PERFORMANCE_STATUSES.COMPLETED.id) && (
                    <Tooltip title={t("showScores") || "Show scores"} arrow>
                        <IconButton
                            size="small"
                            className={`translate-scores-button ${
                                shownScores && "button--shown-scores"
                            }`}
                            onClick={handleShowScores}
                        >
                            <Videocam />
                        </IconButton>
                    </Tooltip>
                )}
        </div>
    );
};

export default RefereeTableIndexCell;
