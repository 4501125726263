import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import "./RefereeHeaderScore.css";

class RefereeHeaderScore extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isRefereeScoreHeaderFixed: false };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        document.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        document.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll() {
        if (
            document
                .getElementsByClassName("referee-score-header-container")[0]
                .getBoundingClientRect().top < 0
        ) {
            this.setState({
                isRefereeScoreHeaderFixed: true,
            });
        } else {
            this.setState({
                isRefereeScoreHeaderFixed: false,
            });
        }
    }

    render() {
        if (!this.props.isVisible) {
            return null;
        }

        return (
            <div className="referee-score-header-container">
                <div className={this.state.isRefereeScoreHeaderFixed ? "fixed-to-top" : ""}>
                    <div className={this.state.isRefereeScoreHeaderFixed ? "container" : ""}>
                        <div className="referee-score-header">
                            <div>
                                <span>{this.props.t("totalPoints")}: </span>
                                <div className="score">{this.props.totalScore}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

/*
    {penalties}
    {scores}
*/

RefereeHeaderScore.propTypes = {
    totalScore: PropTypes.number,
    isVisible: PropTypes.bool,

    // Props from router
    history: PropTypes.array,
};

export default withTranslation("refereePage")(RefereeHeaderScore);
