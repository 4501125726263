import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = ({ padding }) =>
    makeStyles((theme) => ({
        layout: {
            padding: theme.spacing(padding[0], padding[1]),
        },
    }));

type ContentLayoutProps = Partial<{
    component: React.ElementType;
    padding: [number, number];
}>;

const ContentLayout: React.FC<ContentLayoutProps> = (props) => {
    const { children, component: Component = "div", padding = [4, 0] } = props;
    const classes = useStyles({ padding })();

    return <Component className={classes.layout}>{children}</Component>;
};

export default ContentLayout;
