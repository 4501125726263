import { Button, Grid, Paper } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";

import RequestManager from "../../../Modules/RequestManager";
import TransferManager from "../../../Modules/TransferManager";
import RequestAthletes from "./RequestAthletes";
import { useTranslation } from "react-i18next";

const RequestItem = ({ competitionRequestId }) => {
    const [loading, setLoading] = useState(true);
    const [requestAthletes, setRequestAthletes] = useState(null);
    const [tranfered, setTransfered] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("adminPage");
    const targetId = localStorage.getItem("competition_target");

    useEffect(() => {
        const fetch = async () => {
            const res = await RequestManager.getAthletesByCompetitionRequestId(
                competitionRequestId
            );

            setRequestAthletes(res);
            setLoading(false);
        };
        fetch();
    }, [competitionRequestId]);

    const handleTransferClick = useCallback(async () => {
        if (!targetId) return;
        try {
            await TransferManager.transferCompetitionRequest(competitionRequestId, +targetId);
            setTransfered(true);
            enqueueSnackbar(t("appTransferSuccess"), {
                variant: "success",
            });
        } catch (err) {
            enqueueSnackbar(t("appTransferError"), { variant: "error" });
        }
    }, [targetId, competitionRequestId, enqueueSnackbar]);

    if (loading) return null;

    return (
        <Paper>
            <Grid container alignItems="center" alignContent="center">
                <Grid item xs={10}>
                    <RequestAthletes requestAthletes={requestAthletes} />
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleTransferClick}
                        disabled={tranfered || !targetId}
                    >
                        {t("transfer")}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default RequestItem;
