import React, { useState, useEffect } from "react";
import { CompetitionPlan } from "../../../interfaces/competition-plan";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Button,
    Container,
} from "@material-ui/core";
import RefereeTable, { ACTIONS_BY } from "../components/referee-table/referee-table";
import { EVENTS } from "../../../Modules/Referee/Events";
import ProtocolModal from "../components/protocols/protocol-modal";
import { Performance } from "../../../interfaces/performance";
import { PerformanceReferee } from "../../../interfaces/performance-referee";
import { PERFORMANCE_STATUSES, PLAN_STATUSES } from "../../../Modules/Enums";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useStoreon } from "storeon/react";
import { RefereeModuleEvents, RefereeModuleState } from "../../../store/StoreModules/RefereeModule";

export default function RefereeView() {
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation("refereePage");
    const [activeSignalArrows, setActiveSignalArrows] = useState<any[]>([]);

    const [modalShow, setModalShow] = useState<boolean>(false);

    const { plans, selectedPlan, performances, socket, referee, error, dispatch } = useStoreon<
        RefereeModuleState,
        RefereeModuleEvents
    >("plans", "selectedPlan", "performances", "socket", "referee", "error");

    const [performanceInformation, setPerformanceInformation] = useState({
        performanceDetails: {} as Performance,
        performanceReferee: {} as PerformanceReferee,
        onlyCriterionGroups: [] as number[],
    });

    useEffect((): any => {
        socket?.on(EVENTS.REFEREE_RESPONDED_SIGNAL, (refereeId) => {
            const newActiveSignalArrows = [...activeSignalArrows];
            newActiveSignalArrows[refereeId] = null;

            setActiveSignalArrows(newActiveSignalArrows);
        });

        return () => socket?.removeListener(EVENTS.REFEREE_RESPONDED_SIGNAL);
    }, [activeSignalArrows]);

    useEffect(() => {
        if (!error) return;
        enqueueSnackbar(<h5>{error}</h5>, {
            variant: "error",
        });
    }, [error]);

    const handleChangePerformanceStatus = async (performanceId, statusId) => {
        if (!selectedPlan || !referee) return;

        let passed = true;
        performances.forEach((performance: Performance) => {
            if (performance.id !== performanceId)
                if (performance.statusId === PERFORMANCE_STATUSES.IN_PROGRESS.id) passed = false;
        });

        if (!passed) return;

        socket?.emit(EVENTS.REFEREE_CHANGE_PERFORMANCE_STATUS, performanceId, statusId);

        if (statusId === PERFORMANCE_STATUSES.COMPLETED.id) {
            socket?.emit(EVENTS.REFEREE_CALCULATE_PLACES, selectedPlan.id, referee.brigadeNumber);
        }

        setActiveSignalArrows([]);
    };

    const handleEditProtocol = (
        performanceId: number,
        refereeId: number,
        onlyCriterionGroups?: number[]
    ) => {
        performances.forEach((performance: Performance) => {
            if (performance.id === performanceId) {
                const performanceDetails = performance;
                if (performanceDetails.schedule) {
                    performanceDetails.schedule.plan = plans[0];
                }

                performance.performanceReferees?.forEach(
                    (performanceRefereeItem: PerformanceReferee) => {
                        if (performanceRefereeItem.refereeId === refereeId) {
                            setPerformanceInformation({
                                ...performanceInformation,
                                performanceDetails,
                                performanceReferee: performanceRefereeItem,
                                onlyCriterionGroups: onlyCriterionGroups || [],
                            });

                            return;
                        }
                    }
                );
                return;
            }
        });

        setModalShow(true);
    };

    const handleChangeCompetitionPlan = (e) => {
        const competitionPlanId = e.target.value;

        socket?.emit(EVENTS.REFEREE_CHANGE_PLAN, competitionPlanId);
        dispatch(
            "referee/set/selected_plan",
            plans.find((item) => item.id === competitionPlanId) || ({} as CompetitionPlan)
        );

        setActiveSignalArrows([]);
    };

    const handleCalculatePlaces = (e) => {
        if (!selectedPlan || !referee) return;

        socket?.emit(EVENTS.REFEREE_CALCULATE_PLACES, selectedPlan.id, referee.brigadeNumber);
        enqueueSnackbar(<h5>{t("placeCountDone")}</h5>, {
            variant: "success",
        });
    };

    const handleSendSignalToJudge = (refereeId: number, value: "up" | "down") => {
        const msg =
            value === "up" ? (
                <h5>{t("changeGradeSignal", { text: t("raise") })}</h5>
            ) : (
                <h5>
                    {t("changeGradeSignal", {
                        text: t("downgrade"),
                    })}
                </h5>
            );

        socket?.emit(EVENTS.REFEREE_SEND_SIGNAL, refereeId, value);
        enqueueSnackbar(msg, {
            variant: "info",
        });

        const newActiveSignalArrows = [...activeSignalArrows];
        newActiveSignalArrows[refereeId] = value;

        setActiveSignalArrows(newActiveSignalArrows);
    };

    const handleShowScores = (performanceId: number) => {
        socket?.emit(EVENTS.REFEREE_SHOW_SCORES, performanceId);
        enqueueSnackbar(<h5>{t("gradesShown")}</h5>, {
            variant: "success",
        });
    };

    const handleChangeCompetitionPlanStatus = (e) => {
        if (!selectedPlan) return;

        const competitionPlanStatusId = e.target.value;

        socket?.emit(EVENTS.REFEREE_CHANGE_PLAN_STATUS, selectedPlan.id, competitionPlanStatusId);

        enqueueSnackbar(<h5>{t("planStatusChanged")}</h5>, {
            variant: "info",
        });
    };

    return (
        <div>
            {selectedPlan ? (
                <Container>
                    <Grid container justify="space-between" alignItems="center">
                        <Grid item xs={12} md={5}>
                            <FormControl fullWidth>
                                <InputLabel>{t("selectPlan")}</InputLabel>
                                <Select
                                    value={selectedPlan.id}
                                    onChange={handleChangeCompetitionPlan}
                                >
                                    {plans.map((plan: CompetitionPlan) => {
                                        return (
                                            <MenuItem key={plan.id} value={plan.id}>
                                                {plan.category?.worldCode} {plan.ageCategory?.name}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={5} md={3}>
                            <FormControl fullWidth>
                                <InputLabel>{t("selectPlanStatus")}</InputLabel>

                                <Select
                                    value={selectedPlan.statusId}
                                    onChange={handleChangeCompetitionPlanStatus}
                                >
                                    {PLAN_STATUSES.map((item) => {
                                        return (
                                            <MenuItem key={item.id} value={item.id}>
                                                {t(`modules:${item.locale}`)}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Button
                                fullWidth
                                color="primary"
                                onClick={handleCalculatePlaces}
                                variant="contained"
                            >
                                {t("countPlaces")}
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            ) : null}
            <RefereeTable
                actionsBy={ACTIONS_BY.Referee}
                performances={performances}
                onChangePerformanceStatus={handleChangePerformanceStatus}
                onEditPerformance={handleEditProtocol}
                onSendSignal={handleSendSignalToJudge}
                activeSignalArrows={activeSignalArrows}
                onShowScores={handleShowScores}
            />
            {modalShow && referee && (
                <ProtocolModal
                    show={modalShow}
                    onClose={() => setModalShow(false)}
                    referee={referee}
                    performanceDetails={performanceInformation.performanceDetails}
                    performanceReferee={performanceInformation.performanceReferee}
                    onlyCriterionGroups={performanceInformation.onlyCriterionGroups}
                />
            )}
        </div>
    );
}
