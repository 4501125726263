import BaseApiService from "@/api/Base";
import { TokenInterceptor } from "@/api/Http/Interceptors";
import { Settings } from "@/modules/Constants";
import { AgeCategory } from "../../../interfaces/age-category";

class AgeCategoryApiService extends BaseApiService<AgeCategory> {
    constructor(config) {
        super(config);
        this.url = "/age-categories";
        this.http.interceptors.request.use(TokenInterceptor);
    }
}

export default new AgeCategoryApiService({
    baseURL: Settings.MAIN_API_URL_V2,
});
