import React, { FunctionComponent } from "react";
import { TableHead, TableRow, TableCell, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useStoreon } from "storeon/react";
import {
    RefereeModuleEvents,
    RefereeModuleState,
} from "../../../../store/StoreModules/RefereeModule";
import { BrigadeVariant } from "../../../../interfaces/competition";

const RefereeTableHead: FunctionComponent = () => {
    const { t } = useTranslation("refereePage");
    const { competition } = useStoreon<RefereeModuleState, RefereeModuleEvents>("competition");

    const brigadeVariant: BrigadeVariant = competition?.brigadeVariant ?? BrigadeVariant.DEFAULT;

    const colSpan: number = brigadeVariant === BrigadeVariant.DEFAULT ? 5 : 7;
    const categoryRefereesCount: number = brigadeVariant === BrigadeVariant.DEFAULT ? 4 : 6;

    const createColumns = (length: number, baseName: string) =>
        [...new Array(length)].map((_, i) => ({
            name: `${baseName}${i + 1}`,
            i18n_name: "",
        }));

    const columns = [
        ...createColumns(categoryRefereesCount, "E"),
        { name: "Ср", i18n_name: "" },
        ...createColumns(categoryRefereesCount, "A"),
        { name: "Ср", i18n_name: "" },
        ...createColumns(2, "D"),
        { name: "Ср", i18n_name: "" },
        // "АЛ",
        // "АС",
        { name: "Слож", i18n_name: "Dif" },
        { name: "Арб", i18n_name: "Cjp" },
        { name: "Лин", i18n_name: "Line" },
        // "Ср",
    ];

    return (
        <TableHead>
            <TableRow>
                <TableCell rowSpan={2} className="line-number">
                    #
                </TableCell>
                <TableCell rowSpan={2}>{t("participant")}</TableCell>
                <TableCell rowSpan={2}>{t("region")}</TableCell>
                <TableCell colSpan={colSpan} align="center">
                    Исполнение
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className="cell-head-title--i18n"
                    >
                        Execution
                    </Typography>
                </TableCell>
                <TableCell colSpan={colSpan} align="center">
                    Артистизм
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className="cell-head-title--i18n"
                    >
                        Artistic
                    </Typography>
                </TableCell>
                <TableCell colSpan={3} align="center">
                    Сложность
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className="cell-head-title--i18n"
                    >
                        Difficulty
                    </Typography>
                </TableCell>
                <TableCell colSpan={3} align="center">
                    Сбавки
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className="cell-head-title--i18n"
                    >
                        Deduction
                    </Typography>
                </TableCell>
                <TableCell rowSpan={2} className="detailed-scores-column">
                    Общий балл
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className="cell-head-title--i18n"
                    >
                        Total
                    </Typography>
                </TableCell>
                <TableCell rowSpan={2} className="detailed-scores-column">
                    Место
                    <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        className="cell-head-title--i18n"
                    >
                        Place
                    </Typography>
                </TableCell>
            </TableRow>
            <TableRow>
                {columns.map(({ name, i18n_name }, index) => (
                    <TableCell
                        style={{ top: "55px" }}
                        key={index}
                        className={`detailed-scores-column ${
                            name === "Ср" && "styled-cell-header"
                        }`}
                    >
                        {name}
                        {i18n_name && (
                            <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                className="cell-head-title--i18n"
                            >
                                {i18n_name}
                            </Typography>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default React.memo(RefereeTableHead);
