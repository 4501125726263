import React from "react";
import MainGrid from "../Controls/TrainerPage/MainGrid";
import { withAuth } from "../Hoc";
import Auth from "../Modules/Auth";

const TrainerPage: React.FunctionComponent = () => {
    return <MainGrid />;
};

export default withAuth(TrainerPage, [Auth.ROLES.trainer], "/login");
