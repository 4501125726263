import RequestManager from "./RequestManager";
/**
 * Module for user authorization
 */
class LoginManager {
    /**
     * User login
     * @param {object} user
     * @return {Promise}   returns response as Promise
     */
    static login(user) {
        return RequestManager.fetch("login", "POST", user, true);
    }
}

export default LoginManager;
